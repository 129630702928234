import toast from "react-hot-toast";

function errorHandler(error) {
  // console.log(error);
  if (error.response) {
    const response = error?.response || {};
    const data = response?.data || {};
    const error_keys = Object.keys(data || {});

    const status = response.status;

    if (status === 400) {
      if (typeof error?.response?.data === "string") {
        return toast.error(error?.response?.data);
      }
    }

    if (status === 401) {
      sessionStorage.clear();
      return (window.location.href = "/login");
      // return toast.error("Unauthorized");
    }

    if (status === 404) return toast.error("Error 404: Request not found.");

    const first_error_message = data[error_keys[0]] || [
      "Something went wrong, Please retry",
    ];

    return toast.error(
      (error_keys[0] || "Error") + ": " + first_error_message ||
        "Something went wrong, Please retry."
    );
  }

  // return toast.error("Something went wrong, Please retry.");
}

export default errorHandler;
