import React, { useState, useEffect, useMemo } from "react";
import { Table } from "../table";
import moment from "moment";
import { Link } from "react-router-dom";
import { ReactComponent as RightArrow } from "../../assets/svgs/arrow-right-purple.svg";
import toast from "react-hot-toast";
import DotsButton from "../general/DotsButton";
import { v4 } from "uuid";
import numeral from "numeral";
import { get } from "../../api/transport";
import { useAdminTransactionList, useAdminTransactions } from "../admin/broker";

export const PaymentTable = () => {
  const { data, isLoading } = useAdminTransactionList("desc", 5, 0);

  const tableData = useMemo(() => data?.results || [], [data]);

  const COLUMNS = [
    {
      Header: "ID",
      id: "id",
      Cell: ({ value = v4() }) => {
        const [first_partition] = value.split("-");
        return first_partition.toUpperCase();
      },
    },
    {
      Header: "COMPANY",
      accessor: "company",
      Cell: ({ value }) => {
        return `${value?.name || "Echo Feedback"}`;
      },
    },
    {
      Header: "PLAN",
      accessor: "billing_type",
      Cell: ({ value }) => {
        return `${value + "ly"}`;
      },
    },
    {
      Header: "PAYMENT FOR  (FROM – TO)",
      accessor: "payment_for",
      Cell: data => {
        const { billing_type, date_published } = data?.row.original;

        let start_date = moment(date_published);
        let is_month = billing_type.toLowerCase() === "month";

        if (start_date) {
          return `${start_date.format("MMM DD, YYYY")} – ${start_date
            .add(1, is_month ? "M" : "Y")
            .format("MMM DD, YYYY")}`;
        }

        return `Jul 7, 2021 – Aug 7, 2021`;
      },
    },
    {
      Header: "AMOUNT",
      accessor: "amount_paid",
      Cell: ({ value }) => {
        return `₵${numeral(Number(value) / 100).format("0,0.00")}`;
      },
    },
    {
      Header: "STATUS",
      accessor: "status",
      // Cell: ({ value }) => {
      //   return value === "Approved" ? "Success" : "Failed";
      // },
    },
    {
      Header: "PAYMENT DATE",
      accessor: "date_published",
      Cell: ({ value = moment() }) => moment(value).format("MMM D, YYYY"),
    },
    // {
    //   id: "actions",
    //   Cell: () => <DotsButton disabled={true} />,
    // },
  ];

  return (
    <div className="flex flex-column grow">
      <div className="flex justify-between align-center p-v-1">
        <h4 className="m-0">Payments</h4>

        <div className="flex align-center pointer">
          <Link
            className="m-0 bold text-decoration-none purple"
            to="/dashboard/transactions/"
            style={{
              fontSize: "medium",
            }}
          >
            VIEW ALL
          </Link>
          <RightArrow />
        </div>
      </div>
      <Table
        loading={isLoading}
        columns={COLUMNS}
        emptyRenderer
        data={tableData}
        // className="flex flex-column grow"
      />
    </div>
  );
};
