import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import bg from "../../assets/pngs/purple-bg.png";
import { ReactComponent as Logo } from "../../assets/svgs/logo.svg";
import { ReactComponent as Vector5 } from "../../assets/svgs/vector5.svg";
import Walkthrough from "./Walkthrough";

const Sidebar = ({ currentStep, bottomMenu }) => {
  return (
    <StyledSidebar>
      <div className="sidebar-content">
        <div className="logo-container">
          <Logo fill={"#ffffff"} className="logo" />
        </div>

        <div className="text">
          <p id="title">
            Start taking customer <br /> feedback <>&mdash;</> easily.
          </p>
          <p id="subtitle">Get your account setup in just a few steps</p>
        </div>

        <div className="walkthrough-container">
          <Walkthrough currentStep={currentStep} />
        </div>

        {bottomMenu && (
          <div className="sidebar-foot">
            <div>
              Have an account?{" "}
              <Link to="/login" className="sidebar-foot-span">
                Login
              </Link>{" "}
            </div>
            <>&rarr;</>
          </div>
        )}
      </div>
      <Vector5 className="curved-vector" />
    </StyledSidebar>
  );
};

export default Sidebar;

const StyledSidebar = styled.div`
  flex-basis: 25%;
  display: flex;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  position: relative;
  z-index: 0;
  background-image: url(${bg});
  overflow: hidden;
  max-height: 100vh;
  overflow: hidden;

  @media (max-width: 1024px) {
    display: none;
    flex-basis: 0%;
  }

  .sidebar-content {
    display: flex;
    flex: 1;
    flex-direction: column;
    z-index: 2;

    .logo-container {
      padding: 3.8em 4em;

      .logo {
        width: 78.68px;
        height: 32.04px;
      }
    }

    .text {
      padding: 0 4em;

      #title {
        font-family: CircularStdBold;
        font-size: 1.2em;
        margin: 0;
      }

      #subtitle {
        font-size: 1em;
        margin: 1em 0 0 0;
      }
    }

    .walkthrough-container {
      padding: 1.5em 4em;
    }

    .sidebar-foot {
      background: #000000;
      margin-top: auto;
      padding: 2em 4em;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .sidebar-foot-span {
        font-family: CircularStdBold !important;
        color: #ffffff;
        text-decoration: none;
      }
    }
  }

  .curved-vector {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 85%;
    z-index: 0;
  }
`;
