import React, { useState, useRef } from "react";
import styled from "styled-components";
import { Colors } from "../../constants";
import { TextButton } from "../general";
import { ChromePicker } from "react-color";
import { useOutsideDetect } from "../../selectors/UseOutsideDetect";

const ColorPallete = ({ title, setColorProp, selectedColor }) => {
  const default_color_list = [
    Colors.white,
    Colors.yellow,
    Colors.purple,
    Colors.black,
  ];
  const [, setSelected] = useState();
  const [showAltColor, setShowAltColor] = useState(false);
  const [altColor, setAltColor] = useState("#f1f1f1");
  const [showPallete, setShowPallette] = useState(false);
  const pickerRef = useRef();

  const handleChange = color => {
    // console.log(color.hex);
    setAltColor(color.hex);
    setSelected(color.hex);
    setColorProp(color.hex);
  };

  useOutsideDetect(pickerRef, () => setShowPallette(false));

  return (
    <>
      <p className="m-0">{title}</p>
      <StyledColorPallete>
        <div style={{ display: "flex", alignItems: "center" }}>
          {default_color_list.map(el => (
            <Color
              key={Math.random()}
              color={el}
              selected={selectedColor === el}
              onClick={() => {
                setShowAltColor(false);
                setSelected(el);
                setColorProp(el);
              }}
            />
          ))}

          {true && (
            <div className="relative">
              <Color
                onClick={() => {
                  setShowPallette(!showPallete);
                }}
                color={altColor}
                selected={selectedColor === altColor}
              />
              <div
                ref={pickerRef}
                className="absolute"
                style={{ left: "110%", bottom: 0 }}
              >
                {showPallete && (
                  <ChromePicker color={altColor} onChange={handleChange} />
                )}
              </div>
            </div>
          )}
        </div>

        {!showAltColor && (
          <TextButton
            styleProp={{
              padding: ".5em",
            }}
            text="Use a Different Color"
            onClick={() => {
              setShowAltColor(!showAltColor);
              setShowPallette(true);
              setColorProp(altColor);
            }}
          />
        )}
      </StyledColorPallete>
    </>
  );
};

export default ColorPallete;

const StyledColorPallete = styled.div`
  margin: 0.5em 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

function Color({ onClick = () => {}, selected, color }) {
  return (
    <StyledColorOption onClick={onClick} selected={selected} color={color}>
      <div id="color"></div>
    </StyledColorOption>
  );
}

export { Color };

const StyledColorOption = styled.div`
  display: flex;
  border: 2px solid
    ${props => `rgba(114, 0, 76, ${props.selected ? `1` : `.2`})`};
  padding: 0.1em;
  height: 1.5em;
  width: 1.5em;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 5px;

  #color {
    border-radius: 50%;
    background: ${props => props.color || Colors.yellow};
    padding: 0.6em;
  }
`;
