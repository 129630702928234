import { useMutation, useQuery, useQueryClient } from "react-query";
import { delete_request, get, patch, post } from "../../api/transport";
import { useAppContext } from "../../context";
import errorHandler from "../../selectors/errorHandler";

export const defaultOptions = {
  // refetchOnWindowFocus: false,
  refetchOnmount: true,
  refetchOnReconnect: true,
  retry: false,
  staleTime: 86400000,
};

export const useDevices = (limit, offset, search_term = "") => {
  const { appState } = useAppContext();

  const queryKey = ["devices", limit, offset, search_term];

  const queryFn = () =>
    get(`/user/tablet/?limit=${limit}&offset=${offset}&search=${search_term}`);

  const select = response => response.data;

  const options = { select, ...defaultOptions };

  return useQuery(queryKey, queryFn, options);
};

export const useNumberDevices = () => {
  const queryKey = "devices_count";

  const queryFn = () => get("/user/package/");

  const select = ({ data }) => {
    return data?.results?.[0]?.total_number_tablets || 0;
  };

  const options = { select, ...defaultOptions };

  return useQuery(queryKey, queryFn, options);
};

export const useDeleteDevice = id => {
  const queryClient = useQueryClient();

  const queryFn = () => delete_request(`/user/tablet/${id}/`);

  const onSuccess = () => queryClient.invalidateQueries();

  const onError = error => errorHandler(error);

  const options = { onSuccess, onError };

  return useMutation(queryFn, options);
};

export const useCreateDevice = () => {
  const queryClient = useQueryClient();

  const queryFn = data => post(`/user/tablet/`, data);

  const onSuccess = () => queryClient.invalidateQueries();

  const onError = error => errorHandler(error);

  const options = { onSuccess, onError };

  return useMutation(queryFn, options);
};

export const useUpdateDevice = id => {
  const queryClient = useQueryClient();

  const queryFn = data => patch(`/user/tablet/${id}/`, data);

  const onSuccess = () => queryClient.invalidateQueries();

  const onError = error => errorHandler(error);

  const options = { onSuccess, onError };

  return useMutation(queryFn, options);
};
