import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { useCallback, useState } from "react";

const PhoneNumberInput = props => {
  const { value, setValue, placeholder = "Enter phone number" } = props;

  const handleChange = useCallback(
    value => {
      setValue(value);
    },
    [setValue]
  );

  return (
    <PhoneInput
      defaultCountry="GH"
      placeholder={placeholder}
      value={value}
      onChange={handleChange}
      className="border-none"
    />
  );
};

export { PhoneNumberInput };
