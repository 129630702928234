import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Modal from "react-modal";
import { Colors } from "../../constants";
import { ReactComponent as Close } from "../../assets/svgs/close.svg";
import { GenericButton } from "../general/Button";
import colors from "../../constants/colors";
import { v4 as uuid } from "uuid";
import axios from "axios";
import { useAppContext } from "../../context";
import toast from "react-hot-toast";
import errorHandler from "../../selectors/errorHandler";
import AutoCompleteInput from "../general/AutoCompleteInput";
import { post } from "../../api/transport";
import { useCreateBranch } from "../branches/broker";

const AddBranchModal = props => {
  const { mutateAsync: createBranch } = useCreateBranch();
  const [branches, setBranches] = useState([
    {
      id: uuid(),
      name: "",
      location_name: "",
      status: "Approved",
      is_active: true,
    },
  ]);

  const [loading, setLoading] = useState(false);

  const addBranchField = () => {
    // const last_branch = branches[branches.length - 1];
    if (
      branches.every(
        branch => branch.name.length !== 0 && branch.location_name.length !== 0
      )
    ) {
      setBranches([
        ...branches,
        {
          id: uuid(),
          name: "",
          location_name: "",
          status: "Approved",
          is_active: true,
        },
      ]);
    }
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) Modal.setAppElement("body");
    return () => (mounted = false);
  }, []);

  return (
    <Modal
      style={{
        overlay: {
          background: "rgba(0,0,0,0.7)",
        },
        content: {
          background: Colors.white,
          margin: "auto auto",
          width: "35%",
          height: "70%",
        },
      }}
      isOpen={props.isOpen}
      onRequestClose={props.closeModal}
    >
      <AddBranchModalContent>
        <div className="close-button-area">
          <Close id="close-button" onClick={props.closeModal} />
        </div>

        <div className="flex grow flex-column">
          <div className="flex flex-column">
            <div className="m-b-1">
              <h2 className="m-0 m-b-p5">Add Company Branch</h2>
              <p className="m-0" style={{ color: "#696969" }}>
                Add at least one branch to continue
              </p>
            </div>

            <div className="flex flex-column">
              <div className="flex flex-column">
                {branches.map(branch => {
                  return (
                    <React.Fragment key={branch.id}>
                      <div className="form-row">
                        <div className="input-field">
                          <div>
                            <label>
                              {branches.indexOf(branch) + 1}. Branch Name
                            </label>
                          </div>
                          <input
                            type="text"
                            value={branch.name}
                            onChange={event => {
                              const newBranches = branches.map(stateBranch => {
                                if (stateBranch.id === branch.id) {
                                  return {
                                    ...branch,
                                    name: event.target.value,
                                  };
                                }
                                return stateBranch;
                              });
                              setBranches(newBranches);
                            }}
                            placeholder="Eg. Greater Accra"
                          />
                        </div>
                        <div className="input-field relative">
                          <div className="bordered-0 flex justify-between align-center">
                            <label style={{ display: "inline" }}>
                              Location
                            </label>
                            {branches.length > 1 && (
                              <label
                                onClick={() => {
                                  setBranches(
                                    branches.filter(el => el.id !== branch.id)
                                  );
                                }}
                                style={{ display: "inline" }}
                                className="gray pointer"
                              >
                                - Remove Branch
                              </label>
                            )}
                          </div>
                          <AutoCompleteInput
                            placeholder="Eg. Agyia Zongo, Accra, Ghana"
                            onChange={value => {
                              setBranches(
                                branches.map(stateBranch => {
                                  if (stateBranch.id === branch.id)
                                    return {
                                      ...branch,
                                      location_name: value,
                                    };
                                  return stateBranch;
                                })
                              );
                            }}
                          />
                        </div>
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>

              <div className="bordered-0 flex justify-end m-v-1">
                <GenericButton
                  onClick={addBranchField}
                  styleProp={{
                    background: colors.white,
                    color: colors.purple,
                    border: `1.5px solid ${colors.purple}`,
                    textAlign: "center",
                  }}
                >
                  <p>+ Add Another Branch</p>
                </GenericButton>
              </div>
            </div>
          </div>
          <div className="flex justify-center align-center m-v-1 m-t-auto">
            <GenericButton
              // onClick={saveBranches}
              onClick={saveBranches}
              loading={loading}
              disabled={
                branches[branches.length - 1].name.length == 0 ||
                branches[branches.length - 1].location_name.length == 0
              }
            >
              <p>Continue</p>
            </GenericButton>
          </div>
        </div>
      </AddBranchModalContent>
    </Modal>
  );

  function saveBranches() {
    setLoading(true);
    toast.loading("Please wait ");

    // post(
    //   "/user/branch/",
    // branches.map(el => {
    //   const { id, ...branch } = el;
    //   return branch;
    // })
    // )
    createBranch(
      branches.map(el => {
        const { id, ...branch } = el;
        return branch;
      })
    )
      .then(response => {
        setLoading(false);
        setBranches([
          {
            id: uuid(),
            name: "",
            location_name: "",
            status: "Approved",
            is_active: true,
          },
        ]);
        toast.success("Saved");
        props.closeModal();
      })
      .catch(error => {
        setLoading(false);
        errorHandler(error);
      });
  }
};

export default AddBranchModal;

const AddBranchModalContent = styled.div`
  // border: 1px solid;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 100%;

  .close-button-area {
    display: flex;
    flex-direction: row-reverse;
    // ;

    #close-button {
      // border: 1px solid;
      height: 0.5em;
      width: 0.5em;
      cursor: pointer;
    }
  }
`;
