import React, { useContext } from "react";
import styled from "styled-components";
import { Head, PageTitle, Foot } from "../../components/account-creation";
import { Form } from "../../components/account-creation/choose-subscription/";
import { useHistory } from "react-router-dom";
import { Button } from "../../components/general";
import { AppContext } from "../../context";
import { ReactComponent as Logo } from "../../assets/svgs/logo-ellipsis.svg";
import StepsAlt from "../../components/account-creation/StepsAlt";
import toast from "react-hot-toast";

const CompanyInformation = () => {
  const { appState } = useContext(AppContext);
  let history = useHistory();

  return (
    <StyledCompanyInformation>
      <div className="no-show-responsive">
        <Head back={true} currentStep={3} />
      </div>
      <div className="show-responsive">
        <Logo />
        <StepsAlt currentStep={3} />
      </div>
      <PageTitle
        title="Subscription"
        subTitle="How many devices are you subscribing for??"
      />
      <Form />
      <div className="flex justify-end">
        <Button
          onClick={handleClick}
          disabled={appState.subscription.device_count < 1}
        />
      </div>
    </StyledCompanyInformation>
  );

  function handleClick() {
    const user_subscription_info = sessionStorage.getItem(
      "echo_user_subscription_info"
    );

    if (user_subscription_info) {
      const parsed_subscription_info = JSON.parse(user_subscription_info);

      if (parsed_subscription_info?.subscription_id) {
        history.push("/account-setup/make-payment");
      } else {
        toast.error("Add at least one device to continue");
      }
    }
  }
};

export default CompanyInformation;

const StyledCompanyInformation = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  @media (max-width: 1024px) {
    .no-show-responsive {
      display: none;
    }

    .show-responsive {
      display: initial;
    }
  }

  @media (min-width: 1024px) {
    .no-show-responsive {
      display: initial;
    }

    .show-responsive {
      display: none;
    }
  }
`;
