import React, { useEffect, useRef, useState } from "react";
import { country_codes } from "../../assets/mock/CountryCodes";
import { useOutsideDetect } from "../../selectors/UseOutsideDetect";
import colors from "../../constants/colors";

export const CountryCodeDropdown = props => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [, setSelectedCountryCode] = useState("233");
  const SheetRef = useRef();
  const ParentRef = useRef();

  useOutsideDetect(SheetRef, () => setShowDropdown(false));

  const refs = country_codes.reduce((acc, value) => {
    acc[value.code] = React.createRef();
    return acc;
  }, {});

  const handleClick = id =>
    refs[id]?.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });

  const handleKeyDown = event => {
    const regex = /^[A-Za-z]+$/;

    // validate key press is alphabetic
    if (regex.test(event.key)) {
      //   console.log(event.key, "alphabet");
      const { code } =
        country_codes.find(
          country_code => country_code.name.toLowerCase()[0] === event.key
        ) || {};
      handleClick(code);
      //   console.log(code);
    } else {
      //   non-alphabetic
      void 0;
    }
  };

  useEffect(() => {
    let mounted = true;
    if (mounted && showDropdown) {
      SheetRef.current.focus();
    }
    return () => (mounted = false);
  }, [showDropdown]);

  return (
    <div
      onKeyPress={handleKeyDown}
      className="phone-input-selector-section"
      ref={ParentRef}
      onClick={() => {
        setShowDropdown(!showDropdown);
      }}
    >
      +{props.selectedCountryCode}
      <ul
        ref={SheetRef}
        tabIndex={0}
        autoFocus={true}
        className={`absolute bg-w r-8 shadow ${
          props.position === "down" ? "drop-down" : "drop-up"
        }`}
        style={{
          left: "-40%",
          maxHeight: "250px",
          overflow: "auto",
          listStyleType: "none",
          padding: 0,
          zIndex: 1,
          maxWidth: "90vw",
          overflowX: "scroll",
        }}
      >
        {showDropdown &&
          country_codes.map((country_code, idx) => {
            return (
              <li
                ref={refs[country_code.code]}
                className={`p-1 country-code-dropdown ${
                  country_code.code === props.selectedCountryCode && `selected`
                } ${idx % 2 === 0 && `bg-gray`}`}
                key={Math.random()}
                style={{ width: "100%" }}
                onClick={() => {
                  handleClick(country_code.code);
                  setSelectedCountryCode(country_code.code);
                  props.setSelectedCountryCode(country_code.code);
                  setShowDropdown(false);
                }}
              >
                <p>{country_code.name}</p>
              </li>
            );
          })}
      </ul>
    </div>
  );
};
