import React from "react";
import ContentGeneric from "../Content";
import { AccountInformation } from "../../../page-content/account-creation";

const Content = () => {
  return (
    <ContentGeneric>
      <AccountInformation />
    </ContentGeneric>
  );
};

export default Content;
