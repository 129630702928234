import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Modal from "react-modal";
import { Colors } from "../../constants";
import { ReactComponent as Close } from "../../assets/svgs/close.svg";
import { GenericButton } from "../general/Button";
import { useAppContext } from "../../context";
import toast from "react-hot-toast";
import errorHandler from "../../selectors/errorHandler";
import AutoCompleteInput from "../general/AutoCompleteInput";
import { patch } from "../../api/transport";
import { useUpdateCompanyBranch } from "../admin/broker";

const EditBranchModal = props => {
  const { fetchToken, appState } = useAppContext();
  const [loading, setLoading] = useState(false);

  const { mutateAsync, isLoading } = useUpdateCompanyBranch(props.branch.id);
  const [branch_name, set_branch_name] = useState(props.branch.name);
  const [branch_location_name, set_branch_location_name] = useState(
    props.branch.location_name
  );

  useEffect(() => {
    let mounted = true;
    if (mounted) Modal.setAppElement("body");
    return () => (mounted = false);
  }, []);
  // console.log(branches);

  return (
    <Modal
      style={{
        overlay: {
          background: "rgba(0,0,0,0.7)",
        },
        content: {
          background: Colors.white,
          margin: "auto auto",
          width: "35%",
          height: "30%",
        },
      }}
      isOpen={props.isOpen}
      onRequestClose={props.closeModal}
    >
      <EditBranchModalContent>
        <div className="close-button-area">
          <Close id="close-button" onClick={props.closeModal} />
        </div>

        <div className="flex grow flex-column">
          <div className="flex flex-column">
            <div className="m-b-1">
              <h2 className="m-0 m-b-p5">Update Company Branch</h2>
            </div>

            <div className="flex flex-column">
              <div className="flex flex-column">
                <div className="form-row">
                  <div className="input-field">
                    <div>
                      <label>Branch Name</label>
                    </div>
                    <input
                      type="text"
                      value={branch_name}
                      onChange={({ target }) => set_branch_name(target.value)}
                      placeholder="Eg. Greater Accra"
                    />
                  </div>
                  <div className="input-field relative">
                    <div className="bordered-0 flex justify-between align-center">
                      <label style={{ display: "inline" }}>Location Name</label>
                    </div>
                    <AutoCompleteInput
                      placeholder="Eg. Agyia Zongo, Accra, Ghana"
                      onChange={value => set_branch_location_name(value)}
                      defaultValue={props.branch.location_name}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center align-center m-v-1 m-t-auto">
            <GenericButton
              onClick={saveBranch}
              // loading={isLoading}
              disabled={
                isLoading ||
                branch_name?.length === 0 ||
                branch_location_name?.length === 0
              }
            >
              <p>{isLoading ? "Please wait" : "Continue"}</p>
            </GenericButton>
          </div>
        </div>
      </EditBranchModalContent>
    </Modal>
  );

  async function saveBranch() {
    setLoading(true);
    toast.loading("Please wait");
    const patch_data = {
      name: branch_name,
      location_name: branch_location_name,
      status: "Approved",
    };

    try {
      await mutateAsync(patch_data);
      props.closeModal();
      toast.success("Saved");
    } catch (error) {
      errorHandler(error);
    }
  }
};

export default EditBranchModal;

const EditBranchModalContent = styled.div`
  // border: 1px solid;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 100%;

  .close-button-area {
    display: flex;
    flex-direction: row-reverse;

    #close-button {
      // border: 1px solid;
      height: 0.5em;
      width: 0.5em;
      cursor: pointer;
    }
  }
`;
