import React from "react";
import styled from "styled-components";
import { Colors } from "../../constants";
import { ReactComponent as Analytics } from "../../assets/svgs/analytics.svg";

const Button = ({
  Icon = Analytics,
  text = "Continue",
  onClick = () => {},
  background = Colors.purple,
  className,
}) => {
  return (
    <StyledButton
      background={background}
      onClick={onClick}
      className={className}
    >
      <Icon style={{ marginRight: "5px" }} />
      <p>{text}</p>
    </StyledButton>
  );
};

export default Button;

const StyledButton = styled.div`
  background-color: ${props => props.background};
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  color: #ffffff;
  cursor: pointer;
  padding: 0.6em 1.5em;
  margin-left: 0.5em;

  p {
    font-family: CircularStd;
    margin: 0;
    font-size: 14px;
  }
`;
