import React from "react";
import { Layout } from "../components/dashboard";
import { DevicesTable } from "../components/devices/DevicesTable";
import { DocumentHeader } from "../components/general/DocumentHeader";

const Devices = () => {
  return (
    <Layout>
      <DocumentHeader page="Devices" />
      <DevicesTable />
    </Layout>
  );
};

export { Devices };
