import React from "react";
import styled from "styled-components";
import { ReactComponent as Ellipse } from "../../assets/svgs/ellipse.svg";
import { Colors } from "../../constants";
import { useAppContext } from "../../context";

const NotificationItem = () => {
  const { appState } = useAppContext();
  return (
    <StyledNotificationItem>
      <div className="notification-body">
        <div className="notification-title">
          <Ellipse style={{ marginRight: "10px" }} />
          <p style={{ fontFamily: "CircularStdBold", fontSize: ".8em" }}>
            Welcome!
          </p>
        </div>
        <div className="notification-preview">
          <p>
            Hi {appState.profile?.first_name} {appState.profile?.last_name}.{" "}
            Welcome to the Echo Administrator Dashboard.
          </p>
        </div>
      </div>
    </StyledNotificationItem>
  );
};

export default NotificationItem;

const StyledNotificationItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 450px;
  padding: 0 1.5em;
  transition: 0.5s ease;

  .notification-body {
    display: flex;
    flex-direction: column;
    flex: 1;
    border-bottom: 1px solid #f5f3f3;

    .notification-title {
      padding: 10px 0 5px 0;
      display: flex;
    }

    .notification-preview {
      padding: 5px 0 10px 0;

      p {
        font-size: 0.7em;
        color: #696969;
      }
    }
  }

  &:first-child {
    padding-top: 1em;
  }

  &:last-child {
    .notification-body {
      border: 0;
      padding-bottom: 1em;
    }
  }

  &:hover {
    background: ${props => Colors.white_2};
  }
`;
