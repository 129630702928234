import React, { useState } from "react";
import { StyledForm } from "../../components/account-creation/account-information/Form";
import { Layout } from "../../components/dashboard";
import { Tabs } from "../../components/settings/Tabs";
// import { CountryCodeDropdown } from "../../components/general/CountryCodeDropdown";
import { TextButton } from "../../components/general";
import colors from "../../constants/colors";
// import axios from "axios";
// import { useAppContext } from "../../context";
import toast from "react-hot-toast";
import errorHandler from "../../selectors/errorHandler";
import { post } from "../../api/transport";
import { DocumentHeader } from "../../components/general/DocumentHeader";

const ChangePassword = () => {
  // const { fetchToken } = useAppContext();

  const [current_password, set_current_password] = useState("");
  const [new_password, set_new_password] = useState("");
  const [confirm_password, set_confirm_password] = useState("");

  const [show_current_password, set_show_current_password] = useState(false);
  const [show_new_password, set_show_new_password] = useState(false);
  const [show_confirm_password, set_show_confirm_password] = useState(false);

  const [submit_loading, set_submit_loading] = useState(false);

  const [errors, set_errors] = React.useState({
    current_password: [],
    new_password: [],
    confirm_password: [],
  });

  function validatePassword(passwordArg) {
    if (passwordArg === "") {
      set_new_password(passwordArg);
      return set_errors({
        ...errors,
        new_password: ["Password can not be empty", ...errors.new_password],
      });
    } else if (passwordArg.length < 8) {
      set_new_password(passwordArg);
      return set_errors({
        ...errors,
        new_password: ["Password is too short", ...errors.new_password],
      });
    } else if (!passwordArg.match(/\d+/g)) {
      set_new_password(passwordArg);
      return set_errors({
        ...errors,
        new_password: [
          "Password must include a number",
          ...errors.new_password,
        ],
      });
    } else if (passwordArg === current_password) {
      set_new_password(passwordArg);
      return set_errors({
        ...errors,
        new_password: [
          "New password cannot be the same as current password.",
          ...errors.new_password,
        ],
      });
    }

    set_errors({
      ...errors,
      new_password: [],
    });
    return set_new_password(passwordArg);
  }

  function validatePasswordConfirm(confirmPassword) {
    if (confirmPassword === "") {
      set_confirm_password(confirmPassword);
      return set_errors({
        ...errors,
        confirm_password: [
          "Field can not be empty",
          ...errors.confirm_password,
        ],
      });
    } else if (new_password !== confirmPassword) {
      set_confirm_password(confirmPassword);
      return set_errors({
        ...errors,
        confirm_password: [
          "Passwords do not match",
          ...errors.confirm_password,
        ],
      });
    }

    set_errors({
      ...errors,
      confirm_password: [],
    });
    set_confirm_password(confirmPassword);
  }

  function clearChanges() {
    set_current_password("");
    set_new_password("");
    set_confirm_password("");
  }

  return (
    <Layout tabs={Tabs}>
      <DocumentHeader page="Settings" />
      <div className="flex flex-row">
        <div className="flex bordered-0 flex-basis-50 flex-column">
          <h4 className="m-0 m-b-1">Change Password</h4>

          <StyledForm className="bg-w">
            <div className="row p-h-2">
              <div className="input-field">
                <label>Password</label>
                <div className="relative">
                  <input
                    className="pseudo-input"
                    value={current_password}
                    onChange={({ target }) =>
                      set_current_password(target.value)
                    }
                    type={show_current_password ? "text" : "password"}
                    placeholder="Enter Current password"
                    required={true}
                  />
                  <div
                    onClick={() =>
                      set_show_current_password(!show_current_password)
                    }
                    className="pointer flex grow justify-center align-center absolute"
                    style={{ right: "2%", top: "30%" }}
                  >
                    <p className="m-0 bold purple">
                      {show_current_password ? "HIDE" : "SHOW"}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row p-h-2">
              <div className="input-field">
                <label>New Password</label>
                <div className="relative">
                  <input
                    className="pseudo-input"
                    value={new_password}
                    onChange={({ target }) => validatePassword(target.value)}
                    type={show_new_password ? "text" : "password"}
                    placeholder="Enter New password"
                    required={true}
                  />
                  <div
                    onClick={() => set_show_new_password(!show_new_password)}
                    className="pointer flex grow justify-center align-center absolute"
                    style={{ right: "2%", top: "30%" }}
                  >
                    <p className="m-0 bold purple">
                      {show_new_password ? "HIDE" : "SHOW"}
                    </p>
                  </div>
                </div>
                {errors.new_password[0] && (
                  <p className="error-msg">{errors.new_password[0]}</p>
                )}
              </div>
            </div>

            <div className="row p-h-2">
              <div className="input-field ">
                <label>Confirm New Password</label>
                <div className="relative">
                  <input
                    className="pseudo-input"
                    value={confirm_password}
                    onChange={({ target }) =>
                      validatePasswordConfirm(target.value)
                    }
                    type={show_confirm_password ? "text" : "password"}
                    placeholder="Confirm New password"
                    required={true}
                  />
                  <div
                    onClick={() =>
                      set_show_confirm_password(!show_confirm_password)
                    }
                    className="pointer flex grow justify-center align-center absolute"
                    style={{ right: "2%", top: "30%" }}
                  >
                    <p className="m-0 bold purple">
                      {show_confirm_password ? "HIDE" : "SHOW"}
                    </p>
                  </div>
                </div>
                {errors.confirm_password[0] && (
                  <p className="error-msg">{errors.confirm_password[0]}</p>
                )}
              </div>
            </div>

            <div className="row m-h-2 bordered-0 flex jc-flex-end">
              <div className="flex flex-basis-30">
                <TextButton
                  loading={submit_loading}
                  className="grow m-0"
                  text="Change Password"
                  styleProp={{
                    padding: "1em",
                    background: colors.purple,
                    color: "white",
                  }}
                  onClick={e => {
                    e.preventDefault();
                    resetPassword();
                  }}
                />
              </div>
            </div>
          </StyledForm>
        </div>
      </div>
    </Layout>
  );

  function resetPassword() {
    set_submit_loading(true);

    const data = {
      new_password,
      current_password,
    };

    // axios
    //   .post(
    //     "https://echofeed-web-app.df.r.appspot.com/api/auth-token/users/set_password/",
    //     ,
    //     { headers: { Authorization: `Token ${fetchToken()}` } }
    //   )
    post("/auth-token/users/set_password/", data)
      .then(response => {
        set_submit_loading(false);
        toast.success("Password Changed Successfully");
        console.log(response);
        clearChanges();
      })
      .catch(error => {
        set_submit_loading(false);
        errorHandler(error);
      });
  }
};

export { ChangePassword };
