import React from "react";
import { Colors } from "../constants";
import { ReactComponent as Logo } from "../assets/svgs/logo-ellipsis.svg";
import { TextButton } from "./general";
import styled from "styled-components";

const BreakpointScreen = () => {
  return (
    <StyledBreakpointScreen>
      <div className="bordered flex flex-column grow p-2 justify-between align-center">
        <div
          className="flex flex-column align-center"
          style={{ margin: "auto auto" }}
        >
          <Logo />
          <h4 className="center">Visit on Desktop</h4>
          <p className="center">
            This page can only be viewed on a desktop device. Visit on your
            computer to continue.{" "}
          </p>
          <TextButton
            text="Go Back Home"
            onClick={() => (window.location.href = "https://echofeed.co")}
            styleProp={{
              padding: ".5em",
              color: Colors.white,
              background: Colors.purple,
            }}
          />
        </div>
      </div>
    </StyledBreakpointScreen>
  );
};

export default BreakpointScreen;

const StyledBreakpointScreen = styled.div`
  @media (min-width: 1024px) {
    display: none;
  }

  @media (max-width: 1023px) {
    display: flex;
    flex: 1;
    width: 100vw;
    height: 100vh;
  }
`;
