import React from "react";
import { Helmet } from "react-helmet";

export const DocumentHeader = props => {
  const { page = "" } = props;
  return (
    <Helmet>
      <title>{page} - Dashboard | Echo Feedback App</title>
      <meta
        name="description"
        content="Start taking customer feedbacks — easily."
      />
    </Helmet>
  );
};
