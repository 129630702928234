import React from "react";
import styled from "styled-components";
import Router from "./Router";

const AppRouter = () => {
  return (
    <StyledApp>
      <Router />
    </StyledApp>
  );
};

const StyledApp = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  flex: 1;
`;

export default AppRouter;
