import React from "react";
import styled from "styled-components";
import { Colors } from "../../constants";
import { PageTitle } from "../account-creation";
import { ReactComponent as Triangle } from "../../assets/svgs/triangle-down.svg";
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis } from "recharts";
import moment from "moment";
import { subDays } from "date-fns";

const OverallFeedbacks = () => {
  return (
    <StyledOverallFeedbacks>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "10px 0",
        }}
      >
        <PageTitle titleSize={"20px"} title="Analytics" />
        <FilterButton />
      </div>
      <AreaChartSection />
    </StyledOverallFeedbacks>
  );
};

export default OverallFeedbacks;

const StyledOverallFeedbacks = styled.div`
  p {
    margin: 0;
  }
`;

function FilterButton() {
  return (
    <div
      style={{
        border: "1px solid " + Colors.purple,
        padding: ".5em",
        borderRadius: "8px",
        color: Colors.purple,
        cursor: "pointer",
        fontSize: "10px",
      }}
    >
      This Month
      <Triangle fill={Colors.purple} style={{ marginLeft: ".5em" }} />
    </div>
  );
}

function AreaChartSection() {
  return (
    <div
      style={{
        border: "1px solid #F5F3F3",
        display: "flex",
        flexDirection: "column",
        background: "#fff",
        borderRadius: "8px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "20px 0 0 20px",
        }}
      >
        <p style={{ fontSize: "24px", fontFamily: "CircularStdBold" }}>
          1,258,823
        </p>
        <p style={{ color: Colors.grey_2 }}>Overall Feedbacks</p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "200px",
        }}
      >
        <AreaChartComponent />
      </div>
    </div>
  );
}

function AreaChartComponent() {
  const data = [];
  for (let num = 60; num >= 0; num--) {
    data.push({
      date: moment(subDays(new Date(), num)).format("MMM D"),
      value: 0.2 + Math.random() * 0.5,
    });
  }
  return (
    <ResponsiveContainer width={"100%"} height={"100%"}>
      <AreaChart data={data}>
        <Area
          dataKey="value"
          data={data}
          stroke={Colors.purple}
          fill={Colors.yellow}
        />
        <XAxis
          dataKey="date"
          axisLine={false}
          tickLine={false}
          style={{ fontSize: "9px" }}
        />
        <Tooltip cursor={false} content={CustomTooltip} />
      </AreaChart>
    </ResponsiveContainer>
  );
}

// import React from "react";
// import { Area, AreaChart, ResponsiveContainer, Tooltip } from "recharts";
// import { Colors } from "../../constants";
// import moment from "moment";

// const AreaChartComponent = () => {

//   return (
//     <ResponsiveContainer width={"100%"} height={"100%"}>
//       <AreaChart data={data}>
//         <Area dataKey={"value"} stroke={Colors.purple} fill={Colors.yellow} />
//       </AreaChart>
//     </ResponsiveContainer>
//   );
// };

// export default AreaChartComponent;

function CustomTooltip({ active, payload, label }) {
  console.log(payload, "payload");
  if (active) {
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: Colors.purple,
          color: Colors.white,
          padding: "5px",
          border: "1px solid #cccc",
          borderRadius: "4px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <label>{payload[0].payload.date}</label>
      </div>
    );
  }

  return null;
}
