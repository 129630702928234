// import axios from "axios";
import React, { /*useEffect,*/ useState } from "react";
import { v4 } from "uuid";
import { Layout } from "../components/dashboard";
import { EmojiTextButton, TextButton } from "../components/general";
// import { useAppContext } from "../context";
import errorHandler from "../selectors/errorHandler";
import DotsButton from "../components/general/DotsButton";
import Lottie from "react-lottie";
import animationData from "../assets/lottie/loader.json";
import lottieDefaultConfig from "../selectors/lottieDefaultConfig";
import colors from "../constants/colors";
import ConfirmModal from "../components/modal/ConfirmModal";
import toast from "react-hot-toast";
// import { delete_request, get, post } from "../api/transport";
import { DocumentHeader } from "../components/general/DocumentHeader";
import {
  useCreateIndustry,
  useDeleteIndustry,
  useIndustries,
} from "../components/admin/broker";

const SuperAdminIndustries = () => {
  const { data: industries = [], isLoading } = useIndustries();
  const { mutateAsync, /*isLoading: createIndustryLoading*/ } = useCreateIndustry();
  const { mutateAsync: deleteAsync, isLoading: deleteIndustryLoading } =
    useDeleteIndustry();

  const [selected_industries, set_selected_industries] = useState([]);
  const [show_add_industry, set_show_add_industry] = useState(false);

  const [new_industry_name, set_new_industry_name] = useState("");

  let defaultOptions = { ...lottieDefaultConfig, animationData };

  function saveIndustry() {
    if (new_industry_name.length === 0)
      return toast.error("Enter Industry Name");

    // set_save_loading(false);
    toast.loading("Saving");

    const industries_data = new_industry_name?.split(",").map(el => ({
      is_active: true,
      title: el.trim(),
      active: true,
    }));

    mutateAsync(industries_data?.[0])
      .then(response => {
        toast.success("Success");
        // set_industries([...industries, response.data]);
        set_new_industry_name("");
        set_show_add_industry(false);
      })
      .catch(error => {
        errorHandler(error);
      });
  }

  const deleteIndustry = id => {
    toast.loading("Deleting");

    deleteAsync(id)
      .then(() => {
        toast.success("Success");
      })
      .catch(error => {
        errorHandler(error);
      });
  };

  const deleteBulk = async () => {
    try {
      await selected_industries.forEach(async element => {
        await deleteAsync(element.id);
      });
      set_selected_industries([]);
      toast.success("Deleted Successfully");
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <Layout>
      <DocumentHeader page="Industries" />
      <div className="flex grow bordered-0 gap-4">
        <div className="flex flex-column flex-basis-40 bordered-0 ">
          <div className="flex bordered-0 justify-between align-center m-b-1">
            <h3 className="m-0">Industries ({industries?.length})</h3>
            <div className="flex align-center gap-half">
              {selected_industries.length > 0 && (
                <TextButton
                  styleProp={{
                    background: "white",
                    color: "red",
                    borderColor: "red",
                  }}
                  className="bg-purple p-v-p5 p-h-2"
                  text={
                    deleteIndustryLoading ? "Please wait" : "Delete Selected"
                  }
                  onClick={deleteBulk}
                  disabled={deleteIndustryLoading}
                />
              )}

              <EmojiTextButton.Custom
                backgroundColor={colors.purple}
                color="white"
                margin="0 0 0 .5em"
                text="Add Industry"
                padding=".8em 2em"
                onClick={() => set_show_add_industry(!show_add_industry)}
              />
            </div>
          </div>

          <div
            className="grow bg-w r-8 p-1 scroll flex flex-column"
            style={{
              maxHeight: "75vh",
            }}
          >
            {isLoading && (
              <div className="grow bordered-0 flex justify-center align-center">
                <Lottie options={defaultOptions} height={80} width={100} />
              </div>
            )}
            {isLoading ||
              industries
                ?.sort(
                  (a, b) => new Date(b.created_at) - new Date(a.created_at)
                )
                .map(el => {
                  let actions = [
                    {
                      title: "Delete",
                      style: { color: "red" },
                      onClick: async () => {
                        const result = await ConfirmModal.show({
                          text: `Are you sure you want to delete ${el.title}?`,
                        });

                        if (result) {
                          await deleteIndustry(el.id);
                        }
                      },
                    },
                  ];

                  return (
                    <div
                      key={v4()}
                      className="flex justify-between align-center border-bottom-gray-2 p-1"
                    >
                      <div className="flex align-center gap-1">
                        <div className="checkbox-wrapper">
                          <input
                            type="checkbox"
                            id={el.uuid}
                            name={el.uuid}
                            checked={selected_industries.find(
                              industry => industry.uuid === el.uuid
                            )}
                            onChange={event => {
                              if (
                                selected_industries.find(
                                  industry => industry.uuid === el.uuid
                                )
                              ) {
                                return set_selected_industries(
                                  selected_industries.filter(
                                    industry => industry.uuid !== el.uuid
                                  )
                                );
                              }

                              set_selected_industries([
                                ...selected_industries,
                                el,
                              ]);
                            }}
                          />
                        </div>
                        <p className="m-0 gray">{el.title}</p>
                      </div>
                      <DotsButton actions={actions} />
                    </div>
                  );
                })}
          </div>
        </div>

        {show_add_industry && (
          <div className="grow">
            <div className="flex justify-between align-center m-b-1">
              <h3 className="m-0">Add Industry</h3>
            </div>

            <div className="bg-w p-2">
              <div className="input-field">
                <label className="bold">Enter Industry</label>
                <input
                  value={new_industry_name}
                  onChange={({ target }) => set_new_industry_name(target.value)}
                  placeholder="Type industry here..."
                />
                <p className="meta m-v-1">
                  {/* * You can enter multiple industries. Please seperate each
                  industry with a comma “,” */}
                </p>
              </div>

              <div className="m-t-2">
                <TextButton
                  styleProp={{ background: colors.purple, color: "white" }}
                  className="bg-purple p-v-p5 p-h-2"
                  text="Save"
                  onClick={saveIndustry}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default SuperAdminIndustries;
