import _ from "lodash";
import React from "react";
import { Colors } from "../../constants";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import styled from "styled-components";

export const Filters = ({ filters = [] }) => {
  const is_empty = _.isEmpty(filters);
  return (
    <div className="flex align-center gap-half">
      {is_empty ||
        filters?.map(el => {
          return <Tile {...el} key={Math.random()} />;
        })}
      {is_empty && (
        <>
          <Tile title="Today" key={Math.random()} />
          <Tile title="Week" />
          <Tile title="Month" />
          <Tile title="Year" />
          <Tile title="All Time" />
        </>
      )}
    </div>
  );
};

const StyledTile = styled.div`
  border-radius: 5px;
  border: 1px solid;
  padding: 5px 8px;
  font-size: 13px;
  cursor: pointer;

  input.date-range-picker {
    text-align: center;
    font-weight: bold;
    border-radius: 5px;

    padding: 0;
    border: none;
    font-family: CircularStd;
    width: fit-content;
  }
`;

const Tile = ({ title, active, type = "primary", ...rest }) => {
  const { onChange = () => {}, selected = "" } = rest;

  const renderContent = () => {
    if (!type || type === "primary")
      return (
        <span>
          <p className="m-0">{title}</p>
        </span>
      );

    if (type === "date")
      return (
        <DatePicker
          {...rest}
          onChange={date => {
            onChange(date);
          }}
          selected={selected}
          className={`date-range-picker ${
            active ? "white bg-purple" : "purple bg-transparent"
          }`}
        />
      );
  };

  return (
    <StyledTile
      {...rest}
      className={`${active ? "white bg-purple" : "purple bg-transparent"}`}
    >
      {renderContent()}
    </StyledTile>
  );
};
