import React, { useEffect, useState, useMemo } from "react";
import { Table } from "../table";
import moment from "moment";
import { Colors } from "../../constants";
import { EmojiTextButton } from "../general";
import Searchbar from "../dashboard/Searchbar";
import { ReactComponent as Plus } from "../../assets/svgs/plus.svg";
import DotsButton from "../general/DotsButton";
import { AddBranchModal } from "../modal";
import ConfirmModal from "../modal/ConfirmModal";
import { useAppContext } from "../../context";
import toast from "react-hot-toast";
import EditBranchModal from "../modal/EditBranch";
import errorHandler from "../../selectors/errorHandler";
import { delete_request, get } from "../../api/transport";
import { useBranches, useDeleteBranch } from "./broker";
import Paginator from "../table/Paginator";

export const BranchesTable = props => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [search_term, set_search_term] = useState("");

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);

  const { data, isLoading } = useBranches(limit, offset);
  const branches = data?.results || [];

  useEffect(() => {
    let mounted = true;
    if (mounted && props.openModal && !modalIsOpen) {
      setModalIsOpen(true);
    }
    return () => (mounted = false);
  }, [props.openModal]);

  const COLUMNS = [
    {
      Header: "BRANCH ID",
      accessor: "uuid",
      Cell: ({ value }) => {
        const [first_partition] = value.split("-");
        return first_partition.toUpperCase();
      },
    },
    { Header: "BRANCH", accessor: "name" },
    {
      Header: "LOCATION",
      accessor: "location_name",
      // Cell: ({ value }) => value || "Greater Accra, Ghana",
    },
    {
      Header: "TOTAL DEVICES",
      accessor: "total_device",
      // Cell: ({ value }) => Math.ceil(Math.random() * 100),
    },
    {
      Header: "DATE ADDED",
      accessor: "date_published",
      Cell: ({ value }) => moment(value).format("MMM D, YYYY, hh:mm a"),
    },
    {
      accessor: "id",
      id: "action",
      Cell: data => {
        const [open, setOpen] = useState(false);
        const { value } = data;
        const branch = data?.row?.original;
        const { mutate } = useDeleteBranch(value);

        async function deleteBranch() {
          try {
            let result = await ConfirmModal.show({
              text: "Are you sure you want to delete this branch?",
            });
            if (result) {
              toast.loading("Deleting Branch...");
              mutate();
            }
          } catch (error) {
            errorHandler(error);
          }
        }

        function editBranch() {
          setOpen(true);
        }

        return (
          <div className="flex justify-end align-center">
            <DotsButton
              editfunction={editBranch}
              deletefunction={deleteBranch}
            />
            <EditBranchModal
              isOpen={open}
              closeModal={() => setOpen(false)}
              branch={branch}
              branches={branches}
              // setBranches={setBranches}
            />
          </div>
        );
      },
    },
  ];

  const filterBranches = (branches, search_term) => {
    const filtered_list = [...branches].filter(branch => {
      const name_search = branch.name
        .toLowerCase()
        .includes(search_term.toLowerCase());
      const location_name_search = branch.location_name
        ?.toLowerCase()
        .includes(search_term.toLowerCase());
      return name_search || location_name_search;
    });
    return filtered_list;
  };

  const tableData = useMemo(
    () => filterBranches(branches, search_term).reverse(),
    [data, search_term, filterBranches]
  );

  return (
    <div
      className="flex grow flex-column"
      style={{ maxHeight: "90vh", overflowY: "auto" }}
    >
      <div className="flex justify-between align-center p-v-1">
        <h4 style={{ margin: 0 }}>Company Branches</h4>
        <div>
          <Searchbar term={search_term} filter={set_search_term} />
        </div>

        <div className="flex align-center justify-between">
          <EmojiTextButton
            text="Add a Branch"
            IconProp={Plus}
            onClick={() => setModalIsOpen(true)}
            styleProp={{
              background: Colors.purple,
              color: Colors.white,
              padding: ".8em 2em",
              marginLeft: ".5em",
            }}
          />
        </div>
      </div>
      <div className="flex flex-column grow m-t-1 bg-w">
        <AddBranchModal
          isOpen={modalIsOpen}
          closeModal={() => setModalIsOpen(false)}
          primaryButtonText={"Continue"}
        />
        <Table columns={COLUMNS} data={tableData} loading={isLoading} />
        <Paginator
          offset={offset}
          limit={limit}
          count={data?.count}
          setOffset={setOffset}
        />
      </div>
    </div>
  );
};
