import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import {
  AccountInformation,
  CompanyInformation,
  ChooseSubscription,
  MakePayment,
} from "../screens/account-creation";
import { Login } from "../screens/auth";
import { DashboardNavigation } from "./DashboardNavigation";
import {
  LinkSent,
  NewPasswordSet,
  PaymentSuccessful,
  ResetPassword,
  SetNewPassword,
} from "../pages";
import ProtectedRoute from "./ProtectedRoute";
import NotFound from "../pages/NotFound";
import withAuth from "../context/withAuth";

const Router = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact={true} component={Login} />

        <Route path="/login" exact={true} component={Login} />

        <Route
          path="/account-setup/account-information/"
          component={AccountInformation}
        />

        <ProtectedRoute
          path="/account-setup/company-information"
          component={withAuth(CompanyInformation)}
        />

        <ProtectedRoute
          path="/account-setup/choose-subscription"
          component={withAuth(ChooseSubscription)}
        />

        <ProtectedRoute
          path="/account-setup/make-payment"
          component={withAuth(MakePayment)}
        />

        <Route path="/reset-password/" component={ResetPassword} />

        <Route
          path="/password/reset/"
          exact={false}
          component={SetNewPassword}
        />
        <Route
          path="/payment-successful/"
          component={withAuth(PaymentSuccessful)}
        />

        <Route path="/link-sent/" component={LinkSent} />

        <Route path="/new-password-set/" component={NewPasswordSet} />

        <DashboardNavigation />

        <Route path="*" component={withAuth(NotFound)} />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
