import React from "react";
import styled from "styled-components";
import Lottie from "react-lottie";
import animationData from "../../assets/lottie/loader.json";
import lottieDefaultConfig from "../../selectors/lottieDefaultConfig";

export const Loading = () => {
  let defaultOptions = { ...lottieDefaultConfig, animationData };
  return (
    <LoadingStyledComponent>
      <td colSpan={"100%"}>
        <Lottie options={defaultOptions} height={40} width={100} />
        {/* <p>loading...</p> */}
      </td>
    </LoadingStyledComponent>
  );
};

const LoadingStyledComponent = styled.tr`
  font-size: 16px;
  width: 100%;

  td {
    width: 100%;
    max-width: 100%;
    white-space: nowrap;
    text-align: center;
  }
`;
