import React, { useEffect, useState } from "react";
import { StyledForm } from "../../components/account-creation/account-information/Form";
import { Layout } from "../../components/dashboard";
import { Tabs } from "../../components/settings/Tabs";
import { CountryCodeDropdown } from "../../components/general/CountryCodeDropdown";
import { Button, Loader, TextButton } from "../../components/general";
import colors from "../../constants/colors";
import axios from "axios";
import { useAppContext } from "../../context";
import errorHandler from "../../selectors/errorHandler";
import toast from "react-hot-toast";
import { get, patch } from "../../api/transport";
import { DocumentHeader } from "../../components/general/DocumentHeader";
import { PhoneNumberInput } from "../../components/general/PhoneNumberInput";
import validator from "validator";

const AccountInformation = () => {
  const { actions, action } = useAppContext();

  const [errors, setErrors] = useState({
    phone: [],
  });

  const [profile_data, set_profile_data] = useState({});
  const [changes, set_changes] = useState({
    first_name: undefined,
    last_name: undefined,
    email: undefined,
    phone_number: undefined,
  });
  const [selectedCountryCode, setSelectedCountryCode] = useState("233");
  const [fetch_loading, set_fetch_loading] = useState(false);
  const [submit_loading, set_submit_loading] = useState(false);

  function clear_changes() {
    set_changes({});
  }

  const validatePhoneNumber = value => {
    if (
      !value ||
      !validator.isMobilePhone(value, false, { strictMode: true })
    ) {
      set_changes({ ...changes, phone_number: value });
      return setErrors({
        ...errors,
        phone: ["Enter a valid phone number", ...errors.phone],
      });
    }
    set_changes({ ...changes, phone_number: value });
    return setErrors({ ...errors, phone: [] });
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) fetchProfile();
    return () => (mounted = false);
  }, []);

  return (
    <Layout tabs={Tabs}>
      <DocumentHeader page="Settings" />
      <div className="flex flex-row">
        <div className="flex flex-basis-50 flex-column">
          <h4 className="m-0 m-b-1">Account Information</h4>

          {fetch_loading ? (
            <div className="flex grow bg-w p-2">
              <Loader />
            </div>
          ) : (
            <StyledForm className="bg-w">
              <div className="row p-h-2">
                <div className="input-field">
                  <label>First Name</label>
                  <input
                    required={true}
                    aria-required={true}
                    // disabled={true}
                    value={
                      fetch_loading
                        ? "loading..."
                        : changes.first_name || profile_data.first_name
                    }
                    onChange={e => {
                      let val = e.target.value;
                      set_changes({ ...changes, first_name: val.trim() });
                    }}
                    type="text"
                    placeholder="First Name"
                  />
                </div>
                <div className="input-field">
                  <label>Last Name</label>
                  <input
                    // disabled={true}
                    value={
                      fetch_loading
                        ? "loading..."
                        : changes.last_name || profile_data.last_name
                    }
                    onChange={e => {
                      let val = e.target.value;
                      set_changes({ ...changes, last_name: val.trim() });
                    }}
                    type="text"
                    placeholder="Last Name"
                  />
                </div>
              </div>

              <div className="row p-h-2">
                <div className="input-field">
                  <label>Email Address</label>
                  <input
                    required={true}
                    aria-required={true}
                    value={
                      fetch_loading
                        ? "loading..."
                        : changes.email || profile_data.email
                    }
                    type="text"
                    placeholder="Email"
                    disabled={true}
                  />
                </div>
              </div>

              <div className="row p-h-2">
                <div className="input-field">
                  <label>Phone Number</label>
                  <PhoneNumberInput
                    value={changes.phone_number || profile_data?.phone_number}
                    setValue={validatePhoneNumber}
                  />
                  {/* <div className="pseudo-phone-input">
                    <CountryCodeDropdown
                      position="down"
                      selectedCountryCode={selectedCountryCode}
                      setSelectedCountryCode={setSelectedCountryCode}
                    />

                    <div className="phone-input-number-section">
                      <input
                        // disabled={true}
                        value={
                          changes.phone_number || profile_data?.phone_number
                        }
                        onChange={e => {
                          const val = e.target.value;
                          const isNumber = val.match("^[0-9]*$");
                          if (isNumber) {
                            set_changes({
                              ...changes,
                              phone_number: val,
                            });
                          }
                        }}
                        type="tel"
                        placeholder="Eg. 23 324 2342"
                      />
                    </div>
                  </div> */}
                  {errors.phone[0] && (
                    <p className="error-msg">{errors.phone[0]}</p>
                  )}
                </div>
              </div>

              <div className="row m-h-2 bordered-0 flex jc-flex-end">
                <div className="flex flex-basis-50">
                  <TextButton
                    text="Discard Changes"
                    className="grow"
                    styleProp={{ padding: "1em" }}
                    onClick={event => {
                      event.preventDefault();
                      clear_changes();
                    }}
                  />
                  <TextButton
                    className="grow primary"
                    text="Save Changes"
                    styleProp={{
                      padding: "1em",
                      background: colors.purple,
                      color: "white",
                    }}
                    loading={submit_loading}
                    onClick={event => {
                      event.preventDefault();
                      submitChanges();
                    }}
                  />
                </div>
              </div>
            </StyledForm>
          )}
        </div>
      </div>
    </Layout>
  );

  function fetchProfile() {
    set_fetch_loading(true);
    get("/users/me/")
      .then(response => {
        const { first_name, email, last_name, phone_number, country_code } =
          response.data;
        console.log(response.data);
        set_profile_data({
          ...profile_data,
          first_name,
          email,
          last_name,
          phone_number,
        });
        setSelectedCountryCode(country_code);
        set_fetch_loading(false);
      })
      .catch(error => {
        set_fetch_loading(false);
        errorHandler(error);
      });
  }

  function submitChanges() {
    set_submit_loading(true);
    toast.loading("Saving...");
    const patch_data = {
      first_name: changes.first_name || profile_data.first_name,
      last_name: changes.last_name || profile_data.last_name,
      phone_number: changes.phone_number || profile_data.phone_number,
      country_code: selectedCountryCode || profile_data?.country_code,
    };

    patch("/auth-token/users/me/", patch_data)
      .then(response => {
        set_submit_loading(false);
        const new_profile_data = { ...profile_data, ...response.data };
        set_profile_data({
          ...profile_data,
          ...response.data,
        });
        action(actions.setProfile, { profile: new_profile_data });
        set_changes({});
        toast.success("Changes saved successfully");
      })
      .catch(error => {
        set_submit_loading(false);
        errorHandler(error);
      });
  }
};

export { AccountInformation };
