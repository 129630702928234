import React from "react";
import { Preview } from "../components/company";
import { Layout } from "../components/dashboard";
import { EmojisUploadField } from "../components/emojis/UploadField";
import { DocumentHeader } from "../components/general/DocumentHeader";
import { useDeviceSettingsContext } from "../context/DeviceSettingsContext";

const Emojis = () => {
  const { appState } = useDeviceSettingsContext();

  return (
    <>
      <DocumentHeader page="Emojis" />
      <Layout>
        <div className="flex grow m-v-2">
          <div className="flex flex-basis-35 flex-column bg-w bordered r-8 p-1 m-r-2 max-height-80 scroll">
            <p id="file-support-msg">
              * Supported files: PNG or JPEG, not larger than 1MB
            </p>
            <div className="flex flex-column bg-w bordered-0 justify-between">
              <EmojisUploadField
                rating={5}
                name="Excellent"
                emoji={appState.emojis.excellent}
              />

              <EmojisUploadField
                name="Good"
                rating={4}
                emoji={appState.emojis.good}
              />

              <EmojisUploadField
                name="Okay"
                rating={3}
                emoji={appState.emojis.okay}
              />

              <EmojisUploadField
                name="Bad"
                rating={2}
                emoji={appState.emojis.bad}
              />

              <EmojisUploadField
                name="Terrible"
                rating={1}
                emoji={appState.emojis.terrible}
              />
            </div>
          </div>
          <div className="flex grow">
            <Preview />
          </div>
        </div>
      </Layout>
    </>
  );
};

export { Emojis };
