import React, { createContext, useReducer, useContext } from "react";
import { reducer, initialState, actions } from "./DeviceSettingsReducer";

const DeviceSettingsContext = createContext({});

const Provider = props => {
  const [appState, appDispatch] = useReducer(reducer, initialState);

  function action(type, payload) {
    return appDispatch({
      type,
      payload,
    });
  }

  return (
    <DeviceSettingsContext.Provider
      value={{
        appState,
        appDispatch,
        action,
        actions,
      }}
    >
      {props.children}
    </DeviceSettingsContext.Provider>
  );
};

const useDeviceSettingsContext = () => useContext(DeviceSettingsContext);
export { DeviceSettingsContext, Provider, useDeviceSettingsContext };
