import React from "react";
import { Colors } from "../../constants";

const ModalTitle = ({ title, subTitle, ...rest }) => {
  return (
    <div {...rest} className="m-b-1">
      <h4 style={{ margin: "0 0 0.5em 0" }}>{title}</h4>
      <p style={{ color: Colors.grey_2, fontSize: ".8em", margin: 0 }}>
        {subTitle}
      </p>
    </div>
  );
};

export default ModalTitle;
