import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from "react";
import { Table } from "../table";
import moment from "moment";
import { Colors } from "../../constants";
import Searchbar from "../dashboard/Searchbar";
import { ReactComponent as Export } from "../../assets/svgs/export.svg";
import axios from "axios";
import { useAppContext } from "../../context";
import { CSVLink } from "react-csv";
import errorHandler from "../../selectors/errorHandler";
import { get } from "../../api/transport";

export const SuperAdminFeedbacksTable = () => {
  const [search_term, set_search_term] = useState("");
  const [sentiments, set_sentiments] = useState([]);
  const [loading, set_loading] = useState(true);
  const downloadButtonRef = useRef();
  const { fetchToken } = useAppContext();

  const [offset, set_offset] = useState(0);
  const [limit, set_limit] = useState(10);

  const fetchSentiments = async () => {
    try {
      const response = await get(`/sentiment/?offset=${offset}&limit=${limit}`);
      const { results } = response.data;
      set_sentiments(results);
    } catch (error) {
      errorHandler(error);
    } finally {
      set_loading(false);
    }
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) fetchSentiments();
    return () => (mounted = false);
  }, []);

  const COLUMNS = [
    {
      Header: "DEVICE",
      accessor: "tablet.name",
    },
    {
      Header: "BRANCH",
      accessor: "tablet.location.name",
      Cell: data => {
        const sentiment = data?.row?.original;
        const tablet = sentiment?.tablet || {};
        const location =
          (tablet.location?.length > 0 && tablet.location[0]) || {};
        return location?.name || "";
      },
    },
    {
      Header: "CONTACT",
      accessor: "email",
    },
    {
      Header: "SENTIMENT",
      accessor: "sentiment",
      Cell: ({ value }) => {
        const emojis = {
          excellent: "😍",
          good: "😀",
          neutral: "🙄",
          bad: "😕",
          terrible: "😡",
        };

        return (
          <span>
            <span>{emojis[value?.title?.toLowerCase()]} </span>
            {value.title}
          </span>
        );
      },
    },
    {
      Header: "CATEGORY",
      accessor: "category.title",
    },
    {
      Header: "FEEDBACK",
      accessor: "feedback",
      // Cell: ({ value }) => {
      //   return value.slice(0, 50) + "...";
      // },
    },
    {
      Header: "DATE",
      accessor: "date_published",
      Cell: ({ value }) => moment(value).format("MMM D, YYYY"),
    },
  ];

  const filterFeedbacks = useCallback(() => {
    const term = search_term.toLowerCase();
    const filtered_list = [...sentiments].filter(sentiment => {
      const name_search = sentiment.tablet.name
        ?.toLowerCase()
        .includes(search_term.toLowerCase());

      const branch_search =
        sentiment.tablet.location &&
        sentiment.tablet.location.some(el =>
          el?.name?.toLowerCase().includes(term)
        );

      const customer_search = sentiment.email?.toLowerCase().includes(term);

      // const category_search = sentiment.categories.some(category =>
      //   category.title.toLowerCase().includes(search_term.toLowerCase())
      // );

      const category_search = sentiment.category?.title
        ?.toLowerCase()
        .includes(term);

      const feedback_search = sentiment.feedback
        ?.toLowerCase()
        .includes(search_term.toLowerCase());

      return (
        name_search ||
        branch_search ||
        customer_search ||
        category_search ||
        feedback_search
      );
    });
    return filtered_list;
  }, [search_term, sentiments]);

  return (
    <div
      className="flex grow flex-column"
      style={{ maxHeight: "90vh", overflowY: "auto" }}
    >
      <div className="flex justify-between align-center p-v-1">
        <h4 style={{ margin: 0 }}>Feedbacks</h4>
        <div>
          <Searchbar term={search_term} filter={set_search_term} />
        </div>

        <div className="flex align-center justify-between">
          <CSVLink
            ref={downloadButtonRef}
            className="r-8 flex"
            style={{
              background: Colors.purple,
              color: Colors.white,
              padding: ".8em 2em",
              marginLeft: ".5em",
              textDecoration: "none",
            }}
            data={[
              ...sentiments.map(el => {
                return {
                  DEVICE: el.tablet?.name,
                  BRANCH: ` ${el.tablet?.location?.map(el => el.name)}`,
                  CUSTOMER: el.email,
                  SENTIMENT: el.sentiment?.title,
                  // categories: el.categories?.map(el => el.title),
                  CATEGORY: el.category?.title,
                  FEEDBACK: el.feedback,
                  DATE: moment(el.created_at).format("MMM D, YYYY hh:mm a"),
                };
              }),
            ]}
            disabled={true}
          >
            <span className="m-r-p5">
              <Export />
            </span>
            <p className="m-0">Export Data</p>
          </CSVLink>
        </div>
      </div>
      <Table
        loading={loading}
        columns={COLUMNS}
        data={useMemo(() => filterFeedbacks().reverse(), [filterFeedbacks])}
      />
    </div>
  );
};
