import React from "react";
import { ReactComponent as Check } from "../../assets/svgs/green-check.svg";

const StepsAlt = props => {
  const stages = [
    {
      step: 1,
      name: "Account",
    },
    {
      step: 2,
      name: "Company",
    },
    {
      step: 3,
      name: "Subscription",
    },
    {
      step: 4,
      name: "Payment",
    },
  ];

  const { currentStep } = props;

  return (
    <div className="flex row-wrap gap-half m-v-1">
      {stages.map((el, idx) => {
        return (
          <div
            className="p-p5 flex flex-column align-center bordered r-8 bg-w"
            style={{ width: "18%" }}
            key={idx}
          >
            <div
              className="bordered circle"
              style={{ width: "1em", height: "1em" }}
            >
              {el.step <= currentStep && <Check />}
            </div>
            <p style={{ fontSize: ".8em" }} className="m-0 gray-2 center-align">
              {" "}
              {el.name}
            </p>
          </div>
        );
      })}
    </div>
  );
};

export default StepsAlt;
