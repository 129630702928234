import React, { useEffect, useState } from "react";
import { StyledForm } from "../../components/account-creation/account-information/Form";
import { Layout } from "../../components/dashboard";
import { Tabs } from "../../components/settings/Tabs";
import { CountryCodeDropdown } from "../../components/general/CountryCodeDropdown";
import { TextButton } from "../../components/general";
import colors from "../../constants/colors";
import { useAppContext } from "../../context";
import errorHandler from "../../selectors/errorHandler";
import toast from "react-hot-toast";
import { get, patch } from "../../api/transport";
import { DocumentHeader } from "../../components/general/DocumentHeader";

const CompanyInformation = () => {
  useEffect(() => {
    let mounted = true;
    if (mounted) fetch_company();
    return () => (mounted = false);
  }, []);
  const { appState } = useAppContext();
  const [selected_country_code, set_selected_country_code] = useState("233");

  const [fetch_loading, set_fetch_loading] = useState(false);
  const [submit_loading, set_submit_loading] = useState(false);

  const [company_profile, set_company_profile] = useState({});
  const [changes, set_changes] = useState({
    name: undefined,
  });

  return (
    <>
      <DocumentHeader page="Settings" />
      <Layout tabs={Tabs}>
        <div className="flex flex-row">
          <div className="flex bordered-0 flex-basis-50 flex-column">
            <h4 className="m-0 m-b-1">Company Information</h4>

            <StyledForm className="bg-w">
              <div className="row p-h-2">
                <div className="input-field">
                  <label>Company Name</label>
                  <input
                    required={true}
                    aria-required={true}
                    value={
                      fetch_loading
                        ? "loading..."
                        : changes.name || company_profile.name
                    }
                    onChange={e => {
                      let val = e.target.value;
                      // set_company_name(val.trim());
                      set_changes({ ...changes, name: val.trim() });
                    }}
                    type="text"
                    placeholder="Company Name"
                  />
                </div>
              </div>

              <div className="row p-h-2">
                <div className="input-field">
                  <label>Company Email Address</label>
                  <input
                    required={true}
                    aria-required={true}
                    value={
                      fetch_loading
                        ? "loading..."
                        : changes.email || company_profile.email
                    }
                    disabled={true}
                    // onChange={e => {
                    //   let val = e.target.value;
                    // set_email(val.trim());
                    // set_changes({ ...changes, email: val.trim() });
                    // }}
                    type="text"
                    placeholder="Company Email Address"
                  />
                </div>
              </div>

              <div className="row p-h-2">
                <div className="input-field">
                  <label>Phone Number</label>
                  <div className="pseudo-phone-input">
                    <CountryCodeDropdown
                      position="down"
                      selectedCountryCode={selected_country_code}
                      setSelectedCountryCode={set_selected_country_code}
                    />

                    <div className="phone-input-number-section">
                      <input
                        value={
                          fetch_loading
                            ? "loading..."
                            : changes.tel ||
                              company_profile.tel?.slice(
                                3,
                                company_profile.tel?.length
                              )
                        }
                        onChange={e => {
                          const val = e.target.value;
                          const isNumber = val.match("^[0-9]*$");
                          if (isNumber) {
                            set_changes({
                              ...changes,
                              tel: val,
                            });
                          }
                        }}
                        type="tel"
                        placeholder="Eg. 23 324 2342"
                      />
                    </div>
                  </div>
                  {/* {errors.phone[0] && (
                  <p className="error-msg">{errors.phone[0]}</p>
                )} */}
                </div>
              </div>

              <div className="row m-h-2 bordered-0 flex jc-flex-end">
                <div className="flex flex-basis-50">
                  <TextButton
                    text="Discard Changes"
                    className="grow"
                    styleProp={{ padding: "1em" }}
                    onClick={clear_changes}
                  />
                  <TextButton
                    className="grow primary"
                    text="Save Changes"
                    styleProp={{
                      padding: "1em",
                      background: colors.purple,
                      color: "white",
                    }}
                    loading={submit_loading}
                    onClick={event => {
                      event.preventDefault();
                      submitChanges();
                    }}
                  />
                </div>
              </div>
            </StyledForm>
          </div>
        </div>
      </Layout>
    </>
  );

  function fetch_company() {
    set_fetch_loading(true);
    // axios
    //   .get(`https://echofeed-web-app.df.r.appspot.com/api/users/company`, {
    //     headers: { Authorization: `Token ${fetchToken()}` },
    //   })
    get("/users/company")
      .then(response => {
        const { tel } = response.data;
        set_company_profile({
          ...company_profile,
          ...response.data,
        });
        set_selected_country_code(tel?.slice(0, 3));
        set_fetch_loading(false);
      })
      .catch(error => {
        set_fetch_loading(false);
        errorHandler(error);
      });
  }

  function clear_changes(event) {
    event.preventDefault();
    set_changes({});
  }

  function submitChanges() {
    set_submit_loading(true);
    toast.loading("Saving Changes");

    const patch_data = {
      name: changes.name || company_profile.name,
      tel: changes.tel
        ? selected_country_code + changes.tel
        : company_profile.tel,
      status: "Approved",
    };

    patch(`/company/${appState.profile?.company?.id}/`, patch_data)
      .then(response => {
        toast.success("Changes Saved Successfully");

        set_company_profile({
          ...company_profile,
          ...response.data,
        });
        set_changes({});
        set_submit_loading(false);
      })
      .catch(error => {
        set_submit_loading(false);
        errorHandler(error);
      });
  }
};

export { CompanyInformation };
