import React from "react";
import styled from "styled-components";
import { Button } from "../general";

const Foot = ({
  alignButton = "flex-end",
  buttonText = "Continue",
  handleClick = () => {},
}) => {
  return (
    <StyledFoot alignButton={alignButton}>
      <Button text={buttonText} onClick={handleClick} />
    </StyledFoot>
  );
};

export default Foot;

const StyledFoot = styled.div`
  margin-top: auto;
  margin-bottom: 2em;
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.alignButton};
`;
