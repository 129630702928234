import React from "react";
import { FullScreenPage } from "../general/FullScreenPage";
import Sidebar from "./Sidebar";
import { ReactComponent as SuccessBadge } from "../../assets/svgs/success-badge.svg";
import { PageTitle } from ".";
import { GenericButton } from "../general/Button";

export const SuccessPage = props => {
  const { title, subtext, button } = props;
  return (
    <FullScreenPage>
      <div className="flex grow">
        <Sidebar />
        <div className="flex grow flex-column justify-center align-center">
          <SuccessBadge />
          <PageTitle title={title} subTitle={subtext} align="center" />
          <div className="p-v-2 ">
            <GenericButton {...button} />
          </div>
        </div>
      </div>
    </FullScreenPage>
  );
};
