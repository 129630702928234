import React from "react";
import { SuperAdminBranchManagersTable } from "../../components/branch-managers/AdminBranchManagersTable";
import { CompaniesTable, CompanyTabs } from "../../components/companies";
import { Layout } from "../../components/dashboard";
import { DocumentHeader } from "../../components/general/DocumentHeader";

const BranchManagers = () => {
  return (
    <Layout tabs={CompanyTabs}>
      <DocumentHeader page="Branch Managers" />
      <SuperAdminBranchManagersTable />
    </Layout>
  );
};

export default BranchManagers;
