import React, { useCallback, useEffect, useState, memo } from "react";
import styled from "styled-components";
import { ReactComponent as Excellent } from "../../assets/svgs/Excellent.svg";
import { ReactComponent as Good } from "../../assets/svgs/Good.svg";
import { ReactComponent as Terrible } from "../../assets/svgs/Terrible.svg";
import { ReactComponent as Bad } from "../../assets/svgs/Bad.svg";
import { ReactComponent as Okay } from "../../assets/svgs/Okay.svg";
import { useAppContext } from "../../context";
import axios from "axios";
import { useDeviceSettingsContext } from "../../context/DeviceSettingsContext";
import errorHandler from "../../selectors/errorHandler";
import { Loader } from "../general";
import toast from "react-hot-toast";

const EmojisSection = () => {
  const { fetchToken } = useAppContext();
  const { action, actions } = useDeviceSettingsContext();
  const [loading, set_loading] = useState(false);
  const [sentiment_settings, set_sentiment_settings] = useState([]);

  const emojiDictionary = {
    terrible: sentiment_settings?.find(
      el => el.title.toLowerCase() === "terrible"
    ),
    bad: sentiment_settings?.find(el => el.title.toLowerCase() === "bad"),
    okay: sentiment_settings?.find(el => el.title.toLowerCase() === "okay"),
    good: sentiment_settings?.find(el => el.title.toLowerCase() === "good"),
    excellent: sentiment_settings?.find(
      el => el.title.toLowerCase() === "excellent"
    ),
  };

  return (
    <StyledEmojisSection>
      <Emoji
        loading={loading}
        Icon={Excellent}
        name="Excellent"
        sentiment={emojiDictionary.excellent}
      />

      <Emoji
        loading={loading}
        Icon={Good}
        name="Good"
        sentiment={emojiDictionary.good}
      />

      <Emoji
        loading={loading}
        Icon={Okay}
        name="Okay"
        sentiment={emojiDictionary.okay}
      />

      <Emoji
        loading={loading}
        Icon={Bad}
        name="Bad"
        sentiment={emojiDictionary.bad}
      />

      <Emoji
        loading={loading}
        Icon={Terrible}
        name="Terrible"
        sentiment={emojiDictionary.terrible}
      />
    </StyledEmojisSection>
  );
};

export default EmojisSection;

const StyledEmojisSection = styled.div`
  //   border: 1px solid;
  display: flex;
  justify-content: space-evenly;
  margin: 1em 0 1em;
`;

const Emoji = memo(({ Icon = Excellent, name, sentiment, loading }) => {
  const { appState } = useDeviceSettingsContext();

  const this_emoji = appState.emojis[name.toLowerCase()];

  const sentiment_setting_data = appState.company_emojis[name.toLowerCase()];
  const [preview, set_preview] = useState(undefined);

  const { file, link } = sentiment_setting_data;

  const toggleEmojiPreview = () => {
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => set_preview(reader.result);
      reader.readAsDataURL(file);
    } else {
      return set_preview(null);
    }
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) toggleEmojiPreview();
    return () => (mounted = false);
  }, [file]);

  const img_src = file ? preview : link;

  return (
    <StyledEmoji>
      <div
        className={`circle ${!!file || link ? "bg-transparent" : "bg-gray"}`}
        style={{ width: "80px", height: "80px" }}
      >
        {/* {loading && <Loader className="p-1" />} */}
        {loading && "..."}
        {(file || link) && (
          <img
            src={img_src}
            style={{
              width: "inherit",
              height: "inherit",
              objectFit: "cover",
            }}
            className="circle"
          />
        )}
      </div>

      <p style={{ fontSize: "12px", margin: ".5em 0" }}>{name}</p>
    </StyledEmoji>
  );
});

const StyledEmoji = styled.div`
  //   border: 1px solid;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
