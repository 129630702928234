import { useQuery } from "react-query";
import get from "../../api/transport";
import { defaultOptions } from "../devices/broker";

export const useSubscriptionHistory = () => {
  const queryKey = "subscription_history";

  const queryFn = () => get("/user/invoice/");

  const select = ({ data }) => data;

  const options = { select, ...defaultOptions };

  return useQuery(queryKey, queryFn, options);
};
