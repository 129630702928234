import React from "react";
import styled from "styled-components";
import ProfileBadge from "./ProfileBadge";
import { useLocation } from "react-router-dom";

const ContentHeader = props => {
  const location = useLocation();
  const title = location.pathname.split("/")[1];
  return (
    <StyledContentHeader>
      <div>
        <h1 className="content-heading">
          {props.PageTitle || title.toTitleCase()}
        </h1>
      </div>
      <div>
        <ProfileBadge />
      </div>
    </StyledContentHeader>
  );
};

export default ContentHeader;

const StyledContentHeader = styled.div`
  display: flex;
  height: 72px;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  padding: 1em 2em;
  background: white;

  h1 {
    font-family: CircularStdBold;
    font-size: 25px;
    line-height: 120%;
    color: #151515;
  }
`;
