import React, { useEffect, useState, memo } from "react";
import styled from "styled-components";
import Logo from "../../assets/pngs/echo-logo.png";
import { ReactComponent as TopLeft } from "../../assets/svgs/top-left-bg.svg";
import { ReactComponent as BottomRight } from "../../assets/svgs/bottom-right-bg.svg";
import { Emojis } from "../preview";
import { Button } from "../general";
import { useDeviceSettingsContext } from "../../context/DeviceSettingsContext";
import { useAppContext } from "../../context";
import toast from "react-hot-toast";
import errorHandler from "../../selectors/errorHandler";
import _ from "lodash";
import { get } from "../../api/transport";

const fetchTabletSettings = fetchTokenFunction => {
  return get("/user/tablet-settings/")
    .then(response => {
      return response;
    })
    .catch(error => {
      return errorHandler(error);
    });
};

const fetchSentimentSettings = fetchTokenFunction => {
  return get("/user/sentiment-settings/")
    .then(response => response.data)
    .catch(error => errorHandler(error));
};

const PreviewSection = () => {
  const { fetchToken } = useAppContext();
  const { action, actions } = useDeviceSettingsContext();

  const set_original_device_settings = async () => {
    toast.loading("Please wait");
    try {
      let response = await fetchTabletSettings(fetchToken);
      const sentiment_settings = await fetchSentimentSettings(fetchToken);

      let is_empty = _.isEmpty(response.data.results);

      if (!is_empty) {
        const response_length = response.data.results.length;
        const saved_device_settings =
          response.data.results[response_length - 1];
        const {
          id,
          company,
          created_at,
          updated_at,
          date_published,
          date_updated,
          uuid,
          status,
          is_active,
          ...core_saved_device_settings
        } = saved_device_settings;

        action(actions.set_device_settings_id, { id });
        action(actions.set_saved_device_settings, core_saved_device_settings);
        action(actions.set_company_emojis, {
          title: "Array",
          data: sentiment_settings.results,
        });
        toast.success("Success");
      }
    } catch (error) {
      return;
    }
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) set_original_device_settings();
    return () => (mounted = false);
  }, [fetchTabletSettings, fetchSentimentSettings]);
  return (
    <StyledPreviewSection>
      <p className="center-align purple bold m-0">Preview</p>
      <Preview />
    </StyledPreviewSection>
  );
};

export default PreviewSection;

const StyledPreviewSection = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 90vh;
`;

const Preview = memo(() => {
  const { appState: application_state } = useAppContext();
  const { appState } = useDeviceSettingsContext();
  const [logoPreview, setLogoPreview] = useState();
  const { logo_image, btn_color, text_color } = appState.new_device_settings;

  const {
    logo_image: logo_image_saved,
    btn_color: btn_color_saved,
    text_color: text_color_saved,
    // background_image: background_image_saved,
  } = appState.saved_device_settings;

  const toggleLogoPreview = () => {
    if (logo_image) {
      const reader = new FileReader();
      reader.onloadend = () => setLogoPreview(reader.result);
      reader.readAsDataURL(logo_image);
    } else {
      return setLogoPreview(null);
    }
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) toggleLogoPreview();
    return () => (mounted = false);
  }, [logo_image]);

  return (
    <StyledPreview text_color={text_color || text_color_saved}>
      <BackgroundGeneric>
        <div className="flex grow flex-column justify-between p-v-2">
          <div className="flex flex-column align-center">
            <img
              alt="company_logo"
              src={logo_image ? logoPreview : logo_image_saved || Logo}
              style={{ width: "60px", height: "60px", objectFit: "cover" }}
              className="circle"
            />
            <p className="m-0 m-v-1 m-b-2 center-align">
              Hi there, good day. <br /> Welcome to{" "}
              {application_state.profile?.company?.name}
            </p>
            <p className="m-0 bold center-align" style={{ fontSize: "1.5em" }}>
              How was your experience with us?
            </p>
          </div>
          <div>
            <Emojis />
          </div>
          <div className="flex justify-center">
            <Button
              styleProp={{
                backgroundColor: btn_color || btn_color_saved,
                color: text_color || text_color_saved,
              }}
              text="Continue with your feedback"
            />
          </div>
        </div>
      </BackgroundGeneric>
    </StyledPreview>
  );
});

const StyledPreview = styled.div`
  margin-top: 1.5em;
  width: 90%;
  background: #ffffff;
  box-shadow: 0px 0px 0px 16px rgba(21, 21, 21, 0.08);
  border-radius: 24px;
  height: 400px;
  overflow: hidden;
  min-height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: ${props => props.text_color} !important;
`;

function BackgroundGeneric({ children }) {
  const { appState } = useDeviceSettingsContext();
  const [backgroundPreview, setBackgroundPreview] = useState();
  const { background_image, bg_color } = appState.new_device_settings;
  const { background_image: background_image_saved, bg_color: bg_color_saved } =
    appState.saved_device_settings;

  const toggleBackgroundPreview = () => {
    if (background_image) {
      const reader = new FileReader();
      reader.onloadend = () => setBackgroundPreview(reader.result);
      reader.readAsDataURL(background_image);
    } else {
      return setBackgroundPreview(null);
    }
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) toggleBackgroundPreview();
    return () => (mounted = false);
  }, [background_image]);

  return (
    <StyledBackgroundGeneric
      style={{
        backgroundImage: background_image
          ? `url("${backgroundPreview}")`
          : `url("${background_image_saved}")` || "none",
        backgroundSize: "cover",
        backgroundColor: bg_color || bg_color_saved,
        backgroundPosition: "center",
      }}
    >
      {!!background_image_saved || !!bg_color || !!bg_color_saved || (
        <>
          <TopLeft className="svg-item" id="top-left" />
          <BottomRight className="svg-item" id="bottom-right" />
        </>
      )}
      <div className="children-section">{children}</div>
    </StyledBackgroundGeneric>
  );
}

const StyledBackgroundGeneric = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  .svg-item {
    position: absolute;
    z-index: 0.5;
  }

  #top-left {
    top: 0;
    left: 0;
  }

  #bottom-right {
    bottom: 0;
    right: 0;
  }

  .children-section {
    width: 100%;
    height: 100%;
    z-index: 2;
    display: flex;
    flex: 1;
  }
`;
