import React from "react";
import lottieDefaultConfig from "../../selectors/lottieDefaultConfig";
import animationData from "../../assets/lottie/loader.json";
import Lottie from "react-lottie";

const DropdownLoader = () => {
  let defaultOptions = { ...lottieDefaultConfig, animationData };
  return (
    <div className="flex justify-center align-center">
      <Lottie options={defaultOptions} height={40} width={100} />
    </div>
  );
};

export default DropdownLoader;
