import React from "react";
import { Route, useHistory, Redirect } from "react-router-dom";

const ProtectedRoute = ({
  component: Component,
  authStore,
  condition,
  reroute,
  ...rest
}) => {
  const history = useHistory();
  let token = sessionStorage.getItem("token");

  return (
    <Route
      {...rest}
      render={props => {
        if (!token) return history.push("/login");
        return <Component {...props} />;
      }}
    />
  );
};

export default ProtectedRoute;
