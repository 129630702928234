import React from "react";
import styled from "styled-components";
import { Colors } from "../../constants";
import { EmojiTextButton } from "../general";
import { ReactComponent as Close } from "../../assets/svgs/close-purple.svg";
import { EmojiOption } from "./EmojiSelection";

const UploadedEmoji = ({ goBack }) => {
  return (
    <StyledUploadedEmoji>
      <EmojiOption />
      <EmojiTextButton
        onClick={goBack}
        text="Remove"
        styleProp={{
          padding: ".5em 1.5em",
          background: Colors.white_2,
          border: "0",
          fontSize: ".8em",
        }}
        IconProp={Close}
      />
    </StyledUploadedEmoji>
  );
};

export default UploadedEmoji;

const StyledUploadedEmoji = styled.div`
  //   border: 1px solid red;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
