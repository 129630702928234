import React from "react";
import styled from "styled-components";
import { Colors } from "../../constants";
import colors from "../../constants/colors";

const TextButton = ({
  onClick = () => {},
  text,
  styleProp,
  disabled,
  loading,
  ...rest
}) => {
  return (
    <StyledButton
      {...rest}
      disabled={disabled || loading}
      style={styleProp}
      onClick={event => !disabled && onClick(event)}
      className={`${rest.className}`}
    >
      <p
        style={{
          // fontSize: styleProp?.fontSize || "14px",
          margin: 0,
          flexShrink: 0,
          whiteSpace: "nowrap",
        }}
      >
        {loading ? "loading..." : text}
      </p>
    </StyledButton>
  );
};

export default TextButton;

const StyledButton = styled.button`
  border-radius: 8px;
  font-family: CircularStd;
  margin-right: ${({ no_margin }) => (no_margin ? 0 : "0.5em")};
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  padding: ${({ padding }) => padding || "1rem"};
  background-color: ${({ background }) => background || "transparent"};
  color: ${({ color }) => color || colors.purple};
  border: ${({ border }) => border || `1px solid ${colors.purple}`};
  margin-top: ${({ margin_top }) => margin_top || "0"};
  font-size: ${({ fontSize }) => fontSize || "14px"};
`;
