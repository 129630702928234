import React, { useEffect, useRef, useState } from "react";
import { get } from "../../api/transport";
import { ReactComponent as Arrow } from "../../assets/svgs/arrow-pointing-left.svg";
import errorHandler from "../../selectors/errorHandler";
import { useOutsideDetect } from "../../selectors/UseOutsideDetect";

export const DeviceCount = props => {
  const [showMeta, setShowMeta] = useState(false);
  const { number_tablet, count } = props;

  const ref = useRef(null);
  useOutsideDetect(ref, () => setShowMeta(false));

  return (
    <div ref={ref} className="flex align-center relative">
      <div className="m-h-1">
        <p>
          {count} / {number_tablet}
        </p>
      </div>
      <div
        className="circle flex justify-center align-center pointer"
        onClick={() => setShowMeta(!showMeta)}
        style={{
          background: "#72004C",
          width: "20px",
          height: "20px",
          color: "white",
          marginRight: ".5em",
        }}
      >
        i
      </div>

      {showMeta && (
        <>
          <Arrow className="absolute" style={{ left: "105%" }} />
          <div
            className="absolute bg-w p-1 bordered r-8 shadow"
            style={{ left: "115%", top: 0, width: "400px", color: "#696969" }}
          >
            <p style={{ margin: 0 }}>
              This is simply the amount of devices your company subscribed for.
              If you need more slots for devices, you can upgrade account in the
              subscriction section or click here.
            </p>
          </div>
        </>
      )}
    </div>
  );
};
