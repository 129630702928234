import React from "react";
import styled from "styled-components";
import NotificationItem from "./NotificationItem";

const Dropdown = props => {
  return (
    <StyledDropdown>
      <NotificationItem />
      {/* <NotificationItem onClick={props.toggleIsActive} />
      <NotificationItem onClick={props.toggleIsActive} />
      <NotificationItem onClick={props.toggleIsActive} /> */}
    </StyledDropdown>
  );
};

export default Dropdown;

const StyledDropdown = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid #f5f3f3;
  box-sizing: border-box;
  box-shadow: 0px 4px 16px rgba(21, 21, 21, 0.08);
  border-radius: 4px;
  position: absolute;
  z-index: 2;
  top: 120%;
  transition: 0.5s ease;
`;
