import React from "react";
import { Controller } from "react-hook-form";
import ReactSelect from "react-select";
import { mockoptions, selectStyles } from "../../utils/react-select";

const ControlledSelect = React.forwardRef((props, ref) => (
  <ReactSelect {...props} ref={ref} />
));

const Select = props => {
  const { options = mockoptions, withControl = false, error, ...rest } = props;

  const renderContent = () => {
    if (withControl) {
      if (!rest.control) throw Error("You must pass a control prop");

      return (
        <Controller
          {...rest}
          render={({ field }) => {
            return (
              <ControlledSelect
                {...field}
                options={options}
                placeholder={rest.placeholder}
                label="Text field"
                unstyled={true}
                styles={selectStyles(!!error)}
                name={rest.name}
              />
            );
          }}
        />
      );
    }

    return (
      <ReactSelect
        {...rest}
        options={options}
        unstyled={true}
        styles={selectStyles(!!error)}
      />
    );
  };

  return renderContent();
};

export default Select;
