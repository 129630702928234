import React from "react";
import styled from "styled-components";
import { Colors } from "../../constants";
import colors from "../../constants/colors";

const Button = ({
  text = "Continue",
  onClick = () => {},
  styleProp,
  disabled,
  loading = false,
  ...rest
}) => {
  return (
    <StyledButton
      style={{ ...styleProp }}
      disabled={disabled || !!loading === true}
      onClick={e => {
        e.preventDefault();
        loading === false ? onClick() : void 0;
      }}
      {...rest}
    >
      <p>{loading ? "loading..." : text}</p>
      <span className="m-l-1">&rarr;</span>
    </StyledButton>
  );
};

export const GenericButton = ({
  text = "Continue",
  onClick = () => {},
  styleProp,
  disabled,
  loading = false,
  children,
  ...rest
}) => {
  return (
    <StyledButton
      style={{ ...styleProp }}
      disabled={disabled || !!loading === true}
      onClick={e => {
        e.preventDefault();
        loading === false ? onClick() : void 0;
      }}
      {...rest}
    >
      {loading && <p>loading ...</p>}
      {!loading && children}
    </StyledButton>
  );
};

Button.General = props => {
  const { text = "", onClick = () => {}, ...rest } = props;
  return (
    <ButtonSheet onClick={onClick} {...rest}>
      <span>
        <p className="text">{text}</p>
      </span>
    </ButtonSheet>
  );
};

const ButtonSheet = styled.button`
  display: ${({ display }) => display || `initial`};
  flex: ${({ flex }) => flex || `default`};
  flex-grow: ${flexGrow => flexGrow || "initial"};
  flex-shrink: ${flexShrink => flexShrink || "initial"};
  width: ${({ width }) => width || `initial`};
  color: ${({ color }) => color || colors.purple};
  margin: ${({ margin }) => margin || `0`};
  padding: ${({ padding }) => padding || `0`};
  cursor: ${({ cursor }) => cursor || `pointer`};
  border: ${({ border }) => border || `1px solid ${colors.purple}`};
  font-size: ${({ fontSize }) => fontSize || `medium`};
  white-space: ${({ whiteSpace }) => whiteSpace || `nowrap`};
  border-radius: ${({ borderRadius }) => borderRadius || `8px`};
  background-color: ${({ backgroundColor }) =>
    backgroundColor || `transparent`};
  .display-block {
    display: block;
  }

  .text {
    margin: 0;
  }
`;

export default Button;

const StyledButton = styled.button`
  min-width: 200px;
  min-height: 48px;
  background-color: ${props => Colors.purple};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5em 1em;
  border-radius: 8px;
  color: #ffffff;
  border: 0;

  opacity: ${({ disabled }) => (disabled ? ".8" : "1")};
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};

  p {
    font-family: CircularStd;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
  }
`;
