import React, {
  createContext,
  useReducer,
  useContext,
  memo,
  useMemo,
} from "react";
import { reducer, initialState, actions } from "./reducer";
import { useHistory } from "react-router";

const AppContext = createContext({});

const Provider = props => {
  const [appState, appDispatch] = useReducer(reducer, initialState);
  const history = useHistory();

  function action(type, payload) {
    return appDispatch({
      type,
      payload,
    });
  }

  return (
    <AppContext.Provider
      value={{
        appState,
        appDispatch,
        action,
        actions,
        fetchToken,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );

  function fetchToken() {
    const token = appState.token || sessionStorage.getItem("token");
    return token;
  }
};

const useAppContext = () => useContext(AppContext);
export { AppContext, Provider, useAppContext };
