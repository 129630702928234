import breakpoints from "./breakpoints";
import typography from "./typography";
import colors from "./colors.json";

const modules = {
  breakpoints,
  typography,
  colors,
}

export default modules
