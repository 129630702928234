// import axios from "axios";
import React, { useRef, useState } from "react";
import { v4 } from "uuid";
import { Layout } from "../components/dashboard";
import { /*EmojiTextButton,*/ TextButton } from "../components/general";
// import { useAppContext } from "../context";
// import errorHandler from "../selectors/errorHandler";
import DotsButton from "../components/general/DotsButton";
// import Lottie from "react-lottie";
// import animationData from "../assets/lottie/skeleton.json";
// import lottieDefaultConfig from "../selectors/lottieDefaultConfig";
import colors from "../constants/colors";
// import { ReactComponent as Bag } from "../assets/svgs/bag.svg";
import { useOutsideDetect } from "../selectors";
import { DocumentHeader } from "../components/general/DocumentHeader";

const SuperAdminPushNotifications = () => {
  // const { appState, fetchToken } = useAppContext();
  // const [loading, set_loading] = useState(false);
  // const [limit, set_limit] = useState(50);
  // const [offset, set_offset] = useState(0);
  const [push_notifications, /*set_push_notifications*/] = useState([
    {
      uuid: v4(),
      title: "Scheduled Maitenance",
      description: "This is to serve as a notice that all our services...",
    },
    {
      uuid: v4(),
      title: "Scheduled Maitenance",
      description: "This is to serve as a notice that all our services...",
    },
    {
      uuid: v4(),
      title: "Scheduled Maitenance",
      description: "This is to serve as a notice that all our services...",
    },
    {
      uuid: v4(),
      title: "Scheduled Maitenance",
      description: "This is to serve as a notice that all our services...",
    },
    {
      uuid: v4(),
      title: "Scheduled Maitenance",
      description: "This is to serve as a notice that all our services...",
    },
    {
      uuid: v4(),
      title: "Scheduled Maitenance",
      description: "This is to serve as a notice that all our services...",
    },
    {
      uuid: v4(),
      title: "Scheduled Maitenance",
      description: "This is to serve as a notice that all our services...",
    },
    {
      uuid: v4(),
      title: "Scheduled Maitenance",
      description: "This is to serve as a notice that all our services...",
    },
    {
      uuid: v4(),
      title: "Scheduled Maitenance",
      description: "This is to serve as a notice that all our services...",
    },
    {
      uuid: v4(),
      title: "Scheduled Maitenance",
      description: "This is to serve as a notice that all our services...",
    },
    {
      uuid: v4(),
      title: "Scheduled Maitenance",
      description: "This is to serve as a notice that all our services...",
    },
    {
      uuid: v4(),
      title: "Scheduled Maitenance",
      description: "This is to serve as a notice that all our services...",
    },
    {
      uuid: v4(),
      title: "Scheduled Maitenance",
      description: "This is to serve as a notice that all our services...",
    },
    {
      uuid: v4(),
      title: "Scheduled Maitenance",
      description: "This is to serve as a notice that all our services...",
    },
    {
      uuid: v4(),
      title: "Scheduled Maitenance",
      description: "This is to serve as a notice that all our services...",
    },
    {
      uuid: v4(),
      title: "Scheduled Maitenance",
      description: "This is to serve as a notice that all our services...",
    },
    {
      uuid: v4(),
      title: "Scheduled Maitenance",
      description: "This is to serve as a notice that all our services...",
    },
    {
      uuid: v4(),
      title: "Scheduled Maitenance",
      description: "This is to serve as a notice that all our services...",
    },
    {
      uuid: v4(),
      title: "Scheduled Maitenance",
      description: "This is to serve as a notice that all our services...",
    },
    {
      uuid: v4(),
      title: "Scheduled Maitenance",
      description: "This is to serve as a notice that all our services...",
    },
    {
      uuid: v4(),
      title: "Scheduled Maitenance",
      description: "This is to serve as a notice that all our services...",
    },
    {
      uuid: v4(),
      title: "Scheduled Maitenance",
      description: "This is to serve as a notice that all our services...",
    },
    {
      uuid: v4(),
      title: "Scheduled Maitenance",
      description: "This is to serve as a notice that all our services...",
    },
    {
      uuid: v4(),
      title: "Scheduled Maitenance",
      description: "This is to serve as a notice that all our services...",
    },
    {
      uuid: v4(),
      title: "Scheduled Maitenance",
      description: "This is to serve as a notice that all our services...",
    },
    {
      uuid: v4(),
      title: "Scheduled Maitenance",
      description: "This is to serve as a notice that all our services...",
    },
    {
      uuid: v4(),
      title: "Scheduled Maitenance",
      description: "This is to serve as a notice that all our services...",
    },
    {
      uuid: v4(),
      title: "Scheduled Maitenance",
      description: "This is to serve as a notice that all our services...",
    },
    {
      uuid: v4(),
      title: "Scheduled Maitenance",
      description: "This is to serve as a notice that all our services...",
    },
    {
      uuid: v4(),
      title: "Scheduled Maitenance",
      description: "This is to serve as a notice that all our services...",
    },
    {
      uuid: v4(),
      title: "Scheduled Maitenance",
      description: "This is to serve as a notice that all our services...",
    },
  ]);

  // const recipient_list = ["All Companies"];
  const [selected_recipients] = useState([]);
  const [show_recipient_list, set_show_recipient_list] = useState(false);
  const recipient_section = useRef();
  useOutsideDetect(recipient_section, () => set_show_recipient_list(false));

  const [title, set_title] = useState("");
  const [message, set_message] = useState("");
  // const [recipient, set_recipient] = useState([]);

  const [errors, set_errors] = useState({
    title: [],
    message: [],
    recipient: [],
  });

  const validateTitle = title => {
    if (title.length === 0) {
      set_errors({
        ...errors,
        title: ["Title can not be empty", ...errors.title],
      });
      return set_title(title);
    }

    set_errors({ ...errors, title: [] });
    set_title(title);
  };

  const validateMessage = message => {
    if (message.length === 0) {
      set_errors({
        ...errors,
        message: ["Message body can not be empty", ...errors.message],
      });
      return set_message(message);
    }

    set_errors({ ...errors, message: [] });
    set_message(message);
  };

  return (
    <Layout>
      <DocumentHeader page="Push Notifications" />
      <div className="flex grow bordered-0 gap-4">
        <div className="flex flex-column flex-basis-40 bordered-0 ">
          <div className="flex bordered-0 justify-between align-center m-b-1">
            <h3 className="m-0">Send a Push Notification</h3>
          </div>

          <div
            className="grow bg-w r-8 p-1 scroll flex flex-column p-2"
            style={{
              maxHeight: "75vh",
            }}
          >
            <div className="">
              <div className="row m-b-1">
                <div className="input-field">
                  <label>Title</label>
                  <input
                    placeholder="Enter notification title"
                    value={title}
                    onChange={({ target }) => validateTitle(target.value)}
                  />
                  {errors?.title[0] && (
                    <p className="m-0 danger m-v-p5">{errors.title[0]}</p>
                  )}
                </div>
              </div>

              <div className="row m-b-1">
                <div className="input-field m-0">
                  <label>Message</label>
                  <textarea
                    value={message}
                    onChange={({ target }) => validateMessage(target.value)}
                    className="height-20"
                    placeholder="Enter notification message"
                  ></textarea>
                  {errors?.message[0] && (
                    <p className="m-0 danger m-v-p5">{errors.message[0]}</p>
                  )}
                </div>
              </div>

              <div className="row m-b-1" ref={recipient_section}>
                <div className="input-field relative m-0 ">
                  <label>Recipient</label>
                  <p
                    onClick={() =>
                      set_show_recipient_list(!show_recipient_list)
                    }
                    className="pseudo-input m-v-0 gray"
                  >
                    {selected_recipients.length === 0
                      ? "Select Recipients"
                      : selected_recipients}
                  </p>
                  <div className={`dropdown ${!show_recipient_list && "hide"}`}>
                    <div className="dropdown-item">
                      <p className="m-0">All Companies</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row m-v-1">
                <div className="m-0 flex align-center gap-half">
                  <div className="checkbox-wrapper">
                    <input
                      type="checkbox"
                      id={"save-notification"}
                      name={"save-notification"}
                    />
                  </div>
                  <p className="m-0 gray">Save this push notification</p>
                </div>
              </div>
            </div>

            <div className="m-t-auto bordered">
              <TextButton
                text="Send Push Notification"
                styleProp={{
                  width: "100%",
                  background: colors.purple,
                  color: colors.white,
                  padding: "1em",
                }}
              />
            </div>
          </div>
        </div>

        <div className="grow">
          <div className="flex justify-between align-center m-b-1">
            <h3 className="m-0 ">Saved Push Notifications</h3>
          </div>

          <div
            className="grow bg-w r-8 p-2 scroll"
            style={{
              height: "75vh",
              maxHeight: "75vh",
            }}
          >
            {push_notifications.map(el => {
              let actions = [
                {
                  title: "Push this Notification",
                  style: { color: "red" },
                },
                {
                  title: "Edit",
                },
                {
                  title: "Delete",
                },
              ];
              return (
                <div
                  className="flex align-center gap-1  border-bottom-gray-2"
                  key={el.uuid}
                >
                  <div className="flex justify-center align-center gap-1 p-1">
                    <div className="checkbox-wrapper">
                      <input type="checkbox" id={el.uuid} name={el.uuid} />
                    </div>
                    <p className="m-0 gray">{el.title}</p>
                  </div>
                  <div className="grow">
                    <p className="m-0 gray">{el.description}</p>
                  </div>
                  <div>
                    <DotsButton actions={actions} />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SuperAdminPushNotifications;
