import React from "react";
import styled from "styled-components";

const Content = ({ children }) => {
  return (
    <StyledContent>
      <div className="main-content">{children}</div>
    </StyledContent>
  );
};

export default Content;
const StyledContent = styled.div`
  flex-grow: 1;
  display: flex;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;

  .main-content {
    flex: 1;
    
    display: flex;
    flex-direction: column;
    
    max-width: 100%;
    max-height: 100%;
    overflow-x hidden;

    @media (max-width: 1024px) {
      padding: 5% 5%;
    }

    @media (min-width: 1024px) {
      padding: 2em 5em;
    }
  }
`;
