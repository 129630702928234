import React from "react";
import styled from "styled-components";
import Lottie from "react-lottie";
import lottieDefaultConfig from "../../selectors/lottieDefaultConfig";
import animationData from "../../assets/lottie/empty.json";

export const EmptyRenderer = () => {
  const defaultOptions = { ...lottieDefaultConfig, animationData };

  return (
    <Empty>
      <td colSpan={"100%"}>
        {/* <Lottie options={defaultOptions} height={80} width={100} /> */}
        <p className="gray">No data</p>
      </td>
    </Empty>
  );
};

const Empty = styled.tr`
  font-size: 16px;
  width: 100%;

  td {
    width: 100%;
    max-width: 100%;
    white-space: nowrap;
    text-align: center;
  }
`;

// // display: flex;
// align-items: center;
// justify-content: center;
