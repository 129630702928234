import React, { useState } from "react";
import styled from "styled-components";
import { Head, PageTitle, Foot } from "../../components/account-creation";
import { ReactComponent as Logo } from "../../assets/svgs/logo-ellipsis.svg";
import Form from "../../components/account-creation/account-information/Form";
import StepsAlt from "../../components/account-creation/StepsAlt";

const AccountInformation = () => {
  return (
    <StyledAccountInformation>
      <div className="no-show-responsive">
        <Head back={false} currentStep={1} />
      </div>
      <div className="show-responsive">
        <Logo />
        <StepsAlt currentStep={1} />
      </div>
      <PageTitle
        title="Account Information"
        subTitle="Provide your personal information to get started."
        titleSize="1em"
      />
      <Form />
    </StyledAccountInformation>
  );
};

export default AccountInformation;

const StyledAccountInformation = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  @media (max-width: 1024px) {
    .no-show-responsive {
      display: none;
    }

    .show-responsive {
      display: initial;
    }
  }

  @media (min-width: 1024px) {
    .no-show-responsive {
      display: initial;
    }

    .show-responsive {
      display: none;
    }
  }
`;
