import React from "react";
import { BranchManagersTable } from "../components/branch-managers/BranchManagersTable";
import { Layout } from "../components/dashboard";
import { DocumentHeader } from "../components/general/DocumentHeader";

const BranchManagers = props => {
  const { openModal } = props.location?.state || {};
  return (
    <>
      <DocumentHeader page="Branch Managers" />
      <Layout>
        <BranchManagersTable openModal={openModal} />
      </Layout>
    </>
  );
};

export { BranchManagers };
