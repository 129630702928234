import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Sidebar from "./Sidebar";
import { useAppContext } from "../../context";
import { ContentHeader } from "../general";
import BreakpointScreen from "../BreakpointScreen";
// import Walkthrough from "./WalkthroughOverlay";

function Layout(props) {
  const { tabs: Tabs } = props;
  const { fetchToken } = useAppContext();
  const history = useHistory();

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      const token = fetchToken();
      if (!token) history.push("/");
    }
    return () => (mounted = false);
  }, [fetchToken, history]);

  return (
    <StyledLayoutPage>
      <Sidebar />
      <div className="content">
        <ContentHeader />
        {Tabs && <Tabs />}
        <div className="page-specific-content">{props.children}</div>
      </div>
      <BreakpointScreen />
    </StyledLayoutPage>
  );
}

export default Layout;

const StyledLayoutPage = styled.div`
  display: flex;
  flex: 1;
  z-index: 0;
  max-height: 100vh;
  overflow: hidden;

  .content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    background: #fbf9f9;
    height: 100vh;
    overflow: hidden;
    flex-basis: 85%;

    .page-specific-content {
      flex: 1;
      max-height: 100vh;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 2em;
      display: flex;
      flex-direction: column;
    }
  }

  @media (min-width: 1024px) {
    .content {
      display: flex;
    }
  }

  @media (max-width: 1023px) {
    .content {
      display: none;
    }
  }
`;
