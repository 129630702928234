import styled from "styled-components";
import { Colors } from "../../constants";

export default styled.form`
  height: 80%;
  max-height: 100%;
  overflow: auto;
  position: relative;
  z-index: 5;

  .row {
    display: flex;
    align-items: center;
    margin-bottom: 0.5em;
  }

  .input-field {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .input-field + .input-field {
    margin-left: 0.5em;
  }

  .input-field > .label-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: CircularStdBold;
    font-style: normal;
    font-weight: normal;
    font-size: 0.7em;
    color: #000000;
  }

  .meta,
  .input-field > .meta {
    font-size: 0.6em !important;
    color: ${() => Colors.grey_2};
    margin: 0.2em 0;
  }

  .label,
  .input-field > label {
    font-family: CircularStdBold;
    font-style: normal;
    font-weight: normal;
    font-size: 0.7em;
    color: #000000;
    padding-bottom: 0.5em;
  }

  .input-field > input,
  select,
  .pseudo-input {
    width: 100%;
    line-height: 20px !important;
    padding: 0 0.5em !important;
    border: 1px solid #d9dae4;
    box-sizing: border-box;
    border-radius: 6px;
    font-family: CircularStd;
    font-size: small;
  }

  .input-field > select > option {
    background: white;
    color: ${() => Colors.grey_2};
  }

  .input-field > input:focus {
    box-shadow: 0px 0px 0px 4px rgba(114, 0, 76, 0.08);
    outline: none;
  }

  .dropdown {
    font-size: 0.7em;
    width: 99.5%;
    margin-bottom: 2em;
  }
`;
