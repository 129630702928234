import React from "react";
import styled from "styled-components";
import Content from "./Content";
import Sidebar from "./Sidebar";

const Wrapper = ({ children }) => {
  return (
    <StyledWrapper>
      <Sidebar />
      <Content />
    </StyledWrapper>
  );
};

export default Wrapper;

const StyledWrapper = styled.div`
  display: flex;
  flex: 1;
`;
