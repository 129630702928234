import React from "react";
import styled from "styled-components";
import { Head, PageTitle, Foot } from "../../components/account-creation";
import Form from "../../components/account-creation/company-information/Form";
import { useHistory } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/svgs/logo-ellipsis.svg";
import StepsAlt from "../../components/account-creation/StepsAlt";

const CompanyInformation = () => {
  let history = useHistory();
  return (
    <StyledCompanyInformation>
      <div className="no-show-responsive">
        <Head back={false} currentStep={2} />
      </div>
      <div className="show-responsive">
        <Logo />
        <StepsAlt currentStep={2} />
      </div>
      <PageTitle
        title="Company Information"
        subTitle="Let’s know a bit about your company."
        titleSize="1em"
      />
      <Form />
    </StyledCompanyInformation>
  );

  function handleClick() {
    history.push("/account-setup/choose-subscription");
  }
};

export default CompanyInformation;

const StyledCompanyInformation = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  @media (max-width: 1024px) {
    .no-show-responsive {
      display: none;
    }

    .show-responsive {
      display: initial;
    }
  }

  @media (min-width: 1024px) {
    .no-show-responsive {
      display: initial;
    }

    .show-responsive {
      display: none;
    }
  }
`;
