import { useQuery } from "react-query";
import { get } from "../../api/transport";

export const useIndustries = () => {
  const queryKey = "industries";
  const queryFn = () => get("/industry");
  const select = response => response.data.results;
  const options = { select };
  return useQuery(queryKey, queryFn, options);
};
