import React, { useState, useCallback, useEffect } from "react";
import Modal from "react-modal";
import { render } from "react-dom";
import { ReactComponent as Close } from "../../assets/svgs/close.svg";
import { Colors } from "../../constants";
import { TextButton } from "../general";

const Confirm = ({ refShow, ...props }) => {
  let resolve;

  const [isOpen, setIsOpen] = useState(false);

  let [text, setText] = useState(
    "Are you sure you want to complete this action?"
  );

  const handleCancel = useCallback(() => {
    setIsOpen(false);
    resolve(false);
  }, []);

  const handleConfirm = useCallback(() => {
    setIsOpen(false);
    resolve(true);
  }, []);

  const show = useCallback(props_object => {
    setText(props_object?.text);
    setIsOpen(true);

    return new Promise(res => {
      resolve = res;
    });
  }, []);

  useEffect(() => {
    let mounted = true;
    if (mounted) refShow(show);
    return () => (mounted = false);
  }, [show]);

  function afterOpenModal() {
    // subtitle.style.color = default_colors.gray;
  }

  const customStyles = {
    modalStyles: {
      overlay: {
        background: "rgba(0,0,0,0.7)",
      },
      content: {
        background: Colors.white,
        margin: "5em auto",
        width: "30%",
        height: "18%",
        display: "flex",
      },
    },
  };

  let subtitle;

  // Modal.setAppElement("#root");

  return (
    <Modal
      isOpen={isOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={handleCancel}
      style={customStyles.modalStyles}
      contentLabel="Example Modal"
      ariaHideApp={false}
    >
      <div className="flex flex-1 flex-column justify-between p-0">
        <div className="flex justify-between align-center">
          <h2 className="bold m-0">Confirm Action</h2>
          <Close onClick={handleCancel} className="pointer" />
        </div>
        <div className="p-t-p5 grow">
          <p className="gray">{text}</p>
        </div>
        <div className="flex justify-center align-center m-t-auto">
          <div className="mr-half">
            <TextButton
              styleProp={{
                padding: ".8em 2em",
                background: Colors.purple,
                color: Colors.white,
              }}
              text="OK"
              onClick={handleConfirm}
            />
          </div>
          <div className="ml-half">
            <TextButton
              styleProp={{
                padding: ".8em 2em",
              }}
              text="Cancel"
              onClick={handleCancel}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

Confirm.create = props => {
  let containerElement = document.createElement("div");
  const ref = { show: null };
  document.body.appendChild(containerElement);
  render(
    <Confirm
      {...props}
      refShow={showRef => {
        ref.show = showRef;
      }}
    />,
    containerElement
  );

  return ref;
};

export default Confirm.create();
