import React from "react";
import styled from "styled-components";
import { ReactComponent as Cog } from "../../assets/svgs/cog.svg";
import { ReactComponent as Bubble } from "../../assets/svgs/chat-bubble.svg";
import { ReactComponent as Exit } from "../../assets/svgs/exit.svg";
import { Colors } from "../../constants";
import { useHistory } from "react-router-dom";
import { useAppContext } from "../../context";
import axios from "axios";
import toast from "react-hot-toast";
import errorHandler from "../../selectors/errorHandler";
import { useDeviceSettingsContext } from "../../context/DeviceSettingsContext";
import { post } from "../../api/transport";
// import NotificationItem from "./NotificationItem";

const ProfileDropdown = ({ toggleIsActive }) => {
  // const { appState, appDispatch } = useContext(AppContext);

  const { actions, action, fetchToken } = useAppContext();
  const { actions: settings_actions, action: settings_action } =
    useDeviceSettingsContext();
  const history = useHistory();

  const handleSettingsClick = event => {
    event.preventDefault();
    history.replace("/account/settings/account-information/");
    toggleIsActive();
  };

  return (
    <StyledDropdown>
      <DropdownItem
        text="Settings"
        IconProp={Cog}
        onClick={handleSettingsClick}
        // toggleIsActive={toggleIsActive}
      />
      {/* <DropdownItem
        text="Support"
        IconProp={Bubble}
        onClick={toggleIsActive}
        toggleIsActive={toggleIsActive}
      /> */}
      <DropdownItem
        text="Logout"
        IconProp={Exit}
        textColor={Colors.red}
        onClick={handleLogout}
        // toggleIsActive={toggleIsActive}
      />
    </StyledDropdown>
  );

  function handleLogout() {
    toast.loading("Logging out...");

    post(`/users/token/logout/`)
      .then(response => {})
      .catch(error => {
        errorHandler(error);
      })
      .finally(() => {
        sessionStorage.clear();
        action(actions.userLogout);
        settings_action(settings_actions.clear_settings);
        toggleIsActive();
        history.push("/login");
        toast.success("Successfully logged out");
      });
  }
};

export default ProfileDropdown;

const StyledDropdown = styled.div`
  position: absolute;
  background: #ffffff;
  top: 150%;
  right: 0;
  display: flex;
  flex-direction: column;
  border: 1px solid #f5f3f3;
  box-sizing: border-box;
  box-shadow: 0px 4px 16px rgba(21, 21, 21, 0.08);
  border-radius: 4px;
  z-index: 2;
  overflow: hidden;
`;

function DropdownItem({ IconProp, text, styleProp, textColor, onClick }) {
  return (
    <StyledDropdownItem
      textColor={textColor}
      style={{ ...styleProp }}
      onClick={onClick}
    >
      <div className="option-icon-section">
        <IconProp style={{ color: "#696969", width: "15px", height: "15px" }} />
      </div>
      <div className="option-name-section">
        <p>{text}</p>
      </div>
    </StyledDropdownItem>
  );
}

const StyledDropdownItem = styled.div`
  width: 170px;
  padding: 1em;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  transition: 0.2s ease;
  cursor: pointer;
  
  
  &:hover {
    background: ${props => Colors.grey}
  }

  p {
    font-size: 14px;
    color: ${props => props.textColor || `#696969`}};
  }
  .option-icon-section + .option-name-section {
    padding-left: 0.5em;
  }

  .option-icon-section {
    // border: 1px solid;
    display: flex;
    align-items: center;
  }
  .option-name-section {
    // border: 1px solid;
    flex-grow: 1;
    display: flex;
    align-items: center;
  }
`;
