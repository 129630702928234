export const initialState = {
  showWalkthrough: false,
  profile: {},
  user: null,
  company: null,
  companyChanges: {},
  currentSubscription: undefined,
  isCurrentSubscriptionActive: false,
  subscription: {
    device_count: undefined,
    duration: "month",
    unit_price: 0,
    discount: 0,
  },
  branches: [],
};

export const actions = {
  setUser: "SET_USER",
  setToken: "SET_TOKEN",
  setCompany: "SET_COMPANY",
  setCurrentSubscription: "SET_CURRENT_SUBSCRIPTION",
  setCurrentSubscriptionActive: "SET_CURRENT_SUBSCRIPTION_ACTIVE",
  setSubscription: "SET_SUBSCRIPTION",
  removeToken: "REMOVE_TOKEN",
  userLogout: "USER_LOGOUT",
  setProfile: "SET_PROFILE",
  setCompanyChanges: "SET_COMPANY_CHANGES",
  setBranches: "SET_BRANCHES",
  removeBranch: "REMOVE_BRANCH",
  toggleOnboardingStepStatus: "TOGGLE_ONBOARDING_STEP_STATUS",
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case actions.setUser:
      return {
        ...state,
        user: {
          ...state.user,
          ...payload.user,
        },
      };

    case actions.setToken:
      sessionStorage.setItem("token", payload.token);
      return {
        ...state,
        token: payload.token,
      };

    case actions.setCompany:
      return {
        ...state,
        company: payload.company,
      };

    case actions.toggleOnboardingStepStatus:
      return {
        ...state,
        profile: {
          ...state.profile,
          company: {
            ...state.profile.company,
            ...payload,
          },
        },
      };

    case actions.setSubscription:
      return {
        ...state,
        subscription: {
          ...state.subscription,
          ...payload,
        },
      };

    case actions.setProfile:
      return {
        ...state,
        profile: {
          ...state.profile,
          ...payload.profile,
        },
      };

    case actions.setCompanyChanges:
      return {
        ...state,
        companyChanges: {
          ...state.companyChanges,
          ...payload,
        },
      };

    case actions.setCurrentSubscription:
      return {
        ...state,
        currentSubscription: payload.currentSubscription,
      };

    case actions.setCurrentSubscriptionActive:
      return {
        ...state,
        isCurrentSubscriptionActive: payload.isCurrentSubscriptionActive,
      };

    case actions.userLogout:
      sessionStorage.removeItem("token");
      return initialState;

    case "USER_LOGIN":
      localStorage.setItem("token", payload.authToken);
      return {
        ...state,
        isLoggedIn: true,
        authToken: payload.authToken,
      };

    case "CREATE_USER":
      return {
        ...state,
        profile: payload.profile,
      };

    case "TOGGLE_WALKTHROUGH":
      return {
        ...state,
        showWalkthrough: payload.showWalkthrough,
      };

    case "SET_AUTH_TOKEN":
      return {
        ...state,
        authToken: payload.authToken,
      };

    case "SET_PROFILE":
      return {
        ...state,
        profile: payload.profile,
      };

    default:
      return state;
  }
};
