import { useState } from "react";
import toast from "react-hot-toast";
import { useQuery } from "react-query";
import { get } from "../../api/transport";
import { useAppContext } from "../../context";

const defaultOptions = {
  // refetchOnWindowFocus: true,
  refetchOnmount: true,
  // refetchOnReconnect: true,
  // retry: false,
  // staleTime: 120000,
};

export const useSentiments = () => {
  const { appState } = useAppContext();
  const profile = appState.profile || {};
  const is_admin = profile?.is_owner;

  const queryKey = "feedbacks";

  const queryFn = () => get("/user/sentiment/");

  const select = response => response.data.results;

  const options = { select, ...defaultOptions };

  return useQuery(queryKey, queryFn, options);
};

export const useOverviewData = () => {
  const queryKey = ["feedbacks"];

  const queryFn = () => get("/user/overview/");

  const options = { ...defaultOptions };

  return useQuery(queryKey, queryFn, options);
};

export const usePieChartData = () => {
  const { appState } = useAppContext();
  const profile = appState.profile || {};
  const is_admin = profile?.is_owner;

  const queryKey = "feedbacks";

  const queryFn = () => get("/user/sentiment/");

  const select = response => {
    let sentiments = response?.data?.results?.filter(el => {
      if (!is_admin) {
        return el?.tablet?.location?.find(
          el => el?.uuid === profile?.branch?.uuid
        );
      }
      return el;
    });
    return sentiments;
  };

  const options = { select, ...defaultOptions };

  return useQuery(queryKey, queryFn, options);
};

export const useRecentFeedbacks = (orderby = "desc") => {
  const [offset] = useState(0);
  const [limit] = useState(5);

  const queryKey = ["recent-feedbacks", orderby];
  const queryFn = () =>
    get(`/user/sentiment/?offset=${offset}&limit=${limit}&orderby=${orderby}`);
  const select = response => response.data.results;

  const options = { select, ...defaultOptions };

  return useQuery(queryKey, queryFn, options);
};

export const useSentimentOverview = (
  rating = "",
  orderby = "asc",
  filter_type = "",
  date_before = "",
  date_after = "",
  offset = "",
  limit = ""
) => {
  const queryKey = [
    "sentiment-overview",
    rating,
    orderby,
    filter_type,
    date_before,
    date_after,
  ];

  const queryFn = () =>
    get(
      `/user/sentiment/overview/?offset=${offset}&limit=${limit}&rating=${rating}&orderby=${orderby}&filter_type=${filter_type}&date_before=${date_before}&date_after=${date_after}`
    );

  const select = response => response?.data;

  const onError = () => {
    return toast.error("Oops! An error occured. Please try again");
  };

  const options = { select, ...defaultOptions };

  return useQuery(queryKey, queryFn, options);
};
