import axios from "axios";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import { post } from "../api/transport";
import { PageTitle } from "../components/account-creation";
import { GenericButton } from "../components/general/Button";
import { FullScreenPage } from "../components/general/FullScreenPage";
import { Sidebar } from "../components/login";
import { useAppContext } from "../context";
import errorHandler from "../selectors/errorHandler";
import Content from "../components/account-creation/Content";
import { DocumentHeader } from "../components/general/DocumentHeader";

export const SetNewPassword = () => {
  const history = useHistory();
  // const { fetchToken } = useAppContext();
  const [password, set_password] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, set_loading] = useState(false);
  const [errors, set_errors] = React.useState({
    password: [],
    password_confirm: [],
  });

  const changePasswordRequest = data => {
    return post("/auth-token/users/reset_password_confirm/", data)
      .then(response => response.data)
      .catch(error => errorHandler(error));
  };

  const disabled =
    loading ||
    password.length === 0 ||
    confirmPassword.length === 0 ||
    Object.keys(errors).some(el => errors[el].length > 0);

  const handleSubmit = async () => {
    // event.preventDefault();
    set_loading(true);

    try {
      const href = history.location.pathname;
      const [, , , uid, token] = href.split("/");

      await changePasswordRequest({
        uid,
        token,
        new_password: password,
      });
      // toast.success("Success! You have successfully reset your password");
      set_loading(false);
      history.push("/new-password-set");
    } catch (error) {
      set_loading(false);
      return errorHandler(error);
    }
  };

  function validatePassword(passwordArg) {
    if (passwordArg === "") {
      set_password(passwordArg);
      return set_errors({
        ...errors,
        password: ["Password can not be empty", ...errors.password],
      });
    } else if (passwordArg.length < 8) {
      set_password(passwordArg);
      return set_errors({
        ...errors,
        password: ["Password is too short", ...errors.password],
      });
    } else if (!passwordArg.match(/\d+/g)) {
      set_password(passwordArg);
      return set_errors({
        ...errors,
        password: ["Password must include a number", ...errors.password],
      });
    }

    set_errors({
      ...errors,
      password: [],
    });
    return set_password(passwordArg);
  }

  function validatePasswordConfirm(confirmPassword) {
    if (confirmPassword === "") {
      setConfirmPassword(confirmPassword);
      return set_errors({
        ...errors,
        password_confirm: ["Field can not be empty", ...errors.password],
      });
    } else if (password !== confirmPassword) {
      setConfirmPassword(confirmPassword);
      return set_errors({
        ...errors,
        password_confirm: [
          "Passwords do not match",
          ...errors.password_confirm,
        ],
      });
    }

    set_errors({
      ...errors,
      password_confirm: [],
    });
    setConfirmPassword(confirmPassword);
  }

  return (
    <FullScreenPage>
      <DocumentHeader page="Reset Password" />
      <div className="flex grow flex-row">
        <Sidebar />
        <Content>
          <p
            className="purple m-0 pointer bold"
            onClick={() => history.push("/login")}
          >
            <span>&larr;</span> Back to Login
          </p>
          <PageTitle title="Set a new password" subTitle={""} />
          <div className="row m-b-2">
            <div className="input-field">
              <label>New Password</label>
              <input
                value={password}
                onChange={e => validatePassword(e.target.value)}
                type="password"
                placeholder="Enter a password"
                required={true}
              />
              {errors.password[0] && (
                <p className="error-msg">{errors.password[0]}</p>
              )}
            </div>
          </div>
          <div className="row">
            <div className="input-field">
              <label>Confirm New Password</label>
              <input
                value={confirmPassword}
                onChange={e => validatePasswordConfirm(e.target.value)}
                type="password"
                placeholder="Confirm password"
              />
              {errors.password_confirm[0] && (
                <p className="error-msg">{errors.password_confirm[0]}</p>
              )}
            </div>
          </div>
          <div className="bordered-0 m-v-2 flex flex-row justify-end">
            <GenericButton disabled={disabled} onClick={handleSubmit}>
              <div className="flex justify-between align-center grow">
                <p className="m-0 white">
                  {loading ? "loading" : "Reset Password"}
                </p>
                <>&rarr;</>
              </div>
            </GenericButton>
          </div>
        </Content>
      </div>
    </FullScreenPage>
  );
};
