import React from "react";
import styled from "styled-components";
import Form from "./Form";
import { PageTitle } from "../account-creation";
import { ReactComponent as Logo } from "../../assets/svgs/logo-ellipsis.svg";
import colors from "../../constants/colors";
import { useHistory } from "react-router-dom";
import { ReactComponent as GooglePlay } from "../../assets/svgs/google-play.svg";
import { ReactComponent as Apple } from "../../assets/svgs/apple.svg";
import DownloadLink from "./DownloadLink";

const Content = () => {
  const history = useHistory();
  return (
    <StyledContent>
      <div className="main-content">
        <div className="row logo-content">
          <Logo />
        </div>
        <PageTitle
          title="Login"
          subTitle="Provide your account information to continue."
          titleSize="1.5em"
        />
        <Form />
        <div className="center-align">
          <p className="m-0 signup-text">
            Don’t have an account?{" "}
            <span
              onClick={() =>
                history.replace("/account-setup/account-information")
              }
              className="bold purple"
            >
              Sign Up
            </span>
          </p>
        </div>
        <div className="center-align m-t-auto flex gap-1 links">
          <div className="flex-1">
            <DownloadLink
              href="https://apps.apple.com/gh/app/echo-feedback-app/id1487196374"
              icon={Apple}
              store="App Store"
              variant="secondary"
            />
          </div>
          <div className="flex-1">
            <DownloadLink
              href="https://play.google.com/store/apps/details?id=co.effectstudios.echo"
              icon={GooglePlay}
              store="Play Store"
              variant="secondary"
            />
          </div>
        </div>
      </div>
    </StyledContent>
  );
};

export default Content;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 70%;
  flex-grow: 1;
  background: ${() => colors.bg};

  .main-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 10% 15%;
  }

  @media (max-width: 1024px) {
    flex: 1;

    .logo-content,
    .signup-text {
      display: initial;
    }

    .main-content {
      padding: 10%;
      .links {
        display: flex;
      }
    }
  }

  @media (min-width: 1024px) {
    .logo-content,
    .signup-text {
      display: none;
    }
    .main-content {
      .links {
        display: none;
      }
    }
  }
`;
