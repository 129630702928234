import React, { useEffect, useState, useRef, useCallback } from "react";
import styled from "styled-components";
import Modal from "react-modal";
// import { Colors } from "../../constants";
import colors from "../../constants/colors";
import { ReactComponent as Close } from "../../assets/svgs/close.svg";
import { TextButton } from "../general";
import ModalTitle from "./ModalTitle";
import ModalFormStyleSheet from "./ModalFormStyleSheet";
import { useAppContext } from "../../context";
import { useOutsideDetect } from "../../selectors/UseOutsideDetect";
import toast from "react-hot-toast";
import DropdownLoader from "./DropdownLoader";
import errorHandler from "../../selectors/errorHandler";
import { post } from "../../api/transport";
import BillCard from "../account-creation/choose-subscription/BillCard";
import _ from "lodash";
import { capitalizeFirstLetter, determineTotalPrice } from "../../selectors";
import numeral from "numeral";
import { PaystackButton } from "react-paystack";
import PaystackPop from "@paystack/inline-js";

const UpgradeSubscriptionModal = props => {
  const billing_type_dict = {
    0: "Monthly",
    1: "Yearly",
  };

  const {
    current_subscription = {},
    subscriptions,
    set_current_subscription,
    set_transactions,
    transactions,
  } = props;
  const { appState } = useAppContext();
  const [loading, setLoading] = useState();
  const [max_number_tablet, set_max_number_tablet] = useState(0);
  const [billing_type, set_billing_type] = useState(0);
  const [device_count, set_device_count] = useState(0);
  const [showDropdown, setShowDropdown] = useState(false);

  const [subscription, set_subscription] = useState({
    unit_price: current_subscription?.subscription?.unit_price ?? 0.0,
    duration: billing_type_dict[billing_type]?.split("ly")[0].toLowerCase(),
  });

  const exchange_rate = process.env.REACT_APP_USD_CEDI_EXCHANGE_RATE;

  const dropdownRef = useRef();

  let dc_num = Number(device_count);

  useOutsideDetect(dropdownRef, () => setShowDropdown(false));

  subscriptions?.forEach(sub => {
    if (sub.max_number_tablet > max_number_tablet)
      set_max_number_tablet(sub.max_number_tablet);
  });

  const device_count_disabled =
    current_subscription?.number_tablet === max_number_tablet;

  const determine_unit_price = useCallback(() => {
    const subscriptions_not_empty = subscriptions.length !== 0;
    if (subscriptions_not_empty) {
      const subscription_package = subscriptions.find(el => {
        const floor = el.min_number_tablet;
        const ceil = el.max_number_tablet;
        const is_in_range = _.inRange(
          dc_num + current_subscription?.number_tablet,
          floor,
          ceil + 1
        );

        return is_in_range;
      });

      if (subscription_package) {
        set_subscription({
          ...subscription,
          unit_price: subscription_package.unit_price,
          subscription_id: subscription_package.id,
          device_count: dc_num + current_subscription?.number_tablet,
          duration: billing_type_dict[billing_type]
            ?.split("ly")[0]
            ?.toLowerCase(),
        });
      }
    }
  }, [
    device_count,
    current_subscription?.number_tablet,
    subscriptions,
    billing_type,
  ]);

  const handleOpenMakePaymentModal = async () => {
    const paystack = new PaystackPop();

    paystack.newTransaction({
      email: sessionStorage.getItem("email"),
      amount: determineTotalPrice(subscription) * 100,
      key: process.env.REACT_APP_PAYSTACK_KEY,
      currency: "GHS",
      channels: ["card", "mobile_money"],
      onSuccess: async response => {
        const { trxref: reference } = response;
        try {
          await post("/paystack/verify/", { reference });
          props.closeModal();
          toast.success("Success");
        } catch (error) {
          errorHandler(error);
        } finally {
          setLoading(false);
        }
      },
      onCancel: () => {
        setLoading(false);
      },
    });
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) Modal.setAppElement("body");
    return () => (mounted = false);
  }, []);

  const createNewTransaction = () => {
    const transaction_data = {
      amount_paid: determineTotalPrice(subscription) * 100,
      coupon_amount: "0.00",
      payment_method: "paystack",
      balance: "0.00",
      subscription: subscription.subscription_id,
      number_tablet: Number(subscription?.device_count),
      billing_type: capitalizeFirstLetter(subscription?.duration.toLowerCase()),
      status: "Pending",
    };

    return post("/user/transaction/", transaction_data)
      .then(response => response.data)
      .catch(error => errorHandler(error));
  };

  const handleUpgradePayment = async () => {
    setLoading(true);
    try {
      const created_transaction = await createNewTransaction();
      if (created_transaction) {
        set_current_subscription(created_transaction);
        set_transactions([created_transaction, ...transactions]);
        await handleOpenMakePaymentModal();
      }
    } catch (error) {
      errorHandler(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) determine_unit_price();
    return () => (mounted = false);
  }, [
    device_count,
    current_subscription?.number_tablet,
    subscriptions,
    billing_type,
  ]);

  return (
    <Modal
      style={{
        overlay: {
          background: "rgba(0,0,0,0.7)",
        },
        content: {
          background: colors.white,
          margin: "5em auto",
          width: "30%",
          height: "50%",
        },
      }}
      isOpen={props.isOpen}
      onRequestClose={props.closeModal}
    >
      <UpgradeSubscriptionModalContent>
        <div className="close-button-area">
          <Close id="close-button" onClick={props.closeModal} />
        </div>

        <div className="flex grow flex-column ">
          <ModalTitle
            className="z-1"
            title="Upgrade Subscription"
            subTitle={"How many more devices do you need?"}
          />

          <ModalFormStyleSheet>
            <div className="row z-1">
              <div className="input-field">
                <label>Number of Devices</label>
                <input
                  disabled={device_count_disabled}
                  value={device_count}
                  onChange={({ target: { value } }) => {
                    if (
                      value <=
                        max_number_tablet -
                          current_subscription?.number_tablet &&
                      value >= 0
                    ) {
                      set_device_count(value);
                    }
                  }}
                  type="number"
                  max={max_number_tablet - current_subscription?.number_tablet}
                  min={0}
                  placeholder="0"
                  onInput={() => void 0}
                  // onKeyUp={({target: {value}}) => {}}
                />
              </div>

              <div className="relative input-field z-2" ref={dropdownRef}>
                <label>Billing Type</label>
                <div
                  onClick={() => setShowDropdown(!showDropdown)}
                  className="pseudo-input gray flex justify-between align-center pointer "
                >
                  <p>{billing_type_dict[billing_type]}</p>
                  <span className="pointer">▾</span>
                </div>

                <div
                  className="dropdown shadow bordered absolute bg-w p-0 p-t-2"
                  style={{
                    display: showDropdown ? "block" : "none",
                    top: "110%",
                    width: "99%",
                  }}
                >
                  {Object.keys(billing_type_dict).map(el => {
                    return (
                      <div
                        className="m-b-p5 p-p5 pointer dropdown-item"
                        key={Math.random()}
                        onClick={() => {
                          set_billing_type(el);
                          setShowDropdown(false);
                        }}
                      >
                        <p className="m-0 gray-2">{billing_type_dict[el]}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className="flex gap-half">
              <div className="flex-basis-50 flex ">
                <BillCard
                  figure={subscription?.unit_price * exchange_rate}
                  style={{ margin: 0, flex: 1 }}
                />
              </div>
              <div className="flex-basis-50 flex ">
                <BillCard
                  style={{ margin: 0, flex: 1 }}
                  header="Total Subscription Fee"
                  figure={determineTotalPrice(subscription) * exchange_rate}
                  perMeasure={billing_type_dict[billing_type]}
                />
              </div>
            </div>
          </ModalFormStyleSheet>

          <div className="flex justify-center align-center m-0 p-v-1 bg-red">
            <TextButton
              text="Pay Later"
              styleProp={{
                padding: ".8em 2em",
              }}
              onClick={() => props.closeModal()}
            />
            <TextButton
              styleProp={{
                padding: ".8em 2em",
              }}
              background={colors.purple}
              color="white"
              text={
                loading
                  ? `Please Wait`
                  : `Pay Now $${numeral(
                      determineTotalPrice(subscription) * exchange_rate
                    ).format("0,0.00")}`
              }
              onClick={handleUpgradePayment}
            />
          </div>
        </div>
      </UpgradeSubscriptionModalContent>
    </Modal>
  );
};

export default UpgradeSubscriptionModal;

const UpgradeSubscriptionModalContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .close-button-area {
    display: flex;
    flex-direction: row-reverse;
    // ;

    #close-button {
      // border: 1px solid;
      height: 0.5em;
      width: 0.5em;
      cursor: pointer;
    }
  }
`;
