import numeral from "numeral";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { get } from "../api/transport";
import { Layout } from "../components/dashboard";
import { DocumentHeader } from "../components/general/DocumentHeader";
import { Building, Device, Graph } from "../components/icons";
import { AdminOverviewTable, OverviewStat } from "../components/overview";
import errorHandler from "../selectors/errorHandler";

const SuperAdminOverview = () => {
  const [loading, set_loading] = useState(true);
  const [overview_data, set_overview_data] = useState([]);

  const fetchOverviewData = async () => {
    try {
      let data = await get("/admin/overview");
      set_overview_data(data.data);
    } catch (error) {
      errorHandler(error);
    } finally {
      set_loading(false);
    }
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) fetchOverviewData();
    return () => (mounted = false);
  }, []);

  const stats = [
    {
      name: "Total Accounts",
      count: numeral(overview_data?.total_accounts).format("0,0"),
      icon: Building,
      color: "black",
      loading,
    },
    {
      name: "Active Accounts",
      count: numeral(overview_data?.total_active_accounts).format("0,0"),
      icon: Building,
      color: "purple",
      loading,
    },
    {
      name: "Total Tablets",
      count: numeral(overview_data?.total_tablets).format("0,0"),
      icon: Device,
      color: "purple",
      loading,
    },
    {
      name: "Total Revenue",
      count: `₵${numeral(overview_data?.total_revenue).format("0,0")}`,
      icon: Graph,
      color: "purple",
      loading,
    },
    {
      name: "Total Business Signups",
      count: `₵${numeral(overview_data?.total_companies).format("0,0")}`,
      icon: Graph,
      color: "purple",
      loading,
    },
    // {
    //   name: "Another Metric",
    //   count: "0",
    //   icon: undefined,
    //   color: "purple",
    // },
  ];

  return (
    <Layout>
      <DocumentHeader page="Overview" />
      <div className="flex grow bordered-0 flex-column max-width-100">
        <div className="">
          <h2 className="m-0">Overview</h2>
        </div>

        <AdminStatCardRow>
          {stats.map(stat => {
            return (
              <div className="stat-card">
                <OverviewStat {...stat} />
              </div>
            );
          })}
        </AdminStatCardRow>

        <div
          className="grow bordered-0 flex flex-column"
          style={{ flexBasis: "65%" }}
        >
          <AdminOverviewTable />
        </div>
      </div>
    </Layout>
  );
};

export default SuperAdminOverview;

const AdminStatCardRow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 1em 0;
  flex-basis: 35%;
  max-height: 30vh;
  max-width: 80vw;
  overflow-x: auto;

  .stat-card {
    min-height: 12vw;
    min-width: 14vw;
    display: flex;
  }
`;
