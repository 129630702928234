import validator from "validator";

export const validationOptions = {
  email: {
    required: "Email is required",
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      message: "invalid email address",
    },
  },
  password: {
    required: "Password is required",
    minLength: {
      value: 8,
      message: "Password must have at least 8 characters",
    },
  },
  phone: {
    required: "Phone Number is required",
    validate: {
      isPhoneNumber: (value = "") =>
        validator.isMobilePhone(value, false, {
          strictMode: true,
        }) || "Enter a valid Phone Number",
    },
  },
  required: {
    required: "This field is required",
  },
};

// Regex Email Pattern which covers edge cases
// "([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|\"([]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|[[\t -Z^-~]*])",
