import React from "react";
import styled from "styled-components";
import { ReactComponent as Pie } from "../../assets/svgs/pie.svg";
import { Colors } from "../../constants";

const IconBtn = ({ Icon = Pie, background, legendStyle, active, onClick }) => {
  return (
    <StyledIconBtn active={active} background={background} onClick={onClick}>
      <Icon fill={Colors.purple} />
    </StyledIconBtn>
  );
};

export default IconBtn;

const StyledIconBtn = styled.div`
  width: 30px;
  height: 30px;
  // border: 1px solid #d9dae4;
  border: 1px solid
    ${({ background, active }) => (active ? Colors.purple : background)};
  box-sizing: border-box;
  border-radius: 4px;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: ${({ background, active }) =>
    active ? Colors.purple : background};
`;

//   background: ${props => (props.active ? "yellow" : "blue")};
// props.background
