import axios from "axios";
import _ from "lodash";
import React, { useRef, useState } from "react";
import toast from "react-hot-toast";
import { patch, post } from "../api/transport";
import { ColorPallete, Preview } from "../components/company";
import { Layout } from "../components/dashboard";
import { TextButton } from "../components/general";
import { DocumentHeader } from "../components/general/DocumentHeader";
import { UploadField } from "../components/logobg/UploadField";
import { Colors } from "../constants";
import { useAppContext } from "../context";
import { useDeviceSettingsContext } from "../context/DeviceSettingsContext";
import errorHandler from "../selectors/errorHandler";
import objectToFormData from "../selectors/objectToFormData";

const Logo = () => {
  const {
    appState: mainAppState,
    actions: mainAppActions,
    action: mainAppAction,
  } = useAppContext();
  const { appState, action, actions } = useDeviceSettingsContext();

  const [backgroundSetting, setBackgroundSetting] = useState("img");

  const {
    new_device_settings,
    saved_device_settings,
    device_settings_id: id,
  } = appState;

  const { logo_image, background_image, text_color, btn_color, bg_color } =
    new_device_settings;
  const {
    logo_image: logo_image_saved,
    background_image: background_image_saved,
    bg_color: bg_color_saved,
    text_color: text_color_saved,
    btn_color: btn_color_saved,
  } = saved_device_settings;

  const [loading, setLoading] = useState(false);
  const [save_loading, set_save_loading] = useState(false);

  const logoInputRef = useRef();
  const backgroundInputRef = useRef();

  const setLogo = logo_image => {
    action(actions.set_new_device_settings, { logo_image });
  };
  const setBackground = background_image => {
    return action(actions.set_new_device_settings, { background_image });
  };
  const toggleBackgroundSetting = () => {
    if (backgroundSetting === "img") {
      setBackgroundSetting("color");
    } else {
      setBackgroundSetting("img");
    }
  };
  const setBackgroundColor = bg_color =>
    action(actions.set_new_device_settings, {
      background_image: null,
      bg_color,
    });

  const setTextColor = text_color =>
    action(actions.set_new_device_settings, { text_color });

  const setButtonColor = btn_color =>
    action(actions.set_new_device_settings, { btn_color });

  return (
    <>
      <DocumentHeader page={`Logo & Background`} />
      <Layout>
        <div className="flex grow  m-v-2">
          <div className=" flex flex-basis-30 flex-column bg-w bordered r-8 p-2 m-r-2">
            <div className="flex flex-column m-b-1">
              <div className="flex justify-between align-center m-b-p5">
                <p className="m-0">Upload Company Logo</p>
              </div>
              <UploadField
                image={logo_image}
                setter={setLogo}
                showCenter={true}
                showBackground={false}
                refData={logoInputRef}
                idData={"logo-input-id"}
              />
            </div>

            <div className="flex flex-column m-b-1">
              <div className="flex justify-between align-center m-b-p5">
                <p className="m-0">
                  {backgroundSetting === "img"
                    ? "Upload a Background (Optional)"
                    : "Choose Background Color"}
                </p>
                <p
                  className="m-0 purple small-text pointer"
                  onClick={toggleBackgroundSetting}
                >
                  {backgroundSetting === "img"
                    ? "Select Background Colour"
                    : "Upload Background Image"}
                </p>
              </div>
              {backgroundSetting === "img" ? (
                <UploadField
                  image={background_image}
                  setter={setBackground}
                  showCenter={false}
                  showBackground={true}
                  refData={backgroundInputRef}
                  idData={"background-input-id"}
                />
              ) : (
                <ColorPallete
                  setColorProp={setBackgroundColor}
                  selectedColor={bg_color}
                  title=""
                />
              )}
            </div>

            <div className="flex flex-column p-b-1">
              <div className="m-b-1">
                <ColorPallete
                  setColorProp={setTextColor}
                  selectedColor={text_color}
                  title="Choose Text Color"
                />
              </div>
              <div className="m-b-1">
                <ColorPallete
                  setColorProp={setButtonColor}
                  selectedColor={btn_color}
                  title="Choose Button Color"
                />
              </div>
            </div>
            <div className="m-t-auto flex row">
              <div className="flex grow">
                <TextButton
                  styleProp={{
                    flex: 1,
                  }}
                  text="Discard Changes"
                  onClick={() => {
                    action(actions.set_new_device_settings, {
                      bg_color: null,
                      background_image: null,
                      btn_color: null,
                      logo_image: null,
                      text_color: null,
                    });
                  }}
                />
              </div>
              <div className="flex grow">
                <TextButton
                  no_margin={true}
                  styleProp={{
                    flex: 1,
                    padding: "1em",
                    margin: 0,
                    background: Colors.purple,
                    color: Colors.white,
                  }}
                  text="Save Changes"
                  onClick={() => saveChanges()}
                />
              </div>
            </div>
          </div>
          <div className="flex grow r-8">
            <Preview />
          </div>
        </div>
      </Layout>
    </>
  );

  function createSettings(data) {
    return post("/user/tablet-settings/", data)
      .then(response => response.data)
      .catch(error => errorHandler(error));
  }

  function patchSettings(data) {
    return patch(`/user/tablet-settings/${id}/`, data)
      .then(response => response.data)
      .catch(error => errorHandler(error));
  }

  async function saveChanges() {
    toast.loading("Please wait");
    // set_save_loading(false);

    const changes = {
      is_active: true,
      bg_color: bg_color || bg_color_saved,
      text_color: text_color || text_color_saved,
      btn_color: btn_color || btn_color_saved,
      status: "Approved",
      default: "Approved",
      company: mainAppState?.profile?.company?.id,
      background_image,
      logo_image,
    };
    const validated_changes = {};

    for (const key in changes) {
      if (!!changes[key]) {
        const element = changes[key];
        validated_changes[key] = element;
      }
    }

    try {
      if (id) {
        await patchSettings(objectToFormData(validated_changes));
      } else {
        await createSettings(objectToFormData(validated_changes));
      }
      toast.success("Saved");
      if (logo_image)
        mainAppAction(mainAppActions.toggleOnboardingStepStatus, {
          uploaded_logo: true,
        });
    } catch (error) {
      errorHandler(error);
    }
  }
};

export { Logo };
