import React from "react";
import { Layout } from "../components/dashboard";
import { FeedbacksTable } from "../components/feedbacks/FeedbacksTable";
import { DocumentHeader } from "../components/general/DocumentHeader";

const Feedbacks = () => {
  return (
    <Layout>
      <DocumentHeader page="Feedback" />
      <FeedbacksTable />
    </Layout>
  );
};

export { Feedbacks };
