import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Modal from "react-modal";
import { Colors } from "../../constants";
import { ReactComponent as Close } from "../../assets/svgs/close.svg";
import { TextButton } from "../general";
import ModalTitle from "./ModalTitle";
import axios from "axios";
import { useAppContext } from "../../context";
import { useOutsideDetect } from "../../selectors/UseOutsideDetect";
import ModalFormStyleSheet from "../modal/ModalFormStyleSheet";
import toast from "react-hot-toast";
import moment from "moment";
import errorHandler from "../../selectors/errorHandler";
import DropdownLoader from "./DropdownLoader";
import AutoCompleteInput from "../general/AutoCompleteInput";
import { patch, post } from "../../api/transport";

const EditQuestionModal = props => {
  const styles = {
    buttonStyles: {
      padding: ".5em",
      color: Colors.white,
      background: Colors.purple,
    },
    modalStyles: {
      overlay: {
        background: "rgba(0,0,0,0.7)",
      },
      content: {
        background: Colors.white,
        margin: "auto auto",
        width: "30%",
        height: "60%",
      },
    },
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) Modal.setAppElement("body");
    return () => (mounted = false);
  }, []);

  const {
    question: question_object,
    categories,
    setCategories: set_categories,
    branches,
    setBranches: set_branches,
  } = props;

  const [branchSelection, setBranchSelection] = useState("existing");
  const [selected_branches, set_selected_branches] = useState(
    question_object.location
  );
  const [selected_categories, set_selected_categories] = useState(
    question_object.categories
  );
  const category_dropdown_ref = useRef();
  const [category_fetch_loading, set_category_fetch_loading] = useState(false);
  const [show_category_dropdown, set_show_category_dropdown] = useState(false);

  const { fetchToken, appState } = useAppContext();
  // const [loading, setLoading] = useState();
  const dropdownRef = useRef();
  const [showDropdown, setShowDropdown] = useState(false);

  const [question, set_question] = useState(question_object.question);

  const [new_branch_name, set_new_branch_name] = useState("");
  const [new_branch_location, set_new_branch_location] = useState("");

  const [save_loading, set_save_loading] = useState(false);

  useOutsideDetect(category_dropdown_ref, () =>
    set_show_category_dropdown(false)
  );
  const profile = appState?.profile || {};
  const is_admin = profile?.is_owner || profile?.is_superuser;
  return (
    <Modal
      style={styles.modalStyles}
      isOpen={props.isOpen}
      onRequestClose={props.closeModal}
    >
      <EditQuestionModalContent>
        <div className="close-button-area">
          <Close id="close-button" onClick={props.closeModal} />
        </div>

        <div className="flex grow flex-column">
          <ModalTitle
            title="Edit Question"
            subTitle="This question will be later set to display on the specified devices."
          />
          <ModalFormStyleSheet>
            <div className="row">
              <div className="input-field">
                <div className="label-container">
                  <label>Enter Question</label>
                  <p
                    style={{
                      fontSize: ".6em",
                      color: Colors.grey_2,
                    }}
                  >
                    {question?.length}/60
                  </p>
                </div>
                <input
                  type="text"
                  placeholder="Eg. How was your experience with us?"
                  value={question}
                  onChange={({ target }) =>
                    question?.length < 60 && set_question(target.value)
                  }
                />
              </div>
            </div>

            <div
              className="row relative bordered-0"
              ref={category_dropdown_ref}
            >
              <div className="input-field">
                <label>Select Categories (Optional)</label>
                <div
                  onClick={() =>
                    set_show_category_dropdown(!show_category_dropdown)
                  }
                  className="pseudo-input gray flex justify-between align-center pointer "
                >
                  <p>
                    {selected_categories?.length > 0
                      ? `${
                          selected_categories?.length
                        } selected | ${selected_categories.map(
                          el => ` ${el.title}`
                        )}`
                      : "Select categories"}
                  </p>
                  <span className="pointer">▾</span>
                </div>
              </div>

              <div
                className="dropdown bordered absolute bg-w p-t-1"
                style={{
                  display: show_category_dropdown ? "block" : "none",
                  top: "110%",
                  width: "99.5%",
                }}
              >
                {category_fetch_loading && <DropdownLoader />}

                {categories?.map(category => {
                  const toggle_category_select = () => {
                    selected_categories.find(el => el.uuid === category.uuid)
                      ? set_selected_categories(
                          selected_categories.filter(
                            el => el.id !== category.id
                          )
                        )
                      : set_selected_categories([
                          ...selected_categories,
                          category,
                        ]);
                  };

                  return (
                    <div
                      key={Math.random()}
                      className="m-b-1 flex align-center pointer"
                      onClick={toggle_category_select}
                    >
                      <div className="checkbox-wrapper">
                        <input
                          type="checkbox"
                          id={category.uuid}
                          name={category.uuid}
                          checked={selected_categories.find(
                            el => el.uuid === category.uuid
                          )}
                          className="m-h-1"
                          onChange={toggle_category_select}
                        />
                      </div>
                      <p className="m-0 gray-2">{category.title}</p>
                    </div>
                  );
                })}
              </div>
            </div>

            {is_admin && (
              <div className="row">
                <div className="input-field">
                  <div className="label-container">
                    <label>Select Branch</label>
                    <p
                      onClick={toggleBranchSelection}
                      style={{
                        fontSize: ".8em",
                        color: Colors.purple,
                        cursor: "pointer",
                      }}
                    >
                      {branchSelection === "existing" && "+ Add a New Branch"}
                      {branchSelection === "new" && "Select an existing branch"}
                    </p>
                  </div>
                  {branchSelection === "existing" && (
                    <div className="relative bordered-0" ref={dropdownRef}>
                      <div className="input-field">
                        <div
                          onClick={() => setShowDropdown(!showDropdown)}
                          className="pseudo-input gray flex justify-between align-center pointer "
                        >
                          <p>
                            {selected_branches?.length > 0
                              ? `${
                                  selected_branches?.length
                                } selected | ${selected_branches.map(
                                  (el, idx) => {
                                    return ` ${el.name}`;
                                  }
                                )}`
                              : "You can select multiple branches"}
                          </p>
                          <span className="pointer">▾</span>
                        </div>
                        <p className="meta">
                          * This question will be available to be deployed on
                          devices across selected branches only
                        </p>
                      </div>
                      <div
                        className="dropdown bordered absolute bg-w p-t-1"
                        style={{
                          display: showDropdown ? "block" : "none",
                          top: "75%",
                          width: "99.5%",
                        }}
                      >
                        {/* {loading && <DropdownLoader />} */}
                        {branches?.length !== 0 && (
                          <div className="m-b-1 flex align-center">
                            <div className="checkbox-wrapper">
                              <input
                                type="checkbox"
                                id={"all-branches"}
                                name={"all-branches"}
                                checked={
                                  selected_branches?.length === branches?.length
                                }
                                className="m-h-1"
                                onChange={event => {
                                  // console.log(event.target.checked);
                                  if (!event.target.checked) {
                                    return set_selected_branches([]);
                                  }
                                  set_selected_branches(branches?.results);
                                }}
                              />
                            </div>
                            <p className="m-0 gray-2">All Branches</p>
                          </div>
                        )}

                        {branches?.results?.map(branch => (
                          <div
                            key={Math.random()}
                            className="m-b-1 flex align-center"
                          >
                            <div className="checkbox-wrapper">
                              <input
                                type="checkbox"
                                id={branch.uuid}
                                name={branch.uuid}
                                checked={selected_branches.find(
                                  el => el.uuid === branch.uuid
                                )}
                                className="m-h-1"
                                onChange={() => selectBranch(branch)}
                              />
                            </div>
                            <p className="m-0 gray-2">
                              {branch.name} | {branch.location_name}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  {branchSelection === "new" && (
                    <div className="row">
                      <div className="input-field">
                        <div className="label-container">
                          <label>Branch Name</label>
                        </div>
                        <input
                          value={new_branch_name}
                          onChange={e => set_new_branch_name(e.target.value)}
                          type="text"
                          placeholder="Eg. Greater Accra"
                        />
                      </div>
                      <div className="input-field relative">
                        <div className="label-container">
                          <label>Location</label>
                        </div>
                        <AutoCompleteInput
                          className="pseudo-input"
                          onChange={value => set_new_branch_location(value)}
                          placeholder="Eg. Agyia Zongo, Accra, Ghana"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </ModalFormStyleSheet>

          <div className="flex justify-center align-center m-0 p-v-1">
            <TextButton
              text="Continue"
              disabled={false}
              loading={save_loading}
              onClick={() => {
                saveChanges();
              }}
              styleProp={styles.buttonStyles}
            />
          </div>
        </div>
      </EditQuestionModalContent>
    </Modal>
  );

  function createBranch() {
    const data = {
      is_active: true,
      name: new_branch_name,
      location_name: new_branch_location,
      status: "Approved",
      description: "",
      company: appState?.profile?.company?.id,
    };
    return post("/user/branch", data)
      .then(response => response.data)
      .catch(error => errorHandler(error));
  }

  async function saveChanges() {
    let categories = selected_categories.map(el => el.id);
    let location = selected_branches.map(el => el.id);

    set_save_loading(true);
    toast.loading("Please wait");

    if (branchSelection === "new") {
      try {
        const new_branch = await createBranch();
        let new_branch_id = new_branch.id;
        set_branches([...branches, new_branch]);
        location = [...location, new_branch_id];
      } catch (error) {
        void 0;
        props.closeModal();
      }
    }

    const patch_data = {
      is_active: true,
      status: "public",
      company: appState.profile?.company?.id,
      question,
      categories,
      location,
    };

    patch(`/user/question-settings/${props.question.id}/`, patch_data)
      .then(response => {
        set_save_loading(false);
        toast.success("Success");

        const newSet = props.questions.map(el => {
          if (el.id === props.question.id) {
            return response.data;
          }
          return el;
        });

        props.setQuestions(newSet);
        props.closeModal();
      })
      .catch(error => {
        set_save_loading(false);
        errorHandler(error);
      });
  }

  function toggleBranchSelection() {
    if (branchSelection === "new") {
      setBranchSelection("existing");
    } else {
      setBranchSelection("new");
    }
  }

  function selectBranch(branch) {
    if (selected_branches.find(el => el.uuid === branch.uuid)) {
      set_selected_branches(
        selected_branches.filter(el => el.uuid !== branch.uuid)
      );
    } else {
      set_selected_branches([...selected_branches, branch]);
    }
  }
};

export default EditQuestionModal;

const EditQuestionModalContent = styled.div`
  // border: 1px solid;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .close-button-area {
    display: flex;
    flex-direction: row-reverse;
    // ;

    #close-button {
      // border: 1px solid;
      height: 0.5em;
      width: 0.5em;
      cursor: pointer;
    }
  }
`;
