import React, { useState, useMemo, useEffect } from "react";
import { Table } from "../table";
import mock from "../../assets/mock/mock.json";
import moment from "moment";
import { Colors } from "../../constants";
import { EmojiTextButton } from "../general";
import Searchbar from "../dashboard/Searchbar";
import { ReactComponent as Arrow } from "../../assets/svgs/topright.svg";
import { ReactComponent as Plus } from "../../assets/svgs/plus.svg";
import DotsButton from "../general/DotsButton";
import { AddQuestionModal } from "../modal";
import axios from "axios";
import { useAppContext } from "../../context";
import toast from "react-hot-toast";
import EditQuestionModal from "../modal/EditQuestion";
import errorHandler from "../../selectors/errorHandler";
import { delete_request, get, patch } from "../../api/transport";
import {
  useDeleteQuestion,
  useQuestions,
  useQuestionsData,
  useUpdateQuestion,
} from "./broker";
import ConfirmModal from "../modal/ConfirmModal";
import { useCategories } from "../categories/broker";
import { useBranches } from "../branches/broker";
import Paginator from "../table/Paginator";

export const QuestionsTable = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [search_term, set_search_term] = useState("");
  const { data: categories = [] } = useCategories();
  const { data: branches = [] } = useBranches();

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);

  const COLUMNS = [
    {
      Header: "QUESTION",
      accessor: "question",
    },
    {
      Header: "CATEGORIES",
      id: "categories",
      Cell: data => {
        const { categories } = data?.row?.original;
        return `${categories.map(el => ` ${el.title}`)}`;
      },
    },
    {
      Header: "BRANCH",
      accessor: "location",
      Cell: ({ value }) => {
        return `${value.map(el => ` ${el.name}`)}`;
      },
    },
    {
      Header: "DATE ADDED",
      accessor: "date_published",
      Cell: ({ value }) => moment(value).format("MMM D, YYYY hh:mm a"),
    },
    {
      id: "action",
      accessor: "id",
      Cell: ({ value, ...rest }) => {
        const this_question = rest?.row?.original;
        const [modalOpen, setModalOpen] = useState(false);

        const { mutate: deleteQuestion, isLoading: deleteQuestionLoading } =
          useDeleteQuestion(value);

        const { mutate: updateQuestion, isLoading: updateQuestionLoading } =
          useUpdateQuestion(value);

        const handleDeleteQuestion = async () => {
          try {
            const result = await ConfirmModal.show({
              text: "Are you sure you want to delete this question?",
            });

            if (result) {
              toast.loading("Please wait");
              deleteQuestion();
            }
          } catch (error) {
            errorHandler(error);
          }
        };

        const handleOpenEditModal = () => {
          setModalOpen(true);
        };

        const handleToggleActivation = async () => {
          const patch_data = {
            is_active: !this_question?.is_active || false,
            status: "public",
            categories: this_question?.categories?.map(el => el.id),
            location: this_question?.location?.map(el => el.id),
          };
          updateQuestion(patch_data);
        };

        const actions = [
          {
            title: "Edit Question",
            style: {
              color: "#696969",
            },
            onClick: handleOpenEditModal,
          },
          {
            title: "Delete Question",
            style: { color: "red" },
            disabled: false,
            onClick: handleDeleteQuestion,
          },
        ];

        return (
          <div className="flex justify-evenly align-center">
            <EmojiTextButton
              onClick={handleToggleActivation}
              disabled={
                typeof this_question?.is_active === "boolean" ||
                !!!this_question?.is_active
              }
              text={
                updateQuestionLoading
                  ? "Please wait"
                  : this_question?.is_active
                  ? "Deactivate"
                  : "Deploy Question"
              }
              IconProp={Arrow}
              styleProp={{
                background: Colors.purple,
                color: Colors.white,
                padding: "1em 1.5em",
              }}
            />
            <DotsButton actions={actions} />
            <EditQuestionModal
              isOpen={modalOpen}
              closeModal={() => setModalOpen(false)}
              primaryButtonText={"Save"}
              question={this_question}
              questions={questions}
              categories={categories}
              branches={branches}
            />
          </div>
        );
      },
    },
  ];

  const { data, isLoading } = useQuestionsData(limit, offset, search_term);
  const questions = data?.results || [];

  const tableData = useMemo(
    () => questions,
    [data, limit, offset, search_term]
  );

  return (
    <div
      className="flex grow flex-column"
      style={{ maxHeight: "90vh", overflowY: "auto" }}
    >
      <div className="flex justify-between align-center">
        <h4 style={{ margin: 0 }}>Questions</h4>
        <div>
          <Searchbar term={search_term} filter={set_search_term} />
        </div>

        <div className="flex align-center justify-between">
          <EmojiTextButton
            text="Add a Question"
            IconProp={Plus}
            onClick={() => setModalIsOpen(true)}
            styleProp={{
              background: Colors.purple,
              color: Colors.white,
              padding: ".8em 2em",
              marginLeft: ".5em",
            }}
          />
        </div>
      </div>
      <div className="flex flex-column grow m-t-1 bg-w">
        <AddQuestionModal
          isOpen={modalIsOpen}
          closeModal={() => setModalIsOpen(false)}
          primaryButtonText={"Continue"}
          questions={questions}
          categories={categories}
          branches={branches}
        />
        <Table loading={isLoading} columns={COLUMNS} data={tableData} />
        <Paginator
          limit={limit}
          offset={offset}
          count={data?.count}
          setOffset={setOffset}
          canGoNext={!!data?.next}
          canGoPrevious={!!data?.previous}
        />
      </div>
    </div>
  );
};
