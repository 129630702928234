export const determineTotalPrice = (subscription, discounted_amount = 0.0) => {
  const {
    unit_price = 0,
    device_count = 0,
    duration = "month",
    // discount = 0.0,
  } = subscription;

  let base_price = unit_price * device_count;
  let timed_price = duration === "month" ? base_price * 1 : base_price * 12;
  let formatted_price_on_duration = Math.round(timed_price * 100) / 100;

  let new_amount = formatted_price_on_duration - discounted_amount;

  return new_amount <= 0 ? 0 : new_amount;
};
export const getTotal = (
  unit_price,
  number_tablets,
  interval,
  discounted_amount = 0.0
) => {
  let base_price = unit_price * number_tablets;

  switch (interval) {
    case "month":
      base_price *= 1;
      break;

    case "year":
      base_price *= 12;
      break;

    default:
      break;
  }

  let pricePerDuration = Math.round(base_price * 100) / 100;

  let new_amount = pricePerDuration - discounted_amount;

  if (new_amount <= 0) {
    return 0;
  }

  return new_amount;
};
