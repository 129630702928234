import styled from "styled-components";
import theme from "../../theme";
import Flex from "../layout/Flex";
import { Link } from "react-router-dom";

// const ButtonAppearance = {
//   primary: "primary",
//   secondary: "secondary",
//   outline: "outline",
//   thickOutline: "thickoutline",
//   disabled: "disabled",
//   flushed: "flushed",
//   unstyled: "unstyled",
// };

// const ButtonSize = {
//   xs: "xs",
//   sm: "sm",
//   md: "md",
//   lg: "lg",
//   xl: "xl",
// };

// const ButtonShape = {
//   rounded: "rounded",
//   circular: "circular",
// };

// const ButtonComponent = {
//   link: "link",
//   anchor: "anchor",
//   button: "button",
// };

// const ButtonType = {
//   submit: "submit",
//   button: "button",
// };

const buttonAppearanceStyles = {
  primary: {
    background: theme.colors.primary,
    color: theme.colors.white,
    "font-family": theme.typography.fonts.regular,
    ".loader-label": { color: theme.colors.gray[800] },
    "&:active": { background: theme.colors.yellow[400] },
  },
  secondary: {
    background: theme.colors.white,
    color: "#272D37",
    border: `1px solid ${theme.colors.gray[200]}`,
    "font-family": theme.typography.fonts.regular,
    ".loader-label": { color: theme.colors.white },
  },

  outline: {
    background: "transparent",
    border: `1px solid ${theme.colors.primary}`,
    color: theme.colors.primary,
  },
  thickoutline: {
    background: "transparent",
    border: `2px solid ${theme.colors.primary}`,
    color: theme.colors.primary,
  },
  disabled: {
    background: theme.colors.gray[100],
    color: theme.colors.gray[400],
  },
  unstyled: {
    background: "none",
    border: "none",
    padding: 0,
  },
  flushed: {
    background: theme.colors.gray[100],
    color: theme.colors.gray[700],
  },
  anchor: {
    color: theme.colors.grey,
    background: "none",
    "font-family": theme.typography.fonts.regular,
  },
};

const buttonSizeStyles = {
  xs: { height: 24, padding: "0 12px", fontSize: 12 },
  sm: { height: 32, padding: "0 16px", fontSize: 14 },
  md: { height: 40, padding: "0 24px", fontSize: "14px" },
  lg: { height: 48, padding: "0 24px", fontSize: 16 },
  xl: { height: 48, padding: "0 32px", fontSize: 18 },
  xxl: { height: 64, width: 250, padding: "0 25px", fontSize: 20 },
};

const StyledButton = styled.div`
  width: auto;
  display: flex;
  align-items: center;

  button,
  a {
    background: none;
    border: none;
    padding: 0;
    width: 100%;
    height: 100%;

    pointer-events: ${props =>
      props.isDisabled || props.isLoading ? "none" : "auto"};
    cursor: ${props =>
      props.isDisabled || props.isLoading ? "not-allowed" : "auto"};
    &:focus: {
      outline: "none";
    }

    .render {
      ${props => buttonAppearanceStyles[props.appearance]};
      ${props => buttonSizeStyles[props.size]};
      padding: ${props => {
        return props.hasPadding ? buttonSizeStyles[props.size]?.padding : 0;
      }};
      display: flex;
      align-items: center;
      justify-content: ${props => (props.hasPadding ? "center" : "flex-start")};
      border-radius: ${props => {
        const borderRadius =
          props.shape === "circular"
            ? "24px"
            : `${0.2 * buttonSizeStyles[props.size]?.height}px`;
        return borderRadius;
      }} !important;

      font-weight: 400;

      .prepend {
        margin-right: 8px;
      }
      .append {
        margin-left: 8px;
      }
    }
  }
`;

//   ${props => buttonSizeStyles[props.size]};

const Button = props => {
  const {
    path,
    action,
    appearance = "primary",
    size = "md",
    shape = "circular",
    children,
    appendComponent,
    prependComponent,
    component = "button",
    buttonType = "button",
    isLoading,
    className,
    isDisabled,
    hasPadding = true,
    target,
    justifyInnerContent = "center",
    ...rest
  } = props;

  const selectedButtonAppearance = isDisabled ? "disabled" : appearance;

  function renderButton() {
    return (
      <span className="render">
        {isLoading && <p>Please wait</p>}

        {!isLoading && (
          <Flex jc={justifyInnerContent} ai="center" styles={{ flex: 1 }}>
            {prependComponent && (
              <Flex className="add-on prepend">{prependComponent}</Flex>
            )}

            {children}

            {appendComponent && (
              <Flex className="add-on append">{appendComponent}</Flex>
            )}
          </Flex>
        )}
      </span>
    );
  }

  const btnProps = {
    appearance: selectedButtonAppearance,
    size,
    shape,
    isDisabled,
    isLoading,
    hasPadding,
  };

  return (
    <StyledButton
      className={className}
      hasPadding={hasPadding}
      {...btnProps}
      {...rest}
    >
      {component === "anchor" && (
        <a target={target} rel="noreferrer" href={path}>
          {renderButton()}
        </a>
      )}

      {component === "link" && (
        <Link to={path}>
          <a>{renderButton()}</a>
        </Link>
      )}
      {component === "button" && (
        <button type={buttonType} onClick={action}>
          {renderButton()}
        </button>
      )}
    </StyledButton>
  );
};

// Button.defaultProps = {
//   path: "",
//   target: "",
//   action: () => {},
//   appearance: "",
//   size: "",
//   shape: "",
//   prependComponent: undefined,
//   appendComponent: undefined,
//   component: "",
//   buttonType: "",
//   isLoading: false,
//   className: "",
//   isDisabled: false,
//   hasPadding: false,
// };

export default Button;
