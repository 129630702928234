import React from "react";
import { CompaniesTable, CompanyTabs } from "../../components/companies";
import { Layout } from "../../components/dashboard";
import { DocumentHeader } from "../../components/general/DocumentHeader";
import { SuperAdminQuestionsTable } from "../../components/questions/SuperAdminQuestionsTable";

const Questions = () => {
  return (
    <Layout tabs={CompanyTabs}>
      <DocumentHeader page="Questions" />
      <SuperAdminQuestionsTable />
    </Layout>
  );
};

export default Questions;
