import React, { useState } from "react";
import { Pie, PieChart, Cell, ResponsiveContainer, Tooltip } from "recharts";
import { Colors } from "../../constants";

const colors = ["#72004C", "#F8CB4E", "#151515", "#9B9B9B", "#F06669"];

const signs = {
  excellent: "😍",
  good: "😀",
  okay: "🙄",
  bad: "😕",
  terrible: "😡",
};

const PieChartComponent = ({ legendStyle, data, count }) => {
  const [empty_data, set_empty_data] = useState([
    {
      name: "excellent",
      sign: "empty",
      value: 1,
    },
    {
      name: "good",
      sign: "empty",
      value: 1,
    },
    {
      name: "neutral",
      sign: "empty",
      value: 1,
    },
    {
      name: "bad",
      sign: "empty",
      value: 1,
    },
    {
      name: "terrible",
      sign: "empty",
      value: 1,
    },
  ]);

  // console.log(data);
  const empty = data.length === 0;
  const mapping_data = empty ? empty_data : data;

  if (legendStyle === "legend1") {
    return (
      <div className="flex-1">
        <ResponsiveContainer width="100%" height="100%">
          <PieChart width="100%" height="100%">
            <Pie data={mapping_data} dataKey={"value"}>
              {mapping_data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={empty ? Colors.grey : colors[index % colors.length]}
                />
              ))}
            </Pie>
            <Tooltip empty={empty} count={count} content={CustomTooltip} />
          </PieChart>
        </ResponsiveContainer>
      </div>
    );
  }

  return null;
};

export default PieChartComponent;

function CustomTooltip({ active, payload, label, count, empty, ...rest }) {
  const { payload: payload_data } = payload[0]?.payload || {};

  if (active) {
    return (
      <div
        className="custom-tooltip bg-w bordered r-8"
        style={{
          padding: "5px",
        }}
      >
        {empty || (
          <label>{`${payload_data?.name} ${
            signs[payload_data?.name.toLowerCase()]
          } ${((payload_data?.value / count) * 100).toFixed(2)}%`}</label>
        )}
      </div>
    );
  }

  return null;
}
