import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import bg from "../../assets/pngs/login-bg.png";
import { ReactComponent as Logo } from "../../assets/svgs/logo.svg";
import DownloadLink from "./DownloadLink";
import { ReactComponent as GooglePlay } from "../../assets/svgs/google-play.svg";
import { ReactComponent as Apple } from "../../assets/svgs/apple.svg";

const Sidebar = () => {
  return (
    <StyledSidebar>
      <div className="sidebar-content">
        <div className="logo-container">
          <Logo fill={"#ffffff"} className="logo" />
        </div>

        <div className="text">
          <p id="title">Welcome to Echo!</p>
          <p id="subtitle">Receive and manage all feedback from customers.</p>
        </div>

        <div className="sidebar-foot" style={{ padding: 0 }}>
          <div className="flex center-align gap-1 p-h-2 m-v-1">
            <div className="flex-1">
              <DownloadLink
                href="https://apps.apple.com/gh/app/echo-feedback-app/id1487196374"
                icon={Apple}
                store="App Store"
                variant="primary"
              />
            </div>
            <div className="flex-1">
              <DownloadLink
                href="https://play.google.com/store/apps/details?id=co.effectstudios.echo"
                icon={GooglePlay}
                store="Play Store"
                variant="primary"
              />
            </div>
          </div>
          <div className="sidebar-footer">
            <div>
              Don't have an account?{" "}
              <Link
                to="/account-setup/account-information"
                className="sidebar-foot-span"
              >
                Sign up
              </Link>{" "}
            </div>
            <>&rarr;</>
          </div>
        </div>
      </div>
    </StyledSidebar>
  );
};

export default Sidebar;

const StyledSidebar = styled.div`
  flex-basis: 30%;
  display: flex;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  background-image: url(${bg});

  @media (max-width: 1024px) {
    display: none;
    flex-basis: 0%;
  }

  .sidebar-content {
    display: flex;
    flex: 1;
    flex-direction: column;
    z-index: 2;

    .logo-container {
      padding: 3.8em 4em;

      .logo {
        width: 78.68px;
        height: 32.04px;
      }
    }

    .text {
      padding: 0 4em;

      #title {
        font-family: CircularStdBold;
        font-size: 1.5em;
        margin: 0;
      }

      #subtitle {
        font-size: 1em;
        margin: 1em 0 0 0;
      }
    }

    .walkthrough-container {
      padding: 5em 4em;
    }

    .sidebar-foot {
      // border: 1px solid green;
      display: flex;
      flex-direction: column;
      margin-top: auto;
    }

    .sidebar-footer {
      background: linear-gradient(0deg, #51004b 1.02%, #72004c 126.44%);
      padding: 2em 2.5em;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // border: 1px solid red;

      .sidebar-foot-span {
        font-family: CircularStdBold !important;
        cursor: pointer;
        color: #ffffff;
        text-decoration: none;
      }
    }
  }
`;
