import React, { useState, useMemo, useEffect } from "react";
import { Table } from "../table";
import mock from "../../assets/mock/mock.json";
import moment from "moment";
import { Colors } from "../../constants";
import { EmojiTextButton } from "../general";
import Searchbar from "../dashboard/Searchbar";
import { ReactComponent as Arrow } from "../../assets/svgs/topright.svg";
import { ReactComponent as Plus } from "../../assets/svgs/plus.svg";
import DotsButton from "../general/DotsButton";
import { AddQuestionModal } from "../modal";
import axios from "axios";
import { useAppContext } from "../../context";
import toast from "react-hot-toast";
import EditQuestionModal from "../modal/EditQuestion";
import errorHandler from "../../selectors/errorHandler";
import { useParams } from "react-router-dom";
import { delete_request, get, patch } from "../../api/transport";

export const SuperAdminQuestionsTable = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [questions, set_questions] = useState([]);
  const [search_term, set_search_term] = useState("");
  const [branches, set_branches] = useState([]);
  const [categories, set_categories] = useState([]);

  const { appState } = useAppContext();
  const [loading, set_loading] = useState(true);
  const params = useParams();

  const fetchQuestions = async () => {
    try {
      const response = await get(`/admin/company/question/${params.companyid}`);
      const { results } = response.data;
      set_questions(results);
    } catch (error) {
    } finally {
      set_loading(false);
    }
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) fetchQuestions();
    return () => (mounted = false);
  }, []);

  const COLUMNS = [
    {
      Header: "QUESTION",
      accessor: "question",
      // Cell: ({ value }) => (value.length > 30 ? value.slice(0, 30) : value),
    },
    {
      Header: "CATEGORIES",
      id: "categories",
      Cell: data => {
        const { categories } = data?.row?.original;
        return `${categories.map(el => ` ${el.title}`)}`;
      },
    },
    {
      Header: "BRANCH",
      accessor: "location",
      Cell: ({ value }) => {
        return `${value.map(el => ` ${el.name}`)}`;
      },
    },
    {
      Header: "DATE ADDED",
      accessor: "created_at",
      Cell: ({ value }) => moment(value).format("MMM D, YYYY hh:mm a"),
    },
    {
      accessor: "id",
      id: "action",
      Cell: data => {
        // const { categories } =
        const question = data?.row?.original;
        const { id, is_active } = question;

        function deleteQuestion() {
          toast.loading("Deleting Question ...");

          delete_request(`/user/question-settings/${id}/`)
            .then(response => {
              const newSet = questions.filter(el => el.id !== id);
              set_questions(newSet);
              toast.success("Question Deleted Successfully");
            })
            .catch(error => {
              errorHandler(error);
            });
        }

        function activateQuestion() {
          toast.loading("Activating Question ...");
          const patch_data = {
            is_active: false,
            status: "public",
            company: appState.profile?.company?.id,
            categories: [1],
          };
          // axios
          //   .patch(
          //     `https://echofeed-web-app.df.r.appspot.com/api`,
          //     ,
          //     { headers: { Authorization: `Token ${fetchToken()}` } }
          //   )
          patch(`/user/question-settings/${id}/`, patch_data)
            .then(response => {
              const newSet = questions.filter(el => el.id !== id);
              set_questions(newSet);
              toast.success("Question Activated Successfully");
            })
            .catch(error => {
              errorHandler(error);
            });
        }

        const [open, setOpen] = useState(false);

        function editQuestion() {
          setOpen(true);
        }

        const actions = [
          {
            title: "Edit",
            disabled: true,
            style: {
              color: "#696969",
            },
            onClick: editQuestion,
          },
          {
            title: "Delete",
            style: { color: "red" },
            disabled: true,
            onClick: deleteQuestion,
          },
        ];
        return (
          <div className="flex justify-end align-center">
            <EmojiTextButton
              onClick={activateQuestion}
              text="Deploy Question"
              disabled={true}
              IconProp={Arrow}
              //   disabled={typeof is_active !== "boolean" || !!is_active}
              styleProp={{
                background: Colors.purple,
                color: Colors.white,
                padding: "1em 1.5em",
              }}
            />
            {/* <EditQuestionModal
              isOpen={open}
              closeModal={() => setOpen(false)}
              primaryButtonText={"Save"}
              question={question}
              questions={questions}
              setQuestions={setQuestions}
              categories={categories}
              setCategories={set_categories}
              branches={branches}
              setBranches={set_branches}
            /> */}
            <DotsButton
              actions={actions}
              // editfunction={editQuestion}
              // deletefunction={deleteQuestion}
            />
          </div>
        );
      },
    },
  ];

  function filterQuestions() {
    const filtered_list = [...questions].filter(question => {
      const name_search = question.question
        .toLowerCase()
        .includes(search_term.toLowerCase());

      const category_search = question.categories.some(category =>
        category.title.toLowerCase().includes(search_term.toLowerCase())
      );

      const branch_search =
        question.location.length > 0 &&
        question.location.find(el => {
          return el.name.toLowerCase().includes(search_term.toLowerCase());
        });

      return name_search || category_search || branch_search;
    });

    if (!appState.profile?.is_owner)
      return filtered_list.filter(
        el =>
          el.location &&
          el.location.some(el => el.id === appState.profile?.branch?.id)
      );

    return filtered_list;
  }

  return (
    <div
      className="flex grow flex-column"
      style={{ maxHeight: "90vh", overflowY: "auto" }}
    >
      <div className="flex justify-between align-center p-v-1">
        <h4 className="m-0">Questions</h4>
        <div>
          <Searchbar term={search_term} filter={set_search_term} />
        </div>

        <div className="flex align-center justify-between">
          <EmojiTextButton
            text="Add a Question"
            IconProp={Plus}
            onClick={() => setModalIsOpen(true)}
            disabled={true}
            styleProp={{
              background: Colors.purple,
              color: Colors.white,
              padding: ".8em 2em",
              marginLeft: ".5em",
            }}
          />
        </div>
      </div>
      {/* <AddQuestionModal
        isOpen={modalIsOpen}
        closeModal={() => setModalIsOpen(false)}
        primaryButtonText={"Continue"}
        questions={questions}
        setQuestions={setQuestions}
        categories={categories}
        setCategories={set_categories}
        branches={branches}
        setBranches={set_branches}
      /> */}
      <Table
        loading={loading}
        columns={COLUMNS}
        data={useMemo(
          () => filterQuestions().reverse(),
          [search_term, questions]
        )}
      />
    </div>
  );

  //   function fetchQuestions() {
  //     setLoading(true);
  //     toast.loading("Fetching Questions...");
  //     axios
  //       .get(
  //         "https://echofeed-web-app.df.r.appspot.com/api/user/question-settings/",
  //         {
  //           headers: {
  //             Authorization: `Token ${fetchToken()}`,
  //           },
  //         }
  //       )
  //       .then(response => {
  //         setLoading(false);
  //         // console.log(response.data);
  //         setQuestions(response.data.results);
  //         toast.success("Questions fetched successfully");
  //       })
  //       .catch(error => {
  //         setLoading(false);
  //         errorHandler(error);
  //       });
  //   }

  //   function fetchBranches() {
  //     // setLoading(true);
  //     // toast.loading("Fetching Branches...");

  //     axios
  //       .get("https://echofeed-web-app.df.r.appspot.com/api/user/branch/", {
  //         headers: {
  //           Authorization: `Token ${fetchToken()}`,
  //         },
  //       })
  //       .then(response => {
  //         // setLoading(false);
  //         set_branches(response.data.results);
  //         // toast.success("Branches fetched successfully");
  //       })
  //       .catch(error => {
  //         // setLoading(false);
  //         errorHandler(error);
  //       });
  //   }

  //   function fetchCategories() {
  //     // set_category_fetch_loading(true);

  //     axios
  //       .get("https://echofeed-web-app.df.r.appspot.com/api/user/category/", {
  //         headers: { Authorization: `Token ${fetchToken()}` },
  //       })
  //       .then(response => {
  //         // set_category_fetch_loading(false);
  //         set_categories(response.data);
  //       })
  //       .catch(e => {
  //         // set_category_fetch_loading(false);
  //         errorHandler(e);
  //       });
  //   }
};
