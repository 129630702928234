import React from "react";
import styled from "styled-components";
import colors from "../../constants/colors";

const PageTitle = ({
  subTitle,
  styleProp,
  titleSize,
  title = "Title",
  align = "left",
}) => {
  return (
    <StyledPageTitle
      style={{ ...styleProp }}
      titleSize={titleSize}
      align={align}
    >
      <h4>{title}</h4>
      {subTitle && <p>{subTitle}</p>}
    </StyledPageTitle>
  );
};

export default PageTitle;

const StyledPageTitle = styled.div`
  text-align: ${({ align }) => align};

  h4 {
    font-family: CircularStdBold;
    font-style: normal;
    font-weight: bold;
    font-size: ${props => props.titleSize || "2em"};
    margin: ${props => props.margin || props.style?.margin || ".5em 0"};
  }

  p {
    margin: 0.5em 0 0 0;
    font-family: CircularStd;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: ${() => colors.grey_2};
  }
`;
