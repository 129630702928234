import _ from "lodash";

export const initialState = {
  device_settings_id: undefined,
  saved_device_settings: {
    logo_image: undefined,
    background_image: undefined,
    text_color: undefined,
    btn_color: undefined,
    bg_color: undefined,
  },
  new_device_settings: {
    logo_image: undefined,
    background_image: undefined,
    text_color: undefined,
    btn_color: undefined,
    bg_color: undefined,
  },
  emojis: {
    terrible: undefined,
    bad: undefined,
    okay: undefined,
    good: undefined,
    excellent: undefined,
  },
  company_emojis: {
    terrible: {
      id: undefined,
      link: undefined,
      file: undefined,
    },
    bad: {
      id: undefined,
      link: undefined,
      file: undefined,
    },
    okay: {
      id: undefined,
      link: undefined,
      file: undefined,
    },
    good: {
      id: undefined,
      link: undefined,
      file: undefined,
    },
    excellent: {
      id: undefined,
      link: undefined,
      file: undefined,
    },
  },
};

export const actions = {
  set_new_device_settings: "SET_NEW_DEVICE_SETTINGS",
  set_saved_device_settings: "SET_SAVED_DEVICE_SETTINGS",
  set_device_settings_id: "SET_DEVICE_SETTINGS_ID",
  set_emojis: "SET_EMOJIS",
  set_company_emojis: "SET_COMPANY_EMOJIS",
  clear_settings: "CLEAR_SETTINGS",
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case actions.set_device_settings_id:
      return {
        ...state,
        device_settings_id: payload.id,
      };

    case actions.set_saved_device_settings:
      return {
        ...state,
        saved_device_settings: {
          ...state.saved_device_settings,
          ...payload,
        },
      };

    case actions.set_new_device_settings:
      return {
        ...state,
        new_device_settings: {
          ...state.new_device_settings,
          ...payload,
        },
      };

    case actions.set_emojis:
      return {
        ...state,
        emojis: {
          ...state.emojis,
          ...payload,
        },
      };

    case actions.set_company_emojis:
      const { title, data } = payload;
      const is_array = _.isArray(data);
      const is_object = _.isObject(data);
      // console.log(is_array, payload);

      if (is_array) {
        const array_to_object = {};
        data.forEach(el => {
          array_to_object[el.title.toLowerCase()] = {
            ...state.company_emojis[el.title.toLowerCase()],
            id: el.id,
            link: el.emoji,
          };
        });
        // console.log(array_to_object);
        return {
          ...state,
          company_emojis: {
            ...state.company_emojis,
            ...array_to_object,
          },
        };
      }

      return {
        ...state,
        company_emojis: {
          ...state.company_emojis,
          [title]: {
            ...state.company_emojis[title],
            ...data,
          },
        },
      };

    case actions.clear_settings:
      return initialState;

    default:
      return state;
  }
};
