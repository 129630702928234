import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import { all, get, patch } from "../api/transport";
import BillCard from "../components/account-creation/choose-subscription/BillCard";
import { Layout } from "../components/dashboard";
import { Loader, TextButton } from "../components/general";
import { DocumentHeader } from "../components/general/DocumentHeader";
import ConfirmModal from "../components/modal/ConfirmModal";
import UpgradeSubscriptionModal from "../components/modal/UpgradeSubscriptionModal";
import { SubscriptionTable } from "../components/subscription/SubscriptionTable";
import { useAppContext } from "../context";
import errorHandler from "../selectors/errorHandler";
import Grid, { GridItem } from "../components/layout/Grid";
import Flex from "../components/layout/Flex";
import styled from "styled-components";
import theme from "../theme";
import Input from "../components/input/Input";
import Button from "../components/button/Button";
import { Table } from "../components/table";
import Alert from "../components/alert/Alert";
import { useSubscriptionHistory } from "../components/subscription/broker";
import numeral from "numeral";
import moment from "moment";
import UpgradeSubModal from "../components/modal/UpgradeSubModal";
import { useSubscriptionPackages } from "../components/account-creation/choose-subscription/broker";

const exchange_rate = process.env.REACT_APP_USD_CEDI_EXCHANGE_RATE;

const cardAppearanceStyles = {
  default: {
    background: "white",
  },
  gray: {
    background: "#F3F3F7",
  },
};

export const cardSizes = {
  xs: { "min-height": "24px", padding: "24px" },
  sm: { "min-height": "36px", padding: "24px" },
  md: { "min-height": "40px", padding: "24px" },
  lg: { "min-height": "48px", padding: "24px" },
};

export const Card = ({
  appearance = "default",
  size = "md",
  unit = "",
  amount = "",
  subtext = "",
  className = "",
  currency = "₵",
}) => {
  const styleProps = {
    appearance,
    size,
  };

  return (
    <StyledCard spacing={8} stack {...styleProps} className={className}>
      <div className="subtext">
        <p>{subtext}</p>
      </div>

      <div className="value">
        <p>
          <sup>{currency}</sup>

          <span className="amt">{amount}</span>

          <sub>/ {unit}</sub>
        </p>
      </div>
    </StyledCard>
  );
};

const StyledCard = styled(Flex)`
  ${({ appearance }) => cardAppearanceStyles[appearance]};
  ${({ size }) => cardSizes[size]};

  border-radius: 6px;

  .value {
    sup {
      font-size: 16px;
      line-height: 22px;
    }

    .amt {
      font-size: 32px;
      line-height: 40px;
      font-weight: 400;
      font-family: ${theme.typography.fonts.bold};
    }

    sub {
      font-size: 16px;
      line-height: 22px;
    }
  }

  p,
  h2 {
    margin: 0;
  }
`;

const BillingHistory = ({ history = [], isLoading }) => {
  const COLUMNS = [
    {
      Header: "TRANSACTION ID",
      accessor: "uuid",
      Cell: ({ value }) => {
        const transactionId = value
          ?.split("-")
          .slice(0, 2)
          .join("-")
          .toUpperCase();

        return `#${transactionId}`;
      },
    },
    {
      Header: "PAYMENT METHOD",
      accessor: "payment_method",
    },
    {
      Header: "AMOUNT",
      accessor: "total_amount",

      Cell: ({ value }) =>
        `$${numeral((Number(value) / 100) * exchange_rate).format("0,0.00")}`,
    },
    {
      Header: "STATUS",
      accessor: "status",
      Cell: ({ value }) => {
        const colorClasses = {
          active: "green",
          drafted: "gray",
        };

        const className = `${colorClasses[value?.toLowerCase()] || "red"}`;

        return <p className={className}>{value}</p>;
      },
    },
    {
      Header: "DATE",
      accessor: "created_at",
      Cell: ({ value }) => moment(value).format("DD MMM, YYYY"),
    },
  ];

  return (
    <StyledBillingHistory spacing={16} stack>
      <div className="header">
        <Flex>
          <div className="title">
            <p>Billing History</p>
          </div>
        </Flex>
      </div>

      <Flex stack spacing={20} className="body">
        <Table columns={COLUMNS} data={history} loading={isLoading} />
      </Flex>
    </StyledBillingHistory>
  );
};

const StyledBillingHistory = styled(Flex)`
  p {
    margin: 0;
  }

  flex-grow: 1;
  height: 100% !important;

  .title {
    p {
      font-size: 18px;
      font-family: ${theme.typography.fonts.bold};
      line-height: 24px;
    }
  }

  .body {
    border: 1px solid #f5f3f3;
    border-radius: 8px;
    background: ${theme.colors.white};

    padding: 2em;
  }
`;

const CurrentSubscription = ({ currentSubscription = {} }) => {
  const { number_tablets, unit_price_per_day, unit_price } =
    currentSubscription;

  const [showModal, setShowModal] = React.useState(false);

  const handleCloseModal = React.useCallback(
    () => setShowModal(false),
    [setShowModal]
  );

  const handleOpenModal = React.useCallback(
    () => setShowModal(true),
    [setShowModal]
  );

  return (
    <StyledCurrentSubscription spacing={16} stack>
      <UpgradeSubModal isOpen={showModal} handleCancel={handleCloseModal} />

      <div className="header">
        <Flex>
          <div className="title">
            <p>Subscription</p>
          </div>
        </Flex>
      </div>

      <Flex stack spacing={20} className="body">
        <Flex spacing={20} stack>
          <div>
            <Input
              value={number_tablets}
              label="Current Device Slots"
              disabled
            />
          </div>
          <div>
            <Input value={"Monthly"} label="Billing Type" disabled />
          </div>
        </Flex>

        <Flex stack spacing={8}>
          <div>
            <Card
              appearance="gray"
              amount={unit_price}
              subtext="Price Per Tablet"
              unit="tablet"
            />
          </div>

          <div>
            <Card
              appearance="gray"
              subtext="Total Subscription Fee"
              unit="day"
              amount={unit_price_per_day}
            />
          </div>
        </Flex>

        <div>
          <Alert
            type="transparent"
            message="Subscription expired. Make payment now to continue enjoying our services."
          />
        </div>

        <Grid gap="5px" className="btn-sec">
          <GridItem span={6} xl={7}>
            <Button appearance="outline" shape="rounded" size="md">
              <p>Cancel Subscription</p>
            </Button>
          </GridItem>

          <GridItem span={6} xl={5}>
            <Button action={handleOpenModal} shape="rounded" size="md">
              <p>Upgrade Subscription</p>
            </Button>
          </GridItem>
        </Grid>
      </Flex>
    </StyledCurrentSubscription>
  );
};

const StyledCurrentSubscription = styled(Flex)`
  p {
    margin: 0;
  }

  flex-grow: 1;
  height: 100% !important;

  .title {
    p {
      font-size: 18px;
      font-family: ${theme.typography.fonts.bold};
      line-height: 24px;
    }
  }

  .body {
    border: 1px solid #f5f3f3;
    border-radius: 8px;
    background: ${theme.colors.white};
    // padding: 40px;
    padding: 2em;

    .btn-sec {
      margin-top: 2em;
    }
  }
`;

const findCurrentSubscription = list => {
  let subscription = {};

  for (let index = list.length - 1; index > 0; index--) {
    if (list[index]?.status?.toLowerCase() !== "drafted") {
      return (subscription = list[index]);
    }
  }

  return subscription;
};

const NuoSubscription = () => {
  const { data: history, isLoading } = useSubscriptionHistory();

  const { data: packages } = useSubscriptionPackages();

  useEffect(() => {
    if (packages) {
      localStorage.setItem(
        "echo-subscription-packages",
        JSON.stringify(packages)
      );
    }
  }, [packages]);

  const packageHistory = useMemo(() => {
    let data = [];
    if (history) data = history.results;

    return data;
  }, [history]);

  const currentSubscription = findCurrentSubscription(packageHistory);

  return (
    <Layout>
      <DocumentHeader page="Subscription" />
      <StyledPageContent stack>
        <Grid span={12} className="page-grid" gap="40px">
          <GridItem span={5} xxl={4} className="flex flex-column">
            <CurrentSubscription currentSubscription={currentSubscription} />
          </GridItem>
          <GridItem span={7} xxl={8}>
            <BillingHistory history={packageHistory} isLoading={isLoading} />
          </GridItem>
        </Grid>
      </StyledPageContent>
    </Layout>
  );
};

const StyledPageContent = styled(Flex)`
  flex-grow: 1;

  * {
    // border: 1px dashed;
  }

  .page-grid {
    // border: 1px solid green;
    height: 100%;
    overflow-y: scroll;
  }
`;

const Subscription = () => {
  const [current_subscription, set_current_subscription] = useState({});
  const [app_subscriptions, set_app_subscriptions] = useState([]);
  const [transactions, set_transactions] = useState([]);
  const [fetch_loading, set_fetch_loading] = useState(true);
  const history = useHistory();

  const exchange_rate = process.env.REACT_APP_USD_CEDI_EXCHANGE_RATE;

  const fetchTransactions = () => {
    all(["/user/transaction/", "/subscription/"].map(el => get(el)))
      .then(response => {
        const [{ data: transaction_data }, { data: subscription_data }] =
          response;
        const last_transaction =
          transaction_data.count > 0
            ? transaction_data.results[transaction_data.count - 1]
            : {};
        set_transactions(transaction_data.results);
        set_current_subscription(last_transaction);
        set_app_subscriptions(subscription_data.results);
      })
      .catch(e => errorHandler(e))
      .finally(() => set_fetch_loading(false));
  };

  const retry_payment_disabled =
    current_subscription?.status?.toLowerCase() !== "failed";

  useEffect(() => {
    let mounted = true;
    if (mounted) fetchTransactions();
    return () => (mounted = false);
  }, []);

  const [is_open, set_is_open] = useState(false);

  const current_subscription_is_active = current_subscription?.is_active;

  function cancel_subscription() {
    toast.loading("Please wait");
    const {
      number_tablet,
      billing_type,
      id,
      uuid,
      updated_at,
      date_published,
      date_updated,
      is_active,
      company,
      subscription,
      authorization_code,
      ...rest
    } = current_subscription;

    const patch_data = {
      ...rest,
      is_active: false,
      company: company.id,
      subscription: subscription.id,
    };

    patch(`/transaction/${id}/`, patch_data)
      .then(() => {
        toast.success("Success");
        set_current_subscription({ ...current_subscription, is_active: false });
        set_transactions([
          ...transactions.map(transaction => {
            if (transaction.authorization_code === authorization_code) {
              let new_trans = { ...transaction, is_active: false };
              return new_trans;
            }
            return transaction;
          }),
        ]);
      })
      .catch(error => {
        errorHandler(error);
      });
  }

  return (
    <Layout>
      <DocumentHeader page="Subscription" />
      <div className="flex grow  m-v-2">
        <div className=" flex flex-basis-35 flex-column m-r-2">
          <div className="flex justify-between align-center">
            <p className="bold">Subscription</p>
            {/* <TextButton
              text="Update Payment Info"
              styleProp={{ padding: ".5em 1em" }}
              onClick={() => history.push("/account-setup/make-payment")}
            /> */}
          </div>

          <div className="flex grow bordered p-2 bg-w r-8 flex-column">
            {fetch_loading ? (
              <Loader />
            ) : current_subscription_is_active ? (
              <>
                <div className="form-row ">
                  <div className="input-field">
                    <label>Current Device Slots</label>
                    <input
                      type="number"
                      placeholder="Current Device Slots"
                      value={current_subscription?.number_tablet}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="input-field">
                    <label>Billing Type</label>
                    <input
                      type="text"
                      placeholder="Eg. Monthly, Yearly, etc."
                      value={current_subscription?.billing_type}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <BillCard
                    figure={
                      current_subscription?.subscription?.unit_price *
                      exchange_rate
                    }
                    style={{ margin: 0, flex: 1 }}
                  />
                </div>
                <div className="form-row">
                  <BillCard
                    style={{ margin: 0, flex: 1 }}
                    header="Total Subscription Fee"
                    figure={
                      (current_subscription?.amount_paid / 100) * exchange_rate
                    }
                    perMeasure={current_subscription?.billing_type?.toLowerCase()}
                  />
                </div>
              </>
            ) : (
              <div className="flex justify-center align-center flex-1">
                <p>No Active Subscription</p>
              </div>
            )}

            <div className="flex flex-row m-t-auto justify-between align-center p-v-1">
              <div className="flex grow">
                <TextButton
                  text="Cancel Subscription"
                  styleProp={{ padding: ".5em 1em", flexGrow: 1 }}
                  disabled={!current_subscription?.is_active}
                  onClick={async () => {
                    try {
                      const result = await ConfirmModal.show({
                        text: "Are you sure you want to cancel your subscription?",
                      });
                      if (result) return cancel_subscription();
                    } catch (error) {
                      errorHandler(error);
                    }
                  }}
                />
              </div>
              <div className="flex grow">
                <TextButton
                  text={
                    !current_subscription_is_active
                      ? "Create Subscription"
                      : "Upgrade Subscription"
                  }
                  styleProp={{ padding: ".5em 1em", flexGrow: 1 }}
                  onClick={() => {
                    if (current_subscription_is_active) {
                      return set_is_open(true);
                    }

                    history.pushState("/account-creation/choose-subscription");
                  }}
                />

                <UpgradeSubscriptionModal
                  isOpen={is_open && app_subscriptions.length !== 0}
                  closeModal={() => set_is_open(false)}
                  current_subscription={current_subscription}
                  set_current_subscription={set_current_subscription}
                  subscriptions={app_subscriptions}
                  set_subscriptions={set_app_subscriptions}
                  set_transactions={set_transactions}
                  transactions={transactions}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="flex grow flex-column r-8">
          <SubscriptionTable
            transactions={useMemo(
              () => [...transactions.reverse()] || [],
              [transactions]
            )}
            fetch_loading={fetch_loading}
            retry_payment_disabled={retry_payment_disabled}
          />
        </div>
      </div>
    </Layout>
  );
};

export { NuoSubscription as Subscription };
