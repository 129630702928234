import React from "react";
import styled from "styled-components";
import { ReactComponent as Triangle } from "../../assets/svgs/triangle-down.svg";
import { Colors } from "../../constants";
import ProfileDropdown from "./ProfileDropdown";

const ProfileButton = () => {
  return (
    <StyledProfileButton>
      <Triangle />
      {/* {isActive && <ProfileDropdown toggleIsActive={toggleIsActive} />} */}
    </StyledProfileButton>
  );
};

export default ProfileButton;

const StyledProfileButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: ${props => (props.isActive ? Colors.white_2 : "transparent")};
  cursor: pointer;
  // position: relative;
  //   border: 1px solid;
`;
