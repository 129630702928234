import styled from "styled-components";
import theme from "../../../theme";

const StyledInputError = styled.span`
  color: ${theme.colors.red[500]};
  font-size: 14px;
  line-height: 20px;

  display: block;
  font-family: ${theme.typography.fonts.regular};
  margin: 8px 0;

  &:first-letter {
    text-transform: uppercase;
  }
`;

const InputError = ({ children }) => {
  return (
    <StyledInputError className="input-error">{children}</StyledInputError>
  );
};

export { InputError };
