import React from "react";

import { InputError } from "./components";
import { InputLabel } from "./components";
import styled from "styled-components";

// import Icon from "../Icon";
import theme from "../../theme";
import Flex from "../layout/Flex";

export const inputSizes = {
  xs: { "min-height": "24px", "font-size": "12px" },
  sm: { "min-height": "36px", "font-size": "14px" },
  md: { "min-height": "40px", "font-size": "16px" },
  lg: { "min-height": "48px", "font-size": "16px" },
};

export const inputStyles = {
  outline: {
    background: "white",
    border: `1px solid #D9DAE4`,
    padding: "0 12px",
  },
  thickOutline: {
    border: `2px solid ${theme.colors.gray[800]}`,
    padding: "0 12px",
  },
  flushed: {
    background: "transparent",
    border: "none",
    "border-bottom": `1px solid ${theme.colors.gray[200]}`,
    "&:focus": { outline: "none" },
  },
  filled: { background: theme.colors.gray[100] },
  unstyled: { background: "transparent", border: "none" },
  otp: {
    height: "100%",
    border: "1px solid green",
    padding: 0,
    "font-size": "25px",
  },
};

// export interface IInput {
//   label?: string;
//   appearance?: InputAppearanceType;
//   size?: InputSizeType;
//   className?: string;
//   value?: string | Date | number;
//   id?: string;
//   type?: 'password' | 'text';
//   name?: string;
//   error?: string;
//   onChange?(e: any): void;
//   onBlur?(e: any): void;
//   onFocus?(e: any): void;
//   prependComponent?: any;
//   appendComponent?: any;
//   placeholder?: string;
//   innerRef?: any;
//   maxLength?: number;
//   minLength?: number;
// }

const StyledInput = styled.div`
  width: 100%;
  height: ${props => (props.isFullHeight ? "100%" : "auto")};

  .element {
    margin: 0;
    background: transparent;
    outline: none;
    border: none;
    width: 100%;
    color: ${theme.colors.gray[800]};
    font-family: ${theme.typography.fonts.regular};
    font-size: ${props => inputSizes[props.size]["font-size"]};
    text-align: ${props => (props.isFullHeight ? "center" : "left")};
    border-radius: 6px !important;

    
    line-height: 5px;
    padding: 12px 16px;


    &:focus {
    }


    &::placeholder {
      color: ${theme.colors.gray[400]};
      font-size: 15px;
      font-family: ${theme.typography.fonts.regular};
    }
  }

  .input-parent {
    ${props => inputStyles[props.appearance]};
    ${props =>
      props.isFullHeight
        ? inputSizes[props.size]["font-size"]
        : inputSizes[props.size]};
    height: ${props => (props.isFullHeight ? "100% !important" : "auto")};
    padding: 0;
    width: 100%;
    border-radius: 6px !important;

    border-color: ${props =>
      props?.error ? theme.colors.red[500] : "#D9DAE4"};
  }

  .add-on {
    width: ${props => inputSizes[props.size].height};
    height: 100%;
    flex-shrink: 0;
  }

  .password-toggle {
    background: none;
    border: none;
    width: 100%;
    height: 100%;

    &:focus {
        outline: none; 
    };

    .icon {
      color: ${theme.colors.gray[400]};
      transition: color 300ms;
      &:active { color: ${theme.colors.gray[600]} };
      &:hover { cursor: pointer };
    },
  },
`;

const Input = (
  {
    appearance = "outline",
    size = "md",
    shape = "squared",
    label,
    className,
    name,
    id,
    type,
    error,
    onChange,
    onBlur,
    onFocus,
    value,
    prependComponent,
    appendComponent,
    placeholder,
    innerRef,
    isFullHeight,
    link,
    ...props
  },
  ref
) => {
  const [passwordIsMasked, setPasswordIsMasked] = React.useState(true);

  const renderAppendComponent =
    type === "password" ? (
      <button
        type="button"
        onClick={() => {
          setPasswordIsMasked(prev => !prev);
        }}
        className="password-toggle"
      >
        {/* <Icon
          className="icon"
          style={{ width: 20 }}
          icon={["far", passwordIsMasked ? "eye" : "eye-slash"]}
        ></Icon> */}
      </button>
    ) : (
      appendComponent
    );

  const cssProps = {
    appearance,
    size,
    prependComponent,
    appendComponent,
    shape,
    isFullHeight,
  };

  return (
    <StyledInput {...cssProps} error={error} className={className}>
      {label && (
        <Flex jc="space-between" ai="center">
          <InputLabel gap={appearance === "flushed" ? 0 : "8px"} id={id}>
            {label}
          </InputLabel>

          {link?.()}
        </Flex>
      )}

      <Flex className="input-parent">
        {prependComponent && (
          <Flex ai="center" jc="center" className="add-on prepend">
            {prependComponent}
          </Flex>
        )}

        <input
          ref={ref}
          className="element"
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          id={id}
          name={name}
          type={
            type === "password"
              ? passwordIsMasked
                ? "password"
                : "text"
              : type
          }
          value={value}
          {...props}
        />
        {renderAppendComponent && (
          <Flex ai="center" jc="center" className="add-on append">
            {renderAppendComponent}
          </Flex>
        )}
      </Flex>

      {error && <InputError>{error}</InputError>}
    </StyledInput>
  );
};

export default React.forwardRef(Input);
