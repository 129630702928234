import React from "react";
import { useHistory } from "react-router-dom";
import { useAppContext } from "../../context/";

export const Tabs = () => {
  const { appState } = useAppContext();
  const { profile } = appState;
  const is_admin = profile?.is_owner;
  // console.log(appState);

  const routes = [
    {
      title: "Account Info",
      path: "/account/settings/account-information",
    },

    {
      title: "Change Password",
      path: "/account/settings/change-password",
    },
  ];

  const admin_routes = [
    {
      title: "Company Info",
      path: "/account/settings/company-information",
    },
  ];

  const controlled_routes = is_admin ? [...routes, admin_routes] : routes;

  return (
    <div className="bordered bg-w  p-h-4 flex">
      {controlled_routes.map(route => (
        <Tab key={Math.random()} {...route} />
      ))}
    </div>
  );
};

function Tab(props) {
  const history = useHistory();
  const active = history.location.pathname === props.path;

  return (
    <div
      onClick={handleClick}
      className={`bordered-0 p-v-1 m-r-2 pointer ${
        active && `purple bold border-bottom-purple`
      }`}
    >
      {props.title}
    </div>
  );

  function handleClick() {
    history.push(props.path);
  }
}
