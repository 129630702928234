import React, { useState } from "react";

import Icon from "../icon/Icon";
import Flex from "../layout/Flex";

import Input from "../input/Input";
import Grid, { GridItem } from "../layout/Grid";

import { Modal } from "./Modal";
import { InputLabel } from "../input/components";
import Select from "../input/Select";
import { Card } from "../../pages/Subscription";
import Button from "../button/Button";

import { useForm } from "react-hook-form";
import { validationOptions } from "../../utils/form";
import numeral from "numeral";
import {
  determine_subscription_package,
  determine_unit_price,
} from "../../utils/subscription";
import { getTotal } from "../../selectors";
import _ from "lodash";

import StripeCheckoutModal from "./StripeCheckoutModal";
import {
  createNewTransaction,
  initializePayment,
} from "../../page-content/account-creation/MakePayment";
import toast from "react-hot-toast";

const exchange_rate = Number(process.env.REACT_APP_USD_CEDI_EXCHANGE_RATE);

const billingOptions = [
  { value: "month", label: "Monthly" },
  { value: "year", label: "Yearly" },
];

const UpgradeSubModal = props => {
  const { isOpen, handleCancel } = props;

  const [verificationSuccess, setVerificationSuccess] = useState(false);
  const [verificationFailed, setVerificationFailed] = useState(false);

  const [stripeClientSecret, setStripeClientSecret] = useState("");
  const [showCheckoutModal, setShowCheckoutModal] = useState(false);

  const [unitPrice, setUnitPrice] = useState(0);
  const [interval, setInterval] = useState("month");
  const [totalFee, setTotalFee] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    watch,
    reset,
  } = useForm({ mode: "all" });

  const handleCloseModal = () => {
    reset();
    setUnitPrice(0);
    setTotalFee(0);
    setInterval("month");
    handleCancel();
  };

  const modalProps = React.useMemo(
    () => ({
      isOpen,
      handleCancel: handleCloseModal,
    }),
    [isOpen]
  );

  const onSubmit = async data => {
    setVerificationFailed(false);
    setIsLoading(true);

    try {
      const subscription_package = determine_subscription_package(
        data.total_number_tablets
      );

      if (!subscription_package) return console.log("no package found");

      const transPayload = {
        ...subscription_package,
        duration: interval,
        subscription_id: subscription_package.id,
        device_count: data.total_number_tablets,
      };

      const { data: invoice } = await createNewTransaction(
        transPayload,
        totalFee
      );

      const { data: stripeObj } = await initializePayment(invoice);

      const { client_secret } = stripeObj;

      setStripeClientSecret(client_secret);
      setShowCheckoutModal(true);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === "billing_intervals") {
        let billingInterval = value.billing_intervals;

        if (billingInterval) {
          setInterval(billingInterval.value);
        }

        if (unitPrice && !!value.total_number_tablets) {
          const totalFee = getTotal(
            unitPrice,
            value.total_number_tablets,
            billingInterval.value
          );

          setTotalFee(totalFee);
        }

        return;
      }

      if (name === "total_number_tablets") {
        let number_tablets = value.total_number_tablets;

        const up = determine_unit_price(number_tablets);

        if (up) setUnitPrice(up);

        const totalFee = getTotal(up, number_tablets, interval);

        setTotalFee(totalFee);

        return;
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, unitPrice]);

  React.useEffect(() => {
    if (verificationSuccess) {
      toast.success("You have successfully upgraded your subscription");
      setVerificationSuccess(false);
      handleCloseModal();
    }
    return () => {};
  }, [verificationSuccess, setVerificationSuccess]);

  const isDisabled = !_.isEmpty(errors) || totalFee === 0;

  return (
    <Modal {...modalProps}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StripeCheckoutModal
          clientSecret={stripeClientSecret}
          isOpen={showCheckoutModal}
          closeModal={() => setShowCheckoutModal(false)}
          setIsSuccess={setVerificationSuccess}
          setIsFail={setVerificationFailed}
        />

        <Flex spacing={20} stack className="p-4">
          <Flex jc="space-between" ai="flex-start">
            <div>
              <h2 className="text-xl font-extrabold">Upgrade Subscription</h2>
              <p className="text-sm text-gray-500">
                How many more device slots do you need?
              </p>
            </div>

            <div>
              <Icon icon={["fas", "times"]} onClick={handleCloseModal} />
            </div>
          </Flex>

          {verificationFailed && (
            <div className="network-error">
              <p>There was an error with your payment. Please try again</p>
            </div>
          )}

          <Flex stack spacing={30}>
            <Grid span={12} gap="8px">
              <GridItem span={6}>
                <InputLabel className="mb-1">
                  <p className="font-bold">Number of Devices</p>
                </InputLabel>

                <Input
                  type="number"
                  className="mb-1"
                  min="1"
                  name="total_number_tablets"
                  error={errors?.total_number_tablets?.message}
                  {...register("total_number_tablets", {
                    required: "This field is required",
                  })}
                />
                <div>
                  <p className="text-xs text-gray-500">
                    * Specify the amount of device slots to add
                  </p>
                </div>
              </GridItem>

              <GridItem span={6}>
                <InputLabel className="mb-1">
                  <p className="font-bold">Billing Type</p>
                </InputLabel>

                <Select
                  options={billingOptions}
                  placeholder="Select billing type"
                  name="billing_intervals"
                  withControl
                  error={errors?.billing_intervals?.message}
                  control={control}
                  rules={validationOptions["required"]}
                  defaultInputValue="Monthly"
                />

                {errors?.billing_intervals?.message && (
                  <div>
                    <p className="text-red-500 text-sm mt-2">
                      {errors?.billing_intervals?.message}
                    </p>
                  </div>
                )}
              </GridItem>
            </Grid>

            <Grid span={12} gap="8px">
              <GridItem span={6}>
                <Card
                  appearance="gray"
                  subtext="Price Per Tablet"
                  unit="tablet"
                  amount={numeral(unitPrice * exchange_rate).format("0,0.00")}
                  className="!p-4"
                  currency="$"
                />
              </GridItem>
              <GridItem span={6}>
                <Card
                  appearance="gray"
                  subtext="Total Subscription Fee"
                  unit={interval}
                  amount={numeral(totalFee * exchange_rate).format("0,0.00")}
                  className="!p-4"
                  currency="$"
                />
              </GridItem>
            </Grid>
          </Flex>

          <Flex spacing={8} className="mt-32" jc="center" ai="center">
            <Button
              appearance="thickoutline"
              shape="rounded"
              action={handleCloseModal}
            >
              <p>Pay Later</p>
            </Button>
            <Button
              shape="rounded"
              buttonType="submit"
              isDisabled={isDisabled}
              isLoading={isLoading}
            >
              <p>
                Pay Now — ${numeral(totalFee * exchange_rate).format("0,0.00")}
              </p>
            </Button>
          </Flex>
        </Flex>
      </form>
    </Modal>
  );
};

export default UpgradeSubModal;
