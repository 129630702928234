import React from "react";
import { Colors } from "../../constants";
import { ReactComponent as Upload } from "../../assets/svgs/Upload.svg";
import styled from "styled-components";
import colors from "../../constants/colors";

const EmojiTextButton = ({
  onClick,
  text,
  styleProp,
  IconProp = Upload,
  disabled,
  className,
}) => {
  return (
    <button
      className={className}
      disabled={disabled}
      style={{
        border: "1px solid" + Colors.purple,
        borderRadius: "4px",
        color: Colors.purple,
        cursor: "pointer",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        opacity: disabled ? 0.5 : 1,
        ...styleProp,
      }}
      onClick={onClick}
    >
      <IconProp
        style={{
          height: styleProp?.fontSize || "12px",
          width: styleProp?.fontSize || "12px",
          flexShrink: 0,
          marginRight: ".25em",
        }}
      />
      <p
        style={{
          whiteSpace: "nowrap",
          color: styleProp?.color || "white",
          margin: "0 .5em",
        }}
      >
        {text}
      </p>
    </button>
  );
};

EmojiTextButton.Custom = props => {
  const { text = "", onClick = () => {}, ...rest } = props;
  return (
    <ButtonSheet onClick={onClick} {...rest}>
      <span>
        <p className="text">{text}</p>
      </span>
    </ButtonSheet>
  );
};

const ButtonSheet = styled.button`
  display: ${({ display }) => display || `initial`};
  flex: ${({ flex }) => flex || `default`};
  flex-grow: ${flexGrow => flexGrow || "initial"};
  flex-shrink: ${flexShrink => flexShrink || "initial"};
  width: ${({ width }) => width || `initial`};
  color: ${({ color }) => color || colors.purple};
  margin: ${({ margin }) => margin || `0`};
  padding: ${({ padding }) => padding || `0`};
  cursor: ${({ cursor }) => cursor || `pointer`};
  border: ${({ border }) => border || `1px solid ${colors.purple}`};
  font-size: ${({ fontSize }) => fontSize || `medium`};
  white-space: ${({ whiteSpace }) => whiteSpace || `nowrap`};
  border-radius: ${({ borderRadius }) => borderRadius || `8px`};
  background-color: ${({ backgroundColor }) =>
    backgroundColor || `transparent`};
  .display-block {
    display: block;
  }

  .text {
    margin: 0;
  }
`;

export default EmojiTextButton;
