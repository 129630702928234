import React, { useCallback, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Colors } from "../../../constants";
import BillCard from "../choose-subscription/BillCard";
import { StyledForm } from "../account-information/Form";
import colors from "../../../constants/colors";
import { AppContext } from "../../../context";
import { determineTotalPrice } from "../../../selectors";
import errorHandler from "../../../selectors/errorHandler";
import toast from "react-hot-toast";
import { get } from "../../../api/transport";

const Form = ({
  setCouponCode = () => {},
  subscription: subscription,
  setSubscription,
  setCoupon = () => {},
}) => {
  const [code, setCode] = useState("");
  const [loading, set_loading] = useState(false);
  const exchange_rate = process.env.REACT_APP_USD_CEDI_EXCHANGE_RATE;
  const totalPrice = determineTotalPrice(subscription);

  return (
    <StyledForm style={{ padding: "0" }}>
      <div className="row ">
        <BillCard
          perMeasure={subscription?.duration}
          figure={totalPrice * exchange_rate}
          header="Subscription Fee"
          style={{ width: "100%", margin: 0 }}
        />
      </div>

      <div className="row">
        <div className="input-field">
          <label>Coupon Code (Optional)</label>
          <PseudoInput>
            <input
              disabled={loading}
              value={code}
              onChange={e => setCode(e.target.value)}
              placeholder="Enter coupon code"
            />
            <span
              onClick={() => {
                if (loading) return;
                if (code.length === 0)
                  return toast.error("Kindly input a coupon code");
                fetchCoupon();
              }}
              className="pointer flex justify-center align-center"
            >
              <h6 className="purple pointer">APPLY</h6>
            </span>
          </PseudoInput>
        </div>
      </div>
    </StyledForm>
  );

  function fetchCoupon() {
    set_loading(true);
    toast.loading("Please wait");

    get(`/user/coupon/${code}/`)
      .then(response => {
        set_loading(false);
        toast.success("Success");
        setCouponCode(response?.data?.code);

        sessionStorage.setItem(
          "echo_subscription_coupon",
          JSON.stringify(response?.data)
        );

        setSubscription({
          ...subscription,
          coupon_amount: Number(response?.data?.fixed_price),
        });

        setCoupon(response.data);
      })
      .catch(error => {
        set_loading(false);

        setCouponCode("");
        setCode("");
        setCoupon({});

        sessionStorage.setItem(
          "echo_user_subscription_info",
          JSON.stringify({
            ...subscription,
            coupon_amount: 0,
          })
        );

        sessionStorage.removeItem("echo_subscription_coupon");

        setSubscription({
          ...subscription,
          coupon_amount: Number(0),
        });

        if (error?.response?.status == "404") {
          toast.error("Invalid Coupon Code");
        } else {
          errorHandler(error);
        }
      });
  }
};

export default Form;

const PseudoInput = styled.div`
  border: 1px solid ${colors.grey};
  display: flex;
  border: 1px solid #d9dae4;
  box-sizing: border-box;
  border-radius: 6px;
  overflow: hidden;

  h6 {
    margin: 0;
  }

  input {
    flex: 9;
    padding: 1em 0.5em;
    border: 0;
    font-size: 0.9em;
    font-family: CircularStd;

    &:focus {
      outline: none;
    }
  }

  span {
    flex: 1;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0.5em;
  }
`;
