import React, { useCallback, /*useEffect,*/ useMemo, useState } from "react";
import { AreaChartComponent, Layout } from "../components/dashboard";
import { Filters } from "../components/dashboard/Filters";
import moment from "moment";
import { PaymentTable } from "../components/finance";
import { v4 as uuidv4 } from "uuid";
import { DocumentHeader } from "../components/general/DocumentHeader";
import { useAdminTransactions } from "../components/admin/broker";

const SuperAdminFinance = () => {
  const [selectedFilter, setSelectedFilter] = useState("week");

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const { data, isLoading } = useAdminTransactions(
    "desc",
    selectedFilter,
    endDate ? moment(endDate)?.format("YYYY-MM-DD") : "",
    startDate ? moment(startDate)?.format("YYYY-MM-DD") : ""
  );

  const transactions = useMemo(() => data?.data || [], [data]);

  const filters = [
    {
      title: "Today",
      active: selectedFilter.toLowerCase() === "today",
      onClick: () => {
        setStartDate("");
        setEndDate("");
        setSelectedFilter("today");
      },
    },
    {
      title: "Week",
      active: selectedFilter.toLowerCase() === "week",
      onClick: () => {
        setStartDate("");
        setEndDate("");
        setSelectedFilter("week");
      },
    },
    {
      title: "Month",
      active: selectedFilter.toLowerCase() === "month",
      onClick: () => {
        setStartDate("");
        setEndDate("");
        setSelectedFilter("month");
      },
    },
    {
      title: "Year",
      active: selectedFilter.toLowerCase() === "year",
      onClick: () => {
        setStartDate("");
        setEndDate("");
        setSelectedFilter("year");
      },
    },
    {
      title: `Start Date - ${moment(startDate).format("YYYY-MM-DD")}`,
      active: !!startDate && !!endDate,
      selected: startDate || new Date(),
      type: "date",
      onChange: date => {
        setSelectedFilter("");
        setStartDate(date);
      },
    },
    {
      title: `End Date - ${moment(endDate).format("YYYY-MM-DD")}`,
      active: !!startDate && !!endDate,
      selected: endDate || new Date(),
      type: "date",
      onChange: date => {
        setSelectedFilter("");
        setEndDate(date);
      },
    },
  ];

  const isTodayOrWeek = useCallback(() => {
    const isToday = selectedFilter === "today";
    const isWeek = selectedFilter === "week";

    return isToday || isWeek;
  }, [selectedFilter]);

  const collatedTransactionData = useCallback(
    transactions?.reduce((prev, current) => {
      let dateExists = prev?.find(el =>
        moment(el?.date).isSame(
          current?.date_published,
          isTodayOrWeek() ? "hour" : "day"
        )
      );

      if (!dateExists) {
        return [
          ...prev,
          {
            uuid: uuidv4(),
            date: current?.date_published,
            transactions: 1,
          },
        ];
      }

      dateExists = {
        ...dateExists,
        transactions: Number(dateExists.transactions) + 1,
      };

      let new_list = prev?.map(el => {
        if (el.uuid === dateExists.uuid) {
          return dateExists;
        }

        return el;
      });

      return new_list;
    }, []),
    [data, selectedFilter]
  );

  const formatDate = useCallback((arr = []) => {
    return arr
      ?.sort((a, b) => {
        if (moment(a.date).isBefore(moment(b.date))) {
          return -1;
        }
        return 1;
      })
      .map(el => {
        return {
          ...el,
          date: moment(el?.date)?.format("MMM Do | ha"),
        };
      });
  }, []);

  return (
    <Layout>
      <DocumentHeader page="Finance" />
      <div className="flex grow bordered-0 flex-column max-width-100">
        <div className="bordered-0 flex justify-between m-b-1">
          <h2 className="m-0">Finance</h2>
          <Filters filters={filters} />
        </div>

        <div className="bordered-0 flex height-30 m-b-1">
          <AreaChartComponent
            data={formatDate(collatedTransactionData)}
            count={transactions?.length || 0}
            caption={"Total Revenue"}
            dataKey="transactions"
            loading={isLoading}
            tooltipUnit="transactions"
          />
        </div>

        <div className="grow bordered-0">
          <PaymentTable />
        </div>
      </div>
    </Layout>
  );
};

export default SuperAdminFinance;
