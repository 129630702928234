import React, { useMemo } from "react";
import { useTable, usePagination } from "react-table";
import styled from "styled-components";
import { EmptyRenderer } from "./EmptyRenderer";
import { Loading } from "./Loading";

const Table = ({
  columns,
  data,
  pageSizeProp,
  loading = false,
  className,
  rowProps = () => ({}),
}) => {
  const {
    getTableBodyProps,
    getTableProps,
    headerGroups,
    page,
    prepareRow,
    state,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: pageSizeProp || 15,
      },
    },
    usePagination
  );

  const { pageIndex, pageSize } = state;

  return (
    <StyledTable {...getTableProps()} className={className}>
      <thead>
        {headerGroups.map((headerGroup, idx) => (
          <tr key={idx} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, idx) => (
              <th key={idx} {...column.getHeaderProps()}>
                {column.render("Header")}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {loading ? (
          <Loading />
        ) : page.length !== 0 ? (
          page.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps(rowProps(row))} key={Math.random()}>
                {row.cells.map(cell => (
                  <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                ))}
              </tr>
            );
          })
        ) : (
          <EmptyRenderer />
        )}
      </tbody>
    </StyledTable>
  );
};

export default Table;

const StyledTable = styled.table`
  border-collapse: collapse;
  text-align: left;
  width: 100%;
  overflow: auto;
  font-family: CircularStd;
  border-bottom: none;
  color: #696969;

  td,
  th {
    font-family: CircularStd;
    border-left: none;
    border-right: none;
    border-top: none;
    border-bottom: 1px solid #f5f3f3;
  }
  thead {
    th {
      background-color: white;
      font-size: 0.8em;
      padding: 1em 0.5em;
      font-weight: bold !important;
    }
  }

  tbody {
    width: 100%;
    tr {
      background-color: white;
      &:last-child {
        border-bottom: none !important;
      }
    }
    td {
      max-width: 100%;
      padding: 1em 1em 1em 0.5em;
      font-size: 15px;
      font-weight: normal;
      &:first-child {
        padding-left: 50px solid transparent;
      }
    }
  }
`;

// color: ${props => props.theme.default.gray};
// &:nth-child(${props => props.stripes || "even"}) {
//   background-color: ${props => "#F9F9F9"};
// }
