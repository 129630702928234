import React from "react";
import { useLoadScript } from "@react-google-maps/api";
import usePlacesAutoComplete from "use-places-autocomplete";
import styled from "styled-components";
import { Colors } from "../../constants";
// import usePlacesAutoComplete, { getGeocode } from "use-places-autocomplete";

const libraries = ["places"];

const AutoCompleteInput = props => {
  // isLoaded, loadError are returned from useLoadScript

  const { className } = props;
  let { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  // console.log(isLoaded);

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutoComplete({
    defaultValue: props.defaultValue || "",
    initOnMount: true,
  });

  function handleSelect(description) {
    setValue(description, false);
    props.onChange(description);
    clearSuggestions();
  }

  function handleChange(value) {
    setValue(value);
    props.onChange(value);
  }
  return (
    <StyledInputSection>
      <input
        className={props.className}
        value={value}
        onChange={e => {
          const value = e.target.value;
          handleChange(value);
        }}
        placeholder={props.placeholder || "Enter an address"}
        style={{ width: "100%" }}
        disabled={!ready || !isLoaded}
      />

      {status === "OK" && (
        <div className="dropdown">
          {data.map(({ id, description }) => (
            <div
              className="dropdown-item"
              onClick={e => handleSelect(description)}
              key={Math.random()}
            >
              {description}
            </div>
          ))}
        </div>
      )}
    </StyledInputSection>
  );
};

export default AutoCompleteInput;

const StyledInputSection = styled.div`
  input {
    width: 100%;
    height: 50px;
    border: 1px solid #d9dae4;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 15px 20px;

    font-family: CircularStd;
    font-size: medium !important;
    line-height: 20px;
  }

  input:focus {
    border: 1px solid #72004c;
    box-sizing: border-box;
    box-shadow: 0px 0px 0px 4px rgba(114, 0, 76, 0.08);
    border-radius: 6px;
  }

  .dropdown {
    position: absolute;
    top: 105%;
    width: 100%;
    border: 1px solid #d9dae4;
    border-radius: 8px;
    background: white;
    padding: 0.5em 0;
    z-index: 10;

    .dropdown-item:hover {
      background: ${props => "#f4f6fa"};
    }

    .dropdown-item {
      padding: 0.5em 20px;
      cursor: pointer;
      color: ${props => Colors.grey_2};

      p {
        margin: 0;
      }
    }
  }
`;
