import React from "react";
import styled from "styled-components";
import { Colors } from "../../constants";
import { ReactComponent as Ellipse } from "../../assets/svgs/ellipse.svg";

const PieLegend = ({ legendStyle, data, count = 0 }) => {
  const empty_data = [
    { name: "Happy", fill: Colors.grey_1, sign: "😍", value: 0 },
    { name: "Good", fill: Colors.grey_1, sign: "😀", value: 0 },
    { name: "Neutral", fill: Colors.grey_1, sign: "🙄", value: 0 },
    { name: "Bad", fill: Colors.grey_1, sign: "😕", value: 0 },
    { name: "Angry", fill: Colors.grey_1, sign: "😡", value: 0 },
  ];

  const empty = data.length === 0;
  const mapping_data = empty ? empty_data : data;

  return (
    <StyledPieLegend legendStyle={legendStyle}>
      {empty ||
        mapping_data.map(entry => {
          if (empty || legendStyle === "legend1") {
            return (
              <Legend1
                empty={empty}
                count={count}
                entry={entry}
                key={Math.random()}
              />
            );
          }

          return (
            <Legend2
              empty={empty}
              count={count}
              entry={entry}
              key={Math.random()}
            />
          );
        })}
    </StyledPieLegend>
  );
};

export default PieLegend;

function Legend1({ entry, count }) {
  const { value } = entry;
  return (
    <StyledLegend1>
      <div className="flex-basis-50 flex">
        <Ellipse className="m-0" />
        <div style={{ marginLeft: ".2em" }}>
          <p className="m-0 ">{entry?.name}</p>
        </div>
      </div>
      <div className="flex-basis-50 ">
        <p className="m-0 bold inline left-align">{value} - </p>
        {value === 0
          ? "0%"
          : `${Math.floor((value / count) * 100).toFixed(2) || "-"}%`}
      </div>
    </StyledLegend1>
  );
}

const StyledLegend1 = styled.div`
  display: flex;

  @media (max-width: 1279px) {
    display: flex;
    flex-direction: column;
  }
`;

function Legend2({ entry, count }) {
  const { value = 0 } = entry;
  const percentage = `${value > 0 ? Math.floor((value / count) * 100) : 0}%`;

  return (
    <div className="flex align-center justify-center gap-half">
      <div className="flex align-center gap-half">
        <p>{entry?.sign}</p>
      </div>

      <div className="grow flex flex-column gap-1">
        <div className="flex justify-between align-center">
          <p className="bold m-0">{entry?.name}</p>
          <p className="bold m-0">
            {value}{" "}
            {value > 0 && (
              <span>
                ({Math.floor((value / count) * 100).toFixed(2) || ""}%)
              </span>
            )}
          </p>
        </div>
        <Bar percentage={percentage} />
      </div>
    </div>
  );
}

const StyledPieLegend = styled.div`
  transition: 1s ease;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2em 1em;

  p {
    margin: 0;
  }
`;

function Bar({ percentage = "0%" }) {
  return (
    <div
      className="bordered"
      style={{
        width: "100%",
        height: "4px",
        background: "#F1EEEE",
        borderRadius: "8px",
        boxSizing: "border-box",
        padding: "0",
        border: 0,
      }}
    >
      <div
        style={{
          width: percentage,
          height: "4px",
          background: Colors.purple,
          border: "1px solid #F5F3F3",
          borderRadius: "8px",
          boxSizing: "border-box",
        }}
      />
    </div>
  );
}
