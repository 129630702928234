import toast from "react-hot-toast";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { delete_request, get, patch, post } from "../../api/transport";
import { useAppContext } from "../../context";
import errorHandler from "../../selectors/errorHandler";

const defaultOptions = {
  // refetchOnWindowFocus: false,
  refetchOnmount: false,
  refetchOnReconnect: false,
  retry: false,
  staleTime: 86400000,
};

export const useQuestions = (limit = "", offset = "") => {
  const queryKey = "questions";

  const queryFn = () =>
    get(`/user/question-settings/?limit=${limit}&offset=${offset}`);

  const select = response => {
    const results = response?.data?.results;

    return results;
  };

  const options = { select, ...defaultOptions };

  return useQuery(queryKey, queryFn, options);
};

export const useQuestionsData = (limit = "", offset = "", search_term = "") => {
  const queryKey = ["questions", limit, offset, search_term];

  const queryFn = () =>
    get(
      `/user/question-settings/?limit=${limit}&offset=${offset}&orderby=desc&search=${search_term}`
    );

  const select = ({ data }) => data;

  const options = { select, ...defaultOptions };

  return useQuery(queryKey, queryFn, options);
};

export const useCreateQuestion = () => {
  const queryClient = useQueryClient();

  const queryFn = data => post("/user/question-settings/", data);

  const onSuccess = () => queryClient.invalidateQueries("questions");

  const onError = error => errorHandler(error);

  const options = { onSuccess, onError };

  return useMutation(queryFn, options);
};

export const useUpdateQuestion = id => {
  const queryClient = useQueryClient();

  const queryFn = data => patch(`/user/question-settings/${id}/`, data);

  const onSuccess = () => queryClient.invalidateQueries("questions");

  const onError = error => errorHandler(error);

  const options = { onSuccess, onError };

  return useMutation(queryFn, options);
};

export const useDeleteQuestion = id => {
  const queryClient = useQueryClient();

  const queryFn = () => delete_request(`/user/question-settings/${id}/`);

  const onSuccess = () => {
    queryClient.invalidateQueries("questions");
    queryClient.invalidateQueries("devices");
    toast.success("Success");
  };

  const onError = error => errorHandler(error);

  const options = { onSuccess, onError };

  return useMutation(queryFn, options);
};
