import React from "react";
import styled from "styled-components";
import Flex from "../layout/Flex";
import Icon from "../icon/Icon";
import Grid, { GridItem } from "../layout/Grid";

const alertStyles = {
  danger: {
    "background-color": "rgba(216, 0, 39, 0.1)",
  },
  success: {
    "background-color": "rgba(65, 166, 107, 0.1)",
  },
  transparent: {
    "background-color": "transparent",
  },
};

const alertDict = {
  danger: {
    icon: {
      bg: "rgba(255, 82, 82, 0.1)",
      color: "#FF5252",
    },
  },
  success: {
    icon: {},
  },
  transparent: {
    icon: {
      color: "#FF5252",
    },
  },
};

const iconDict = {
  success: ["fas", "triangle-exclamation"],
  success: ["fas", "triangle-exclamation"],
};

const StyledAlert = styled(Flex)`
  padding: 16px 24px;
  border-radius: 6px;
  ${({ type }) => alertStyles[type]}

  .icon-container {
    background-color: ${({ type }) => alertDict[type]?.icon?.bg};
    // border-radius: 50%;
    // padding: 8px;
    // width: 30px;
    // height: 30px;

    display: grid;
    place-content: center;
    align-content: center;

    // margin-right: em;
  }

  .alert-msg {
    color: #696969;
    font-size: 14px;
  }
`;

const Alert = props => {
  const { type = "success", message = "" } = props;

  const styleProps = { type };

  return (
    <StyledAlert ai="center" {...styleProps}>
      <Grid gap="10px" span={12}>
        <GridItem span={1}>
          <div className="icon-container">
            <Icon
              icon={["fas", "circle-exclamation"]}
              color={alertDict[type]?.icon?.color}
            />
          </div>
        </GridItem>

        <GridItem span={11}>
          <div className="alert-msg">{message}</div>
        </GridItem>
      </Grid>
    </StyledAlert>
  );
};

export default Alert;
