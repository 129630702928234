import React from "react";
import { SuperAdminBranchesTable } from "../../components/branches/AdminBranchesTable";
import { CompaniesTable, CompanyTabs } from "../../components/companies";
import { Layout } from "../../components/dashboard";
import { DocumentHeader } from "../../components/general/DocumentHeader";

const Branches = () => {
  return (
    <Layout tabs={CompanyTabs}>
      <DocumentHeader page="Branches" />
      <SuperAdminBranchesTable />
    </Layout>
  );
};

export default Branches;
