import React, { useState, useMemo, useRef, useCallback } from "react";
import { Table } from "../table";
import moment from "moment";
import { Colors } from "../../constants";
import Searchbar from "../dashboard/Searchbar";
import { ReactComponent as Export } from "../../assets/svgs/export.svg";
import { CSVLink } from "react-csv";
import { useFeedbackData } from "./broker";
import Paginator from "../table/Paginator";

export const FeedbacksTable = () => {
  const [search_term, set_search_term] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const downloadButtonRef = useRef();

  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);

  const { data, isLoading } = useFeedbackData(
    offset,
    limit,
    "desc",
    search_term
  );
  const sentiments = data?.results || [];

  const COLUMNS = [
    {
      Header: "DEVICE",
      accessor: "tablet.name",
    },
    {
      Header: "BRANCH",
      accessor: "tablet.location.name",
      Cell: data => {
        const sentiment = data?.row?.original;

        if (
          !sentiment?.tablet?.location ||
          sentiment?.tablet?.location?.length === 0
        ) {
          return "";
        }

        return `${sentiment?.tablet?.location?.map(
          el => ` ${el?.name || ""}`
        )}`;
      },
    },
    {
      Header: "CONTACT",
      accessor: "email",
    },
    {
      Header: "SENTIMENT",
      accessor: "sentiment",
      Cell: ({ value }) => {
        const emojis = {
          excellent: "😍",
          good: "😀",
          okay: "🙄",
          bad: "😕",
          terrible: "😡",
        };

        return (
          <span className="flex align-center">
            <span className="m-r-p5">
              <img
                style={{ width: "1.5em", height: "1.5em" }}
                src={value?.emoji}
                alt="emoji"
              />
            </span>
            <p className="m-0">{value.title}</p>
          </span>
        );
      },
    },
    {
      Header: "CATEGORY",
      accessor: "category.title",
    },
    {
      Header: "FEEDBACK",
      accessor: "feedback",
    },
    {
      Header: "DATE",
      accessor: "date_published",
      Cell: ({ value }) => moment(value).format("MMM D, YYYY"),
    },
  ];

  const tableData = useMemo(
    () => sentiments,
    [data, limit, offset, search_term]
  );

  return (
    <div
      className="flex grow flex-column"
      style={{ maxHeight: "90vh", overflowY: "auto" }}
    >
      <div className="flex justify-between align-center p-v-1">
        <h4 style={{ margin: 0 }}>All Feedback</h4>
        <div>
          <Searchbar term={search_term} filter={set_search_term} />
        </div>

        <div className="flex align-center justify-between">
          <CSVLink
            ref={downloadButtonRef}
            className="r-8 flex"
            style={{
              background: Colors.purple,
              color: Colors.white,
              padding: ".8em 2em",
              marginLeft: ".5em",
              textDecoration: "none",
            }}
            data={[
              ...sentiments?.map(el => {
                return {
                  DEVICE: el?.tablet?.name,
                  BRANCH: ` ${el.tablet?.location?.map(el => el.name)}`,
                  CUSTOMER: el?.email,
                  SENTIMENT: el?.sentiment.title,
                  CATEGORY: el?.category?.title,
                  FEEDBACK: el?.feedback,
                  DATE: moment(el.created_at).format("MMM D, YYYY hh:mm a"),
                };
              }),
            ]}
            disabled={true}
          >
            <span className="m-r-p5">
              <Export />
            </span>
            <p className="m-0">Export Data</p>
          </CSVLink>
        </div>
      </div>
      <div className="flex flex-column grow m-t-1 bg-w">
        <Table loading={isLoading} columns={COLUMNS} data={tableData} />
        <Paginator
          limit={limit}
          offset={offset}
          setOffset={setOffset}
          count={data?.count}
        />
      </div>
    </div>
  );
};
