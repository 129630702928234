import styled from "styled-components";
import SidebarItem from "./SidebarItem";
import { Colors } from "../../constants";

export default function SidebarCategory({ name, items = [], ...props }) {
  return (
    <SidebarCategoryParent>
      <div className="category-title">
        <h5>{name}</h5>
      </div>
      {items.map((item, idx) => (
        <SidebarItem key={Math.random()} {...item} />
      ))}
    </SidebarCategoryParent>
  );
}

const SidebarCategoryParent = styled.section`
  padding: 0.5rem 0;
  z-index: 0;
  // border: 1px solid yellow;
  max-width: 17em;
  overflow-x: visible;

  .category-title {
    padding: 0 15px;
    // border: 1px solid white;
    margin: 0 0 10px 0;
    position: relative;
    z-index: 0;

    .walkthrough-item {
      position: absolute;
      left: 70%;
      top: -110%;
      // border: 1px solid black;
      color: yellow;
      z-index: 10;
      background: white;
      min-height: 8em;
      min-width: 10em;
    }

    h5 {
      font-weight: 400;
      color: ${Colors.white_4};
      margin: 0;
    }
  }
`;
