import { useQuery } from "react-query";
import { get } from "../../api/transport";

export const useProfile = () => {
  const queryKey = "profile";
  const queryFn = () => get("/users/me");
  const select = response => response?.data;
  const options = { select };

  return useQuery(queryKey, queryFn, options);
};
