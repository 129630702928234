export default function Svg ({
  children,
  className,
  viewBox="0 0 14 14",
  size="1.5em",
  color="#fff",
  ...rest
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
      width={size}
      fill={color}
      className={`echo-icon ${className || ''}`}
      {...rest}
    >
      { children }
    </svg>
  )
};
