import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Modal from "react-modal";
import { Colors } from "../../constants";
import { ReactComponent as Close } from "../../assets/svgs/close.svg";
import { ModalForm } from "../devices";
import { TextButton } from "../general";
import ModalTitle from "./ModalTitle";
import QRCode from "../devices/QRCode";
import AwaitingDevice from "../devices/AwaitingDevice";

const AddDeviceModal = props => {
  const [device, set_device] = useState();

  const pageStates = {
    0: "default",
    1: "awaiting",
    2: "getQR",
  };

  const pageTitles = {
    default: {
      title: "Add a Device",
      subTitle:
        "To complete device setup, a unique device code would be generated here. You’ll also get a QR Code to print in case you don’t have a tablet device.",
    },
    awaiting: {
      title: "Complete Device Setup",
      subTitle:
        "Open the Echo app on the device you’re about to setup and enter the code shown below. Once code is entered, device will be setup automatically.",
    },
    complete: {
      title: "",
    },
    getQR: {
      title: "Get QR Code",
      subTitle:
        "The QR Code generated below would be used to give feedback when a customer scans it.",
    },
  };

  const [page, setPage] = useState(pageStates[0]);

  useEffect(() => {
    let mounted = true;
    if (mounted) Modal.setAppElement("body");
    return () => (mounted = false);
  }, []);

  return (
    <Modal
      style={{
        overlay: {
          background: "rgba(0,0,0,0.7)",
        },
        content: {
          background: Colors.white,
          margin: "auto auto",
          width: "40%",
          height: "70%",
        },
      }}
      isOpen={props.isOpen}
      onRequestClose={props.closeModal}
    >
      <AddDeviceModalContent>
        <div className="close-button-area">
          <Close id="close-button" onClick={props.closeModal} />
        </div>

        <div className="flex flex-column grow">
          <ModalTitle
            title={pageTitles[page]?.title}
            subTitle={pageTitles[page]?.subTitle}
          />

          {page === "default" && (
            <ModalForm
              page={page}
              setPage={setPage}
              setDevice={set_device}
              {...props}
            />
          )}
          {page === "awaiting" && (
            <AwaitingDevice
              page={page}
              setPage={setPage}
              device={device}
              {...props}
            />
          )}
        </div>
      </AddDeviceModalContent>
    </Modal>
  );
};

export default AddDeviceModal;

const AddDeviceModalContent = styled.div`
  // border: 1px solid;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .close-button-area {
    display: flex;
    flex-direction: row-reverse;
    // ;

    #close-button {
      // border: 1px solid;
      height: 0.5em;
      width: 0.5em;
      cursor: pointer;
    }
  }
`;
