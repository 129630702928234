import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Modal from "react-modal";
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { TextButton } from "../general";
import colors from "../../constants/colors";
import { Colors } from "../../constants";
import { post } from "../../api/transport";

const stripeKey = process.env.REACT_APP_STRIPE_KEY;

const stripePromise = loadStripe(stripeKey);

const modalStyles = {
  overlay: {
    background: "rgba(0,0,0,0.7)",
    zIndex: 2,
  },
  content: {
    background: "white",
    margin: "auto auto",
    maxWidth: "600px",
    height: "fit-content",
  },
};

const StyledForm = styled.form`
  gap: 40px;

  .form-header {
    gap: 4px;
    h2,
    p {
      margin: 0;
    }

    h2 {
      color: ${Colors.purple};
    }
    p {
      color: ${Colors.grey_2};
    }
  }
`;

const CheckoutForm = props => {
  const { cancel, setIsSuccess, setIsFail } = props;

  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async event => {
    event.preventDefault();

    if (!stripe || !elements) return;

    setLoading(true);
    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      redirect: "if_required",
      confirmParams: {},
    });

    if (result.error) {
      console.log(result.error.message);
      cancel();
      setIsFail(true);
      return setLoading(false);
    }

    const { paymentIntent } = result;

    try {
      await post("/payment/verify/", {
        payment_method: "STRIPE",
        stripe_payment_intent: paymentIntent.id,
      });
      setIsSuccess(true);
    } catch (error) {
      setIsFail(true);
    } finally {
      cancel();
      setLoading(false);
    }
  };

  return (
    <StyledForm className="flex flex-column" onSubmit={handleSubmit}>
      <div className="form-header flex flex-column">
        <h2>Payment details</h2>
        <p>Add your payment details to complete payment</p>
      </div>
      <div className="">
        <PaymentElement />
      </div>
      <div className="flex justify-end m-t-1">
        <TextButton
          padding="8px 12px"
          text="Cancel"
          fontSize="16px"
          margin="0"
          onClick={cancel}
        />
        <TextButton
          background={colors.purple}
          color="white"
          padding="8px 12px"
          text={loading ? "Please wait" : "Pay Now"}
          fontSize="16px"
          margin="0"
          disabled={!stripe || loading}
          type="submit"
        />
      </div>
    </StyledForm>
  );
};

const StripeCheckoutModal = props => {
  const {
    clientSecret = "",
    closeModal,
    isOpen,
    setIsSuccess,
    setIsFail,
  } = props;

  //   console.log(clientSecret);

  useEffect(() => {
    let mounted = true;
    if (mounted) Modal.setAppElement("body");
    return () => (mounted = false);
  }, []);

  const options = {
    // passing the client secret obtained from the server
    clientSecret,
  };

  return (
    <Modal
      style={modalStyles}
      isOpen={isOpen}
      onRequestClose={closeModal}
      ariaHideApp={false}
    >
      <StripeCheckoutModalContent>
        {clientSecret && (
          <Elements stripe={stripePromise} options={options}>
            <CheckoutForm
              cancel={closeModal}
              setIsSuccess={setIsSuccess}
              setIsFail={setIsFail}
            />
          </Elements>
        )}
      </StripeCheckoutModalContent>
    </Modal>
  );
};

export default StripeCheckoutModal;

const StripeCheckoutModalContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .close-button-area {
    display: flex;
    flex-direction: row-reverse;

    #close-button {
      height: 0.5em;
      width: 0.5em;
      cursor: pointer;
    }
  }
`;
