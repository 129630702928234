import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Modal from "react-modal";
import { Colors } from "../../constants";
import { ReactComponent as Close } from "../../assets/svgs/close.svg";
import { ReactComponent as Download } from "../../assets/svgs/download.svg";
import { ReactComponent as Echo } from "../../assets/svgs/echo.svg";
import { ReactComponent as A1 } from "../../assets/svgs/qr_artwork_1.svg";
import { ReactComponent as A2 } from "../../assets/svgs/qr_artwork_2.svg";
import { ReactComponent as A3 } from "../../assets/svgs/qr_artwork_3.svg";
import { ReactComponent as A4 } from "../../assets/svgs/qr_artwork_4.svg";
import { EmojiTextButton, TextButton } from "../general";
import ModalTitle from "./ModalTitle";
import QRCode from "react-qr-code";
import { v4 } from "uuid";
import {
  exportComponentAsJPEG,
  exportComponentAsPDF,
  exportComponentAsPNG,
} from "react-component-export-image";

const QRCodeModal = props => {
  const [page, set_page] = useState(1);
  const QRRef1 = useRef();
  const QRRef2 = useRef();

  const set_modal_element = () => {
    Modal.setAppElement("body");
    return () => {};
  };

  const modal_style = {
    overlay: {
      background: "rgba(0,0,0,0.7)",
    },
    content: {
      background: Colors.white,
      margin: "auto auto",
      width: "30%",
      height: "60%",
    },
  };

  // console.log(props.device);

  const button_styles = {
    with_icon: {
      display: "flex",
      justifyContent: "center",
      padding: ".8em",
      border: "1px solid #D9DAE4",
      background: "#FBF9F9",
      color: "black",
    },
    without_icon: {
      background: "#72004C",
      color: "white",
      padding: ".8em",
    },
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) set_modal_element();
    return () => (mounted = false);
  }, []);

  const QRCodeArtWork = React.forwardRef((props, ref) => (
    <div
      ref={ref}
      className="flex flex-basis-60 flex-column bg-cream relative justify-center align-center "
    >
      <A1 className="absolute" style={{ top: 0, right: "20%" }} />
      <A2 className="absolute" style={{ left: 0, top: "30%" }} />
      <A3 className="absolute" style={{ bottom: 0, right: 0 }} />
      <A4 className="absolute" style={{ top: "40%" }} />

      <div className="flex flex-column gap-half" style={{ zIndex: 1 }}>
        <div className="-0 flex justify-center align-center">
          <Echo />
        </div>
        <h2 className="m-0 center-align">Let's hear from you!</h2>
        <div className="bordered-0 flex justify-center">
          <div
            className="bordered-0 r-8 bg-purple"
            style={{
              height: "12em",
              width: "10em",
              border: "1px solid #72004C",
            }}
          >
            <div
              className="r-8 bg-w flex justify-center align-center"
              style={{ height: "90%", width: "100%" }}
            >
              <QRCode
                size={120}
                value={`https://qr.echofeed.co/?passcode=${props.device?.pass_code}`}
              />
            </div>
            <p className="white m-0 small-text center-align">SCAN ME</p>
          </div>
        </div>
        <p className="center-align m-0">
          Kindly scan the QR Code above to <br />
          send us your feedback.
        </p>
      </div>
    </div>
  ));

  const QRCodeDefault = React.forwardRef((props, ref) => (
    <div
      ref={ref}
      className="flex flex-basis-60 bordered flex-column justify-center align-center r-8 dashed bg-peach"
    >
      <div className="bordered-0">
        <QRCode
          size={220}
          value={`https://qr.echofeed.co/?passcode=${props.device?.pass_code}`}
        />
      </div>
    </div>
  ));

  return (
    <Modal
      style={modal_style}
      isOpen={props.isOpen}
      onRequestClose={props.closeModal}
    >
      <ModalContent>
        <div className="close-button-area">
          <Close id="close-button" onClick={props.closeModal} />
        </div>

        <div className="flex grow flex-column">
          <div className="flex grow flex-column">
            <ModalTitle
              title={"Get QR Code"}
              subTitle={`The QR Code generated below would be used to give feedback when a customer scans it. QR Code is for ${
                props.device?.name
              },${props.device?.location?.map(el => ` ${el.name}`)}.`}
            />

            <div className="flex grow flex-column m-t-2">
              <Tabs handleSelect={key => set_page(key)} selected_page={page} />
              <div className="flex grow bordered-0 p-v-3">
                {page === 0 && (
                  <QRCodeArtWork device={props.device} ref={QRRef1} />
                )}

                {page === 1 && (
                  <QRCodeDefault device={props.device} ref={QRRef2} />
                )}

                <div className="flex grow bordered-0 flex-column p-l-p5">
                  <div className="bordered-0 flex flex-column gap-half">
                    <EmojiTextButton
                      IconProp={Download}
                      text={"Download Image File"}
                      styleProp={button_styles.with_icon}
                      onClick={event => {
                        event.preventDefault();
                        // console.log(page);
                        if (page === 0) {
                          exportComponentAsJPEG(QRRef1);
                        } else {
                          exportComponentAsJPEG(QRRef2);
                        }
                      }}
                    />
                    <EmojiTextButton
                      IconProp={Download}
                      text={"Download PDF File"}
                      styleProp={button_styles.with_icon}
                      onClick={event => {
                        event.preventDefault();
                        // console.log(page);
                        if (page === 0) {
                          exportComponentAsPDF(QRRef1);
                        } else {
                          exportComponentAsPDF(QRRef2);
                        }
                      }}
                    />
                    {page === 1 && (
                      <EmojiTextButton
                        IconProp={Download}
                        text={"Download Vector File"}
                        styleProp={button_styles.with_icon}
                      />
                    )}
                  </div>
                  <div className="bordered-0 m-t-auto flex flex-column">
                    <TextButton
                      styleProp={button_styles.without_icon}
                      text={"Done"}
                      onClick={props.closeModal}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
};

export default QRCodeModal;

const ModalContent = styled.div`
  // border: 1px solid;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .close-button-area {
    display: flex;
    flex-direction: row-reverse;
    // ;

    #close-button {
      // border: 1px solid;
      height: 0.5em;
      width: 0.5em;
      cursor: pointer;
    }
  }
`;

function Tabs(props) {
  const Sheet = styled.div`
    border-bottom: 1px solid #f5f3f3;
    display: flex;

    .tab {
      margin-right: 1em;
      cursor: pointer;

      &:hover {
        border-bottom: 1px solid #72004c;

        p {
          color: #72004c;
        }
      }
    }

    p {
      margin: 0.5em 0;
      font-size: 0.9em;
      color: #696969;
    }
  `;

  const pages = [
    {
      id: v4(),
      name: "Artwork with QR Code",
      onClick: () => props.handleSelect(0),
      selected: props.selected_page === 0,
    },
    {
      id: v4(),
      name: "QR Code Only",
      onClick: () => props.handleSelect(1),
      selected: props.selected_page === 1,
    },
  ];

  return (
    <Sheet>
      {pages.map(page => {
        return (
          <div
            className="tab"
            style={{
              borderBottom: "1px solid",
              borderBottomColor: page.selected ? "#72004c" : "transparent",
            }}
            key={page.id}
            onClick={page.onClick}
          >
            <p style={{ color: page.selected ? "#72004c" : "initial" }}>
              {page.name}
            </p>
          </div>
        );
      })}
    </Sheet>
  );
}
