import React from "react";
import Lottie from "react-lottie";
import lottieDefaultConfig from "../../selectors/lottieDefaultConfig";
import animationData from "../../assets/lottie/loader.json";

export const Loader = props => {
  const defaultOptions = { ...lottieDefaultConfig, animationData };

  return (
    <div
      className={`full-width-percent flex justify-center align-center flex-column grow ${props.className}`}
    >
      <Lottie options={defaultOptions} height={40} width={100} />
      <p className="gray bold m-0">please wait</p>
    </div>
  );
};
