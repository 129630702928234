import React from "react";
import { Layout } from "../components/dashboard";
import { DocumentHeader } from "../components/general/DocumentHeader";
import { QuestionsTable } from "../components/questions/QuestionsTable";

const Questions = () => {
  return (
    <Layout>
      <DocumentHeader page="Questions" />
      <QuestionsTable />
    </Layout>
  );
};

export { Questions };
