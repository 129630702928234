import React, { useMemo } from "react";
import { Table } from "../table";
import moment from "moment";
import { Link } from "react-router-dom";
import { ReactComponent as RightArrow } from "../../assets/svgs/arrow-right-purple.svg";
import { Colors } from "../../constants";
import { useRecentFeedbacks } from "./broker";

export const OverviewTable = props => {
  const { data, isLoading } = useRecentFeedbacks();
  const tableData = useMemo(() => data || [], [data]);

  const COLUMNS = [
    {
      Header: "DEVICE",
      accessor: "tablet.name",
    },
    {
      Header: "BRANCH",
      accessor: "tablet.location.name",
      Cell: data => {
        const sentiment = data?.row?.original;
        return sentiment?.tablet?.location?.[0]?.name || "";
      },
    },
    {
      Header: "CONTACT",
      accessor: "email",
    },
    {
      Header: "SENTIMENT",
      accessor: "sentiment",
      Cell: ({ value }) => {
        const emojis = {
          excellent: "😍",
          good: "😀",
          okay: "🙄",
          bad: "😕",
          terrible: "😡",
        };

        return (
          <span className="flex align-center">
            <span className="m-r-p5">
              <img
                style={{ width: "1.5em", height: "1.5em" }}
                src={value?.emoji}
                alt="emoji"
              />
            </span>
            <p className="m-0">{value.title}</p>
          </span>
        );
      },
    },
    {
      Header: "CATEGORY",
      accessor: "category.title",
    },
    {
      Header: "FEEDBACK",
      accessor: "feedback",
      // Cell: ({ value }) => {
      //   return value.slice(0, 50) + "...";
      // },
    },
    {
      Header: "DATE",
      accessor: "date_published",
      Cell: ({ value }) => moment(value).format("MMM D, YYYY"),
    },
  ];

  return (
    <>
      <div className="flex justify-between align-center p-v-1">
        <h4 style={{ margin: 0 }}>RECENT FEEDBACKS</h4>

        <div className="flex align-center pointer">
          <Link
            to="/dashboard/feedbacks"
            style={{
              margin: 0,
              fontSize: "16px",
              fontFamily: "CircularStdBold",
              color: Colors.purple,
              textDecoration: "none",
            }}
          >
            VIEW ALL
          </Link>
          <RightArrow />
        </div>
      </div>
      <Table
        loading={isLoading}
        columns={COLUMNS}
        emptyRenderer
        data={tableData}
      />
    </>
  );
};
