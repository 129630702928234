// import { DefaultHTMLProps, StyleFunction } from 'utils/types';
import styled from "styled-components";
import theme from "../../../theme";

// interface IProps {
//   gap?: number;
// }

const StyledLabel = styled.div`
  label {
    display: block;
    margin-bottom: ${props => props.gap};
    font-size: 14px;
    color: ${theme.colors.black};
    font-family: ${theme.typography.fonts.regular};

    &:first-letter {
      text-transform: uppercase;
    }
  }
`;

const InputLabel = ({ children, className, id, gap = 8 }) => {
  return (
    <StyledLabel gap={gap} className={className}>
      <label htmlFor={id}>{children}</label>
    </StyledLabel>
  );
};

export { InputLabel };
