import React, { useEffect, useState, useMemo } from "react";
import { Table } from "../table";
import moment from "moment";
import { Colors } from "../../constants";
import { EmojiTextButton } from "../general";
import Searchbar from "../dashboard/Searchbar";
import { ReactComponent as Plus } from "../../assets/svgs/plus.svg";
import DotsButton from "../general/DotsButton";
import { AddBranchModal } from "../modal";
import EditBranchModal from "../modal/EditBranch";
import errorHandler from "../../selectors/errorHandler";
import { useParams } from "react-router-dom";
import { get } from "../../api/transport";
import { useCompanyBranches, useDeleteCompanyBranch } from "../admin/broker";

export const SuperAdminBranchesTable = props => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loading, set_loading] = useState(true);
  const [search_term, set_search_term] = useState("");
  const params = useParams();

  const filterBranches = data => {
    const filtered_list = [...data].filter(branch => {
      const name_search = branch.name
        .toLowerCase()
        .includes(search_term.toLowerCase());
      const location_name_search = branch.location_name
        ?.toLowerCase()
        .includes(search_term.toLowerCase());
      return name_search || location_name_search;
    });
    return filtered_list;
  };

  const { data, isLoading } = useCompanyBranches(params.companyid);
  const tableData = useMemo(
    () => filterBranches(data?.results || []).reverse(),
    [data, search_term]
  );

  const COLUMNS = [
    {
      Header: "BRANCH ID",
      accessor: "uuid",
      Cell: ({ value }) => {
        const [first_partition] = value.split("-");
        return first_partition.toUpperCase();
      },
    },
    { Header: "BRANCH", accessor: "name" },
    {
      Header: "LOCATION",
      accessor: "location_name",
      // Cell: ({ value }) => value || "Greater Accra, Ghana",
    },
    {
      Header: "TOTAL DEVICES",
      accessor: "total_device",
      // Cell: ({ value }) => Math.ceil(Math.random() * 100),
    },
    {
      Header: "DATE ADDED",
      accessor: "created_at",
      Cell: ({ value }) => moment(value).format("MMM D, YYYY, hh:mm a"),
    },
    {
      accessor: "id",
      id: "action",
      Cell: data => {
        const [open, setOpen] = useState(false);
        const branch = data?.row?.original;
        const { id } = branch;
        const { mutate, isLoading } = useDeleteCompanyBranch(id);

        function editBranch() {
          setOpen(true);
        }

        return (
          <div className="flex justify-end align-center">
            <DotsButton editfunction={editBranch} deletefunction={mutate} />
            <EditBranchModal
              isOpen={open}
              closeModal={() => setOpen(false)}
              branch={branch}
              branches={data?.results || []}
            />
          </div>
        );
      },
    },
  ];

  return (
    <div
      className="flex grow flex-column"
      style={{ maxHeight: "90vh", overflowY: "auto" }}
    >
      <div className="flex justify-between align-center p-v-1">
        <h4 className="m-0">Branches</h4>
        <div>
          <Searchbar term={search_term} filter={set_search_term} />
        </div>

        <div className="flex align-center justify-between">
          <EmojiTextButton
            text="Add a Branch"
            IconProp={Plus}
            disabled={false}
            onClick={() => setModalIsOpen(true)}
            styleProp={{
              background: Colors.purple,
              color: Colors.white,
              padding: ".8em 2em",
              marginLeft: ".5em",
            }}
          />
        </div>
      </div>
      <AddBranchModal
        isOpen={modalIsOpen}
        closeModal={() => setModalIsOpen(false)}
        primaryButtonText={"Continue"}
        branches={data?.results || []}
      />
      <Table columns={COLUMNS} data={tableData} loading={isLoading} />
    </div>
  );
};
