import React from "react";
import styled from "styled-components";
import { Colors } from "../../constants";

const Steps = ({ currentStep = 1, numberOfSteps = 4 }) => {
  return (
    <StyledSteps>
      <div>
        <p
          style={{
            fontFamily: "CircularStd",
            color: Colors.grey_1,
          }}
        >
          <span className="step-number"> Step {currentStep} </span> of{" "}
          {numberOfSteps}
        </p>
      </div>
      <div className="indicator-sec">
        {[1, 2, 3, 4].map(el => {
          const colored = el <= currentStep;
          return (
            <div
              className="indicator"
              key={Math.random()}
              style={{
                backgroundColor: colored ? Colors.purple : Colors.grey,
              }}
            />
          );
        })}
      </div>
    </StyledSteps>
  );
};

export default Steps;

const StyledSteps = styled.div`
  display: flex;
  flex-direction: column;
  //   border: 1px solid black;

  .step-number {
    font-family: CircularStdBold;
    color: ${() => Colors.purple};
  }

  .indicator-sec {
    display: flex;
    align-items: center;
  }

  .indicator {
    width: 36px;
    height: 4px;
    border-radius: 1000px;
  }

  .indicator + .indicator {
    margin-left: 4px;
  }
`;
