import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAppContext } from "../context";
import { dashboard_routes } from "./routes";
import errorHandler from "../selectors/errorHandler";
import { get } from "../api/transport";
import NotFound from "../pages/NotFound";
import withAuth from "../context/withAuth";
import { Switch, Route } from "react-router-dom";

export const DashboardNavigation = () => {
  const [profile, setProfile] = useState({});
  const [, setLoading] = useState(true);
  const [subscription_active, set_subscription_active] = useState(false);
  const { actions, action, appState } = useAppContext();

  useEffect(() => {
    let mounted = true;
    if (mounted) fetchProfile();
    return () => (mounted = false);
  }, []);

  const application_profile = profile || appState?.profile || {};

  function fetchProfile() {
    let endpoints = [`/users/me/`, `/user/transaction/`];

    get(endpoints[0])
      .then(response => {
        const { data: profile } = response;
        action(actions.setProfile, { profile });
        setProfile(profile);
      })
      .catch(error => errorHandler(error))
      .finally(() => setLoading(false));
  }

  const app_dashboard_routes = dashboard_routes(application_profile);

  return (
    <Switch>
      {app_dashboard_routes.map((route, idx) => {
        const { component: Component, ...rest } = route;
        return (
          <Route
            key={Math.random()}
            {...rest}
            component={withAuth(Component)}
          />
        );
      })}
      {app_dashboard_routes?.length > 0 && (
        <Route path={`/dashboard/*`} component={withAuth(NotFound)} />
      )}
    </Switch>
  );
};
