import React, { useState, useRef } from "react";
import styled from "styled-components";
import { Colors } from "../../constants";
import { ReactComponent as Bell } from "../../assets/svgs/bell.svg";
import { ReactComponent as Ellipse } from "../../assets/svgs/ellipse.svg";
import { Dropdown } from "../notifications";
import { useOutsideDetect } from "../../selectors/UseOutsideDetect";

const NotificationsButton = () => {
  const [isActive, setIsActive] = useState(false);
  const notificationsRef = useRef(null);
  useOutsideDetect(notificationsRef, () => setIsActive(false));
  return (
    <StyledNotificationsButton
      ref={notificationsRef}
      isActive={isActive}
      onClick={toggleIsActive}
    >
      <Bell style={{ width: "20px", height: "20px" }} />
      <Ellipse style={{ position: "absolute", top: 10, right: 10 }} />
      {isActive && <Dropdown toggleIsActive={toggleIsActive} />}
    </StyledNotificationsButton>
  );

  function toggleIsActive() {
    return setIsActive(!isActive);
  }
};

export default NotificationsButton;

const StyledNotificationsButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-right: 20px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  transition: 0.2s ease;
  background: ${props => (props.isActive ? Colors.white_2 : "transparent")};
`;
