import "./App.css";
import Router from "./navigation/index";
import { Provider } from "./context";
import { Provider as DeviceSettingsProvider } from "./context/DeviceSettingsContext";
import { Provider as CreateSubscriptionContextProvider } from "./context/CreateSubscriptionContext";
import { ToastProvider } from "./context/toaster";
import { ReactQueryProvider } from "./context/react-query";
import { startIconLibrary } from "./components/icon/Icon";

startIconLibrary();

function App() {
  return (
    <>
      <ToastProvider />
      <ReactQueryProvider>
        <Provider id="root">
          <CreateSubscriptionContextProvider>
            <DeviceSettingsProvider>
              <div className="app">
                <div className="main-app">
                  <Router />
                </div>
              </div>
            </DeviceSettingsProvider>
          </CreateSubscriptionContextProvider>
        </Provider>
      </ReactQueryProvider>
    </>
  );
}

export default App;
