import React from "react";
import styled from "styled-components";

export const PageTitle = props => {
  const { title = "Title", subtitle = "" } = props;
  return (
    <StyledPageTitle>
      <h2>{title}</h2>
      <p>{subtitle}</p>
    </StyledPageTitle>
  );
};

const StyledPageTitle = styled.div`
  h4 {
    font-family: CircularStdBold;
    margin: 0;
  }

  p {
    font-family: CircularStd;
    color: #151515;
    opacity: 0.72;
  }
`;
