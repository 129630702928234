import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Modal from "react-modal";
import { Colors } from "../../constants";
import { ReactComponent as Close } from "../../assets/svgs/close.svg";
// import { ReactComponent as Check } from "../../assets/svgs/check-ellipsis-purple.svg";
import { ReactComponent as Failed } from "../../assets/svgs/Failed.svg";
import { EmojiTextButton, TextButton } from "../general";
import { EmojiSelection, UploadedEmoji } from "../emojis";

const AddEmojiModal = props => {
  const {
    primaryButtonText = "Primary Button",
    secondaryButtonText = "Secondary Button",
    type,
    emojiData = {},
  } = props;

  const [page, setPage] = useState("default");

  useEffect(() => {
    let mounted = true;
    if (mounted) Modal.setAppElement("body");
    return () => (mounted = false);
  }, []);
  return (
    <Modal
      style={{
        overlay: {
          background: "rgba(0,0,0,0.7)",
        },
        content: {
          background: Colors.white,
          margin: "auto auto",
          width: "30%",
          height: "60%",
        },
      }}
      isOpen={props.isOpen}
      onRequestClose={props.closeModal}
    >
      <AddEmojiModalContent>
        <div className="close-button-area">
          <Close id="close-button" onClick={props.closeModal} />
        </div>
        <div
          style={{
            display: "flex",
            flexGrow: 1,
            // border: "1px solid red",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              flex: 1,
              // border: "1px solid yellow",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <h4>Select an Emoji for "{emojiData?.name}"</h4>
            <p style={{ color: Colors.grey_2, fontSize: ".6em" }}>
              Choose an emoji from the ones provide below. You can change this
              later.
            </p>
            {page === "default" && <EmojiSelection />}
            {page === "upload" && (
              <UploadedEmoji goBack={() => setPage("default")} />
            )}

            <div
              style={{
                // border: "1px solid",
                display: "flex",
                justifyContent: "center",
                marginTop: "auto",
              }}
            >
              <EmojiTextButton
                text="Upload Your Image"
                styleProp={{
                  padding: ".5em",
                  marginRight: ".2em",
                  fontSize: ".8em",
                }}
                onClick={() => {
                  setPage("upload");
                }}
              />
              <TextButton
                text="Continue"
                styleProp={{
                  padding: ".5em",
                  marginLeft: ".2em",
                  background: Colors.purple,
                  color: Colors.white,
                  fontSize: ".8em",
                }}
                onClick={props.closeModal}
              />
            </div>
          </div>
        </div>
      </AddEmojiModalContent>
    </Modal>
  );
};

export default AddEmojiModal;

const AddEmojiModalContent = styled.div`
  // border: 1px solid;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  h4,
  p {
    margin: 0;
  }

  .close-button-area {
    display: flex;
    flex-direction: row-reverse;
    // ;

    #close-button {
      // border: 1px solid;
      height: 0.5em;
      width: 0.5em;
      cursor: pointer;
    }
  }
`;

{
  /* <div style={{ flexGrow: 1 }}>
    <h4>Select an Emoji for "Excellent"</h4>
    <p>sfas</p>
    </div>
    <div className="control-buttons-area">
    <TextButton
    styleProp={{
      color: Colors.white,
      background: Colors.purple,
      padding: "2% 2%",
      fontSize: ".9em",
      marginRight: ".5em",
    }}
    text={primaryButtonText}
    />
    </div> */
}

// .control-buttons-area {
//   // border: 1px solid;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   padding: 1.5em 0;
// }
