import React from "react";
import { Layout } from "../components/dashboard";
import { AllTransactionsTable } from "../components/finance/AllTransactionsTable";
import { DocumentHeader } from "../components/general/DocumentHeader";

const AllTransactions = () => {
  return (
    <Layout>
      <DocumentHeader page="Finance" />
      <AllTransactionsTable />
    </Layout>
  );
};

export { AllTransactions };
