import React, { useEffect, useState } from "react";
import { CompaniesTable, CompanyTabs } from "../../components/companies";
import { Color } from "../../components/company/ColorPallete";
import { Layout } from "../../components/dashboard";
import { SuperAdminUploadField } from "../../components/logobg/AdminUploadField";
import { UploadField } from "../../components/logobg/UploadField";
import colors from "../../constants/colors";

import { ReactComponent as Excellent } from "../../assets/svgs/Excellent.svg";
import { ReactComponent as Good } from "../../assets/svgs/Good.svg";
import { ReactComponent as Terrible } from "../../assets/svgs/Terrible.svg";
import { ReactComponent as Bad } from "../../assets/svgs/Bad.svg";
import { ReactComponent as Okay } from "../../assets/svgs/Okay.svg";
import { get } from "../../api/transport";
import { useParams } from "react-router-dom";
import errorHandler from "../../selectors/errorHandler";
import toast from "react-hot-toast";
import { DocumentHeader } from "../../components/general/DocumentHeader";
import { useCompanySettings } from "../../components/admin/broker";

const LogoBackground = () => {
  const params = useParams();

  const { data: settings, isLoading: loading } = useCompanySettings(
    params.companyid
  );

  const emoji_dictionary = [
    { name: "Terrible", icon: Terrible },
    { name: "Bad", icon: Bad },
    { name: "Okay", icon: Okay },
    { name: "Good", icon: Good },
    { name: "Excellent", icon: Excellent },
  ];

  return (
    <Layout tabs={CompanyTabs}>
      <DocumentHeader page={`Logo & Background`} />
      <div className="flex grow m-v-2 justify-between">
        <div className="flex flex-basis-35 flex-column ">
          <h4 className="bold m-0 m-b-1">Logo {"&"} Background</h4>

          <div className=" flex grow flex-column bg-w bordered r-8 p-2">
            <div className="flex flex-column m-b-1">
              <div className="flex justify-between align-center m-b-p5">
                <p className="m-0">Company Logo</p>
              </div>
              <SuperAdminUploadField {...settings} is_bg={false} />
            </div>

            <div className="flex flex-column m-b-1">
              <div className="flex justify-between align-center m-b-p5">
                <p className="m-0">Background</p>
              </div>
              <SuperAdminUploadField is_bg={true} {...settings} />
            </div>

            <div className="flex flex-column p-b-1">
              <div className="m-b-1">
                <p className="bold">Text Color</p>
                <Color
                  color={settings?.text_color || "#fff"}
                  selected={false}
                />
              </div>
              <div className="m-b-1">
                <p className="bold">Button Color</p>
                <Color color={settings?.btn_color || "#fff"} selected={false} />
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-basis-60 flex-column">
          <h4 className="bold m-0 m-b-1">Emojis</h4>
          <div className="flex flex-basis-40 r-8 bg-w justify-center align-center">
            <div className="bordered-0 flex justify-around align-center wrap">
              {emoji_dictionary.map(el => (
                <div
                  key={Math.random()}
                  className="flex flex-column justify-center align-center bordered-0 m-h-1"
                >
                  <div
                    className="bg-gray circle flex justify-center align-center"
                    style={{ height: "5em", width: "5em" }}
                  >
                    <el.icon style={{ height: "5em", width: "5em" }} />
                  </div>
                  <p>{el.name}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default LogoBackground;
