import React, { useEffect } from "react";
import styled from "styled-components";
import Modal from "react-modal";
import { Colors } from "../../constants";
import { ReactComponent as Close } from "../../assets/svgs/close.svg";
import { ReactComponent as Check } from "../../assets/svgs/check-ellipsis-purple.svg";
import { ReactComponent as Failed } from "../../assets/svgs/Failed.svg";
import { TextButton } from "../general";

const SuccessModal = props => {
  const {
    primaryButtonText = "Primary Button",
    secondaryButtonText = "Secondary Button",
    type,
  } = props;

  useEffect(() => {
    let mounted = true;
    if (mounted) Modal.setAppElement("body");
    return () => (mounted = false);
  }, []);

  return (
    <Modal
      style={{
        overlay: {
          background: "rgba(0,0,0,0.7)",
        },
        content: {
          background: Colors.white,
          margin: "auto auto",
          width: "30%",
          height: "60%",
        },
      }}
      isOpen={props.isOpen}
      onRequestClose={props.closeModal}
    >
      <SuccessModalContent>
        <div className="close-button-area">
          <Close id="close-button" onClick={props.closeModal} />
        </div>
        <div className="success-message-area">
          <Failed />
          <h2>Success Message!</h2>
          <p>
            Something was completed successfully and this text has to be
            updated.
          </p>
        </div>
        <div className="control-buttons-area">
          <TextButton
            styleProp={{
              color: Colors.purple,
              background: Colors.white,
              border: "1px solid" + Colors.purple,
              padding: ".1em 1em",
              fontSize: "1em",
              marginRight: ".5em",
            }}
            text={secondaryButtonText}
          />
          <TextButton
            styleProp={{
              color: Colors.white,
              background: Colors.purple,
              padding: ".1em 1em",
              fontSize: "1em",
              marginRight: ".5em",
            }}
            text={primaryButtonText}
          />
        </div>
      </SuccessModalContent>
      {/* <button onClick={props.closeModal}>Close Modal</button>
      <h2>Modal Title</h2>
      <p>Modal Body</p> */}
    </Modal>
  );
};

export default SuccessModal;

const SuccessModalContent = styled.div`
  // border: 1px solid;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .close-button-area {
    display: flex;
    flex-direction: row-reverse;

    #close-button {
      // border: 1px solid;
      height: 1em;
      width: 1em;
      cursor: pointer;
    }
  }

  .success-message-area {
    // border: 1px solid;
    text-align: center;
    padding: 0 20%;
  }

  .control-buttons-area {
    // border: 1px solid;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5em 0;
  }
`;
