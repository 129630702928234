import React from "react";
import styled from "styled-components";
import { ReactComponent as Happy } from "../../assets/svgs/happy.svg";
import transformColumns from "../table/TransformColumns";

const FeedbackTable = () => {
  const columns = transformColumns([
    "DEVICE",
    "BRANCH",
    "CUSTOMER",
    "CONTACT",
    "SENTIMENT",
    "CATEGORY",
    "FEEDBACK",
    "DATE",
  ]);

  return (
    <StyledFeedbackTable>
      <thead>
        <tr>
          <th>DEVICE</th>
          <th>BRANCH</th>
          <th>CUSTOMER</th>
          <th>CONTACT</th>
          <th>SENTIMENT</th>
          <th>CATEGORY</th>
          <th>FEEDBACK</th>
          <th>DATE</th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td>SALES TEAM</td>
          <td>GREATER ACCRA</td>
          <td>JAMES ANDERSON</td>
          <td>+233 123 321 444</td>
          <td>
            <Happy /> Happy
          </td>
          <td>Pricing</td>
          <td>It was quite an amazing experie...</td>
          <td>Jul 7, 2021 12:38 am</td>
        </tr>
        <tr>
          <td>SALES TEAM</td>
          <td>GREATER ACCRA</td>
          <td>JAMES ANDERSON</td>
          <td>+233 123 321 444</td>
          <td>
            <Happy /> Happy
          </td>
          <td>Pricing</td>
          <td>It was quite an amazing experie...</td>
          <td>Jul 7, 2021 12:38 am</td>
        </tr>
        <tr>
          <td>SALES TEAM</td>
          <td>GREATER ACCRA</td>
          <td>JAMES ANDERSON</td>
          <td>+233 123 321 444</td>
          <td>
            <Happy /> Happy
          </td>
          <td>Pricing</td>
          <td>It was quite an amazing experie...</td>
          <td>Jul 7, 2021 12:38 am</td>
        </tr>
        <tr>
          <td>SALES TEAM</td>
          <td>GREATER ACCRA</td>
          <td>JAMES ANDERSON</td>
          <td>+233 123 321 444</td>
          <td>
            <Happy /> Happy
          </td>
          <td>Pricing</td>
          <td>It was quite an amazing experie...</td>
          <td>Jul 7, 2021 12:38 am</td>
        </tr>
      </tbody>
    </StyledFeedbackTable>
  );
};

export default FeedbackTable;

const StyledFeedbackTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-left: none;
  border-right: none;
  color: #696969;
  td,
  th {
    border: 1px solid #f5f3f3;
    padding: 10px 25px;
    text-align: left;
    border-left: none;
    border-right: none;
  }

  th {
    font-size: 10px;
    padding: 10px 25px;
  }

  td {
    font-size: 15px;
    padding: 25px;
  }
`;
