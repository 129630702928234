import React, { useCallback, useRef, useState } from "react";
import { Feedbacks } from "../components/analytics";
import { AreaChartComponent, Layout } from "../components/dashboard";
import { Filters } from "../components/dashboard/Filters";
import moment from "moment";
import { DocumentHeader } from "../components/general/DocumentHeader";
import Excellent from "../assets/pngs/Excellent.png";
import Good from "../assets/pngs/Good.png";
import Terrible from "../assets/pngs/Terrible.png";
import Bad from "../assets/pngs/Bad.png";
import Okay from "../assets/pngs/Okay.png";
import { useSentimentOverview } from "../components/overview/broker";
import { v4 as uuidv4 } from "uuid";
import { ReactComponent as Export } from "../assets/svgs/export.svg";
import { CSVLink } from "react-csv";
import colors from "../constants/colors";

const Analytics = () => {
  const [selected_filter, set_selected_filter] = useState("week");
  const downloadButtonRef = useRef();

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const { data: overviewData = [], isLoading: overviewLoading } =
    useSentimentOverview(
      "",
      "",
      selected_filter,
      endDate ? moment(endDate)?.format("YYYY-MM-DD") : "",
      startDate ? moment(startDate)?.format("YYYY-MM-DD") : ""
    );

  const initialBarChartData = {
    excellent: [],
    good: [],
    okay: [],
    bad: [],
    terrible: [],
  };

  const isTodayOrWeek = useCallback(() => {
    const isToday = selected_filter === "today";
    const isWeek = selected_filter === "week";

    return isToday || isWeek;
  }, [selected_filter]);

  const collatedSentimentData = useCallback(
    (overviewData || [])?.reduce((prev, current) => {
      let dateExists = prev?.find(el =>
        moment(el?.date).isSame(
          current?.date_published,
          isTodayOrWeek() ? "hour" : "day"
        )
      );

      if (!dateExists) {
        return [
          ...prev,
          {
            uuid: uuidv4(),
            date: current?.date_published,
            sentiment_count: 1,
          },
        ];
      }

      dateExists = {
        ...dateExists,
        sentiment_count: Number(dateExists.sentiment_count) + 1,
      };

      let new_list = prev?.map(el => {
        if (el.uuid === dateExists.uuid) {
          return dateExists;
        }

        return el;
      });

      return new_list;
    }, []),
    [overviewData, selected_filter]
  );

  const collatedBarChartData = useCallback(
    overviewData?.reduce((prev, current) => {
      const currentSentimentTitle = current?.sentiment?.title?.toLowerCase();
      const currentSentimentDate = current?.date_published;
      const sentimentArrayValue = prev?.[currentSentimentTitle];

      let existingDateObject = sentimentArrayValue?.find(el =>
        moment(el.date).isSame(
          currentSentimentDate,
          isTodayOrWeek() ? "hour" : "day"
        )
      );

      if (existingDateObject) {
        return {
          ...prev,
          [currentSentimentTitle]: sentimentArrayValue?.map(el => {
            if (el.uuid === existingDateObject.uuid) {
              return {
                ...existingDateObject,
                sentiment_count: existingDateObject?.sentiment_count + 1,
              };
            }

            return el;
          }),
        };
      }

      return {
        ...prev,
        [currentSentimentTitle]: [
          ...sentimentArrayValue,
          { uuid: uuidv4(), sentiment_count: 1, date: currentSentimentDate },
        ],
      };
    }, initialBarChartData),
    [overviewData, selected_filter]
  );

  const filterTitles = {
    today: "Today",
    week: "This week",
    month: "This month",
    year: "This year",
  };

  const filters = [
    {
      title: "Today",
      active: selected_filter.toLowerCase() === "today",
      onClick: () => {
        setStartDate("");
        setEndDate("");
        set_selected_filter("today");
      },
    },
    {
      title: "Week",
      active: selected_filter.toLowerCase() === "week",
      onClick: () => {
        setStartDate("");
        setEndDate("");
        set_selected_filter("week");
      },
    },
    {
      title: "Month",
      active: selected_filter.toLowerCase() === "month",
      onClick: () => {
        setStartDate("");
        setEndDate("");
        set_selected_filter("month");
      },
    },
    {
      title: "Year",
      active: selected_filter.toLowerCase() === "year",
      onClick: () => {
        setStartDate("");
        setEndDate("");
        set_selected_filter("year");
      },
    },
    {
      title: `Start Date - ${moment(startDate).format("YYYY-MM-DD")}`,
      active: !!startDate && !!endDate,
      selected: startDate || new Date(),
      type: "date",
      onChange: date => {
        set_selected_filter("");
        setStartDate(date);
      },
    },
    {
      title: `End Date - ${moment(endDate).format("YYYY-MM-DD")}`,
      active: !!startDate && !!endDate,
      selected: endDate || new Date(),
      type: "date",
      onChange: date => {
        set_selected_filter("");
        setEndDate(date);
      },
    },
  ];

  const formatDate = useCallback((arr = []) => {
    return arr
      ?.sort((a, b) => {
        if (moment(a.date).isBefore(moment(b.date))) {
          return -1;
        }
        return 1;
      })
      .map(el => {
        return {
          ...el,
          date: moment(el?.date)?.format("MMM Do | ha"),
        };
      });
  }, []);

  const feedback_data = [
    {
      data: formatDate(collatedBarChartData["excellent"]),
      title: "Happy Feedback",
      emoji: Excellent,
    },
    {
      data: formatDate(collatedBarChartData["good"]),
      title: "Good Feedback",
      emoji: Good,
    },
    {
      data: formatDate(collatedBarChartData["okay"]),
      title: "Neutral Feedback",
      emoji: Okay,
    },
    {
      data: formatDate(collatedBarChartData["bad"]),
      title: "Bad Feedback",
      emoji: Bad,
    },
    {
      data: formatDate(collatedBarChartData["terrible"]),
      title: "Terrible Feedback",
      emoji: Terrible,
    },
  ];

  return (
    <Layout>
      <DocumentHeader page="Analytics" />
      <div className="height-40 flex flex-column m-b-2 ">
        <div className="flex bordered-0 p-v-1 justify-between align-center">
          <h2 className="m-0">Analytics</h2>

          <div className="flex align-center">
            <CSVLink
              ref={downloadButtonRef}
              filename={`Your Echo Feedback for: ${
                filterTitles[selected_filter] ||
                `${moment(startDate)?.format("Do MMM YYYY")} - ${moment(
                  endDate
                )?.format("Do MMM YYYY")}`
              }`}
              className="r-8 flex"
              style={{
                background: colors.purple,
                color: colors.white,
                padding: "8px 10px",
                marginRight: "1em",
                textDecoration: "none",
                fontSize: "13px",
                borderRadius: "5px",
              }}
              data={[
                ...overviewData?.map(el => {
                  return {
                    DEVICE: el?.tablet?.name,
                    BRANCH: ` ${el.tablet?.location?.map(el => el.name)}`,
                    CUSTOMER: el?.email,
                    SENTIMENT: el?.sentiment.title,
                    CATEGORY: el?.category?.title,
                    FEEDBACK: el?.feedback,
                    DATE: moment(el.date_published).format(
                      "MMM D, YYYY hh:mm a"
                    ),
                  };
                }),
              ]}
              disabled={true}
            >
              <span className="m-r-p5">
                <Export />
              </span>
              <p className="m-0">Export Data</p>
            </CSVLink>
            <Filters filters={filters} />
          </div>
        </div>

        <AreaChartComponent
          data={formatDate(collatedSentimentData)}
          count={overviewData?.length}
          dataKey={"sentiment_count"}
          loading={overviewLoading}
          tooltipUnit="sentiments"
        />
      </div>

      <div className="flex bordered-0 row-wrap gap-1">
        {feedback_data.map(el => (
          <Feedbacks loading={overviewLoading} key={Math.random()} {...el} />
        ))}
      </div>
    </Layout>
  );
};

export { Analytics };
