import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import Modal from "react-modal";
import { Colors } from "../../constants";
import { ReactComponent as Close } from "../../assets/svgs/close.svg";
import ModalTitle from "./ModalTitle";
import ModalFormStyleSheet from "./ModalFormStyleSheet";
import { useAppContext } from "../../context";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { TextButton } from "../../components/general";
import toast from "react-hot-toast";
import errorHandler from "../../selectors/errorHandler";
import DropdownLoader from "./DropdownLoader";
import { useOutsideDetect } from "../../selectors/UseOutsideDetect";
import AutoCompleteInput from "../general/AutoCompleteInput";
import { useCreateBranch } from "../branches/broker";
import { useCreateQuestion } from "../questions/broker";
import { useUpdateDevice } from "../devices/broker";

const EditDeviceModal = props => {
  let { device, questions, branches, categories } = props;

  const [question_selection, set_question_selection] = useState("existing");
  const [question, set_question] = useState("");
  const [selected_question, set_selected_question] = useState(device.question);
  const [start_date, set_start_date] = useState(
    new Date(device.question_start_date)
  );
  const [end_date, set_end_date] = useState(new Date(device.question_end_date));

  const [branch_selection, set_branch_selection] = useState("existing");
  const [show_branch_dropdown, set_show_branch_dropdown] = useState(false);
  const [new_branch_name, set_new_branch_name] = useState("");
  const [new_branch_location, set_new_branch_location] = useState("");
  const [selected_branches, set_selected_branches] = useState(device.location);
  const branch_dropdown_ref = useRef();
  // const [branch_fetch_loading, set_branch_fetch_loading] = useState(false);

  const [selected_categories, set_selected_categories] = useState([]);
  const [show_category_dropdown, set_show_category_dropdown] = useState(false);
  const category_dropdown_ref = useRef();
  // const [questions, set_questions] = useState([]);
  // const [branches, set_branches] = useState([]);

  const [show_dropdown, set_show_dropdown] = useState(false);

  const [loading, set_loading] = useState(false);
  const [create_device_loading, set_create_device_loading] = useState(false);
  const [device_name, set_device_name] = useState(device.name);

  const dropdown_ref = useRef();

  const { fetchToken, appState } = useAppContext();

  const profile = appState?.profile || {};
  const is_admin = profile?.is_owner;

  // const { data: categories = [] } = useCategories();

  const { mutateAsync: addBranch, isLoading: createBranchLoading } =
    useCreateBranch();

  const { mutateAsync: addQuestion, isLoading: createQuestionLoading } =
    useCreateQuestion();

  const { mutateAsync: updateDevice, isLoading: updateDeviceLoading } =
    useUpdateDevice(device?.id);

  useOutsideDetect(dropdown_ref, () => set_show_dropdown(false));
  useOutsideDetect(branch_dropdown_ref, () => set_show_branch_dropdown(false));
  useOutsideDetect(category_dropdown_ref, () =>
    set_show_category_dropdown(false)
  );

  useEffect(() => {
    let mounted = true;
    if (mounted) Modal.setAppElement("body");
    return () => (mounted = false);
  }, []);

  return (
    <Modal
      style={{
        overlay: {
          background: "rgba(0,0,0,0.7)",
        },
        content: {
          background: Colors.white,
          margin: "auto auto",
          width: "40%",
          height: "70%",
        },
      }}
      isOpen={props.isOpen}
      onRequestClose={props.closeModal}
    >
      <EditDeviceModalContent>
        <div className="close-button-area">
          <Close id="close-button" onClick={props.closeModal} />
        </div>

        <div className="flex flex-column grow">
          <div className="flex flex-column grow">
            <ModalTitle title={"Edit Device"} />

            <ModalFormStyleSheet>
              <div className="row">
                <div className="input-field">
                  <label>Device Name</label>
                  <input
                    value={device_name}
                    onChange={event => set_device_name(event.target.value)}
                    type="text"
                    placeholder="Eg. Customer Success"
                  />
                </div>
              </div>

              {is_admin && (
                <div className="row">
                  <div
                    className="input-field relative"
                    ref={branch_dropdown_ref}
                  >
                    <div className="label-container">
                      <label>
                        {branch_selection === "new" && "Create New Branch"}
                        {branch_selection === "existing" && "Select Branch"}
                      </label>
                      <p
                        onClick={toggleBranchSelection}
                        style={{
                          fontSize: ".8em",
                          color: Colors.purple,
                          cursor: "pointer",
                        }}
                      >
                        {branch_selection === "new" &&
                          "Select an existing branch"}
                        {branch_selection === "existing" &&
                          "+ Add a New Branch"}
                      </p>
                    </div>

                    {branch_selection === "existing" && (
                      <>
                        <div
                          onClick={() =>
                            set_show_branch_dropdown(!show_branch_dropdown)
                          }
                          className="pseudo-input gray flex justify-between align-center pointer "
                        >
                          <p>
                            {selected_branches.length > 0
                              ? `${
                                  selected_branches.length
                                } selected | ${selected_branches.map(
                                  (el, idx) => {
                                    return ` ${el.name}`;
                                  }
                                )}`
                              : "You can select multiple branches"}
                          </p>
                          <span className="pointer">▾</span>
                        </div>
                        <p className="meta">
                          * Let’s know where this device would be used
                        </p>

                        <div
                          className="dropdown bordered absolute bg-w "
                          style={{
                            display: show_branch_dropdown ? "block" : "none",
                            top: "85%",
                            width: "99.5%",
                          }}
                        >
                          {branches.length !== 0 && (
                            <div className="m-v-1 flex align-center">
                              <div className="checkbox-wrapper">
                                <input
                                  type="checkbox"
                                  id={"all-branches"}
                                  name={"all-branches"}
                                  checked={
                                    selected_branches.length === branches.length
                                  }
                                  className="m-h-1"
                                  onChange={event => {
                                    if (!event.target.checked) {
                                      return set_selected_branches([]);
                                    }
                                    set_selected_branches(branches);
                                  }}
                                />
                              </div>
                              <p className="m-0 gray-2">All Branches</p>
                            </div>
                          )}

                          {branches?.results?.map(branch => (
                            <div
                              key={Math.random()}
                              className="m-v-1 flex align-center"
                            >
                              <div className="checkbox-wrapper">
                                <input
                                  type="checkbox"
                                  id={branch.uuid}
                                  name={branch.uuid}
                                  checked={selected_branches.find(
                                    el => el.uuid === branch.uuid
                                  )}
                                  className="m-h-1"
                                  onChange={() => {
                                    selected_branches.find(
                                      el => el.uuid === branch.uuid
                                    )
                                      ? set_selected_branches(
                                          selected_branches.filter(
                                            el => el.id !== branch.id
                                          )
                                        )
                                      : set_selected_branches([
                                          ...selected_branches,
                                          branch,
                                        ]);
                                  }}
                                />
                              </div>
                              <p className="m-0 gray-2">
                                {branch.name} | {branch.location_name}
                              </p>
                            </div>
                          ))}
                        </div>
                      </>
                    )}

                    {branch_selection === "new" && (
                      <div className="row">
                        <div className="input-field">
                          <div className="label-container">
                            <label>Branch Name</label>
                          </div>
                          <input
                            value={new_branch_name}
                            onChange={e => set_new_branch_name(e.target.value)}
                            type="text"
                            placeholder="Eg. Greater Accra"
                          />
                        </div>
                        <div className="input-field relative">
                          <div className="label-container">
                            <label>Location</label>
                          </div>

                          <AutoCompleteInput
                            className="pseudo-input"
                            placeholder="Eg. Agyia Zongo, Accra, Ghana"
                            onChange={value => set_new_branch_location(value)}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}

              <div className="row">
                <div className="input-field">
                  <div className="label-container">
                    <label>
                      {question_selection === "new" &&
                        "Enter Question (Optional)"}
                      {question_selection === "existing" &&
                        "Select Question (Optional)"}
                    </label>
                    <p
                      onClick={toggleQuestionSelection}
                      style={{
                        fontSize: ".8em",
                        color: Colors.purple,
                        cursor: "pointer",
                      }}
                    >
                      {question_selection === "new" &&
                        "Select an existing question"}
                      {question_selection === "existing" &&
                        "+ Add a New Question"}
                    </p>
                  </div>

                  {question_selection === "existing" && (
                    <div>
                      <div
                        className="row relative bordered-0"
                        ref={dropdown_ref}
                      >
                        <div className="input-field">
                          <div
                            onClick={() => set_show_dropdown(!show_dropdown)}
                            className="pseudo-input gray flex justify-between align-center pointer "
                            // style={{ border: "1px solid red" }}
                          >
                            <p>
                              {selected_question
                                ? `${
                                    selected_question?.question
                                  } | ${selected_question?.categories?.map(
                                    el => ` ${el.title}`
                                  )}`
                                : "Select a question to be displayed on this device"}
                            </p>
                            <span className="pointer">▾</span>
                          </div>

                          <p className="meta">
                            * This question will be available to be deployed on
                            devices across selected branches only
                          </p>
                        </div>

                        <div
                          className="dropdown bordered absolute bg-w p-t-1"
                          style={{
                            display: show_dropdown ? "block" : "none",
                            top: "80%",
                            width: "99.5%",
                          }}
                        >
                          {/* {loading && <DropdownLoader />} */}
                          {questions?.map(question => (
                            <div
                              key={Math.random()}
                              className="flex align-center p-h-1"
                            >
                              <div className="checkbox-wrapper">
                                <input
                                  type="checkbox"
                                  id={question.uuid}
                                  name={question.uuid}
                                  checked={
                                    selected_question?.uuid === question.uuid
                                  }
                                  onChange={() =>
                                    set_selected_question(question)
                                  }
                                />
                              </div>
                              <p className="gray-2">
                                {`${
                                  question.question
                                } | ${question?.categories?.map(
                                  el => ` ${el.title}`
                                )}`}
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div
                        className="row flex align-start"
                        style={{ border: "1px solid !important" }}
                      >
                        <div className="input-field ">
                          <label>Set Start Date</label>
                          <DatePicker
                            selected={start_date}
                            onChange={date => {
                              if (moment(date).isSameOrAfter(moment(), "day")) {
                                set_start_date(date);
                              }
                            }}
                            className="pseudo-input"
                          />
                        </div>
                        <div className="input-field ">
                          <label>Set End Date</label>
                          <DatePicker
                            selected={end_date}
                            onChange={date => {
                              if (moment(date).isAfter(moment(), "day")) {
                                set_end_date(date);
                              }
                            }}
                            className="pseudo-input"
                          />
                        </div>
                      </div>
                      <p className="meta small-text">
                        * Specify how long you want the question to show on the
                        device
                      </p>
                    </div>
                  )}

                  {question_selection === "new" && (
                    <div>
                      <div className="row">
                        <div className="input-field m-0">
                          <input
                            value={question}
                            type="text"
                            placeholder="Enter a question to be deployed on this device"
                            onChange={e => set_question(e.target.value)}
                          />
                          <p className="meta">
                            * Please seperate each category with a comma “,”
                          </p>
                        </div>
                      </div>
                      <div className="row relative" ref={category_dropdown_ref}>
                        <div className="input-field">
                          <label>Select Category</label>
                          <div
                            onClick={() =>
                              set_show_category_dropdown(
                                !show_category_dropdown
                              )
                            }
                            className="pseudo-input gray flex justify-between align-center pointer "
                          >
                            <p>
                              {selected_categories.length > 0
                                ? `${selected_categories?.map(
                                    el => ` ${el.title}`
                                  )}`
                                : "Select a category for this question"}
                            </p>
                            <span className="pointer">▾</span>
                          </div>

                          {/* <p className="meta">
                              * This question will be available to be deployed
                              on devices across selected branches only
                            </p> */}
                        </div>

                        <div
                          className="dropdown bordered absolute bg-w p-t-1"
                          style={{
                            display: show_category_dropdown ? "block" : "none",
                            top: "105%",
                            width: "99.6%",
                          }}
                        >
                          {/* {loading && <DropdownLoader />} */}
                          {categories?.map(category => (
                            <div
                              key={Math.random()}
                              className="flex align-center dropdown-item"
                            >
                              <div className="checkbox-wrapper">
                                <input
                                  type="checkbox"
                                  id={category.uuid}
                                  name={category.uuid}
                                  checked={selected_categories.find(
                                    el => el.uuid === category.uuid
                                  )}
                                  onChange={() => {
                                    if (
                                      selected_categories.find(
                                        el => el.uuid === category.uuid
                                      )
                                    ) {
                                      return set_selected_categories(
                                        selected_categories.filter(
                                          el => el.uuid !== category.uuid
                                        )
                                      );
                                    }
                                    set_selected_categories([
                                      ...selected_categories,
                                      category,
                                    ]);
                                  }}
                                />
                              </div>
                              <p className="m-0 gray-2">{category.title}</p>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="row">
                        <div className="input-field">
                          <label>Set Start Date</label>
                          <DatePicker
                            selected={start_date}
                            onChange={date => {
                              if (moment(date).isSameOrAfter(moment(), "day")) {
                                set_start_date(date);
                              }
                            }}
                            className="pseudo-input"
                          />
                        </div>
                        <div className="input-field">
                          <label>Set End Date</label>
                          <DatePicker
                            selected={end_date}
                            onChange={date => {
                              if (moment(date).isAfter(moment(), "day")) {
                                set_end_date(date);
                              }
                            }}
                            className="pseudo-input"
                          />
                        </div>
                      </div>
                      <p className="meta">
                        * Specify how long you want the question to show on the
                        device
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </ModalFormStyleSheet>

            <div className="flex justify-center align-center m-0 p-v-1">
              <TextButton
                text={
                  createBranchLoading ||
                  createQuestionLoading ||
                  updateDeviceLoading
                    ? "Please wait"
                    : "Save"
                }
                styleProp={{
                  background: "#72004C",
                  padding: ".5em 2em",
                  color: "white",
                }}
                disabled={
                  createBranchLoading ||
                  createQuestionLoading ||
                  updateDeviceLoading
                }
                onClick={save_device}
              />
            </div>
          </div>
        </div>
      </EditDeviceModalContent>
    </Modal>
  );

  function toggleQuestionSelection() {
    if (question_selection === "new") {
      set_question("");
      set_question_selection("existing");
    } else {
      set_selected_question(undefined);
      set_question_selection("new");
    }
  }

  function toggleBranchSelection() {
    if (branch_selection === "new") {
      set_branch_selection("existing");
    } else {
      set_branch_selection("new");
    }
  }

  // function createNewBranch() {

  //   return post(`/user/branch/`, data)
  //     .then(response => response.data)
  //     .catch(error => errorHandler(error));
  // }

  // function createNewQuestion(location) {
  //

  //   return post("/user/question-settings/", data)
  //     .then(response => response.data)
  //     .catch(error => errorHandler(error));
  // }

  async function save_device() {
    toast.loading("Saving");
    let branch = selected_branches.map(el => el.id);
    let question = selected_question?.id;
    // let location;

    if (branch_selection === "new") {
      const post_data = {
        is_active: true,
        name: new_branch_name,
        location_name: new_branch_location,
        status: "Approved",
        company: appState.profile?.company?.id,
      };

      try {
        const { data } = await addBranch(post_data);
        // const new_branch = await createNewBranch();
        branch = [...branch, data?.id];
        // set_branches([...branches, new_branch]);
      } catch (error) {
        return;
      }
    }

    if (question_selection === "new") {
      const post_data = {
        is_active: true,
        question,
        status: "public",
        company: appState.profile?.company?.id,
        location: is_admin ? branch : [profile?.branch?.id],
        categories: selected_categories.map(el => el.id),
      };
      try {
        const { data } = await addQuestion(post_data);
        question = data?.id;
        // const new_question = await createNewQuestion(branch);
        // set_questions([...questions, new_question]);
      } catch (error) {
        return;
      }
    }

    const patch_data = {
      name: device_name,
      status: "Approved",
      company: appState.profile?.company?.id,
      location: branch,
      question: question,
      question_start_date: start_date.toISOString(),
      question_end_date: end_date.toISOString(),
    };

    updateDevice(patch_data)
      .then(() => {
        toast.success("Success");
        props.closeModal();
      })
      .catch(error => {
        errorHandler(error);
      });
  }
};

export default EditDeviceModal;

const EditDeviceModalContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .close-button-area {
    display: flex;
    flex-direction: row-reverse;

    #close-button {
      height: 0.5em;
      width: 0.5em;
      cursor: pointer;
    }
  }
`;
