import React from "react";
import colors from "../../constants/colors";
import { TopRightArrow } from "../icons";

export const OverviewStat = props => {
  const { name, count, icon: Icon, color = "purple", loading } = props;
  return (
    <div className="r-8 bordered bg-w p-1 flex flex-column grow">
      <div className="flex bordered-0 justify-end m-0 m-b-auto">
        <TopRightArrow
          color={color === "purple" ? colors.purple : color}
          width={12}
        />
      </div>

      <div className="bordered-0 flex justify-center align-center m-v-auto">
        <div
          style={{ width: "4em", height: "4em" }}
          className={`circle bordered-0 flex justify-center align-center bg-${color}`}
        >
          {Icon && <Icon />}
        </div>
      </div>

      <div className="m-t-auto bordered-0">
        {loading ? (
          <p className="gray-2 m-v-half m-v-auto center-align">...</p>
        ) : (
          <h2 className="bold m-0 m-v-half center-align">{count}</h2>
        )}
        <p className="gray-2 small m-0 m-t-p5 center-align">{name}</p>
      </div>
    </div>
  );
};
