import axios from "axios";
import _ from "lodash";
import React, { useState, useEffect, useRef } from "react";
import toast from "react-hot-toast";
import { StyledForm } from "../../components/account-creation/account-information/Form";
import { CompaniesTable, CompanyTabs } from "../../components/companies";
import { Layout } from "../../components/dashboard";
import { DropdownEmpty, TextButton } from "../../components/general";
import { CountryCodeDropdown } from "../../components/general/CountryCodeDropdown";
import DropdownLoader from "../../components/modal/DropdownLoader";
import colors from "../../constants/colors";
import { useAppContext } from "../../context";
import errorHandler from "../../selectors/errorHandler";
import { ReactComponent as Caret } from "../../assets/svgs/caret-down.svg";
import { get } from "../../api/transport";
import { useParams } from "react-router-dom";
import { DocumentHeader } from "../../components/general/DocumentHeader";
import { useCompanyAdmin } from "../../components/admin/broker";
import { useIndustries } from "../../components/industries/broker";

const About = () => {
  // const [loading, set_loading] = useState(true);
  // const [data, set_data] = useState(undefined);
  const params = useParams();

  const industry_dropdown_ref = useRef();

  const [changes, set_changes] = useState({});

  const [selectedCountryCode, setSelectedCountryCode] = useState("233");
  const [fetch_loading, set_fetch_loading] = useState(false);
  const [submit_loading, set_submit_loading] = useState(false);

  const [selected_country_code, set_selected_country_code] = useState("233");
  const [company_industries, set_company_industries] = useState([]);
  const [showIndustryDropdown, setShowIndustryDropdown] = useState(false);

  const { data: industriesData, isLoading: industriesLoading } =
    useIndustries();

  const errorText = "Error, Refetch";

  const fetchData = async () => {
    try {
      // const response = await get(`/admin/company/${params.companyid}`);
      // const response_data = response.data;
      // set_data(response_data);
    } catch (error) {
      errorHandler(error);
    } finally {
      // set_loading(false);
    }
  };

  const { data, isLoading: loading } = useCompanyAdmin(params.companyid);

  useEffect(() => {
    let mounted = true;
    // if (mounted) fetchData();
    return () => (mounted = false);
  }, []);

  return (
    <Layout tabs={CompanyTabs}>
      <DocumentHeader page="Company" />
      <div className="flex grow flex-row gap-2 justify-between align-stretch  p-b-2">
        <div className="flex bordered-0 flex-basis-50 flex-column">
          <div className="flex flex-basis-50 flex-column">
            <h4 className="m-0 m-b-1">Account Information</h4>

            <StyledForm className="bg-w">
              <div className="row p-h-2">
                <div className="input-field">
                  <label>First Name</label>
                  <input
                    type="text"
                    placeholder="First Name"
                    value={
                      loading
                        ? "loading"
                        : changes?.first_name ?? (data?.first_name || errorText)
                    }
                    onChange={({ target: { value } }) =>
                      set_changes({ ...changes, first_name: value })
                    }
                  />
                </div>

                <div className="input-field">
                  <label>Last Name</label>
                  <input
                    type="text"
                    placeholder="Last Name"
                    value={
                      loading
                        ? "loading"
                        : changes?.last_name ?? (data?.last_name || errorText)
                    }
                    onChange={({ target: { value } }) =>
                      set_changes({ ...changes, last_name: value })
                    }
                  />
                </div>
              </div>

              <div className="row p-h-2">
                <div className="input-field">
                  <label>Email Address</label>
                  <input
                    type="email"
                    placeholder="Email"
                    disabled={true}
                    value={loading ? "loading" : data?.email || errorText}
                  />
                </div>
              </div>

              <div className="row p-h-2">
                <div className="input-field">
                  <label>Phone Number</label>
                  <div className="pseudo-phone-input">
                    <CountryCodeDropdown
                      position="down"
                      selectedCountryCode={selectedCountryCode}
                      setSelectedCountryCode={setSelectedCountryCode}
                    />

                    <div className="phone-input-number-section">
                      <input
                        disabled={true}
                        value={
                          loading ? "loading" : data?.phone_number ?? errorText
                        }
                        type="tel"
                        placeholder="Eg. 23 324 2342"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row m-h-2 bordered-0 flex jc-flex-end">
                <div className="flex flex-basis-50">
                  <TextButton
                    text="Discard Changes"
                    className="grow"
                    styleProp={{ padding: "1em" }}
                    disabled={true}
                  />
                  <TextButton
                    className="grow primary"
                    text="Save Changes"
                    styleProp={{
                      padding: "1em",
                      background: colors.purple,
                      color: "white",
                    }}
                    loading={false}
                    disabled={true}
                  />
                </div>
              </div>
            </StyledForm>
          </div>
        </div>

        <div className="flex bordered-0 flex-basis-50 flex-column bordered">
          <h4 className="m-0 m-b-1">Company Information</h4>

          <StyledForm className="bg-w">
            <div className="row p-h-2">
              <div className="input-field">
                <label>Company Name</label>
                <input
                  value={
                    loading
                      ? "loading"
                      : changes?.company?.name ??
                        (data?.company?.name || errorText)
                  }
                  onChange={({ target: { value } }) =>
                    set_changes({
                      ...changes,
                      company: {
                        ...changes.company,
                        name: value,
                      },
                    })
                  }
                  type="text"
                  placeholder="Company Name"
                />
              </div>
            </div>

            <div className="row p-h-2" ref={industry_dropdown_ref}>
              <div className="input-field relative">
                <label>Industry</label>
                <div
                  className="pseudo-input"
                  onClick={() => setShowIndustryDropdown(!showIndustryDropdown)}
                >
                  <p className="black">
                    {industriesLoading && "Please Wait"}
                    {loading
                      ? ""
                      : data?.company?.industry?.length === 0 && (
                          <span className="gray">None</span>
                        )}

                    {`${
                      !loading
                        ? data?.company?.industry.map(el => ` ${el}`)
                        : ""
                    }`}
                  </p>
                  <span className="pointer flex justify-center align-center">
                    <Caret />
                  </span>
                </div>
                {showIndustryDropdown && (
                  <div
                    className={`dropdown ${
                      _.isEmpty(industriesData) && `no-data`
                    }`}
                  >
                    {industriesLoading && "Please wait"}
                    {industriesLoading ||
                      (industriesData.length === 0 && "No data")}

                    {industriesLoading ||
                      industriesData.map((industry, idx) => {
                        const selected = company_industries?.some(
                          ind => ind.id === industry.id
                        );

                        return (
                          <div
                            className="dropdown-item"
                            style={{
                              background: selected ? "#f8fafc" : "initial",
                            }}
                            // onClick={() => {
                            //   selectIndustry(industry);
                            // }}
                            key={idx}
                          >
                            <p>{industry.title}</p>
                          </div>
                        );
                      })}
                  </div>
                )}
              </div>
            </div>

            <div className="row p-h-2">
              <div className="input-field">
                <label>Company Email Address</label>
                <input
                  value={loading ? "loading" : data?.company.email || errorText}
                  disabled={true}
                  type="email"
                  placeholder="Company Email Address"
                />
              </div>
            </div>

            <div className="row p-h-2">
              <div className="input-field">
                <label>Phone Number</label>
                <div className="pseudo-phone-input">
                  <CountryCodeDropdown
                    position="down"
                    selectedCountryCode={selected_country_code}
                    setSelectedCountryCode={set_selected_country_code}
                  />

                  <div className="phone-input-number-section">
                    <input
                      disabled={true}
                      value={
                        loading ? "loading" : data?.company.tel ?? errorText
                      }
                      type="tel"
                      placeholder="Eg. 23 324 2342"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row m-h-2 bordered-0 flex jc-flex-end">
              <div className="flex flex-basis-50">
                <TextButton
                  text="Discard Changes"
                  className="grow"
                  styleProp={{ padding: "1em" }}
                />
                <TextButton
                  className="grow primary"
                  text="Save Changes"
                  styleProp={{
                    padding: "1em",
                    background: colors.purple,
                    color: "white",
                  }}
                  disabled={true}
                />
              </div>
            </div>
          </StyledForm>
        </div>
      </div>
    </Layout>
  );

  function fetchProfile() {
    // set_fetch_loading(true);
    // axios
    //   .get("https://echofeed-web-app.df.r.appspot.com/api/users/me/", {
    //     headers: { Authorization: `Token ${fetchToken()}` },
    //   })
    //   .then(response => {
    //     const { first_name, email, last_name, phone_number } = response.data;
    //     set_profile_data({
    //       ...profile_data,
    //       first_name,
    //       email,
    //       last_name,
    //       phone_number,
    //     });
    //     setSelectedCountryCode(phone_number?.slice(0, 3));
    //     set_fetch_loading(false);
    //   })
    //   .catch(error => {
    //     set_fetch_loading(false);
    //     errorHandler(error);
    // const msg = Object.keys(error.response?.data)[0];
    // console.log(`${error.response?.data[msg][0]}`);
    // set_fetch_loading(false);
    // });
  }

  function submitChanges() {
    // set_submit_loading(true);
    // toast.loading("Saving...");
    // axios
    //   .patch(
    //     "https://echofeed-web-app.df.r.appspot.com/api/auth-token/users/me/",
    //     {
    //       first_name: changes.first_name || profile_data.first_name,
    //       last_name: changes.last_name || profile_data.last_name,
    //       phone_number: changes.phone_number
    //         ? selectedCountryCode + changes.phone_number
    //         : profile_data.phone_number,
    //     },
    //     { headers: { Authorization: `Token ${fetchToken()}` } }
    //   )
    //   .then(response => {
    //     set_submit_loading(false);
    //     set_profile_data({
    //       ...profile_data,
    //       ...response.data,
    //     });
    //     set_changes({});
    //     toast.success("Changes saved successfully");
    //     // console.log(response);
    //   })
    //   .catch(error => {
    //     set_submit_loading(false);
    //     errorHandler(error);
    //   });
  }
};

export default About;
