import React, { useEffect } from "react";
import { Colors } from "../../constants";
import { ReactComponent as Loader } from "../../assets/svgs/loader.svg";
import { TextButton } from "../general";

const AwaitingDevice = props => {
  // console.log(props);
  useEffect(() => {
    let mounted = true;
    return () => {
      props.setPage("default");
      props.closeModal();
      mounted = false;
    };
  });

  return (
    <div className="flex grow flex-column">
      <div className="m-t-2">
        <p
          className="m-0 bold m-b-p5"
          style={{
            fontSize: ".6em",
          }}
        >
          Device Setup Code
        </p>
        <div
          className="flex align-center justify-center p-1 r-8 border-box"
          style={{
            background: Colors.bg,
            border: "1px dashed" + Colors.grey_3,
          }}
        >
          <h4 style={{ fontSize: "2.5em", margin: 0, color: Colors.purple }}>
            {props.device?.pass_code}
          </h4>
        </div>
        <p
          style={{
            margin: ".5em 0",
            color: Colors.grey_2,
            fontSize: ".5em",
            textAlign: "center",
          }}
        >
          * Enter the code above on the device you’re about to setup
        </p>
      </div>
      <div className="flex align-center justify-center flex-column grow">
        <Loader style={{ width: "5em", height: "5em" }} />
        <p style={{ fontSize: ".8em" }}>Awaiting Device...</p>
      </div>
      <div
        className="flex justify-center align-center"
        style={{
          margin: "1.5em 0",
        }}
      >
        <TextButton
          text="Continue"
          styleProp={{
            padding: ".5em",
            color: Colors.white,
            background: Colors.purple,
          }}
          onClick={() => props.closeModal()}
        />
      </div>
    </div>
  );
};

export default AwaitingDevice;
