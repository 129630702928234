import React from "react";
import styled from "styled-components";
import { Content } from "../../components/account-creation/company-information/";
import Sidebar from "../../components/account-creation/Sidebar";
import { DocumentHeader } from "../../components/general/DocumentHeader";

import colors from "../../constants/colors";

const Wrapper = () => {
  return (
    <StyledWrapper>
      <DocumentHeader page="Onboarding" />
      <Sidebar currentStep={2} />
      <Content currentStep={2} />
    </StyledWrapper>
  );
};

export default Wrapper;

const StyledWrapper = styled.div`
  display: flex;
  flex: 1;
  background: ${() => colors.bg};

  max-width: 100vw;
  max-height: 100vh;
  overflow: hidden;
`;
