import React, { useState } from "react";
import styled from "styled-components";
import { ReactComponent as Check } from "../../assets/svgs/green-check.svg";

const Walkthrough = props => {
  const [steps] = useState([
    { key: 1, title: "Account Information" },
    { key: 2, title: "Company Information" },
    { key: 3, title: "Choose Subscription" },
    { key: 4, title: "Make Payment" },
  ]);

  return (
    <StyledWalkthrough>
      {steps.map((step, idx) => {
        return (
          <div
            key={idx}
            className={`step ${
              step.key === props.currentStep && `step-active`
            }`}
          >
            <div>
              <div className="circle">
                {step.key === props.currentStep && (
                  <div
                    style={{
                      width: ".8em",
                      height: ".8em",
                      borderRadius: "50%",
                      cursor: "default",
                      background: "#ffffff",
                    }}
                  />
                )}

                {step.key < props.currentStep && (
                  <Check style={{ width: "2em", height: "2em" }} />
                )}
              </div>
            </div>
            <div>
              <div className="title">{step.title}</div>
            </div>
          </div>
        );
      })}
    </StyledWalkthrough>
  );
};

export default Walkthrough;

const StyledWalkthrough = styled.div`
  .step {
    position: relative;
    color: white;
    display: flex;
    align-items: center;
    cursor: default;
  }

  .step + .step {
    margin-top: 0.5em;
  }

  .step > div:first-child {
    padding: 1em;
    display: flex;
  }

  .step > div:not(:first-child) {
    padding: 1em;
  }

  .title {
    font-family: CircularStd;
    font-size: 0.8em;
    color: rgba(255, 255, 255, 0.3);
  }

  .circle {
    background: rgba(255, 255, 255, 0.2);
    height: 2em;
    width: 2em;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border: 0;
  }

  .circle:after {
    content: " ";
    position: absolute;
    display: block;
    top: 1px;
    right: 50%;
    bottom: 1px;
    left: 50%;
    height: 100%;
    width: 1px;
    transform: scale(2, 1.3);
    transform-origin: 50% -310%;
    background-color: rgba(255, 255, 255, 0.1);
    // z-index: 0;
  }

  .step:last-child .circle:after {
    display: none;
  }

  // .step.step-active {
  //   color: #4285f4;
  // }
  // .step.step-active .circle {
  //   background-color: #4285f4;
  // }

  .step.step-active .title {
    font-family: CircularStdBold;
    color: #ffffff;
  }
`;
