import React, { useState, useEffect, useMemo } from "react";
import { Table } from "../table";
import mock from "../../assets/mock/mock.json";
import moment from "moment";
import { Link } from "react-router-dom";
import { ReactComponent as RightArrow } from "../../assets/svgs/arrow-right-purple.svg";
import toast from "react-hot-toast";
import { useAppContext } from "../../context";
import DotsButton from "../general/DotsButton";
import { get } from "../../api/transport";
import { Colors } from "../../constants";
import errorHandler from "../../selectors/errorHandler";

export const AdminOverviewTable = props => {
  const [companies, set_companies] = useState([]);
  const [loading, set_loading] = useState(true);

  const fetchCompanies = async () => {
    try {
      let data = await get("/company/?limit=5");
      const { results } = data.data;
      set_companies(results);
    } catch (error) {
      errorHandler(error);
    } finally {
      set_loading(false);
    }
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) fetchCompanies();
    return () => (mounted = false);
  }, []);

  // function sortCompanies() {
  //   const sorted_list = [...companies].sort((a, b) => {
  //     return new Date(b.created_at) - new Date(a.created_at);
  //   });

  //   return sorted_list.splice(0, 5);
  // }

  const COLUMNS = [
    {
      Header: "COMPANY",
      accessor: "name",
    },
    {
      Header: "INDUSTRY",
      accessor: "industry",
      Cell: ({ value }) => {
        return ` ${value.map(el => ` ${el}`)}`;
      },
    },
    {
      Header: "COUNTRY",
      accessor: "country",
    },
    {
      Header: "EMAIL ADDRESS",
      accessor: "email",
    },
    {
      Header: "PHONE NUMBER",
      accessor: "tel",
    },
    {
      Header: "TABLETS",
      accessor: "number_tablet",
      Cell: ({ value }) => {
        return value || Math.ceil(Math.random() * 10);
      },
    },
    // {
    //   Header: "DATE",
    //   accessor: "created_at",
    //   Cell: ({ value }) => moment(value).format("MMM D, YYYY"),
    // },
    // {
    //   id: "actions",
    //   Cell: () => <DotsButton disabled={true} />,
    // },
  ];

  return (
    <div className="flex flex-column grow">
      <div className="flex justify-between align-center p-v-1">
        <h4 style={{ margin: 0 }}>Recent Companies</h4>

        <div className="flex align-center pointer">
          <Link
            className="m-0 bold text-decoration-none purple"
            to="/management/company/all"
            style={{
              fontSize: "16px",
            }}
          >
            VIEW ALL
          </Link>
          <RightArrow />
        </div>
      </div>
      <Table
        loading={loading}
        columns={COLUMNS}
        emptyRenderer
        data={useMemo(() => [...companies] || [], [companies])}
      />
    </div>
  );
};
