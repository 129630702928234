import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as UploadIcon } from "../../assets/svgs/upload-ellipsis.svg";
// import { Colors } from "../../constants";
// import { useAppContext } from "../../context";
// import ellipse from "../../assets/pngs/ellipse.png";

export const UploadField = props => {
  // const fileInputRef = useRef();
  const [preview, setPreview] = useState();
  const [, setImageState] = useState();
  const { image, setter, showBackground } = props;

  const handleChange = event => {
    console.log("handle change event triggered");
    event.preventDefault();
    const file = event.target.files[0];
    const isImage = file && file.type.substr(0, 5) === "image";
    if (isImage) {
      console.log("file selected", file);
      setter(file);
      setImageState(file);
    }
  };

  const handleUpload = event => {
    console.log("trigger button to click input ref ran");
    event.preventDefault();
    props.refData.current.click();
  };

  const handleRemoveImage = event => {
    setter(null);
    setPreview(null);
  };

  const setPreviewHelper = () => {
    if (image) {
      const reader = new FileReader();
      reader.onloadend = () => setPreview(reader.result);
      reader.readAsDataURL(image);
    } else {
      return setPreview(null);
    }
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) setPreviewHelper();
    return () => (mounted = false);
  }, [image]);

  return (
    <StyledUploadField>
      {!preview && (
        <div
          className="flex flex-column justify-center align-center full-width-percent grow r-8"
          style={{ background: "#FBF9F9", border: "1px dashed" + "#D9DAE4" }}
        >
          <UploadIcon className="pointer" onClick={handleUpload} />
          <p className="m-0 gray-2">Click to upload</p>
        </div>
      )}

      {preview && (
        <div
          className="flex flex-column justify-center align-center full-width-percent grow r-8 relative"
          style={{
            border: "1px dashed" + "#D9DAE4",
            backgroundImage:
              image && showBackground ? `url("${preview}")` : "none",
            backgroundSize: "cover",
            backgroundColor: "#FBF9F9",
          }}
        >
          {!props.showBackground && (
            <img
              // style={{
              //   width: "6em",
              //   height: "6em",
              // }}
              src={image ? preview : ""}
              style={{ width: "60px", height: "60px", objectFit: "cover" }}
              className="circle"
            />
          )}
          <button
            className="r-8 bg-w p-1 absolute bordered red pointer"
            style={{ right: 10, bottom: 10 }}
            onClick={handleRemoveImage}
          >
            Remove
          </button>
        </div>
      )}
      {props.showCaption || (
        <p
          className="m-0 absolute gray"
          style={{ fontSize: ".7em", top: "105%" }}
        >
          * Supported files: PNG or JPEG, not larger than 1MB
        </p>
      )}

      <input
        className="hide"
        accept="image/*"
        type="file"
        id={props.idData}
        ref={props.refData}
        onChange={handleChange}
      />
    </StyledUploadField>
  );
};

const StyledUploadField = styled.div`
  // border: 1px solid red;
  min-height: 8em;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5em;
  position: relative;

  .upload-button {
    width: 4em;
    height: 4em;
  }

  .remove-button {
  }
`;
