const Flex = ({
  tabIndex,
  children,
  ai = "",
  jc = "",
  stack = false,
  className,
  onClick,
  style,
  onBlur,
  id,
  isStretched,
  spacing,
  ...rest
}) => {
  return (
    <div
      id={id}
      {...rest}
      tabIndex={tabIndex}
      onClick={onClick}
      onBlur={onBlur}
      className={className}
      style={{
        display: "flex",
        flexDirection: stack ? "column" : "row",
        alignItems: ai,
        justifyContent: jc,
        cursor: "pointer",
        height: isStretched ? "100%" : "auto",
        gap: spacing || "initial",

        ...style,
      }}
    >
      {children}
    </div>
  );
};

export default Flex;

// flex-properties {
//   stack?: boolean;
//   ai?: 'center' | 'flex-start' | 'flex-end' | 'baseline';
//   jc?: 'center' | 'space-between' | 'flex-start' | 'flex-end';
//   className?: string;
//   id?: string;
//   onClick?(e?: any): void;
//   onBlur?(e?: any): void;
//   styles?: CSSObject;
//   tabIndex?: number;
//   isStretched?: boolean;
//   wrap?: boolean;
// }
