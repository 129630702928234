export const isOnboardingComplete = profile => {
  const {
    added_branch,
    added_branch_manager,
    added_device,
    added_emojis,
    added_question,
    // logo,
  } = profile?.company || {};

  const account_setup_checks = [
    added_branch,
    added_branch_manager,
    added_device,
    added_emojis,
    added_question,
  ];

  const account_setup_complete = account_setup_checks.every(el => {
    return !!el;
  });

  return account_setup_complete;
};
