import React, { useEffect, useState, useRef, useCallback } from "react";
import { StyledForm } from "../account-information/Form";
import { ReactComponent as Caret } from "../../../assets/svgs/caret-down.svg";
import AutoCompleteInput from "../../general/AutoCompleteInput";
import { Button, DropdownEmpty } from "../../general";
import validator from "validator";
import { listIndustries } from "../../../api/industry";
import { useAppContext } from "../../../context";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { useOutsideDetect } from "../../../selectors/UseOutsideDetect";
import { CountryCodeDropdown } from "../../general/CountryCodeDropdown";
import errorHandler from "../../../selectors/errorHandler";
import DropdownLoader from "../../modal/DropdownLoader";
import _ from "lodash";
import { post } from "../../../api/transport";
import { PhoneNumberInput } from "../../general/PhoneNumberInput";

const Form = () => {
  const { fetchToken, action, actions } = useAppContext();
  const [showIndustryDropdown, setShowIndustryDropdown] = useState(false);
  const [company_name, set_company_name] = useState("");
  const [company_industries, set_company_industries] = useState([]);
  const [company_main_address, set_company_main_address] = useState("");
  const [company_email, set_company_email] = useState("");
  const [phone, set_phone] = useState("");
  const [website, setWebsite] = useState("");
  const [industries, set_industries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [industries_loading, set_industries_loading] = useState(false);
  const [selectedCountryCode, setSelectedCountryCode] = useState("233");
  const [showCountrySelectorDropdown, setShowCountrySelectorDropdown] =
    useState(false);

  const [errors, set_errors] = React.useState({
    phone: [],
    email: [],
    website: [],
  });

  const industry_dropdown_ref = useRef(null);
  useOutsideDetect(industry_dropdown_ref, () => setShowIndustryDropdown(false));

  const history = useHistory();

  const disabled =
    [
      company_name,
      company_email,
      company_main_address,
      company_industries,
    ].some(el => el.length === 0) ||
    !phone ||
    Object.keys(errors).some(el => errors[el].length > 0);

  const handleIndustryFetch = useCallback(async () => {
    set_industries_loading(true);
    try {
      const response = await listIndustries();
      const { results } = response.data;
      set_industries(results);
      set_industries_loading(false);
    } catch (error) {
      set_industries_loading(false);
      errorHandler(error);
    }
  }, [fetchToken]);

  const selectIndustry = industry => {
    if (company_industries.some(ind => ind.id === industry.id))
      return set_company_industries(
        company_industries.filter(ind => ind.id !== industry.id)
      );

    return set_company_industries([...company_industries, industry]);
  };

  function validateEmail(emailArg) {
    if (emailArg === "") {
      set_company_email(emailArg);
      return set_errors({
        ...errors,
        email: ["Email field can not be left blank", ...errors.email],
      });
    }

    if (!emailArg.includes("@") || !validator.isEmail(emailArg)) {
      set_errors({
        ...errors,
        email: ["Invalid Email", ...errors.email],
      });
      return set_company_email(emailArg);
    }

    set_errors({
      ...errors,
      email: [],
    });
    set_company_email(emailArg);
  }
  // console.log(industries);
  function validateWebsite(website) {
    function validURL(str) {
      var pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ); // fragment locator
      return !!pattern.test(str);
    }

    if (website === "") {
      setWebsite(website);
      return set_errors({
        ...errors,
        website: ["Website field can not be left blank", ...errors.website],
      });
    }

    if (!validURL(website)) {
      setWebsite(website);
      return set_errors({
        ...errors,
        website: ["Invalid URL", ...errors.website],
      });
    }

    set_errors({
      ...errors,
      website: [],
    });
    setWebsite(website);
  }

  function validatePhoneNumber(phoneNumber) {
    const empty = !phoneNumber;
    const string = String(phoneNumber);

    if (empty) {
      set_phone(phoneNumber);
      return set_errors({
        ...errors,
        phone: ["Phone number can not be left blank", ...errors.phone],
      });
    }

    if (!validator.isMobilePhone(string, false, { strictMode: true })) {
      set_phone(phone);
      return set_errors({
        ...errors,
        phone: ["Enter a valid phone number", ...errors.phone],
      });
    }

    set_errors({
      ...errors,
      phone: [],
    });
    set_phone(phoneNumber);
  }

  const handleSubmit = async () => {
    setLoading(true);

    const postData = {
      name: company_name,
      status: "Approved",
      onboarding_stage: "COMPANY_INFORMATION",
      email: company_email,
      address: company_main_address,
      tel: phone,
      industry: [...company_industries.map(ind => ind.id)],
      country: "Ghana",
      website,
    };

    try {
      const { data: company } = await post("/user/company/", postData);

      setLoading(false);

      action(actions.setCompany, { company: company });

      let profileData = sessionStorage.getItem("profile");

      if (profileData) {
        profileData = JSON.parse(profileData);

        profileData["company"] = company;

        sessionStorage.setItem("profile", JSON.stringify(profileData));
      }

      history.push("/account-setup/choose-subscription");
    } catch (error) {
      setLoading(false);
      errorHandler(error);
    }
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) handleIndustryFetch();
    return () => (mounted = false);
  }, [handleIndustryFetch]);

  return (
    <StyledForm>
      <div className="row">
        <div className="input-field">
          <label>Company Name</label>
          <input
            type="text"
            value={company_name}
            placeholder="Eg. Echo Feedback Company"
            onChange={e => set_company_name(e.target.value)}
          />
        </div>
      </div>

      <div className="row">
        <div className="input-field relative" ref={industry_dropdown_ref}>
          <label>Industry</label>
          <div
            className="pseudo-input"
            onClick={() => setShowIndustryDropdown(!showIndustryDropdown)}
          >
            <p
              className={`m-0 ${
                !_.isEmpty(company_industries) ? "black" : "gray-2"
              }`}
            >
              {industries_loading && "Please wait"}
              {industries_loading ||
                (_.isEmpty(company_industries) && "Select Industry")}
              {industries_loading ||
                company_industries?.map((ind, idx) => (
                  <span key={idx}>
                    {`${ind.title.trim()}${
                      company_industries.length > 1 &&
                      idx !== company_industries.length - 1
                        ? ", "
                        : ""
                    }`}
                  </span>
                ))}
            </p>
            <span className="pointer flex justify-center align-center">
              <Caret />
            </span>
          </div>
          {showIndustryDropdown && (
            <div className={`dropdown ${_.isEmpty(industries) && `no-data`}`}>
              {industries_loading && <DropdownLoader />}
              {industries_loading ||
                (industries.length === 0 && <DropdownEmpty />)}

              {industries_loading ||
                industries.map((industry, idx) => {
                  const selected = company_industries?.some(
                    ind => ind.id === industry.id
                  );
                  return (
                    <div
                      className="dropdown-item"
                      style={{
                        background: selected ? "#f8fafc" : "initial",
                      }}
                      onClick={() => {
                        selectIndustry(industry);
                      }}
                      key={idx}
                    >
                      <p>{industry.title}</p>
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      </div>

      <div className="row">
        <div className="input-field relative">
          <label>Company Main Address</label>

          <AutoCompleteInput
            placeholder="Eg. 123, Auto Crescent, Greater Accra, Ghana"
            onChange={set_company_main_address}
          />
        </div>
      </div>

      <div className="row">
        <div className="input-field">
          <label>Company Website</label>
          <input
            type="text"
            placeholder="www.company.com"
            required={true}
            value={website}
            onChange={e => validateWebsite(e.target.value)}
          />
          {errors.website[0] && (
            <p className="error-msg">{errors.website[0]}</p>
          )}
        </div>
      </div>

      <div className="row">
        <div className="input-field">
          <label>Company Email Address</label>
          <input
            type="email"
            placeholder="Eg. someone@company.com"
            required={true}
            value={company_email}
            onChange={e => validateEmail(e.target.value)}
          />
          {errors.email[0] && <p className="error-msg">{errors.email[0]}</p>}
        </div>
      </div>

      <div className="row">
        <div className="input-field">
          <label>Company Phone Number</label>
          {/* <div className="pseudo-phone-input">
            <CountryCodeDropdown
              position="up"
              selectedCountryCode={selectedCountryCode}
              setSelectedCountryCode={setSelectedCountryCode}
              showDropdown={showCountrySelectorDropdown}
              setShowDropdown={setShowCountrySelectorDropdown}
            />

            <div className="phone-input-number-section">
              <input
                value={phone}
                onChange={e => {
                  const val = e.target.value;
                  const isNumber = val.match("^[0-9]*$");
                  if (isNumber) {
                    validatePhoneNumber(val);
                  }
                }}
                type="tel"
                placeholder="Eg. 23 324 2342"
              />
            </div>
          </div> */}
          <PhoneNumberInput
            value={phone}
            setValue={validatePhoneNumber}
            placeholder="Eg. 024 424 2342"
          />

          {errors.phone[0] && <p className="error-msg">{errors.phone[0]}</p>}
        </div>
      </div>

      <div className="row jc-flex-end">
        <Button
          disabled={disabled}
          loading={loading}
          onClick={handleSubmit}
          text={loading ? "Loading" : "Continue"}
        />
      </div>
    </StyledForm>
  );
};

export default Form;
