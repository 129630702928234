import React, { useRef, useState } from "react";
import styled from "styled-components";
import { Colors } from "../../constants";
import NotificationsButton from "./NotificationsButton";
import ProfileButton from "./ProfileButton";
import { useAppContext } from "../../context";
import animationData from "../../assets/lottie/profile-skeleton.json";
import lottieDefaultConfig from "../../selectors/lottieDefaultConfig";
import _ from "lodash";

import Lottie from "react-lottie";
import ProfileDropdown from "./ProfileDropdown";
import colors from "../../constants/colors";
import { useOutsideDetect } from "../../selectors";

const ProfileBadge = () => {
  const [isActive, setIsActive] = useState(false);
  const { appState } = useAppContext();
  const { profile } = appState;
  const { image } = profile?.profile || {};
  // const [loading, setLoading] = useState(false);
  let defaultOptions = { ...lottieDefaultConfig, animationData };
  const dropdown_ref = useRef(null);
  useOutsideDetect(dropdown_ref, () => setIsActive(false));

  const role = profile?.is_superuser
    ? "Super Admin"
    : profile?.is_owner
    ? "Admin"
    : "Manager";

  const name = `${profile?.first_name || profile?.email || `Anonymous`} ${
    profile?.last_name || ""
  }`;
  const img_parent = profile?.profile;
  const backgroundColor = img_parent?.image ? "none" : colors.purple;
  const backgroundImage = img_parent?.image ? `url("${image}")` : "none";
  return (
    <StyledProfileBadge>
      <NotificationsButton />
      <div
        className="flex align-center pointer relative"
        onClick={toggleIsActive}
        ref={dropdown_ref}
      >
        {_.isEmpty(profile) ? (
          <Lottie options={defaultOptions} height={40} width={150} />
        ) : (
          <div className="flex align-center justify-between">
            <div className="flex flex-2" style={{ width: "120px" }}>
              <div
                className="flex circle white justify-center align-center m-r-1 background-size-cover"
                style={{
                  backgroundColor,
                  backgroundImage,
                  height: "40px",
                  width: "40px",
                }}
              >
                {!profile?.profile?.image && (
                  <p>
                    {profile?.first_name?.slice(0, 1) || "A"}
                    {profile?.last_name?.slice(0, 1) || "N"}
                  </p>
                )}
              </div>
            </div>
            <div className="flex flex-column" style={{ flex: 8 }}>
              <p className="m-0 bold" style={{ fontSize: "12px" }}>
                {name}
              </p>
              <p className="m-0 bold" style={{ fontSize: "10px" }}>
                {role}
              </p>
            </div>
          </div>
        )}
        <ProfileButton />
        {isActive && <ProfileDropdown toggleIsActive={toggleIsActive} />}
      </div>
    </StyledProfileBadge>
  );

  function toggleIsActive() {
    return setIsActive(!isActive);
  }
};

export default ProfileBadge;

const StyledProfileBadge = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  // position: relative;

  p {
    margin: 0;
  }
`;
