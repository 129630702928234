import {
  Overview,
  GettingStarted,
  Analytics,
  Devices,
  Feedbacks,
  Questions,
  Logo,
  Emojis,
  Branches,
  BranchManagers,
  Subscription,
} from "./../pages/";

import {
  AccountInformation,
  CompanyInformation,
  ChangePassword,
} from "../pages/Settings";
import SuperAdminOverview from "../pages/SuperAdminOverview";
import SuperAdminFinance from "../pages/SuperAdminFinance";
import SuperAdminCompanies from "../pages/SuperAdminCompany/All";
import SuperAdminIndustries from "../pages/SuperAdminIndustries";
import SuperAdminEmojis from "../pages/SuperAdminEmojis";
import SuperAdminPushNotifications from "../pages/SuperAdminPushNotifications";
import _ from "lodash";
import About from "../pages/SuperAdminCompany/About";
import LogoBackground from "../pages/SuperAdminCompany/Logo-Background";
import SuperAdminBranches from "../pages/SuperAdminCompany/Branches";
import SuperAdminBranchManagers from "../pages/SuperAdminCompany/BranchManagers";
import SuperAdminDevices from "../pages/SuperAdminCompany/Devices";
import SuperAdminQuestions from "../pages/SuperAdminCompany/Questions";
import SuperAdminFeedbacks from "../pages/SuperAdminCompany/Feedbacks";
import SuperAdminSubscription from "../pages/SuperAdminCompany/Subscription";
import { AllTransactions } from "../pages/SuperAdminFinanceAll";

export const dashboard_routes = (profile = {}) => {
  const is_super_admin = profile?.is_superuser;
  const is_admin = profile?.is_owner;

  if (_.isEmpty(profile)) return [];

  let admin_routes = [
    // {
    //   path: "/dashboard/feedbacks",
    //   component: Feedbacks,
    // },
    {
      path: "/company/logo-background",
      component: Logo,
    },
    {
      path: "/company/emojis",
      component: Emojis,
    },
    {
      path: "/company/branches",
      component: Branches,
    },
    {
      path: "/company/branch-managers",
      component: BranchManagers,
    },
    {
      path: "/account/settings/company-information",
      component: CompanyInformation,
    },
  ];

  let super_admin_routes = [
    {
      path: "/dashboard/overview",
      component: SuperAdminOverview,
    },
    {
      path: "/dashboard/finance",
      component: SuperAdminFinance,
    },
    {
      path: "/dashboard/transactions",
      component: AllTransactions,
    },
    {
      path: "/management/company/all/",
      component: SuperAdminCompanies,
    },
    {
      path: "/management/company/:companyid/about/",
      component: About,
    },
    {
      path: "/management/company/:companyid/logo-bg/",
      component: LogoBackground,
    },
    {
      path: "/management/company/:companyid/branches/",
      component: SuperAdminBranches,
    },
    {
      path: "/management/company/:companyid/branch-managers/",
      component: SuperAdminBranchManagers,
    },
    {
      path: "/management/company/:companyid/devices/",
      component: SuperAdminDevices,
    },
    {
      path: "/management/company/:companyid/questions/",
      component: SuperAdminQuestions,
    },
    {
      path: "/management/company/:companyid/feedbacks/",
      component: SuperAdminFeedbacks,
    },
    {
      path: "/management/company/:companyid/subscription/",
      component: SuperAdminSubscription,
    },
    {
      path: "/management/industries",
      component: SuperAdminIndustries,
    },
    {
      path: "/management/emojis",
      component: SuperAdminEmojis,
    },
    {
      path: "/management/push-notifications",
      component: SuperAdminPushNotifications,
    },
    {
      path: "/account/settings/account-information",
      component: AccountInformation,
    },
    {
      path: "/account/settings/change-password",
      component: ChangePassword,
    },
  ];

  let manager_routes = [
    {
      path: "/getting-started",
      component: GettingStarted,
    },
    {
      path: "/dashboard/overview",
      component: Overview,
    },
    {
      path: "/dashboard/analytics",
      component: Analytics,
    },
    {
      path: "/dashboard/feedbacks",
      component: Feedbacks,
    },
    {
      path: "/dashboard/devices",
      component: Devices,
    },

    {
      path: "/dashboard/questions",
      component: Questions,
    },

    {
      path: "/account/settings/account-information",
      component: AccountInformation,
    },

    {
      path: "/account/settings/change-password",
      component: ChangePassword,
    },
    {
      path: "/account/subscription",
      component: Subscription,
    },
  ];

  if (is_super_admin) return super_admin_routes;
  if (is_admin) return [...manager_routes, ...admin_routes];
  return manager_routes;
};
