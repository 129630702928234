import React, { useState } from "react";
import styled from "styled-components";
import IconBtn from "./IconBtn";
import { Button } from "../dashboard";
import PieChart from "./PieChart";
import PieLegend from "./PieLegend";
import { ReactComponent as Pie } from "../../assets/svgs/pie.svg";
import { ReactComponent as Bar } from "../../assets/svgs/bar.svg";
import { useHistory } from "react-router-dom";
import { Loader } from "../general";

const AnalyticsSmall = ({ data = [], count = 0, isLoading = true }) => {
  const [legendStyle, setLegendStyle] = useState("legend1");
  const history = useHistory();
  const empty = data.length == 0;

  return (
    <StyledAnalyticsSmall isLoading={isLoading}>
      {isLoading ? (
        <div className="loading-class">
          <Loader />
        </div>
      ) : (
        <>
          <div className="top-sect">
            <div className="flex align-center gap-half">
              <IconBtn
                onClick={() => toggleLegend("legend1")}
                active={legendStyle === "legend1"}
                legendStyle={legendStyle}
                background="#fff"
              />
              {empty || (
                <IconBtn
                  onClick={() => toggleLegend("legend2")}
                  active={legendStyle === "legend2"}
                  Icon={Bar}
                  background="#D9DAE4"
                />
              )}
            </div>
            <div>
              <Button
                text="View Analytics"
                className={`m-0`}
                onClick={() => history.push("/dashboard/analytics")}
              />
            </div>
          </div>
          <div className="bottom-sect">
            <PieChart data={data} count={count} legendStyle={legendStyle} />
            <PieLegend data={data} count={count} legendStyle={legendStyle} />
          </div>
        </>
      )}
    </StyledAnalyticsSmall>
  );

  function toggleLegend(lgnd) {
    setLegendStyle(lgnd);
  }
};

export default AnalyticsSmall;

const StyledAnalyticsSmall = styled.div`
  display: flex;
  flex: 1;
  flex-direction: ${props => (props.isLoading ? "column-reverse" : "column")};
  gap: 1em;

  .loading-class {
    flex-basis: 85%;
    background-color: ${props => (props.isLoading ? "#fff" : "transparent")};
    display: flex;
  }

  .top-sect {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-basis: 10%;
  }

  .bottom-sect {
    display: flex;
    border-radius: 8px;
    background: #fff;
    flex-basis: 90%;
  }
`;
