import React, { useCallback, useEffect, useState } from "react";
import BillCard from "./BillCard";
import _ from "lodash";
import { determineTotalPrice } from "../../../selectors";
import { StyledForm } from "../account-information/Form";
import { useSubscriptionPackages } from "./broker";
import { useAppContext } from "../../../context";

const min_number_tablet = 0;

const Form = props => {
  const { data: subscriptions = [], isLoading } = useSubscriptionPackages();

  const billing_types = ["monthly", "yearly"];

  const [max_number_tablet, set_max_number_tablet] = useState(0);

  const [device_count, set_device_count] = useState();

  const [unit_price, set_unit_price] = useState();

  const [duration, set_duration] = useState("month");

  const [subscription_id, set_subscription_id] = useState();

  const [fetched_user_subscription, set_fetched_user_subscription] = useState(
    {}
  );

  const handleInputChange = ({ target: { value } }) => {
    const is_number = typeof Number(value) === "number";

    const is_in_range = _.inRange(
      Number(value),
      min_number_tablet,
      max_number_tablet + 1
    );

    if (value === "") {
      return set_device_count(undefined);
    }

    if (is_number && is_in_range) {
      return set_device_count(Number(value));
    }
  };

  const handleSelectDuration = billing_type => {
    const determined_duration = billing_type.split("l")[0];
    set_duration(determined_duration);
  };

  const determine_unit_price = useCallback(() => {
    if (!device_count) {
      set_subscription_id(undefined);
      return set_unit_price(0);
    }

    const subscription_package = subscriptions.find(
      ({ min_number_tablet, max_number_tablet }) => {
        const floor = min_number_tablet;

        const ceil = max_number_tablet;

        const is_in_range = _.inRange(
          Number(device_count || 0),
          floor,
          ceil + 1
        );

        return is_in_range;
      }
    );

    if (subscription_package) {
      const determined_unit_price = Number(subscription_package.unit_price);
      set_unit_price(determined_unit_price);
      set_subscription_id(subscription_package.id);
    }
  }, [device_count, subscriptions]);

  const userSub = {
    device_count,
    unit_price,
    duration,
    subscription_id,
  };

  useEffect(() => {
    subscriptions?.forEach(sub => {
      if (sub.max_number_tablet > max_number_tablet)
        set_max_number_tablet(sub.max_number_tablet);
    });
    return () => {};
  }, [subscriptions]);

  useEffect(() => {
    determine_unit_price();
    return () => {};
  }, [device_count]);

  useEffect(() => {
    let sub = userSub;
    if (duration) {
      sub = { ...userSub, duration };
    }
    const stringifiedUserSubscription = JSON.stringify(sub);
    sessionStorage.setItem(
      "echo_user_subscription_info",
      stringifiedUserSubscription
    );
  }, [userSub]);

  const exchange_rate = process.env.REACT_APP_USD_CEDI_EXCHANGE_RATE;

  return (
    <StyledForm>
      <div className="flex m-b-1 ">
        <div className="input-field">
          <label>Number of devices</label>
          <input
            disabled={_.isEmpty(subscriptions)}
            type="number"
            value={device_count ?? fetched_user_subscription?.device_count}
            default={0}
            min={0}
            placeholder="Enter number of devices"
            onChange={handleInputChange}
          />
        </div>
      </div>

      <div className="row">
        <div className="input-field ">
          <label>Billing Type</label>
          <div className="flex align-center">
            {billing_types.map((billing_type, idx) => {
              const localDuration = duration + "ly";
              const globalDuration = fetched_user_subscription?.duration;

              const isGlobalDuration =
                globalDuration && billing_type === globalDuration;
              const isLocalDuration =
                localDuration && billing_type === localDuration;

              return (
                <div key={idx} className="billing-type">
                  <input
                    style={{ background: "#6FCF97" }}
                    type="radio"
                    name="billing"
                    value={billing_type}
                    onChange={() => handleSelectDuration(billing_type)}
                    checked={isLocalDuration || isGlobalDuration}
                  />
                  <label>Bill {billing_type}</label>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="card-container">
          <BillCard
            style={{ flex: 0.49, margin: 0 }}
            figure={
              (unit_price || fetched_user_subscription.unit_price || 0) *
              exchange_rate
            }
          />
          <BillCard
            style={{ flex: 0.49, margin: 0 }}
            header="Total Subscription Fee"
            figure={
              determineTotalPrice(
                fetched_user_subscription?.subscription_id
                  ? fetched_user_subscription
                  : userSub
              ) * exchange_rate
            }
            perMeasure={fetched_user_subscription?.duration || duration}
          />
        </div>
      </div>
    </StyledForm>
  );
};

export default Form;
