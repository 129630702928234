import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Table } from "../table";
import moment from "moment";
import { Colors } from "../../constants";
import { EmojiTextButton } from "../general";
import Searchbar from "../dashboard/Searchbar";
import { ReactComponent as Plus } from "../../assets/svgs/plus.svg";
import DotsButton from "../general/DotsButton";
import { AddBranchManagerModal } from "../modal";
import axios from "axios";
import { useAppContext } from "../../context";
import errorHandler from "../../selectors/errorHandler";
import UpdateBranchManagerModal from "../modal/UpdateBranchManager";
import toast from "react-hot-toast";
import { useHistory, useParams } from "react-router-dom";
import { get, patch } from "../../api/transport";
import {
  useCompanyBranchManagers,
  useUpdateCompanyBranchManager,
} from "../admin/broker";

export const SuperAdminBranchManagersTable = props => {
  const history = useHistory();
  const params = useParams();
  const { data, isLoading } = useCompanyBranchManagers(params.companyid);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loading, set_loading] = useState(true);
  const [branch_fetch_loading, set_branch_fetch_loading] = useState(false);
  const [limit, set_limit] = useState(8);
  const [offset, set_offset] = useState(1);

  // const [branch_managers, set_branch_managers] = useState([]);
  const [branches, set_branches] = useState([]);
  const [search_term, set_search_term] = useState("");

  const filterBranchManagers = data => {
    const filtered_list = [...data].filter(manager => {
      const name_search =
        manager.first_name.toLowerCase().includes(search_term.toLowerCase()) ||
        manager.last_name.toLowerCase().includes(search_term.toLowerCase());

      const branch_search =
        typeof manager.branch === "object" &&
        manager.branch?.name.toLowerCase().includes(search_term.toLowerCase());

      const email_search = manager.email
        ?.toLowerCase()
        .includes(search_term.toLowerCase());

      const status_search = manager.status
        ?.toLowerCase()
        .includes(search_term.toLowerCase());

      return name_search || branch_search || email_search || status_search;
    });
    return filtered_list;
  };

  const tableData = useMemo(
    () => filterBranchManagers(data?.results || []).reverse(),
    [data, search_term]
  );

  const COLUMNS = [
    {
      Header: "MANAGER ID",
      accessor: "uuid",
      Cell: ({ value }) => {
        const [first_partition] = value.split("-");
        return first_partition.toUpperCase();
      },
    },
    {
      Header: "NAME",
      id: "name",
      Cell: data => {
        const { first_name, last_name } = data?.row?.original;

        return `${first_name} ${last_name}`;
      },
    },
    {
      Header: "EMAIL ADDRESS",
      accessor: "email",
    },
    {
      Header: "BRANCH",
      accessor: "branch.name",
      Cell: data => {
        const { branch, is_owner } = data?.row?.original;
        return (
          branch?.name ||
          (is_owner && <p className="green m-0">Super Admin</p>) ||
          "Not Set"
        );
      },
    },
    {
      Header: "STATUS",
      accessor: "is_active",
      Cell: ({ value }) => {
        const render = (
          <p className={`p-0 ${value ? `green` : `light-grey`}`}>
            {value ? "Active" : "Deactivated"}
          </p>
        );
        return render;
      },
    },
    {
      Header: "DATE ADDED",
      accessor: "created_at",
      Cell: ({ value }) => moment(value).format("MMM D, YYYY, hh:mm a"),
    },
    {
      id: "action",
      Cell: data => {
        const branch_manager = data?.row.original;

        const [update_modal_open, set_update_modal_open] = useState(false);
        const { mutateAsync, isLoading } = useUpdateCompanyBranchManager(
          branch_manager.uuid
        );
        const actions = [
          {
            title: "Update Info",
            style: {
              color: "#696969",
            },
            onClick: () => {
              if (branch_manager.is_owner) return;
              return set_update_modal_open(true);
            },
          },
          {
            title: !!branch_manager.is_active
              ? "Deactivate Account"
              : "Reactivate Account",
            style: { color: branch_manager.is_active ? "red" : "green" },
            disabled: true,
            onClick: async () => {
              const patch_data = {
                is_active: !!!branch_manager.is_active,
                is_branch_manager: true,
              };

              try {
                await mutateAsync(patch_data);
              } catch (error) {
                errorHandler(error);
              }
            },
          },
          // {
          //   title: "Delete Account",
          //   style: { color: "red" },
          //   disabled: true,
          // },
        ];

        return (
          <div className="flex justify-end align-center">
            <DotsButton actions={actions} />
            {update_modal_open && (
              <UpdateBranchManagerModal
                isOpen={update_modal_open}
                closeModal={() => set_update_modal_open(false)}
                branchManager={branch_manager}
                branchManagers={data?.results || []}
                // setBranchManagers={set_branch_managers}
                // branches={branches}
                // setBranches={set_branches}
              />
            )}
          </div>
        );
      },
    },
  ];

  return (
    <div
      className="flex grow flex-column"
      style={{ maxHeight: "90vh", overflowY: "auto" }}
    >
      <div className="flex justify-between align-center p-v-1">
        <h4 style={{ margin: 0 }}>Branch Managers</h4>
        <div>
          <Searchbar term={search_term} filter={set_search_term} />
        </div>

        <div className="flex align-center justify-between">
          <EmojiTextButton
            text="Add a Branch Manager"
            IconProp={Plus}
            disabled={true}
            // onClick={() => setModalIsOpen(true)}
            styleProp={{
              background: Colors.purple,
              color: Colors.white,
              padding: ".8em 2em",
              marginLeft: ".5em",
            }}
          />
        </div>
      </div>
      {/* <AddBranchManagerModal
        isOpen={modalIsOpen}
        closeModal={() => setModalIsOpen(false)}
        primaryButtonText={"Continue"}
        branchManagers={branch_managers}
        setBranchManagers={set_branch_managers}
        branches={branches}
        setBranches={set_branches}
      /> */}
      <Table columns={COLUMNS} loading={isLoading} data={tableData} />
    </div>
  );
};
