import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import { Head, PageTitle } from "../../components/account-creation";
import { Form, Summary } from "../../components/account-creation/make-payment";
import { capitalizeFirstLetter, determineTotalPrice } from "../../selectors";
import errorHandler from "../../selectors/errorHandler";
import { post } from "../../api/transport";
import { ReactComponent as Logo } from "../../assets/svgs/logo-ellipsis.svg";
import StepsAlt from "../../components/account-creation/StepsAlt";
import PaystackPop from "@paystack/inline-js";
import { TextButton } from "../../components/general";
import colors from "../../constants/colors";
import toast from "react-hot-toast";
import StripeCheckoutModal from "../../components/modal/StripeCheckoutModal";

const getTotalPrice = (coupon, subscription) => {
  const { type = "Fixed", fixed_price = 0, percent_off = "" } = coupon;

  if (type === "Fixed") {
    const discounted_amount = fixed_price;

    return determineTotalPrice(subscription, discounted_amount);
  }

  if (type === "Percentage") {
    const discounted_amount =
      (percent_off / 100) * determineTotalPrice(subscription);

    return determineTotalPrice(subscription, discounted_amount);
  }
};

const getCouponAmount = (coupon, subscription, total) => {
  const { type = "Fixed", fixed_price = 0, percent_off = "" } = coupon;

  if (type === "Fixed") {
    return fixed_price;
  }

  if (type === "Percentage") {
    const percentage = Number(percent_off) / 100;
    const new_amount = percentage * Number(total);
    return new_amount;
  }
};

export const createNewTransaction = (subscription, totalPrice) => {
  let profile = sessionStorage.getItem("profile");

  if (!profile) return;

  profile = JSON.parse(profile);

  let { company } = profile;

  const post_data = {
    company: company.id,
    subscription: subscription.subscription_id,
    total_number_tablets: Number(subscription?.device_count),
    is_autorenewal: true,
    payment_method: totalPrice === 0 ? "CASH" : "STRIPE",
    status: "Active",
    billing_intervals:
      capitalizeFirstLetter(subscription?.duration.toLowerCase()) + "ly",
  };

  return post("/user/package/", post_data);
};

export const initializePayment = (invoice = {}) => {
  const userEmail = sessionStorage.getItem("email");

  const { id, total_amount, payment_method, coupon_code, coupon_amount } =
    invoice;

  const post_data = {
    email: userEmail,
    amount: Number(total_amount),
    invoice_id: id,
    payment_method: payment_method,
    coupon_code,
    coupon_amount,
  };

  return post("/payment/", post_data);
};

const MakePayment = props => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const [verificationSuccess, setVerificationSuccess] = useState(false);
  const [verificationFailed, setVerificationFailed] = useState(false);

  const [stripeClientSecret, setStripeClientSecret] = useState("");
  const [showCheckoutModal, setShowCheckoutModal] = useState(false);

  const userEmail = sessionStorage.getItem("email");

  useEffect(() => {
    if (verificationSuccess) history.push("/payment-successful");
    return () => {};
  }, [verificationSuccess, setVerificationSuccess]);

  const [subscription, setSubscription] = useState({});

  const restoreSubscription = useCallback(() => {
    const sub = sessionStorage.getItem("echo_user_subscription_info");
    if (sub) {
      const parsedSub = JSON.parse(sub);
      setSubscription(parsedSub);
    }
    return () => {};
  }, [setSubscription]);

  useEffect(() => {
    restoreSubscription();
  }, [restoreSubscription]);

  const [couponCode, setCouponCode] = useState("");

  const [coupon, setCoupon] = useState({});

  useEffect(() => {
    const saved_coupon = sessionStorage.getItem("echo_subscription_coupon");
    if (saved_coupon) {
      let parsed_coupon = JSON.parse(saved_coupon);
      setCoupon(parsed_coupon);
    } else {
      setCoupon({});
    }
    return () => {};
  }, [setCoupon]);

  const exchange_rate = process.env.REACT_APP_USD_CEDI_EXCHANGE_RATE;
  const payStackKey = process.env.REACT_APP_PAYSTACK_KEY;

  const totalPrice = useMemo(() => {
    const { type = "Fixed", fixed_price = 0, percent_off = "" } = coupon;

    if (type === "Fixed") {
      const discounted_amount = fixed_price;

      return determineTotalPrice(subscription, discounted_amount);
    }

    if (type === "Percentage") {
      const discounted_amount =
        (percent_off / 100) * determineTotalPrice(subscription);

      return determineTotalPrice(subscription, discounted_amount);
    }
  }, [determineTotalPrice, subscription, coupon]);

  const finalCouponAmount = useMemo(() => {
    const { type = "Fixed", fixed_price = 0, percent_off = "" } = coupon;

    if (type === "Fixed") {
      return Number(fixed_price);
    }

    if (type === "Percentage") {
      const percentage = Number(percent_off) / 100;
      const new_amount = percentage * determineTotalPrice(subscription);
      return new_amount;
    }
  }, [coupon, determineTotalPrice, coupon]);

  // const verifyTransaction = reference => {
  //   return post("/paystack/verify/", { reference });
  // };

  // const handleOpenMakePaymentModal = async data => {
  //   if (totalPrice === 0) return setIsPaymentVerified(true);

  //   const { access_code, total_amount } = data;

  //   const paystack = new PaystackPop();

  //   return paystack.checkout({
  //     accessCode: access_code,
  //     key: payStackKey,
  //     email: userEmail,
  //     amount: total_amount,
  //     onSuccess: async response => {
  //       const { reference } = response;

  //       try {
  //         await post("/payment/verify/", {
  //           reference,
  //           payment_method: "PAYSTACK",
  //         });
  //         setIsPaymentVerified(true);
  //       } catch (error) {
  //         errorHandler(error);
  //       } finally {
  //         setLoading(false);
  //       }
  //     },
  //     onCancel: () => {},
  //     onError: error => {
  //       toast.error("Sorry, an error occured");
  //       console.warn(error);
  //     },
  //   });

  //   // return paystack.newTransaction({
  //   //   email: sessionStorage.getItem("email"),
  //   //   amount: Number(data.total_amount) * 100,
  //   //   key: payStackKey,
  //   //   currency: "GHS",
  //   //   channels: ["card", "mobile_money"],
  //   //   onSuccess: async response => {
  //   //     const { trxref, reference } = response;

  //   //     try {
  //   //       await post("/paystack/verify/", { reference });
  //   //       setIsPaymentVerified(true);
  //   //     } catch (error) {
  //   //       errorHandler(error);
  //   //     } finally {
  //   //       setLoading(false);
  //   //     }
  //   //   },
  //   //   onCancel: () => {
  //   //     setLoading(false);
  //   //   },
  //   // });
  // };

  const handleMakePayment = async () => {
    setVerificationFailed(false);
    setLoading(true);

    try {
      const { data: invoice } = await createNewTransaction(
        subscription,
        totalPrice
      );

      const initializePaymentPayload = {
        ...invoice,
        coupon_code: coupon?.code || "",
        coupon_amount:
          getCouponAmount(coupon, subscription, invoice.total_amount).toFixed(
            2
          ) || "0.00",
      };

      console.log(initializePaymentPayload);

      const { data } = await initializePayment(initializePaymentPayload);

      const { client_secret } = data;

      setStripeClientSecret(client_secret);

      setShowCheckoutModal(true);
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const sub = sessionStorage.getItem("echo_user_subscription_info");
    if (sub) {
      const parsedSub = JSON.parse(sub);
      setSubscription(parsedSub);
    }
    return () => {};
  }, [setSubscription]);

  const mutateSubscription = useCallback(
    payload => setSubscription(payload),
    [setSubscription]
  );

  return (
    <StyledMakePayment>
      <div className="no-show-responsive">
        <Head back={true} currentStep={props.currentStep} />
      </div>
      <div className="show-responsive">
        <Logo />
        <StepsAlt currentStep={4} />
      </div>

      {verificationFailed && (
        <div className="network-error">
          <p>There was an error with your payment. Please try again</p>
        </div>
      )}

      <PageTitle title="Payment" titleSize="2em" />
      <div className="make-payment-sheet">
        <div className="flex flex-column flex-1">
          <p style={{ margin: "0.5em 0 1.5em 0" }}>
            How many devices are you subscribing for?
          </p>
          <Form
            subscription={subscription}
            setSubscription={mutateSubscription}
            setCouponCode={setCouponCode}
            couponCode={couponCode}
            setCoupon={setCoupon}
          />
        </div>
        <div className="flex flex-1 justify-end p-v-1 summary-container">
          <Summary
            subscription={subscription}
            setSubscription={mutateSubscription}
            coupon={coupon}
          />
        </div>
      </div>
      <div className="pay-btn-container">
        <TextButton
          no_margin={true}
          text={loading ? "Please wait" : "Pay Now"}
          background={colors.purple}
          color="white"
          fontSize="medium"
          onClick={handleMakePayment}
          disabled={loading}
        />
      </div>

      <StripeCheckoutModal
        clientSecret={stripeClientSecret}
        isOpen={showCheckoutModal}
        closeModal={() => setShowCheckoutModal(false)}
        setIsSuccess={setVerificationSuccess}
        setIsFail={setVerificationFailed}
      />
    </StyledMakePayment>
  );
};

export default MakePayment;

const StyledMakePayment = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  .network-error {
    background-color: #fee2e2;
    padding: 8px 16px;
    border-radius: 8px;
    margin-top: 12px;

    p {
      margin: 0;
      color: #991b1b;
    }
  }

  .make-payment-sheet {
    display: flex;
    flex-direction: row;
    gap: 1em;

    .summary-container {
      justify-content: flex-end;
    }
  }

  .pay-btn-container {
    justify-content: flex-end;
    margin-top: auto;

    .paystack-button {
      width: 100%;
    }
  }

  @media (max-width: 1024px) {
    .no-show-responsive {
      display: none;
    }

    .show-responsive {
      display: initial;
    }

    .make-payment-sheet {
      flex-direction: column;

      .summary-container {
        justify-content: flex-start;
      }
    }

    .pay-btn-container {
      justify-content: flex-start;
      // border: 1px solid;

      .paystack-button {
        width: 100%;
      }
    }
  }

  @media (min-width: 1024px) {
    .no-show-responsive {
      display: initial;
    }

    .show-responsive {
      display: none;
    }
  }
`;
