import React from "react";
import { matchPath, useHistory, useParams } from "react-router-dom";
import { useAppContext } from "../../context/";

export const CompanyTabs = () => {
  const { appState } = useAppContext();
  const { profile } = appState;
  const is_admin = profile?.is_owner;

  const routes = [
    {
      title: "About",
      path: "/management/company/:companyid/about/",
    },
    {
      title: "Logo & Background",
      path: "/management/company/:companyid/logo-bg/",
    },
    {
      title: "Branches",
      path: "/management/company/:companyid/branches/",
    },
    {
      title: "Branch Managers",
      path: "/management/company/:companyid/branch-managers/",
    },
    {
      title: "Devices",
      path: "/management/company/:companyid/devices/",
    },
    {
      title: "Questions",
      path: "/management/company/:companyid/questions/",
    },
    {
      title: "Feedbacks",
      path: "/management/company/:companyid/feedbacks/",
    },
    {
      title: "Subscription",
      path: "/management/company/:companyid/subscription/",
    },
  ];

  return (
    <div className="bordered bg-w  p-h-4 flex">
      {routes.map(route => (
        <Tab key={Math.random()} {...route} />
      ))}
    </div>
  );
};

function Tab(props) {
  const history = useHistory();
  const { companyid } = useParams();
  //   console.log(companyid);

  //   const fix_param = () =>

  const match = matchPath(history.location.pathname, {
    path: props.path,
    exact: false,
    strict: false,
  });

  const active = !!match;

  return (
    <div
      onClick={handleClick}
      className={`bordered-0 p-v-1 m-r-2 pointer ${
        active && `purple bold border-bottom-purple`
      }`}
    >
      <p className={`${active ? "purple" : "gray-2"} m-0`}> {props.title} </p>
    </div>
  );

  function handleClick() {
    const path = props.path.replace(":companyid", companyid);
    // console.log(path);
    history.push(path);
  }
}
