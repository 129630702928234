import React, { useState, useEffect, useMemo } from "react";
import { Table } from "../table";
import moment from "moment";
import { useHistory, useParams } from "react-router-dom";
import { Colors } from "../../constants";
import { EmojiTextButton } from "../general";
import { ReactComponent as QR } from "../../assets/svgs/Qrcode.svg";
import Searchbar from "../dashboard/Searchbar";
import { ReactComponent as Arrow } from "../../assets/svgs/topright.svg";
import { ReactComponent as Plus } from "../../assets/svgs/plus.svg";
import { DeviceCount } from "./DeviceCount";
import DotsButton from "../general/DotsButton";
import { useAppContext } from "../../context";
import errorHandler from "../../selectors/errorHandler";
import { get } from "../../api/transport";

export const SuperAdminDevicesTable = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [devices, set_devices] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [branches, setBranches] = useState([]);
  const [categories, setCategories] = useState([]);
  const [search_term, set_search_term] = useState("");
  const { fetchToken, appState } = useAppContext();
  const [loading, set_loading] = useState(true);
  const [qr_code_modal_open, set_qr_code_modal_open] = useState(false);
  const [device, set_device] = useState();
  const history = useHistory();
  const params = useParams();

  const fetchDevices = async () => {
    try {
      const response = await get(`/admin/company/device/${params.companyid}`);
      const { results } = response.data;
      set_devices(results);
    } catch (error) {
      errorHandler(error);
    } finally {
      set_loading(false);
    }
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) fetchDevices();
    return () => (mounted = false);
  }, []);

  const COLUMNS = [
    {
      Header: "DEVICE",
      accessor: "name",
    },
    {
      Header: "BRANCH",
      accessor: "location",
      Cell: data => {
        const { location } = data?.row?.original;
        return `${location.map(el => ` ${el.name}`)}`;
      },
    },
    {
      Header: "DATE ADDED",
      accessor: "created_at",
      Cell: ({ value }) => moment(value).format("MMM D, YYYY hh:mm a"),
    },
    {
      Header: "SENTIMENTS",
      accessor: "total_sentiment",
    },
    {
      Header: "QR CODE",
      id: "qr_code_column",
      accessor: "pass_code",
      Cell: data => {
        const device = data?.row?.original;
        return (
          <EmojiTextButton
            onClick={() => {
              set_device(device);
              set_qr_code_modal_open(true);
            }}
            text="Get QR Code"
            IconProp={QR}
            styleProp={{
              background: Colors.purple,
              color: Colors.white,
              padding: ".8em 2em",
            }}
          />
        );
      },
    },
    {
      Header: "ACTIVE QUESTION",
      accessor: "question.question",
    },
    {
      Header: "TIME LEFT FOR QUESTION",
      accessor: "question_end_date",
      Cell: ({ value }) => {
        return moment(value).isSameOrBefore(moment(), "minute")
          ? "Expired"
          : `${value ? moment(value).fromNow(true) : ""}`;
      },
    },
    {
      id: "action",
      Cell: data => {
        const tablet = data?.row?.original;
        const { id } = tablet;
        const [open, setOpen] = useState(false);

        function deleteDevice() {}

        function editDevice() {}

        return (
          <>
            <DotsButton
              deletefunction={deleteDevice}
              editfunction={editDevice}
            />
          </>
        );
      },
    },
  ];

  function filterDevices() {
    const filtered_list = [...devices].filter(device => {
      const name_search = device.name
        ?.toLowerCase()
        .includes(search_term.toLowerCase());

      const branch_search =
        device.location &&
        device.location.some(location =>
          location.name?.toLowerCase().includes(search_term.toLowerCase())
        );
      const question_search =
        device.question &&
        device.question?.question
          ?.toLowerCase()
          .includes(search_term.toLowerCase());

      return name_search || branch_search || question_search;
    });
    if (!appState.profile?.is_owner)
      return filtered_list.filter(
        el =>
          el.location &&
          el.location.some(el => el.id === appState.profile?.branch?.id)
      );
    return filtered_list;
  }

  return (
    <div
      className="flex grow flex-column"
      style={{ maxHeight: "90vh", overflowY: "auto" }}
    >
      <div className="flex justify-between align-center p-v-1">
        <div className="flex align-center justify-between">
          <h4 style={{ margin: 0 }}>Devices</h4>
          <DeviceCount count={devices.length} />
        </div>

        <div>
          <Searchbar term={search_term} filter={set_search_term} />
        </div>

        <div className="flex align-center justify-between">
          <EmojiTextButton
            text="Deploy Question"
            IconProp={Arrow}
            disabled={true}
            styleProp={{
              background: Colors.black,
              color: Colors.white,
              padding: ".8em 2em",
            }}
            onClick={() => history.push("/dashboard/questions")}
          />
          <EmojiTextButton
            text="Add a Device"
            IconProp={Plus}
            disabled={true}
            styleProp={{
              background: Colors.purple,
              color: Colors.white,
              padding: ".8em 2em",
              marginLeft: ".5em",
            }}
            onClick={() => setModalIsOpen(true)}
          />
        </div>
      </div>
      <Table
        columns={COLUMNS}
        data={useMemo(() => filterDevices().reverse(), [search_term, devices])}
        loading={loading}
      />
      {/* 
      <AddDeviceModal
        isOpen={modalIsOpen}
        closeModal={() => setModalIsOpen(false)}
        primaryButtonText={"Continue"}
        devices={devices}
        setDevices={setDevices}
        questions={questions}
        setQuestions={setQuestions}
        branches={branches}
        setBranches={setBranches}
        categories={categories}
      />

      <QRCodeModal
        isOpen={qr_code_modal_open}
        closeModal={() => set_qr_code_modal_open(false)}
        device={device}
        setDevice={set_device}
      /> */}
    </div>
  );

  //   function fetchDevices() {
  //     setLoading(true);
  //     toast.loading("Fetching Devices...");
  //     axios
  //       .get("https://echofeed-web-app.df.r.appspot.com/api/user/tablet/", {
  //         headers: {
  //           Authorization: `Token ${fetchToken()}`,
  //         },
  //       })
  //       .then(response => {
  //         setLoading(false);
  //         setDevices(response.data.results);
  //         toast.success("Devices fetched successfully");
  //         // console.log(response.data);

  //         return () => {};
  //       })
  //       .catch(error => {
  //         setLoading(false);
  //         errorHandler(error);
  //       });
  //   }

  //   function fetchQuestions() {
  //     // toast.loading("Fetching Questions...");
  //     axios
  //       .get(
  //         "https://echofeed-web-app.df.r.appspot.com/api/user/question-settings/",
  //         {
  //           headers: {
  //             Authorization: `Token ${fetchToken()}`,
  //           },
  //         }
  //       )
  //       .then(response => {
  //         setQuestions(response.data.results);
  //         // toast.success("Devices fetched successfully");
  //       })
  //       .catch(error => {
  //         setLoading(false);
  //         errorHandler(error);
  //       });
  //   }

  //   function fetchBranches() {
  //     // toast.loading("Fetching Branches...");
  //     axios
  //       .get("https://echofeed-web-app.df.r.appspot.com/api/user/branch/", {
  //         headers: { Authorization: `Token ${fetchToken()}` },
  //       })
  //       .then(response => {
  //         setBranches(response.data.results);
  //       })
  //       .catch(e => {
  //         errorHandler(e);
  //       });
  //   }
  //   function fetchCategories() {
  //     // toast.loading("Fetching Categories...");
  //     axios
  //       .get("https://echofeed-web-app.df.r.appspot.com/api/user/category/", {
  //         headers: { Authorization: `Token ${fetchToken()}` },
  //       })
  //       .then(response => {
  //         setCategories(response.data);
  //       })
  //       .catch(e => {
  //         errorHandler(e);
  //       });
  //   }
};
