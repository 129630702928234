export const country_codes = [
  {
    ISO: "AF",
    name: "AFGHANISTAN",
    code: "93",
  },
  {
    ISO: "AL",
    name: "ALBANIA",
    code: "355",
  },
  {
    ISO: "DZ",
    name: "ALGERIA",
    code: "213",
  },
  {
    ISO: "AS",
    name: "AMERICAN SAMOA",
    code: "1684",
  },
  {
    ISO: "AD",
    name: "ANDORRA",
    code: "376",
  },
  {
    ISO: "AO",
    name: "ANGOLA",
    code: "244",
  },
  {
    ISO: "AI",
    name: "ANGUILLA",
    code: "1264",
  },
  {
    ISO: "AQ",
    name: "ANTARCTICA",
    code: "672",
  },
  {
    ISO: "AG",
    name: "ANTIGUA AND BARBUDA",
    code: "1268",
  },
  {
    ISO: "AN",
    name: "ANTILLES NETHERLANDS",
    code: "599",
  },
  {
    ISO: "AR",
    name: "ARGENTINA",
    code: "54",
  },
  {
    ISO: "AM",
    name: "ARMENIA",
    code: "374",
  },
  {
    ISO: "AW",
    name: "ARUBA",
    code: "297",
  },
  {
    ISO: "AU",
    name: "AUSTRALIA",
    code: "61",
  },
  {
    ISO: "AT",
    name: "AUSTRIA",
    code: "43",
  },
  {
    ISO: "AZ",
    name: "AZERBAIJAN",
    code: "994",
  },
  {
    ISO: "BS",
    name: "BAHAMAS",
    code: "1242",
  },
  {
    ISO: "BH",
    name: "BAHRAIN",
    code: "973",
  },
  {
    ISO: "BD",
    name: "BANGLADESH",
    code: "880",
  },
  {
    ISO: "BB",
    name: "BARBADOS",
    code: "1246",
  },
  {
    ISO: "BL",
    name: "BARTHELEMY, SAINT",
    code: "590",
  },
  {
    ISO: "BY",
    name: "BELARUS",
    code: "375",
  },
  {
    ISO: "BE",
    name: "BELGIUM",
    code: "32",
  },
  {
    ISO: "BZ",
    name: "BELIZE",
    code: "501",
  },
  {
    ISO: "BJ",
    name: "BENIN",
    code: "229",
  },
  {
    ISO: "BM",
    name: "BERMUDA",
    code: "1441",
  },
  {
    ISO: "BT",
    name: "BHUTAN",
    code: "975",
  },
  {
    ISO: "BO",
    name: "BOLIVIA",
    code: "591",
  },
  {
    ISO: "BA",
    name: "BOSNIA AND HERZEGOVINA",
    code: "387",
  },
  {
    ISO: "BW",
    name: "BOTSWANA",
    code: "267",
  },
  {
    ISO: "BR",
    name: "BRAZIL",
    code: "55",
  },
  {
    ISO: "BN",
    name: "BRUNEI DARUSSALAM",
    code: "673",
  },
  {
    ISO: "BG",
    name: "BULGARIA",
    code: "359",
  },
  {
    ISO: "BF",
    name: "BURKINA FASO",
    code: "226",
  },
  {
    ISO: "BI",
    name: "BURUNDI",
    code: "257",
  },
  {
    ISO: "KH",
    name: "CAMBODIA",
    code: "855",
  },
  {
    ISO: "CM",
    name: "CAMEROON",
    code: "237",
  },
  {
    ISO: "CA",
    name: "CANADA",
    code: "1",
  },
  {
    ISO: "CV",
    name: "CAPE VERDE",
    code: "238",
  },
  {
    ISO: "KY",
    name: "CAYMAN ISLANDS",
    code: "1345",
  },
  {
    ISO: "CF",
    name: "CENTRAL AFRICAN REPUBLIC",
    code: "236",
  },
  {
    ISO: "TD",
    name: "CHAD",
    code: "235",
  },
  {
    ISO: "CL",
    name: "CHILE",
    code: "56",
  },
  {
    ISO: "CN",
    name: "CHINA",
    code: "86",
  },
  {
    ISO: "CX",
    name: "CHRISTMAS ISLAND",
    code: "61",
  },
  {
    ISO: "CC",
    name: "COCOS (KEELING) ISLANDS",
    code: "61",
  },
  {
    ISO: "CO",
    name: "COLOMBIA",
    code: "57",
  },
  {
    ISO: "KM",
    name: "COMOROS",
    code: "269",
  },
  {
    ISO: "CG",
    name: "CONGO",
    code: "242",
  },
  {
    ISO: "CD",
    name: "CONGO, THE DEMOCRATIC REPUBLIC OF THE",
    code: "243",
  },
  {
    ISO: "CK",
    name: "COOK ISLANDS",
    code: "682",
  },
  {
    ISO: "CR",
    name: "COSTA RICA",
    code: "506",
  },
  {
    ISO: "CI",
    name: "COTE D IVOIRE",
    code: "225",
  },
  {
    ISO: "HR",
    name: "CROATIA",
    code: "385",
  },
  {
    ISO: "CU",
    name: "CUBA",
    code: "53",
  },
  {
    ISO: "CY",
    name: "CYPRUS",
    code: "357",
  },
  {
    ISO: "CZ",
    name: "CZECH REPUBLIC",
    code: "420",
  },
  {
    ISO: "DK",
    name: "DENMARK",
    code: "45",
  },
  {
    ISO: "DJ",
    name: "DJIBOUTI",
    code: "253",
  },
  {
    ISO: "DM",
    name: "DOMINICA",
    code: "1767",
  },
  {
    ISO: "DO",
    name: "DOMINICAN REPUBLIC",
    code: "1809",
  },
  {
    ISO: "EC",
    name: "ECUADOR",
    code: "593",
  },
  {
    ISO: "EG",
    name: "EGYPT",
    code: "20",
  },
  {
    ISO: "SV",
    name: "EL SALVADOR",
    code: "503",
  },
  {
    ISO: "GQ",
    name: "EQUATORIAL GUINEA",
    code: "240",
  },
  {
    ISO: "ER",
    name: "ERITREA",
    code: "291",
  },
  {
    ISO: "EE",
    name: "ESTONIA",
    code: "372",
  },
  {
    ISO: "ET",
    name: "ETHIOPIA",
    code: "251",
  },
  {
    ISO: "FK",
    name: "FALKLAND ISLANDS (MALVINAS)",
    code: "500",
  },
  {
    ISO: "FO",
    name: "FAROE ISLANDS",
    code: "298",
  },
  {
    ISO: "FJ",
    name: "FIJI",
    code: "679",
  },
  {
    ISO: "FI",
    name: "FINLAND",
    code: "358",
  },
  {
    ISO: "FR",
    name: "FRANCE",
    code: "33",
  },
  {
    ISO: "PF",
    name: "FRENCH POLYNESIA",
    code: "689",
  },
  {
    ISO: "GA",
    name: "GABON",
    code: "241",
  },
  {
    ISO: "GM",
    name: "GAMBIA",
    code: "220",
  },
  {
    ISO: "GE",
    name: "GEORGIA",
    code: "995",
  },
  {
    ISO: "DE",
    name: "GERMANY",
    code: "49",
  },
  {
    ISO: "GH",
    name: "GHANA",
    code: "233",
  },
  {
    ISO: "GI",
    name: "GIBRALTAR",
    code: "350",
  },
  {
    ISO: "GR",
    name: "GREECE",
    code: "30",
  },
  {
    ISO: "GL",
    name: "GREENLAND",
    code: "299",
  },
  {
    ISO: "GD",
    name: "GRENADA",
    code: "1473",
  },
  {
    ISO: "GU",
    name: "GUAM",
    code: "1671",
  },
  {
    ISO: "GT",
    name: "GUATEMALA",
    code: "502",
  },
  {
    ISO: "GN",
    name: "GUINEA",
    code: "224",
  },
  {
    ISO: "GW",
    name: "GUINEA-BISSAU",
    code: "245",
  },
  {
    ISO: "GY",
    name: "GUYANA",
    code: "592",
  },
  {
    ISO: "HT",
    name: "HAITI",
    code: "509",
  },
  {
    ISO: "VA",
    name: "HOLY SEE (VATICAN CITY STATE)",
    code: "39",
  },
  {
    ISO: "HN",
    name: "HONDURAS",
    code: "504",
  },
  {
    ISO: "HK",
    name: "HONG KONG",
    code: "852",
  },
  {
    ISO: "HU",
    name: "HUNGARY",
    code: "36",
  },
  {
    ISO: "IS",
    name: "ICELAND",
    code: "354",
  },
  {
    ISO: "IN",
    name: "INDIA",
    code: "91",
  },
  {
    ISO: "ID",
    name: "INDONESIA",
    code: "62",
  },
  {
    ISO: "IR",
    name: "IRAN, ISLAMIC REPUBLIC OF",
    code: "98",
  },
  {
    ISO: "IQ",
    name: "IRAQ",
    code: "964",
  },
  {
    ISO: "IE",
    name: "IRELAND",
    code: "353",
  },
  {
    ISO: "IM",
    name: "ISLE OF MAN",
    code: "44",
  },
  {
    ISO: "IL",
    name: "ISRAEL",
    code: "972",
  },
  {
    ISO: "IT",
    name: "ITALY",
    code: "39",
  },
  {
    ISO: "JM",
    name: "JAMAICA",
    code: "1876",
  },
  {
    ISO: "JP",
    name: "JAPAN",
    code: "81",
  },
  {
    ISO: "JO",
    name: "JORDAN",
    code: "962",
  },
  {
    ISO: "KZ",
    name: "KAZAKSTAN",
    code: "7",
  },
  {
    ISO: "KE",
    name: "KENYA",
    code: "254",
  },
  {
    ISO: "KI",
    name: "KIRIBATI",
    code: "686",
  },
  {
    ISO: "KP",
    name: "KOREA DEMOCRATIC PEOPLES REPUBLIC OF",
    code: "850",
  },
  {
    ISO: "KR",
    name: "KOREA REPUBLIC OF",
    code: "82",
  },
  {
    ISO: "XK",
    name: "KOSOVO",
    code: "381",
  },
  {
    ISO: "KW",
    name: "KUWAIT",
    code: "965",
  },
  {
    ISO: "KG",
    name: "KYRGYZSTAN",
    code: "996",
  },
  {
    ISO: "LA",
    name: "LAO PEOPLES DEMOCRATIC REPUBLIC",
    code: "856",
  },
  {
    ISO: "LV",
    name: "LATVIA",
    code: "371",
  },
  {
    ISO: "LB",
    name: "LEBANON",
    code: "961",
  },
  {
    ISO: "LS",
    name: "LESOTHO",
    code: "266",
  },
  {
    ISO: "LR",
    name: "LIBERIA",
    code: "231",
  },
  {
    ISO: "LY",
    name: "LIBYAN ARAB JAMAHIRIYA",
    code: "218",
  },
  {
    ISO: "LI",
    name: "LIECHTENSTEIN",
    code: "423",
  },
  {
    ISO: "LT",
    name: "LITHUANIA",
    code: "370",
  },
  {
    ISO: "LU",
    name: "LUXEMBOURG",
    code: "352",
  },
  {
    ISO: "MO",
    name: "MACAU",
    code: "853",
  },
  {
    ISO: "MK",
    name: "MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF",
    code: "389",
  },
  {
    ISO: "MG",
    name: "MADAGASCAR",
    code: "261",
  },
  {
    ISO: "MW",
    name: "MALAWI",
    code: "265",
  },
  {
    ISO: "MY",
    name: "MALAYSIA",
    code: "60",
  },
  {
    ISO: "MV",
    name: "MALDIVES",
    code: "960",
  },
  {
    ISO: "ML",
    name: "MALI",
    code: "223",
  },
  {
    ISO: "MT",
    name: "MALTA",
    code: "356",
  },
  {
    ISO: "MH",
    name: "MARSHALL ISLANDS",
    code: "692",
  },
  {
    ISO: "MR",
    name: "MAURITANIA",
    code: "222",
  },
  {
    ISO: "MU",
    name: "MAURITIUS",
    code: "230",
  },
  {
    ISO: "YT",
    name: "MAYOTTE",
    code: "262",
  },
  {
    ISO: "MX",
    name: "MEXICO",
    code: "52",
  },
  {
    ISO: "FM",
    name: "MICRONESIA, FEDERATED STATES OF",
    code: "691",
  },
  {
    ISO: "MD",
    name: "MOLDOVA, REPUBLIC OF",
    code: "373",
  },
  {
    ISO: "MC",
    name: "MONACO",
    code: "377",
  },
  {
    ISO: "MN",
    name: "MONGOLIA",
    code: "976",
  },
  {
    ISO: "ME",
    name: "MONTENEGRO",
    code: "382",
  },
  {
    ISO: "MS",
    name: "MONTSERRAT",
    code: "1664",
  },
  {
    ISO: "MA",
    name: "MOROCCO",
    code: "212",
  },
  {
    ISO: "MZ",
    name: "MOZAMBIQUE",
    code: "258",
  },
  {
    ISO: "MM",
    name: "MYANMAR",
    code: "95",
  },
  {
    ISO: "NA",
    name: "NAMIBIA",
    code: "264",
  },
  {
    ISO: "NR",
    name: "NAURU",
    code: "674",
  },
  {
    ISO: "NP",
    name: "NEPAL",
    code: "977",
  },
  {
    ISO: "NL",
    name: "NETHERLANDS",
    code: "31",
  },
  {
    ISO: "NC",
    name: "NEW CALEDONIA",
    code: "687",
  },
  {
    ISO: "NZ",
    name: "NEW ZEALAND",
    code: "64",
  },
  {
    ISO: "NI",
    name: "NICARAGUA",
    code: "505",
  },
  {
    ISO: "NE",
    name: "NIGER",
    code: "227",
  },
  {
    ISO: "NG",
    name: "NIGERIA",
    code: "234",
  },
  {
    ISO: "NU",
    name: "NIUE",
    code: "683",
  },
  {
    ISO: "MP",
    name: "NORTHERN MARIANA ISLANDS",
    code: "1670",
  },
  {
    ISO: "NO",
    name: "NORWAY",
    code: "47",
  },
  {
    ISO: "OM",
    name: "OMAN",
    code: "968",
  },
  {
    ISO: "PK",
    name: "PAKISTAN",
    code: "92",
  },
  {
    ISO: "PW",
    name: "PALAU",
    code: "680",
  },
  {
    ISO: "PA",
    name: "PANAMA",
    code: "507",
  },
  {
    ISO: "PG",
    name: "PAPUA NEW GUINEA",
    code: "675",
  },
  {
    ISO: "PY",
    name: "PARAGUAY",
    code: "595",
  },
  {
    ISO: "PE",
    name: "PERU",
    code: "51",
  },
  {
    ISO: "PH",
    name: "PHILIPPINES",
    code: "63",
  },
  {
    ISO: "PN",
    name: "PITCAIRN",
    code: "870",
  },
  {
    ISO: "PL",
    name: "POLAND",
    code: "48",
  },
  {
    ISO: "PT",
    name: "PORTUGAL",
    code: "351",
  },
  {
    ISO: "PR",
    name: "PUERTO RICO",
    code: "1",
  },
  {
    ISO: "QA",
    name: "QATAR",
    code: "974",
  },
  {
    ISO: "RO",
    name: "ROMANIA",
    code: "40",
  },
  {
    ISO: "RU",
    name: "RUSSIAN FEDERATION",
    code: "7",
  },
  {
    ISO: "RW",
    name: "RWANDA",
    code: "250",
  },
  {
    ISO: "SH",
    name: "SAINT HELENA",
    code: "290",
  },
  {
    ISO: "KN",
    name: "SAINT KITTS AND NEVIS",
    code: "1869",
  },
  {
    ISO: "LC",
    name: "SAINT LUCIA",
    code: "1758",
  },
  {
    ISO: "MF",
    name: "SAINT MARTIN",
    code: "1599",
  },
  {
    ISO: "PM",
    name: "SAINT PIERRE AND MIQUELON",
    code: "508",
  },
  {
    ISO: "VC",
    name: "SAINT VINCENT AND THE GRENADINES",
    code: "1784",
  },
  {
    ISO: "WS",
    name: "SAMOA",
    code: "685",
  },
  {
    ISO: "SM",
    name: "SAN MARINO",
    code: "378",
  },
  {
    ISO: "ST",
    name: "SAO TOME AND PRINCIPE",
    code: "239",
  },
  {
    ISO: "SA",
    name: "SAUDI ARABIA",
    code: "966",
  },
  {
    ISO: "SN",
    name: "SENEGAL",
    code: "221",
  },
  {
    ISO: "RS",
    name: "SERBIA",
    code: "381",
  },
  {
    ISO: "SC",
    name: "SEYCHELLES",
    code: "248",
  },
  {
    ISO: "SL",
    name: "SIERRA LEONE",
    code: "232",
  },
  {
    ISO: "SG",
    name: "SINGAPORE",
    code: "65",
  },
  {
    ISO: "SK",
    name: "SLOVAKIA",
    code: "421",
  },
  {
    ISO: "SI",
    name: "SLOVENIA",
    code: "386",
  },
  {
    ISO: "SB",
    name: "SOLOMON ISLANDS",
    code: "677",
  },
  {
    ISO: "SO",
    name: "SOMALIA",
    code: "252",
  },
  {
    ISO: "ZA",
    name: "SOUTH AFRICA",
    code: "27",
  },
  {
    ISO: "ES",
    name: "SPAIN",
    code: "34",
  },
  {
    ISO: "LK",
    name: "SRI LANKA",
    code: "94",
  },
  {
    ISO: "SD",
    name: "SUDAN",
    code: "249",
  },
  {
    ISO: "SR",
    name: "SURINAME",
    code: "597",
  },
  {
    ISO: "SZ",
    name: "SWAZILAND",
    code: "268",
  },
  {
    ISO: "SE",
    name: "SWEDEN",
    code: "46",
  },
  {
    ISO: "CH",
    name: "SWITZERLAND",
    code: "41",
  },
  {
    ISO: "SY",
    name: "SYRIAN ARAB REPUBLIC",
    code: "963",
  },
  {
    ISO: "TW",
    name: "TAIWAN, PROVINCE OF CHINA",
    code: "886",
  },
  {
    ISO: "TJ",
    name: "TAJIKISTAN",
    code: "992",
  },
  {
    ISO: "TZ",
    name: "TANZANIA, UNITED REPUBLIC OF",
    code: "255",
  },
  {
    ISO: "TH",
    name: "THAILAND",
    code: "66",
  },
  {
    ISO: "TL",
    name: "TIMOR-LESTE",
    code: "670",
  },
  {
    ISO: "TG",
    name: "TOGO",
    code: "228",
  },
  {
    ISO: "TK",
    name: "TOKELAU",
    code: "690",
  },
  {
    ISO: "TO",
    name: "TONGA",
    code: "676",
  },
  {
    ISO: "TT",
    name: "TRINIDAD AND TOBAGO",
    code: "1868",
  },
  {
    ISO: "TN",
    name: "TUNISIA",
    code: "216",
  },
  {
    ISO: "TR",
    name: "TURKEY",
    code: "90",
  },
  {
    ISO: "TM",
    name: "TURKMENISTAN",
    code: "993",
  },
  {
    ISO: "TC",
    name: "TURKS AND CAICOS ISLANDS",
    code: "1649",
  },
  {
    ISO: "TV",
    name: "TUVALU",
    code: "688",
  },
  {
    ISO: "UG",
    name: "UGANDA",
    code: "256",
  },
  {
    ISO: "UA",
    name: "UKRAINE",
    code: "380",
  },
  {
    ISO: "AE",
    name: "UNITED ARAB EMIRATES",
    code: "971",
  },
  {
    ISO: "GB",
    name: "UNITED KINGDOM",
    code: "44",
  },
  {
    ISO: "US",
    name: "UNITED STATES",
    code: "1",
  },
  {
    ISO: "UY",
    name: "URUGUAY",
    code: "598",
  },
  {
    ISO: "UZ",
    name: "UZBEKISTAN",
    code: "998",
  },
  {
    ISO: "VU",
    name: "VANUATU",
    code: "678",
  },
  {
    ISO: "VE",
    name: "VENEZUELA",
    code: "58",
  },
  {
    ISO: "VN",
    name: "VIET NAM",
    code: "84",
  },
  {
    ISO: "VG",
    name: "VIRGIN ISLANDS, BRITISH",
    code: "1284",
  },
  {
    ISO: "VI",
    name: "VIRGIN ISLANDS, U.S.",
    code: "1340",
  },
  {
    ISO: "WF",
    name: "WALLIS AND FUTUNA",
    code: "681",
  },
  {
    ISO: "YE",
    name: "YEMEN",
    code: "967",
  },
  {
    ISO: "ZM",
    name: "ZAMBIA",
    code: "260",
  },
  {
    ISO: "ZW",
    name: "ZIMBABWE",
    code: "263",
  },
];

// [
//   { ISO: "AD", name: "ANDORRA", code: "376" },
//   { ISO: "AE", name: "UNITED ARAB EMIRATES", code: "971" },
//   { ISO: "AF", name: "AFGHANISTAN", code: "93" },
//   { ISO: "AG", name: "ANTIGUA AND BARBUDA", code: "1268" },
//   { ISO: "AI", name: "ANGUILLA", code: "1264" },
//   { ISO: "AL", name: "ALBANIA", code: "355" },
//   { ISO: "AM", name: "ARMENIA", code: "374" },
//   { ISO: "AN", name: "ANTILLES NETHERLANDS", code: "599" },
//   { ISO: "AO", name: "ANGOLA", code: "244" },
//   { ISO: "AQ", name: "ANTARCTICA", code: "672" },
//   { ISO: "AR", name: "ARGENTINA", code: "54" },
//   { ISO: "AS", name: "AMERICAN SAMOA", code: "1684" },
//   { ISO: "AT", name: "AUSTRIA", code: "43" },
//   { ISO: "AU", name: "AUSTRALIA", code: "61" },
//   { ISO: "AW", name: "ARUBA", code: "297" },
//   { ISO: "AZ", name: "AZERBAIJAN", code: "994" },
//   { ISO: "BA", name: "BOSNIA AND HERZEGOVINA", code: "387" },
//   { ISO: "BB", name: "BARBADOS", code: "1246" },
//   { ISO: "BD", name: "BANGLADESH", code: "880" },
//   { ISO: "BE", name: "BELGIUM", code: "32" },
//   { ISO: "BF", name: "BURKINA FASO", code: "226" },
//   { ISO: "BG", name: "BULGARIA", code: "359" },
//   { ISO: "BH", name: "BAHRAIN", code: "973" },
//   { ISO: "BI", name: "BURUNDI", code: "257" },
//   { ISO: "BJ", name: "BENIN", code: "229" },
//   { ISO: "BL", name: "BARTHELEMY, SAINT", code: "590" },
//   { ISO: "BM", name: "BERMUDA", code: "1441" },
//   { ISO: "BN", name: "BRUNEI DARUSSALAM", code: "673" },
//   { ISO: "BO", name: "BOLIVIA", code: "591" },
//   { ISO: "BR", name: "BRAZIL", code: "55" },
//   { ISO: "BS", name: "BAHAMAS", code: "1242" },
//   { ISO: "BT", name: "BHUTAN", code: "975" },
//   { ISO: "BW", name: "BOTSWANA", code: "267" },
//   { ISO: "BY", name: "BELARUS", code: "375" },
//   { ISO: "BZ", name: "BELIZE", code: "501" },
//   { ISO: "CA", name: "CANADA", code: "1" },
//   { ISO: "CC", name: "COCOS (KEELING) ISLANDS", code: "61" },
//   { ISO: "CD", name: "CONGO, THE DEMOCRATIC REPUBLIC OF THE", code: "243" },
//   { ISO: "CF", name: "CENTRAL AFRICAN REPUBLIC", code: "236" },
//   { ISO: "CG", name: "CONGO", code: "242" },
//   { ISO: "CI", name: "COTE D IVOIRE", code: "225" },
//   { ISO: "CK", name: "COOK ISLANDS", code: "682" },
//   { ISO: "CL", name: "CHILE", code: "56" },
//   { ISO: "CM", name: "CAMEROON", code: "237" },
//   { ISO: "CN", name: "CHINA", code: "86" },
//   { ISO: "CO", name: "COLOMBIA", code: "57" },
//   { ISO: "CR", name: "COSTA RICA", code: "506" },
//   { ISO: "CU", name: "CUBA", code: "53" },
//   { ISO: "CV", name: "CAPE VERDE", code: "238" },
//   { ISO: "CX", name: "CHRISTMAS ISLAND", code: "61" },
//   { ISO: "CY", name: "CYPRUS", code: "357" },
//   { ISO: "CZ", name: "CZECH REPUBLIC", code: "420" },
//   { ISO: "DE", name: "GERMANY", code: "49" },
//   { ISO: "DJ", name: "DJIBOUTI", code: "253" },
//   { ISO: "DK", name: "DENMARK", code: "45" },
//   { ISO: "DM", name: "DOMINICA", code: "1767" },
//   { ISO: "DO", name: "DOMINICAN REPUBLIC", code: "1809" },
//   { ISO: "DZ", name: "ALGERIA", code: "213" },
//   { ISO: "EC", name: "ECUADOR", code: "593" },
//   { ISO: "EE", name: "ESTONIA", code: "372" },
//   { ISO: "EG", name: "EGYPT", code: "20" },
//   { ISO: "ER", name: "ERITREA", code: "291" },
//   { ISO: "ES", name: "SPAIN", code: "34" },
//   { ISO: "ET", name: "ETHIOPIA", code: "251" },
//   { ISO: "FI", name: "FINLAND", code: "358" },
//   { ISO: "FJ", name: "FIJI", code: "679" },
//   { ISO: "FK", name: "FALKLAND ISLANDS (MALVINAS)", code: "500" },
//   { ISO: "FM", name: "MICRONESIA, FEDERATED STATES OF", code: "691" },
//   { ISO: "FO", name: "FAROE ISLANDS", code: "298" },
//   { ISO: "FR", name: "FRANCE", code: "33" },
//   { ISO: "GA", name: "GABON", code: "241" },
//   { ISO: "GB", name: "UNITED KINGDOM", code: "44" },
//   { ISO: "GD", name: "GRENADA", code: "1473" },
//   { ISO: "GE", name: "GEORGIA", code: "995" },
//   { ISO: "GH", name: "GHANA", code: "233" },
//   { ISO: "GI", name: "GIBRALTAR", code: "350" },
//   { ISO: "GL", name: "GREENLAND", code: "299" },
//   { ISO: "GM", name: "GAMBIA", code: "220" },
//   { ISO: "GN", name: "GUINEA", code: "224" },
//   { ISO: "GQ", name: "EQUATORIAL GUINEA", code: "240" },
//   { ISO: "GR", name: "GREECE", code: "30" },
//   { ISO: "GT", name: "GUATEMALA", code: "502" },
//   { ISO: "GU", name: "GUAM", code: "1671" },
//   { ISO: "GW", name: "GUINEA-BISSAU", code: "245" },
//   { ISO: "GY", name: "GUYANA", code: "592" },
//   { ISO: "HK", name: "HONG KONG", code: "852" },
//   { ISO: "HN", name: "HONDURAS", code: "504" },
//   { ISO: "HR", name: "CROATIA", code: "385" },
//   { ISO: "HT", name: "HAITI", code: "509" },
//   { ISO: "HU", name: "HUNGARY", code: "36" },
//   { ISO: "ID", name: "INDONESIA", code: "62" },
//   { ISO: "IE", name: "IRELAND", code: "353" },
//   { ISO: "IL", name: "ISRAEL", code: "972" },
//   { ISO: "IM", name: "ISLE OF MAN", code: "44" },
//   { ISO: "IN", name: "INDIA", code: "91" },
//   { ISO: "IQ", name: "IRAQ", code: "964" },
//   { ISO: "IR", name: "IRAN, ISLAMIC REPUBLIC OF", code: "98" },
//   { ISO: "IS", name: "ICELAND", code: "354" },
//   { ISO: "IT", name: "ITALY", code: "39" },
//   { ISO: "JM", name: "JAMAICA", code: "1876" },
//   { ISO: "JO", name: "JORDAN", code: "962" },
//   { ISO: "JP", name: "JAPAN", code: "81" },
//   { ISO: "KE", name: "KENYA", code: "254" },
//   { ISO: "KG", name: "KYRGYZSTAN", code: "996" },
//   { ISO: "KH", name: "CAMBODIA", code: "855" },
//   { ISO: "KI", name: "KIRIBATI", code: "686" },
//   { ISO: "KM", name: "COMOROS", code: "269" },
//   { ISO: "KN", name: "SAINT KITTS AND NEVIS", code: "1869" },
//   { ISO: "KP", name: "KOREA DEMOCRATIC PEOPLES REPUBLIC OF", code: "850" },
//   { ISO: "KR", name: "KOREA REPUBLIC OF", code: "82" },
//   { ISO: "KW", name: "KUWAIT", code: "965" },
//   { ISO: "KY", name: "CAYMAN ISLANDS", code: "1345" },
//   { ISO: "KZ", name: "KAZAKSTAN", code: "7" },
//   { ISO: "LA", name: "LAO PEOPLES DEMOCRATIC REPUBLIC", code: "856" },
//   { ISO: "LB", name: "LEBANON", code: "961" },
//   { ISO: "LC", name: "SAINT LUCIA", code: "1758" },
//   { ISO: "LI", name: "LIECHTENSTEIN", code: "423" },
//   { ISO: "LK", name: "SRI LANKA", code: "94" },
//   { ISO: "LR", name: "LIBERIA", code: "231" },
//   { ISO: "LS", name: "LESOTHO", code: "266" },
//   { ISO: "LT", name: "LITHUANIA", code: "370" },
//   { ISO: "LU", name: "LUXEMBOURG", code: "352" },
//   { ISO: "LV", name: "LATVIA", code: "371" },
//   { ISO: "LY", name: "LIBYAN ARAB JAMAHIRIYA", code: "218" },
//   { ISO: "MA", name: "MOROCCO", code: "212" },
//   { ISO: "MC", name: "MONACO", code: "377" },
//   { ISO: "MD", name: "MOLDOVA, REPUBLIC OF", code: "373" },
//   { ISO: "ME", name: "MONTENEGRO", code: "382" },
//   { ISO: "MF", name: "SAINT MARTIN", code: "1599" },
//   { ISO: "MG", name: "MADAGASCAR", code: "261" },
//   { ISO: "MH", name: "MARSHALL ISLANDS", code: "692" },
//   {
//     ISO: "MK",
//     name: "MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF",
//     code: "389",
//   },
//   { ISO: "ML", name: "MALI", code: "223" },
//   { ISO: "MM", name: "MYANMAR", code: "95" },
//   { ISO: "MN", name: "MONGOLIA", code: "976" },
//   { ISO: "MO", name: "MACAU", code: "853" },
//   { ISO: "MP", name: "NORTHERN MARIANA ISLANDS", code: "1670" },
//   { ISO: "MR", name: "MAURITANIA", code: "222" },
//   { ISO: "MS", name: "MONTSERRAT", code: "1664" },
//   { ISO: "MT", name: "MALTA", code: "356" },
//   { ISO: "MU", name: "MAURITIUS", code: "230" },
//   { ISO: "MV", name: "MALDIVES", code: "960" },
//   { ISO: "MW", name: "MALAWI", code: "265" },
//   { ISO: "MX", name: "MEXICO", code: "52" },
//   { ISO: "MY", name: "MALAYSIA", code: "60" },
//   { ISO: "MZ", name: "MOZAMBIQUE", code: "258" },
//   { ISO: "NA", name: "NAMIBIA", code: "264" },
//   { ISO: "NC", name: "NEW CALEDONIA", code: "687" },
//   { ISO: "NE", name: "NIGER", code: "227" },
//   { ISO: "NG", name: "NIGERIA", code: "234" },
//   { ISO: "NI", name: "NICARAGUA", code: "505" },
//   { ISO: "NL", name: "NETHERLANDS", code: "31" },
//   { ISO: "NO", name: "NORWAY", code: "47" },
//   { ISO: "NP", name: "NEPAL", code: "977" },
//   { ISO: "NR", name: "NAURU", code: "674" },
//   { ISO: "NU", name: "NIUE", code: "683" },
//   { ISO: "NZ", name: "NEW ZEALAND", code: "64" },
//   { ISO: "OM", name: "OMAN", code: "968" },
//   { ISO: "PA", name: "PANAMA", code: "507" },
//   { ISO: "PE", name: "PERU", code: "51" },
//   { ISO: "PF", name: "FRENCH POLYNESIA", code: "689" },
//   { ISO: "PG", name: "PAPUA NEW GUINEA", code: "675" },
//   { ISO: "PH", name: "PHILIPPINES", code: "63" },
//   { ISO: "PK", name: "PAKISTAN", code: "92" },
//   { ISO: "PL", name: "POLAND", code: "48" },
//   { ISO: "PM", name: "SAINT PIERRE AND MIQUELON", code: "508" },
//   { ISO: "PN", name: "PITCAIRN", code: "870" },
//   { ISO: "PR", name: "PUERTO RICO", code: "1" },
//   { ISO: "PT", name: "PORTUGAL", code: "351" },
//   { ISO: "PW", name: "PALAU", code: "680" },
//   { ISO: "PY", name: "PARAGUAY", code: "595" },
//   { ISO: "QA", name: "QATAR", code: "974" },
//   { ISO: "RO", name: "ROMANIA", code: "40" },
//   { ISO: "RS", name: "SERBIA", code: "381" },
//   { ISO: "RU", name: "RUSSIAN FEDERATION", code: "7" },
//   { ISO: "RW", name: "RWANDA", code: "250" },
//   { ISO: "SA", name: "SAUDI ARABIA", code: "966" },
//   { ISO: "SB", name: "SOLOMON ISLANDS", code: "677" },
//   { ISO: "SC", name: "SEYCHELLES", code: "248" },
//   { ISO: "SD", name: "SUDAN", code: "249" },
//   { ISO: "SE", name: "SWEDEN", code: "46" },
//   { ISO: "SG", name: "SINGAPORE", code: "65" },
//   { ISO: "SH", name: "SAINT HELENA", code: "290" },
//   { ISO: "SI", name: "SLOVENIA", code: "386" },
//   { ISO: "SK", name: "SLOVAKIA", code: "421" },
//   { ISO: "SL", name: "SIERRA LEONE", code: "232" },
//   { ISO: "SM", name: "SAN MARINO", code: "378" },
//   { ISO: "SN", name: "SENEGAL", code: "221" },
//   { ISO: "SO", name: "SOMALIA", code: "252" },
//   { ISO: "SR", name: "SURINAME", code: "597" },
//   { ISO: "ST", name: "SAO TOME AND PRINCIPE", code: "239" },
//   { ISO: "SV", name: "EL SALVADOR", code: "503" },
//   { ISO: "SY", name: "SYRIAN ARAB REPUBLIC", code: "963" },
//   { ISO: "SZ", name: "SWAZILAND", code: "268" },
//   { ISO: "CH", name: "SWITZERLAND", code: "41" },
//   { ISO: "TC", name: "TURKS AND CAICOS ISLANDS", code: "1649" },
//   { ISO: "TD", name: "CHAD", code: "235" },
//   { ISO: "TG", name: "TOGO", code: "228" },
//   { ISO: "TH", name: "THAILAND", code: "66" },
//   { ISO: "TJ", name: "TAJIKISTAN", code: "992" },
//   { ISO: "TK", name: "TOKELAU", code: "690" },
//   { ISO: "TL", name: "TIMOR-LESTE", code: "670" },
//   { ISO: "TM", name: "TURKMENISTAN", code: "993" },
//   { ISO: "TN", name: "TUNISIA", code: "216" },
//   { ISO: "TO", name: "TONGA", code: "676" },
//   { ISO: "TR", name: "TURKEY", code: "90" },
//   { ISO: "TT", name: "TRINIDAD AND TOBAGO", code: "1868" },
//   { ISO: "TV", name: "TUVALU", code: "688" },
//   { ISO: "TW", name: "TAIWAN, PROVINCE OF CHINA", code: "886" },
//   { ISO: "TZ", name: "TANZANIA, UNITED REPUBLIC OF", code: "255" },
//   { ISO: "UA", name: "UKRAINE", code: "380" },
//   { ISO: "UG", name: "UGANDA", code: "256" },
//   { ISO: "US", name: "UNITED STATES", code: "1" },
//   { ISO: "UY", name: "URUGUAY", code: "598" },
//   { ISO: "UZ", name: "UZBEKISTAN", code: "998" },
//   { ISO: "VA", name: "HOLY SEE (VATICAN CITY STATE)", code: "39" },
//   { ISO: "VC", name: "SAINT VINCENT AND THE GRENADINES", code: "1784" },
//   { ISO: "VE", name: "VENEZUELA", code: "58" },
//   { ISO: "VG", name: "VIRGIN ISLANDS, BRITISH", code: "1284" },
//   { ISO: "VI", name: "VIRGIN ISLANDS, U.S.", code: "1340" },
//   { ISO: "VN", name: "VIET NAM", code: "84" },
//   { ISO: "VU", name: "VANUATU", code: "678" },
//   { ISO: "WF", name: "WALLIS AND FUTUNA", code: "681" },
//   { ISO: "WS", name: "SAMOA", code: "685" },
//   { ISO: "XK", name: "KOSOVO", code: "381" },
//   { ISO: "YE", name: "YEMEN", code: "967" },
//   { ISO: "YT", name: "MAYOTTE", code: "262" },
//   { ISO: "ZA", name: "SOUTH AFRICA", code: "27" },
//   { ISO: "ZM", name: "ZAMBIA", code: "260" },
//   { ISO: "ZW", name: "ZIMBABWE", code: "263" },
// ];
