import React from "react";
import Colors from "../../constants/colors";

function UpNext(props) {
  return (
    <div
      style={{
        border: "1px solid" + Colors.white_1,
        borderRadius: "4px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: ".5em",
        cursor: "pointer",
        background: Colors.white,
        margin: 0,
      }}
    >
      <p
        style={{
          fontFamily: "CircularStd",
          color: Colors.purple,
          fontSize: ".6em",
        }}
      >
        Up Next
      </p>
      <div style={{ margin: "0 .5em" }}>
        <p
          style={{
            fontFamily: "CircularStdBold",
            color: Colors.black,
            fontSize: ".8em",
          }}
        >
          Add {props.next}
        </p>
      </div>
    </div>
  );
}

export default UpNext;
