import React from "react";
import { ReactComponent as ArrowLeft } from "../../assets/svgs/arrow-left-gray.svg";
import { ReactComponent as ArrowRight } from "../../assets/svgs/arrow-right-gray.svg";

const Paginator = ({
  limit = 0,
  count = 0,
  offset = 0,
  canGoNext = false,
  canGoPrevious = false,
  setOffset = () => {},
}) => {
  // const pageCount = Math.ceil(count / limit);

  const currentPage = offset / limit + 1;

  let limitAndOffset = limit + offset;

  const boundary = limitAndOffset > count ? count : limitAndOffset;

  const goNext = () => {
    if (offset + limit < count) {
      setOffset(offset + limit);
    }
  };

  const goPrevious = () => {
    if (offset - limit >= 0) {
      setOffset(offset - limit);
    }
  };

  return (
    <div
      className=" m-t-auto bg-w p-v-1 flex justify-between align-center p-h-2 r-8 bordered"
      style={{ position: "sticky", bottom: 0 }}
    >
      <div className="">
        <p className="gray m-0">Show {limit} items per page</p>
      </div>
      <div className="bordered-blacko flex">
        <div
          onClick={goPrevious}
          className="bordered p-h-1 p-v-p5 r-8 pointer bg-gray border-color-gray flex justify-center align-center"
        >
          <ArrowLeft width={".8em"} height={"1em"} />
        </div>
        <div className="bordered border-color-purple p-h-1 p-v-p5 r-8 m-h-p5 bg-purple pointer">
          <p className="m-0 white">{currentPage}</p>
        </div>
        <div
          onClick={goNext}
          className="bordered p-h-1 p-v-p5 r-8 pointer bg-gray border-color-gray"
        >
          <ArrowRight width={".8em"} height={"1em"} />
        </div>
      </div>
      <div className="">
        <p className="m-0 gray">
          Showing {offset} {"–"} {boundary} of {count}
        </p>
      </div>
    </div>
  );
};

export default Paginator;
