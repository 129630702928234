import React from "react";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faFacebookF,
  faFacebookSquare,
  faGithubSquare,
  faInstagram,
  faLinkedin,
  faTwitter,
  faTwitterSquare,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

import {
  faAngleRight,
  faAngleLeft,
  faArrowLeft,
  faArrowRight,
  faBars,
  faAngleDown,
  faSearch,
  faCaretDown,
  faClose,
  faFilter,
  faEye as solidEye,
  faTachometer,
  faChartLine,
  faPlusCircle,
  faCog,
  faAddressBook,
  faBell,
  faUnlockAlt,
  faTriangleExclamation,
  faCircleExclamation,
  faCartPlus,
  faHouse,
  faCirclePlus,
  faRightFromBracket,
  faArrowRightFromBracket as s,
  faArrowRightFromBracket,
  faTableColumns,
  faMoneyCheck,
  faIdCard,
  faMobile,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

import {
  faTrashCan,
  faEye,
  faEyeSlash,
  faEdit,
  faBuilding,
  faPaperPlane,
  faDotCircle,
  faCircleUser,
  faCreditCard,
  faFilePdf,
  faFileImage,
  faCalendar,
} from "@fortawesome/free-regular-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import theme from "../../theme";

export const startIconLibrary = () => {
  library.add(
    faFacebookF,
    faFacebookSquare,
    faGithubSquare,
    faInstagram,
    faLinkedin,
    faTwitter,
    faTwitterSquare,
    faYoutube,
    faAngleRight,
    faAngleLeft,
    faAngleDown,
    faBars,
    faSearch,
    faClose,
    faCaretDown,
    faFilter,
    faArrowLeft,
    faTrashCan,
    faEye,
    faEdit,
    solidEye,
    faTachometer,
    faChartLine,
    faDotCircle,
    faPlusCircle,
    faCog,
    faBuilding,
    faPaperPlane,
    faAddressBook,
    faBell,
    faUnlockAlt,
    faEyeSlash,
    faTriangleExclamation,
    faCircleUser,
    faCartPlus,
    faHouse,
    faCirclePlus,
    faArrowRight,
    faRightFromBracket,
    faArrowRightFromBracket,
    faTableColumns,
    faMoneyCheck,
    faIdCard,
    faCreditCard,
    faMobile,
    faFilePdf,
    faFileImage,
    faCalendar,
    faCircleExclamation,
    faTimes
  );
};

const Icon = ({
  icon,
  size,
  color = theme.colors.secondary,
  style,
  className,
  ...rest
}) => {
  return (
    <FontAwesomeIcon
      {...rest}
      className={className}
      style={style}
      color={color}
      size={size}
      icon={icon}
    ></FontAwesomeIcon>
  );
};

export default Icon;
