import React, { useEffect, useState } from "react";
import { Table } from "../table";
import mock from "../../assets/mock/mock.json";
import { Colors } from "../../constants";
import { EmojiTextButton, TextButton } from "../general";
import { ReactComponent as QR } from "../../assets/svgs/Qrcode.svg";
import axios from "axios";
import { useAppContext } from "../../context";
import toast from "react-hot-toast";
import numeral from "numeral";
import moment from "moment";
import { useHistory, useParams } from "react-router-dom";
import { get } from "../../api/transport";

export const SuperAdminSubscriptionTable = props => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [fetch_loading, set_fetch_loading] = useState(true);
  const [transactions, set_transactions] = useState([]);
  const history = useHistory();
  const params = useParams();

  const fetchTransactions = async () => {
    set_fetch_loading(true);
    toast.loading("Fetching Transaction History...");
    try {
      const response = await get(
        `/admin/company/transaction/${params.companyid}`
      );
      const transactions = response.data.results;
      set_transactions(transactions);
      const last_transaction_object = transactions[transactions.length - 1];
      props.set_current_subscription(last_transaction_object);
      toast.success("Successfully fetched");
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    } finally {
      set_fetch_loading(false);
    }
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) fetchTransactions();
    return () => (mounted = false);
  }, []);

  const COLUMNS = [
    {
      Header: "TRANSACTION ID",
      accessor: "uuid",
      Cell: ({ value }) => value || "F923-AS91-131",
    },
    {
      Header: "PAYMENT METHOD",
      accessor: "payment_method",
      // Cell: () => "Debit Card",
    },
    {
      Header: "AMOUNT",
      accessor: "amount_paid",
      Cell: ({ value }) =>
        `GHS${numeral(Number(value) / 100).format("0,0.00")}`,
    },
    {
      Header: "STATUS",
      accessor: "status",
    },
    {
      Header: "DATE",
      accessor: "date_published",
      Cell: ({ value }) => moment(value).format("DD MMM, YYYY"),
    },
  ];

  // console.log(last_transaction);
  return (
    <>
      <div className="flex row justify-between align-center">
        <p className="bold">Billing History</p>
      </div>
      <Table
        columns={COLUMNS}
        data={[...transactions].reverse()}
        loading={fetch_loading}
      />
    </>
  );
};
