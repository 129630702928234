import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Table } from "../table";
import moment from "moment";
import { Colors } from "../../constants";
import { EmojiTextButton } from "../general";
import Searchbar from "../dashboard/Searchbar";
import { ReactComponent as Plus } from "../../assets/svgs/plus.svg";
import DotsButton from "../general/DotsButton";
import { AddBranchManagerModal } from "../modal";
import axios from "axios";
import { useAppContext } from "../../context";
import errorHandler from "../../selectors/errorHandler";
import UpdateBranchManagerModal from "../modal/UpdateBranchManager";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import { get, patch } from "../../api/transport";

export const BranchManagersTable = props => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [loading, set_loading] = useState(true);
  const [branch_fetch_loading, set_branch_fetch_loading] = useState(false);
  const [limit, set_limit] = useState(8);
  const [offset, set_offset] = useState(1);

  const [branch_managers, set_branch_managers] = useState([]);
  const [branches, set_branches] = useState([]);
  const [search_term, set_search_term] = useState("");

  const history = useHistory();

  useEffect(() => {
    let mounted = true;
    if (mounted && props.openModal && !modalIsOpen) {
      setModalIsOpen(true);
    }
    return () => {
      setModalIsOpen(false);
      mounted = false;
    };
  }, [props.openModal]);

  useEffect(() => {
    let mounted = true;
    if (mounted) fetchData();
    return () => {
      set_branch_managers([]);
      set_branches([]);
      set_loading(false);
      set_branch_fetch_loading(false);
      mounted = false;
    };
  }, []);

  const COLUMNS = [
    {
      Header: "MANAGER ID",
      accessor: "uuid",
      Cell: ({ value }) => {
        const [first_partition] = value.split("-");
        return first_partition.toUpperCase();
      },
    },
    {
      Header: "NAME",
      id: "name",
      Cell: data => {
        const { first_name, last_name } = data?.row?.original;

        return `${first_name} ${last_name}`;
      },
    },
    {
      Header: "EMAIL ADDRESS",
      accessor: "email",
    },
    {
      Header: "BRANCH",
      accessor: "branch.name",
      Cell: data => {
        const { branch, is_owner } = data?.row?.original;
        return branch?.name || (is_owner && <p className="green m-0">Admin</p>);
      },
    },
    {
      Header: "STATUS",
      accessor: "is_active",
      Cell: ({ value }) => {
        const render = (
          <p className={`p-0 ${value ? `green` : `light-grey`}`}>
            {value ? "Active" : "Deactivated"}
          </p>
        );
        return render;
      },
    },
    {
      Header: "DATE ADDED",
      accessor: "created_at",
      Cell: ({ value }) => moment(value).format("MMM D, YYYY, hh:mm a"),
    },
    {
      id: "action",
      Cell: data => {
        const branch_manager = data?.row.original;
        const [update_modal_open, set_update_modal_open] = useState(false);
        const actions = [
          {
            title: "Update Info",
            disabled: branch_manager.is_owner,
            style: {
              color: "#696969",
            },
            onClick: () => {
              if (branch_manager.is_owner)
                return history.push("/account/settings/account-information");
              return set_update_modal_open(true);
            },
          },
          {
            title: !!branch_manager.is_active
              ? "Deactivate Account"
              : "Reactivate Account",
            style: { color: branch_manager.is_active ? "red" : "green" },
            disabled: typeof branch_manager.is_active !== "boolean",
            onClick: () => {
              const { id } = branch_manager.branch;
              const patch_data = {
                is_active: !!!branch_manager.is_active,
                is_branch_manager: true,
                branch: id,
              };

              toast.loading("Please wait");
              patch(`/users/staff/${branch_manager.uuid}/`, patch_data)
                .then(response => {
                  const newSet = [...branch_managers].map(el => {
                    if (el.uuid === response.data.uuid) {
                      return {
                        ...el,
                        ...response.data,
                      };
                    }
                    return el;
                  });
                  set_branch_managers(newSet);
                  toast.success("Successfully Updated Branch Manager");
                })
                .catch(error => {
                  errorHandler(error);
                });
            },
          },
        ];

        return (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <DotsButton actions={actions} />
            {update_modal_open && (
              <UpdateBranchManagerModal
                isOpen={update_modal_open}
                closeModal={() => set_update_modal_open(false)}
                branchManager={branch_manager}
                branchManagers={branch_managers}
                setBranchManagers={set_branch_managers}
                branches={branches}
                setBranches={set_branches}
              />
            )}
          </div>
        );
      },
    },
  ];

  return (
    <div
      className="flex grow flex-column"
      style={{ maxHeight: "90vh", overflowY: "auto" }}
    >
      <div className="flex justify-between align-center p-v-1">
        <h4 style={{ margin: 0 }}>Branch Managers</h4>
        <div>
          <Searchbar term={search_term} filter={set_search_term} />
        </div>

        <div className="flex align-center justify-between">
          <EmojiTextButton
            text="Add a Branch Manager"
            IconProp={Plus}
            onClick={() => setModalIsOpen(true)}
            styleProp={{
              background: Colors.purple,
              color: Colors.white,
              padding: ".8em 2em",
              marginLeft: ".5em",
            }}
          />
        </div>
      </div>
      <AddBranchManagerModal
        isOpen={modalIsOpen}
        closeModal={() => setModalIsOpen(false)}
        primaryButtonText={"Continue"}
        branchManagers={branch_managers}
        setBranchManagers={set_branch_managers}
        branches={branches}
        setBranches={set_branches}
      />
      <Table
        columns={COLUMNS}
        loading={loading}
        data={useMemo(
          () => [...branch_managers?.reverse()],
          [branch_managers, search_term]
        )}
      />
    </div>
  );

  function fetchData() {
    const endpoints = ["/user/branch/", "/users/branch/managers"];

    Promise.all(endpoints.map(endpoint => get(endpoint)))
      .then(
        axios.spread((...allData) => {
          const [branch_data, branch_manager_data] = allData;
          set_branches(branch_data.data.results);
          set_branch_managers(branch_manager_data.data);
        })
      )
      .catch(error => errorHandler(error))
      .finally(() => set_loading(false));
  }
};
