import _ from "lodash";

export const determine_unit_price = number_tablets => {
  let packages = localStorage.getItem("echo-subscription-packages");

  if (!packages) return;

  packages = JSON.parse(packages);

  const pkg = packages.find(pkg => {
    const floor = pkg.min_number_tablet;
    const ceil = pkg.max_number_tablet;

    const is_in_range = _.inRange(number_tablets, floor, ceil + 1);

    return is_in_range;
  });

  if (pkg) {
    return pkg.unit_price;
  }

  return;
};

export const determine_subscription_package = number_tablets => {
  let packages = localStorage.getItem("echo-subscription-packages");

  if (!packages) return null;

  packages = JSON.parse(packages);

  const pkg = packages.find(pkg => {
    const floor = pkg.min_number_tablet;
    const ceil = pkg.max_number_tablet;

    const is_in_range = _.inRange(number_tablets, floor, ceil + 1);

    return is_in_range;
  });

  if (pkg) return pkg;

  return null;
};
