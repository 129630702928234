import React from "react";
import styled from "styled-components";
import { ReactComponent as Search } from "../../assets/svgs/search.svg";
import { Colors } from "../../constants";

const Searchbar = props => {
  // const [term, set_term] = useState("");
  const { term, filter = () => {} } = props;
  return (
    <StyledSearchbar>
      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Search />
      </div>
      <input
        placeholder="Search"
        value={term}
        onChange={e => {
          filter(e.target.value);
        }}
      />
    </StyledSearchbar>
  );
};

export default Searchbar;

const StyledSearchbar = styled.div`
  display: flex;
  width: 400px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #f5f3f3;
  box-sizing: border-box;
  border-radius: 4px;

  input {
    display: flex;
    flex: 9;
    border: 0;
    font-size: 1em;

    :focus {
      outline-color: ${props => Colors.purple};
    }
  }
`;
