import React, { useCallback } from "react";
import styled from "styled-components";
import { AreaChartComponent, Layout } from "../components/dashboard";
import { AnalyticsSmall } from "../components/overview";
import { OverviewTable } from "../components/overview/OverviewTable";
import { DocumentHeader } from "../components/general/DocumentHeader";
import { useSentimentOverview } from "../components/overview/broker";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";

const TopSection = styled.div`
  display: flex;
  min-height: 40vh;
  margin-bottom: 2em;

  .area-chart-left-section {
    display: flex;
    flex-basis: 60%;
    flex-direction: column;
    margin-right: 0.5em;
    gap: 1em;

    .title-section {
      // border: 1px solid;
      flex-basis: 10%;
      display: flex;
      align-items: center;
    }

    .chart-section {
      // border: 1px solid blue;
      flex-basis: 90%;
      display: flex;
    }
  }

  .pie-chart-right-section {
    display: flex;
    flex-basis: 40%;
    margin-left: 0.5em;
    // border: 1px solid;
  }

  @media (max-width: 1500px) {
    // flex-direction: column;

    .area-chart-left-section {
      flex-basis: 50%;
    }

    .pie-chart-right-section {
      flex-basis: 50%;
    }
  }
`;

const Overview = () => {
  const { data: overview = [], isLoading: isLoading } = useSentimentOverview(
    "",
    "desc",
    "month"
  );

  const formatDate = useCallback((arr = []) => {
    return arr
      ?.sort((a, b) => {
        if (moment(a.date).isBefore(moment(b.date))) {
          return -1;
        }
        return 1;
      })
      .map(el => {
        return {
          ...el,
          date: moment(el?.date)?.format("MMM Do | ha"),
        };
      });
  }, []);

  const collatedSentimentData = useCallback(
    overview?.reduce((prev, current) => {
      let dateExists = prev?.find(el =>
        moment(el?.date).isSame(current?.date_published, "day")
      );

      if (!dateExists) {
        return [
          ...prev,
          {
            uuid: uuidv4(),
            date: current?.date_published,
            sentiment_count: 1,
          },
        ];
      }

      dateExists = {
        ...dateExists,
        sentiment_count: Number(dateExists.sentiment_count) + 1,
      };

      let new_list = prev?.map(el => {
        if (el.uuid === dateExists.uuid) {
          return dateExists;
        }

        return el;
      });

      return new_list;
    }, []),
    [overview]
  );

  const initialPieData = [
    {
      uuid: uuidv4(),
      name: "Terrible",
      value: 0,
    },
    {
      uuid: uuidv4(),
      name: "Bad",
      value: 0,
    },
    {
      uuid: uuidv4(),
      name: "Okay",
      value: 0,
    },
    {
      uuid: uuidv4(),
      name: "Good",
      value: 0,
    },
    {
      uuid: uuidv4(),
      name: "Excellent",
      value: 0,
    },
  ];

  const collatedPieChartData = useCallback(
    overview?.reduce((prev, current) => {
      const currentSentimentTitle = current?.sentiment?.title;

      const new_list = prev?.map(el => {
        if (el.name === currentSentimentTitle) {
          return {
            ...el,
            value: el.value + 1,
          };
        }
        return el;
      });

      return new_list;
    }, initialPieData),
    [overview]
  );

  return (
    <Layout>
      <DocumentHeader page="Overview" />
      <TopSection>
        <div className="area-chart-left-section">
          <div className="title-section">
            <h2 className="m-0">Company Overview</h2>
          </div>
          <div className="chart-section">
            <AreaChartComponent
              data={formatDate(collatedSentimentData)}
              count={overview?.length}
              dataKey={"sentiment_count"}
              loading={isLoading}
              caption="Month-to-date"
            />
          </div>
        </div>
        <div className="pie-chart-right-section">
          <AnalyticsSmall
            data={collatedPieChartData}
            count={overview?.length}
            isLoading={isLoading}
          />
        </div>
      </TopSection>
      <div className="flex flex-column m-b-2">
        <OverviewTable />
      </div>
    </Layout>
  );
};

export { Overview };
