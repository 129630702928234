import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Colors from "../../constants/colors";
import { ReactComponent as Smiley } from "../../assets/svgs/Smiley.svg";
import { EmojiTextButton } from "../general";
import SelectEmojiModal from "../modal/SelectEmojiModal";
import { useDeviceSettingsContext } from "../../context/DeviceSettingsContext";

export const EmojisUploadField = ({ name, rating, emoji }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [preview, set_preview] = useState(undefined);

  const fileInputRef = useRef();

  const { action, actions, appState } = useDeviceSettingsContext();

  const { file: emoji_file, link } =
    appState.company_emojis[name.toLowerCase()];

  const toggleEmojiPreview = () => {
    if (emoji_file) {
      const reader = new FileReader();
      reader.onloadend = () => set_preview(reader.result);
      reader.readAsDataURL(emoji_file);
    } else {
      return set_preview(null);
    }
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) toggleEmojiPreview();
    return () => (mounted = false);
  }, [emoji_file]);

  const handleChange = event => {
    event.preventDefault();
    const file = event.target.files[0];
    const isImage = file && file.type.substr(0, 5) === "image";
    if (isImage) {
      action(actions.set_company_emojis, {
        title: name.toLowerCase(),
        data: {
          file,
        },
      });
    }
  };

  const handleUpload = event => {
    event.preventDefault();
    fileInputRef.current.click();
  };

  const buttonStyles = {
    left: {
      background: Colors.purple,
      color: Colors.white,
      padding: "1em .5em",
      margin: 0,
      display: "flex",
      flex: 1,
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
    },
    right: {
      color: Colors.purple,
      padding: "1em .5em",
      display: "flex",
      flex: 1,
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
    },
  };

  const img_src = preview || link || "";

  return (
    <StyledUploadField>
      <div className="flex flex-basis-20 justify-center align-center ">
        <div
          className="circle bg-w bordered flex justify-center aligm-center"
          style={{
            width: "4em",
            height: "4em",
          }}
        >
          {(emoji_file || link) && (
            <img
              alt="emoji"
              src={img_src}
              style={{
                width: "4em",
                height: "4em",
                objectFit: "cover",
              }}
              className="circle"
            />
          )}
        </div>
      </div>

      <div className="flex grow flex-column justify-between align-stretch ">
        <div className="m-b-p5">
          <p className="m-0">
            Upload an Emoji for
            <span className="bold"> "{`${name}`}"</span>
          </p>
        </div>

        <div className="flex flex-row">
          <div className="flex grow m-r-p5">
            <EmojiTextButton
              IconProp={Smiley}
              text={"Select Emoji"}
              onClick={() => setModalIsOpen(!modalIsOpen)}
              styleProp={buttonStyles.left}
            />
          </div>

          <div className="flex grow">
            <EmojiTextButton
              styleProp={buttonStyles.right}
              text={"Upload Emoji"}
              onClick={handleUpload}
            />
            <input
              className="hide"
              accept="image/*"
              type="file"
              ref={fileInputRef}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>

      <SelectEmojiModal
        isOpen={modalIsOpen}
        closeModal={() => setModalIsOpen(false)}
        primaryButtonText={"Continue"}
        data={{
          name,
          rating,
        }}
      />
    </StyledUploadField>
  );
};

const StyledUploadField = styled.div`
  display: flex;
  border: 1px dashed #d9dae4;
  box-sizing: border-box;
  border-radius: 6px;
  background: #fbf9f9;
  display: flex;
  margin-bottom: 1em;
  padding: 1em 0.5em;
`;
