import React from "react";
import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

export const ReactQueryProvider = ({ children }) => {
  const client = new QueryClient();

  return (
    <QueryClientProvider client={client}>
      <ReactQueryDevtools initialIsOpen={false} position={"bottom-right"} />
      {children}
    </QueryClientProvider>
  );
};
