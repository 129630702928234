import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

export default function SidebarItem({
  name,
  icon: Icon,
  route,
  slug,
  isActive,
  ...props
}) {
  const { pathname } = useLocation();
  // console.log(pathname, route);

  let linkProps = {
    to: route,
  };

  if (isActive) {
    linkProps = {
      ...linkProps,
      isActive,
    };
  }

  return (
    <NavLink
      {...linkProps}
      style={{
        textDecoration: "none",
        color: "#fff",
      }}
      key={Math.random()}
    >
      <SidebarItemParent
        className={pathname.includes(route) ? "active" : ""}
        {...props}
      >
        <Icon size="1.15em" style={{ marginRight: "1em" }} />
        <p>{name}</p>
      </SidebarItemParent>
    </NavLink>
  );
}

export const SidebarItemParent = styled.div`
  --alpha: 0;
  --color: hsla(0, 0%, 100%, var(--alpha));
  border-radius: 8px;
  padding: 0.8em 15px;
  font-size: 14px;
  display: flex;
  align-items: center;
  background-color: var(--color);
  transition: background-color 0.2s ease;
  // position: relative;

  &.active {
    --alpha: 0.08;
  }

  &:not(.active):hover {
    --alpha: 0.045;
  }
`;
