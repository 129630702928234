import React, { useState } from "react";
import { CompaniesTable, CompanyTabs } from "../../components/companies";
import { Layout } from "../../components/dashboard";
import axios from "axios";
import toast from "react-hot-toast";
import { useHistory, useParams } from "react-router-dom";
import BillCard from "../../components/account-creation/choose-subscription/BillCard";
import { TextButton } from "../../components/general";
import { SubscriptionTable } from "../../components/subscription/SubscriptionTable";
import { useAppContext } from "../../context";
import errorHandler from "../../selectors/errorHandler";
import { SuperAdminSubscriptionTable } from "../../components/subscription/AdminSubscriptionTable";
import { patch } from "../../api/transport";
import { DocumentHeader } from "../../components/general/DocumentHeader";

const Subscription = () => {
  const [current_subscription, set_current_subscription] = useState();
  const history = useHistory();
  const params = useParams();

  function cancel_subscription() {
    toast.loading("Cancelling...");
    const {
      number_tablet,
      billing_type,
      id,
      uuid,
      updated_at,
      date_published,
      date_updated,
      is_active,
      company,
      subscription,
      ...rest
    } = current_subscription;

    const patch_data = {
      ...rest,
      is_active: false,
      company: company.id,
      subscription: subscription.id,
    };

    patch(`/transaction/${id}/`, patch_data)
      .then(response => {
        toast.success("Subscription Canceled Successfully");
      })
      .catch(error => {
        errorHandler(error);
      });
  }
  return (
    <Layout tabs={CompanyTabs}>
      <DocumentHeader page="Subscription" />
      <div className="flex grow  m-v-1">
        <div className=" flex flex-basis-35 flex-column m-r-2">
          <div className="flex justify-between align-center">
            <p className="bold">Current Subscription</p>
          </div>
          <div className="flex grow bordered p-2 bg-w r-8 flex-column">
            <div className="form-row ">
              <div className="input-field">
                <label>Current Device Slots</label>
                <input
                  type="number"
                  placeholder="Current Device Slots"
                  value={current_subscription?.number_tablet}
                />
              </div>
            </div>

            <div className="form-row">
              <div className="input-field">
                <label>Billing Type</label>
                <select
                  disabled={true}
                  value={current_subscription?.billing_type}
                  placeholder="Eg. Monthly, Yearly, etc."
                >
                  <option value={"Month"}>Monthly</option>
                  <option value={"Year"}>Yearly</option>
                </select>
              </div>
            </div>

            <div className="form-row">
              <BillCard
                figure={current_subscription?.subscription?.unit_price}
                style={{ margin: 0, flex: 1 }}
              />
            </div>

            <div className="form-row">
              <BillCard
                style={{ margin: 0, flex: 1 }}
                header="Total Subscription Fee"
                figure={current_subscription?.amount_paid / 100}
                perMeasure={current_subscription?.billing_type?.toLowerCase()}
              />
            </div>
          </div>
        </div>
        <div className="flex grow flex-column r-8">
          <SuperAdminSubscriptionTable
            set_current_subscription={set_current_subscription}
          />
        </div>
      </div>
    </Layout>
  );
};

export default Subscription;
