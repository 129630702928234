import React from "react";
import styled from "styled-components";
import { Head, PageTitle, Foot } from "../../components/account-creation";
import { ReactComponent as SuccessBadge } from "../../assets/svgs/success-badge.svg";
// import { Form } from "../../components/account-creation/make-payment";

const PaymentSuccessful = props => {
  return (
    <StyledMakePayment>
      <div className="success-info">
        <SuccessBadge id="success-badge-icon" />
        <PageTitle
          title="Payment Successful!"
          subTitle="You’ve successfully made payment for your subscription."
          align="center"
        />
      </div>
      <Foot buttonText="Go to Dashboard" alignButton="center" />
    </StyledMakePayment>
  );
};

export default PaymentSuccessful;

const StyledMakePayment = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  .success-info {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  #success-badge-icon {
    align-self: center;
    padding-bottom: 1.5em;
  }
`;
