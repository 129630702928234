import React, { useState } from "react";
import styled from "styled-components";
import { Button } from "../general";
import validator from "validator";
import { useAppContext } from "../../context";
import { useHistory } from "react-router";
import axios from "axios";
import { isOnboardingComplete } from "../../selectors/isOnboardingComplete";
import errorHandler from "../../selectors/errorHandler";
import { get, post } from "../../api/transport";

const Form = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const { action, actions } = useAppContext();
  const [loading, setLoading] = useState(false);

  const valid =
    email.length !== 0 && password.length !== 0 && validator.isEmail(email);
  const history = useHistory();

  const handleLogin = () => {
    setLoading(true);
    const data = {
      email,
      password,
    };

    post("/users/token/login/", data)
      .then(response => {
        const token = response.data.auth_token;

        sessionStorage.setItem("email", email);
        sessionStorage.setItem("token", token);

        get("/users/me/").then(response => {
          const profile_response = response.data;

          sessionStorage.setItem("profile", JSON.stringify(response?.data));

          action(actions.setUser, { user: response.data });

          if (profile_response.is_superuser) {
            return history.push("/dashboard/overview");
          }

          if (!response.data.company) {
            return history.push("/account-setup/company-information");
          }
          const company = response.data?.company;
          const { onboarding_stage } = company;
          switch (onboarding_stage) {
            case "COMPANY_INFORMATION":
              history.push("/account-setup/choose-subscription");
              break;

            case "ONBOARDING_COMPLETE":
              const account_setup_complete = isOnboardingComplete(
                response.data
              );

              if (account_setup_complete) {
                return history.push("/dashboard/overview");
              }

              history.push("/getting-started");
              break;

            default:
              setLoading(false);
              break;
          }
          setLoading(false);
        });
      })
      .catch(e => {
        setLoading(false);
        errorHandler(e);
      });
  };

  return (
    <StyledForm>
      <div className="row">
        <div className="input-field">
          <label>Your Email Address</label>
          <input
            value={email}
            type="email"
            placeholder="Eg. someone@gmail.com"
            onChange={e => {
              const val = e.target.value;
              setEmail(val);
            }}
          />
        </div>
      </div>

      <div className="row">
        <div className="input-field">
          <div className="flex justify-between align-center p-b-p5">
            <label>Password</label>
            <div
              onClick={() => history.push("/reset-password")}
              className="pointer "
            >
              <p className="m-0 bold purple">Forgot Password?</p>
            </div>
          </div>

          <div className="flex align-stretch">
            <div className="grow relative">
              <input
                className="input"
                value={password}
                type={showPassword ? "text" : "password"}
                placeholder="Enter a password"
                onChange={e => {
                  const val = e.target.value;
                  setPassword(val);
                }}
              />
              <div
                onClick={() => setShowPassword(!showPassword)}
                className="pointer flex grow justify-center align-center absolute"
                style={{ right: "2%", top: "30%" }}
              >
                <p className="m-0 bold purple">
                  {showPassword ? "HIDE" : "SHOW"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="btn-container">
        <Button
          className="btn-submit"
          disabled={!valid}
          loading={loading}
          onClick={handleLogin}
        />
      </div>
    </StyledForm>
  );
};

export default Form;

const StyledForm = styled.form`
  padding: 5% 0;
  display: flex;
  flex-direction: column;

  .btn-container {
    display: flex;
    flex-direction: row-reverse;
    padding: 2em 0;

    .btn-submit {
      width: initial;
      transition: 4s ease;
      @media (max-width: 1024px) {
        width: 100%;
      }
    }
  }

  .row {
    display: flex;
    align-items: center;
  }

  .row + .row {
    padding-top: 2em;
  }
  .input-field {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .input-field + .input-field {
    margin-left: 1em;
  }

  .input-field > label {
    font-family: CircularStd;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
    padding-bottom: 6px;
  }

  .input-field > input {
    width: 100%;
    height: 50px;
    border: 1px solid #d9dae4;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 15px 20px;

    font-family: CircularStd;
    font-size: 16px;
    line-height: 20px;
  }

  .input-field > input:focus {
    box-shadow: 0px 0px 0px 4px rgba(114, 0, 76, 0.08);
    outline: none;
  }
`;
