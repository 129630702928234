import React, { useRef, useState } from "react";
import { Colors } from "../../constants";
import ModalFormStyleSheet from "../modal/ModalFormStyleSheet";
import { TextButton } from "../general";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { useAppContext } from "../../context";
import toast from "react-hot-toast";
import errorHandler from "../../selectors/errorHandler";
import { useOutsideDetect } from "../../selectors/UseOutsideDetect";
import DropdownLoader from "../modal/DropdownLoader";
import AutoCompleteInput from "../general/AutoCompleteInput";
import { useCreateDevice } from "./broker";
import { useCreateBranch } from "../branches/broker";
import { useCreateQuestion } from "../questions/broker";

const ModalForm = props => {
  let {
    // device,
    questions,
    setQuestions: set_questions,
    branches,
    setBranches: set_branches,
    categories,
    devices,
    setDevices: set_devices,
  } = props;

  const [question_selection, set_question_selection] = useState("existing");
  const [branch_selection, set_branch_selection] = useState("existing");

  const [show_dropdown, set_show_dropdown] = useState(false);
  const [show_branch_dropdown, set_show_branch_dropdown] = useState(false);
  const [show_category_dropdown, set_show_category_dropdown] = useState(false);

  // const [loading, set_loading] = useState(false);
  const [branch_fetch_loading, set_branch_fetch_loading] = useState(false);
  const [question_fetch_loading, set_question_fetch_loading] = useState(false);
  const [create_device_loading, set_create_device_loading] = useState(false);
  const [category_fetch_loading, set_category_fetch_loading] = useState(false);

  const dropdown_ref = useRef();
  const branch_dropdown_ref = useRef();
  const category_dropdown_ref = useRef();

  const { appState } = useAppContext();

  const [new_branch_name, set_new_branch_name] = useState("");
  const [new_branch_location, set_new_branch_location] = useState("");

  const [device_name, set_device_name] = useState("");
  const [question, set_question] = useState("");
  const [selected_question, set_selected_question] = useState();
  const [selected_branches, set_selected_branches] = useState([]);
  const [selected_categories, set_selected_categories] = useState([]);
  const [start_date, set_start_date] = useState(new Date());
  const [end_date, set_end_date] = useState(new Date());

  useOutsideDetect(dropdown_ref, () => set_show_dropdown(false));
  useOutsideDetect(branch_dropdown_ref, () => set_show_branch_dropdown(false));
  useOutsideDetect(category_dropdown_ref, () =>
    set_show_category_dropdown(false)
  );

  // const { data: categories = [], isLoading: categoriesLoading } =
  //   useCategories();
  const { mutateAsync: addBranch, isLoading: createBranchLoading } =
    useCreateBranch();
  const { mutateAsync: addQuestion, isLoading: createQuestionLoading } =
    useCreateQuestion();
  const { mutateAsync: addDevice, isLoading: createDeviceLoading } =
    useCreateDevice();

  const profile = appState?.profile || {};
  const is_admin = profile?.is_owner;

  return (
    <>
      <ModalFormStyleSheet>
        <div className="row">
          <div className="input-field">
            <label>Device Name</label>
            <input
              value={device_name}
              onChange={event => set_device_name(event.target.value)}
              type="text"
              placeholder="Eg. Customer Success"
            />
          </div>
        </div>

        {is_admin && (
          <div className="row">
            <div className="input-field relative" ref={branch_dropdown_ref}>
              <div className="label-container">
                <label>
                  {branch_selection === "new" && "Create New Branch"}
                  {branch_selection === "existing" && "Select Branch"}
                </label>
                <p
                  onClick={toggleBranchSelection}
                  style={{
                    fontSize: ".8em",
                    color: Colors.purple,
                    cursor: "pointer",
                  }}
                >
                  {branch_selection === "new" && "Select an existing branch"}
                  {branch_selection === "existing" && "+ Add a New Branch"}
                </p>
              </div>

              {branch_selection === "existing" && (
                <>
                  <div
                    className="pseudo-input gray flex justify-between align-center pointer "
                    onClick={() =>
                      set_show_branch_dropdown(!show_branch_dropdown)
                    }
                  >
                    <p>
                      {selected_branches.length > 0
                        ? `${
                            selected_branches.length
                          } selected | ${selected_branches.map((el, idx) => {
                            return ` ${el.name}`;
                          })}`
                        : "You can select multiple branches"}
                    </p>
                    <span className="pointer">▾</span>
                  </div>

                  <p className="meta">
                    * Let’s know where this device would be used
                  </p>

                  <div
                    className="dropdown bordered absolute bg-w"
                    style={{
                      display: show_branch_dropdown ? "block" : "none",
                      top: "85%",
                      width: "99.5%",
                    }}
                  >
                    {branch_fetch_loading && (
                      <p className="m-v-1 disabled">please wait</p>
                    )}

                    {branches.length === 0 && (
                      <p className="m-v-1 disabled">No Data</p>
                    )}

                    {branches.length !== 0 && !branch_fetch_loading && (
                      <div className="m-v-1 flex align-center p-h-0">
                        <div className="checkbox-wrapper">
                          <input
                            type="checkbox"
                            checked={
                              selected_branches.length === branches.length
                            }
                            className="m-h-1"
                            onChange={event => {
                              if (!event.target.checked) {
                                return set_selected_branches([]);
                              }
                              set_selected_branches(branches);
                            }}
                          />
                        </div>
                        <p className="m-0 gray-2">All Branches</p>
                      </div>
                    )}

                    {branches?.results?.map(branch => {
                      const key = Math.random();
                      const checked = selected_branches.find(
                        el => el.uuid === branch.uuid
                      );
                      const onChange = () => {
                        selected_branches.find(el => el.uuid === branch.uuid)
                          ? set_selected_branches(
                              selected_branches.filter(
                                el => el.id !== branch.id
                              )
                            )
                          : set_selected_branches([
                              ...selected_branches,
                              branch,
                            ]);
                      };

                      return (
                        <div key={key} className="m-v-1 flex align-center">
                          <div className="checkbox-wrapper">
                            <input
                              type="checkbox"
                              checked={checked}
                              className="m-h-1"
                              onChange={onChange}
                            />
                          </div>
                          <p className="m-0 gray-2">
                            {branch.name} | {branch.location_name}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}

              {branch_selection === "new" && (
                <>
                  <div className="flex grow">
                    <div className="m-r-p5 flex-basis-50">
                      <div className="label-container">
                        <label className="label">Branch Name</label>
                      </div>
                      <input
                        className="pseudo-input"
                        value={new_branch_name}
                        onChange={e => set_new_branch_name(e.target.value)}
                        type="text"
                        placeholder="Eg. Greater Accra"
                      />
                    </div>

                    <div className="relative m-l-p5 flex-basis-50">
                      <div className="label-container">
                        <label className="label">Location</label>
                      </div>

                      <AutoCompleteInput
                        className="pseudo-input"
                        placeholder="Eg. Agyia Zongo, Accra, Ghana"
                        onChange={value => set_new_branch_location(value)}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        )}

        <div className="row">
          <div className="input-field relative">
            <div className="label-container">
              <label>
                {question_selection === "new" && "Enter Question (Optional)"}
                {question_selection === "existing" &&
                  "Select Question (Optional)"}
              </label>
              <p
                onClick={toggleQuestionSelection}
                style={{
                  fontSize: ".8em",
                  color: Colors.purple,
                  cursor: "pointer",
                }}
              >
                {question_selection === "new" && "Select an existing question"}
                {question_selection === "existing" && "+ Add a New Question"}
              </p>
            </div>

            {question_selection === "existing" && (
              <div>
                <div className="row bordered-0" ref={dropdown_ref}>
                  <div className="input-field relative">
                    <div
                      onClick={() => set_show_dropdown(!show_dropdown)}
                      className="pseudo-input gray flex justify-between align-center pointer"
                    >
                      <p>
                        {selected_question
                          ? `${
                              selected_question?.question
                            } | ${selected_question?.categories?.map(
                              (el, idx) => {
                                return ` ${el.title}`;
                              }
                            )}`
                          : "Select a question to be displayed on this device"}
                      </p>
                      <span className="pointer">▾</span>
                    </div>

                    <div
                      className="dropdown bordered absolute bg-w"
                      style={{
                        display: show_dropdown ? "block" : "none",
                        top: "80%",
                        width: "99.5%",
                      }}
                    >
                      {question_fetch_loading && (
                        <p className="dropdown-item disabled">please wait...</p>
                      )}
                      {questions.length === 0 && (
                        <p className="dropdown-item disabled">No Data</p>
                      )}
                      {questions?.map(question => (
                        <div
                          key={Math.random()}
                          className="m-v-1 flex align-center"
                        >
                          <div className="checkbox-wrapper">
                            <input
                              type="checkbox"
                              id={question.uuid}
                              name={question.uuid}
                              className="m-h-1"
                              checked={
                                selected_question?.uuid === question.uuid
                              }
                              onChange={() => set_selected_question(question)}
                            />
                          </div>
                          <p className="m-0 gray-2">
                            {`${
                              question.question
                            } | ${question?.categories?.map(
                              el => ` ${el.title}`
                            )}`}
                          </p>
                        </div>
                      ))}
                    </div>

                    <p className="meta">
                      * This question will be available to be deployed on
                      devices across selected branches only
                    </p>
                  </div>
                </div>

                <div
                  className="row flex align-start"
                  style={{ border: "1px solid !important" }}
                >
                  <div className="input-field ">
                    <label>Set Start Date</label>
                    <DatePicker
                      selected={start_date}
                      onChange={date => {
                        if (moment(date).isSameOrAfter(moment(), "day")) {
                          set_start_date(date);
                        }
                      }}
                      className="pseudo-input"
                    />
                  </div>
                  <div className="input-field ">
                    <label>Set End Date</label>
                    <DatePicker
                      selected={end_date}
                      onChange={date => {
                        if (moment(date).isAfter(moment(), "day")) {
                          set_end_date(date);
                        }
                      }}
                      className="pseudo-input"
                    />
                  </div>
                </div>

                <p className="meta small-text">
                  * Specify how long you want the question to show on the device
                </p>
              </div>
            )}

            {question_selection === "new" && (
              <div>
                <div className="row">
                  <div className="input-field m-0">
                    <input
                      value={question}
                      type="text"
                      placeholder="Enter a question to be deployed on this device"
                      onChange={e => set_question(e.target.value)}
                    />
                  </div>
                </div>

                <div className="row bordered-0" ref={category_dropdown_ref}>
                  <div className="input-field relative">
                    <label>Select Categories</label>
                    <div
                      onClick={() =>
                        set_show_category_dropdown(!show_category_dropdown)
                      }
                      className="pseudo-input gray flex justify-between align-center pointer "
                    >
                      <p>
                        {selected_categories.length > 0
                          ? `${
                              selected_categories.length
                            } selected | ${selected_categories.map(
                              el => ` ${el.title}`
                            )}`
                          : "Select categories"}
                      </p>
                      <span className="pointer">▾</span>
                    </div>

                    <div
                      className="dropdown bordered absolute bg-w"
                      style={{
                        display: show_category_dropdown ? "block" : "none",
                        top: "105%",
                        width: "99.5%",
                      }}
                    >
                      {category_fetch_loading && (
                        <p className="m-v-1 disabled">Please wait</p>
                      )}

                      {categories.length === 0 && !category_fetch_loading && (
                        <p className="m-v-1 disabled">No Data</p>
                      )}

                      {categories?.map(category => {
                        const toggle_category_select = () => {
                          selected_categories.find(
                            el => el.uuid === category.uuid
                          )
                            ? set_selected_categories(
                                selected_categories.filter(
                                  el => el.id !== category.id
                                )
                              )
                            : set_selected_categories([
                                ...selected_categories,
                                category,
                              ]);
                        };

                        const checked = selected_categories.find(
                          el => el.uuid === category.uuid
                        );

                        const key = Math.random();

                        return (
                          <div
                            key={key}
                            className="m-v-1 flex align-center pointer"
                            onClick={toggle_category_select}
                          >
                            <div className="checkbox-wrapper">
                              <input
                                type="checkbox"
                                id={category.uuid}
                                name={category.uuid}
                                checked={checked}
                                className="m-h-1"
                                onChange={toggle_category_select}
                              />
                            </div>
                            <p className="m-0 gray-2">{category.title}</p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="input-field">
                    <label>Set Start Date</label>
                    <DatePicker
                      selected={start_date}
                      onChange={date => {
                        if (moment(date).isSameOrAfter(moment(), "day")) {
                          set_start_date(date);
                        }
                      }}
                      className="pseudo-input"
                    />
                  </div>
                  <div className="input-field">
                    <label>Set End Date</label>
                    <DatePicker
                      selected={end_date}
                      onChange={date => {
                        if (moment(date).isAfter(moment(), "day")) {
                          set_end_date(date);
                        }
                      }}
                      className="pseudo-input"
                    />
                  </div>
                </div>
                <p className="meta m-0">
                  * Specify how long you want the question to show on the device
                </p>
              </div>
            )}
          </div>
        </div>
      </ModalFormStyleSheet>

      <div className="flex justify-center align-center m-0 p-v-1">
        <TextButton
          text={
            createQuestionLoading || createBranchLoading || createDeviceLoading
              ? "Please wait"
              : "Continue"
          }
          disabled={createDeviceLoading}
          styleProp={{
            padding: ".5em",
            color: Colors.white,
            background: Colors.purple,
          }}
          onClick={createDevice}
        />
      </div>
    </>
  );

  function toggleQuestionSelection() {
    if (question_selection === "new") {
      set_question("");
      set_question_selection("existing");
    } else {
      set_selected_question(undefined);
      set_question_selection("new");
    }
  }

  function toggleBranchSelection() {
    if (branch_selection === "new") {
      set_branch_selection("existing");
    } else {
      set_branch_selection("new");
    }
  }

  async function createDevice() {
    let branch = selected_branches.map(el => el.id);
    let question_id = selected_question?.id;
    let location;
    toast.loading("Saving");

    if (branch_selection === "new") {
      const branch_data = {
        is_active: true,
        name: new_branch_name,
        location_name: new_branch_location,
        status: "Approved",
        company: appState.profile?.company?.id,
      };

      try {
        let response = await addBranch(branch_data);
        let new_branch = response?.data;
        branch = [...branch, new_branch?.id];
      } catch (error) {
        return;
      }
    }

    if (question_selection === "new") {
      const question_data = {
        is_active: true,
        question,
        status: "public",
        company: appState.profile?.company?.id,
        location: is_admin ? branch : [profile?.branch?.id],
        categories: selected_categories.map(el => el.id),
      };
      try {
        let response = await addQuestion(question_data);
        let new_question = response?.data;
        question_id = new_question?.id;
      } catch (error) {
        return;
      }
    }

    const tablet_data = {
      name: device_name,
      status: "Approved",
      company: appState.profile?.company?.id,
      location: is_admin ? branch : [profile?.branch?.id],
      question: question_id,
      question_start_date: start_date.toISOString(),
      question_end_date: end_date.toISOString(),
    };

    addDevice(tablet_data)
      .then(response => {
        const created_device = response.data;
        props.setDevice(created_device);
        toast.success("Success");
        props.setPage("awaiting");
      })
      .catch(error => {
        errorHandler(error);
      });
  }
};

export default ModalForm;
