import React, { useCallback, useEffect, useRef, useState } from "react";
import { Layout } from "../components/dashboard";
import { EmojiTextButton } from "../components/general";
import { v4 as uuidv4 } from "uuid";

import colors from "../constants/colors";
import { GenericButton } from "../components/general/Button";
import { DocumentHeader } from "../components/general/DocumentHeader";

import {
  useAddEmoji,
  useAddEmojiPack,
  useDeleteEmoji,
  useDeleteEmojiPack,
  useEmojiPacks,
} from "../components/admin/broker";

import toast from "react-hot-toast";
import errorHandler from "../selectors/errorHandler";
import objectToFormData from "../selectors/objectToFormData";
import ConfirmModal from "../components/modal/ConfirmModal";
import _ from "lodash";

const SuperAdminEmojis = () => {
  const { data, isLoading } = useEmojiPacks();

  const [addPack, setAddPack] = useState(false);

  const [newPack, setNewPack] = useState({
    title: "",
  });

  const [newTitle, setNewTitle] = useState();

  const mutateTitle = useCallback(
    ({ target: { value } }) => {
      setNewTitle(value);
    },
    [setNewTitle]
  );

  const [packToUpdate, setPackToUpdate] = useState({});

  const { mutateAsync: addEmojiPack, isLoading: addEmojiPackLoading } =
    useAddEmojiPack();

  const { mutateAsync: addEmojiAsync, isLoading: addEmojiLoading } =
    useAddEmoji();

  const { mutateAsync: deleteEmojiAsync, isLoading: deleteEmojiLoading } =
    useDeleteEmoji();

  const { mutateAsync: deletePackAsync, isLoading: deletePackLoading } =
    useDeleteEmojiPack();

  const deleteEmoji = async id => {
    try {
      await deleteEmojiAsync(id);
    } catch (error) {
      errorHandler(error);
    }
  };

  const deleteEmojiPack = async id => {
    try {
      await deletePackAsync(id);
    } catch (error) {
      errorHandler(error);
    }
  };

  const [sentimentSettings, setSentimentSettings] = useState([
    { title: "Terrible", rating: 1, emoji: undefined },
    { title: "Bad", rating: 2, emoji: undefined },
    { title: "Okay", rating: 3, emoji: undefined },
    { title: "Good", rating: 4, emoji: undefined },
    { title: "Excellent", rating: 5, emoji: undefined },
  ]);

  const savePack = useCallback(async () => {
    if (!newPack.title) return;

    const postData = {};

    postData["title"] = newPack?.title;
    postData["status"] = true;

    postData["sentiments"] = [
      // {
      //   title: "Excellent",
      //   rating: 5,
      //   emoji: "",
      //   status: true,
      // },
    ];

    try {
      await addEmojiPack(postData);
      toast.success("Created Successfully");
    } catch (error) {
      errorHandler(error);
    }
  }, [newPack]);

  const handleUploadEmoji = useCallback(
    (rating, file) => {
      const newSentimentSettingsList = sentimentSettings.map(el => {
        if (el.rating === rating) {
          return {
            ...el,
            emoji: file,
          };
        }
        return el;
      });

      setSentimentSettings(newSentimentSettingsList);
    },
    [setSentimentSettings, sentimentSettings]
  );

  const setAddPackValue = useCallback(() => {
    setPackToUpdate({});
    return setAddPack(true);
  }, [setAddPack, setPackToUpdate]);

  const setShowUpdateEmoji = useCallback(
    pack => {
      if (_.isEmpty(packToUpdate) && !!pack.id) {
        setAddPack(false);
        return setPackToUpdate(pack);
      }

      return setPackToUpdate(pack);
    },
    [packToUpdate, setPackToUpdate, setAddPack]
  );

  let initialValue = [
    { rating: 1, emoji: "", title: "Terrible" },
    { rating: 2, emoji: "", title: "Bad" },
    { rating: 3, emoji: "", title: "Okay" },
    { rating: 4, emoji: "", title: "Good" },
    { rating: 5, emoji: "", title: "Excellent" },
  ];

  const returnSentimentList = useCallback(
    sentiments => {
      return sentiments?.reduce((previousValue, currentSentiment) => {
        return previousValue?.map(previousValueItem => {
          if (currentSentiment.rating === previousValueItem.rating) {
            return {
              ...previousValueItem,
              emoji: currentSentiment.emoji,
              id: currentSentiment.id,
            };
          }
          return previousValueItem;
        });
      }, initialValue);
    },
    [data]
  );

  return (
    <Layout>
      <DocumentHeader page="Emojis" />
      <div className="flex grow justify-between">
        <div className="flex flex-basis-50 flex-column max-height-90 m-r-p5">
          <div className="m-b-1 flex justify-between align-center">
            <h4 className="bold m-0">Emoji Packs</h4>
            <EmojiTextButton.Custom
              onClick={setAddPackValue}
              backgroundColor={colors.purple}
              text="Add an Emoji"
              color="white"
              padding="1em"
            />
          </div>

          <div className="flex flex-column flex-1 grow max-height-80 scroll">
            {data?.map(el => {
              return (
                <div
                  key={uuidv4()}
                  className="flex p-t-2 p-h-1 r-8 bg-w flex-column justify-start bordered m-b-1"
                >
                  <div className="m-b-1 flex justify-between align-center">
                    <p className="m-0 bold">{el.title}</p>
                    <div className="flex flex-basis-20 justify-between align-center">
                      <p
                        className="m-0 bold gray pointer"
                        onClick={() => setShowUpdateEmoji(el)}
                      >
                        Edit
                      </p>
                      <p
                        className="m-0 bold red pointer"
                        onClick={async () => {
                          const result = await ConfirmModal.show({
                            text: "Are you sure you want to delete this pack?",
                          });
                          if (result) {
                            try {
                              await deleteEmojiPack(el.id);
                            } catch (error) {
                              errorHandler(error);
                            }
                          }
                        }}
                      >
                        Delete
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-between align-center wrap">
                    {returnSentimentList(el.sentiments)?.map(el => {
                      return (
                        <div
                          key={uuidv4()}
                          className="flex flex-column justify-center align-center -0 m-h-1"
                        >
                          <div
                            className="upload-image-circle circle flex justify-center align-center"
                            style={{
                              background: `${
                                !!el.emoji ? `transparent` : `${colors.bg}`
                              } url('${el.emoji}')`,
                              backgroundPosition: "center",
                              backgroundSize: "100% 100%",
                            }}
                          />
                          <p>{el.title}</p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        {!!addPack && (
          <div className="flex flex-basis-50 flex-column align-end m-l-p5">
            <div className="flex grow flex-column ">
              <div className="m-b-1 flex">
                <div className="flex-basis-30 flex align-center">
                  <h4 className="m-0 ">Pack Title - </h4>
                </div>
                <div className="flex-basis-70 flex bordered">
                  <input
                    type="text"
                    onChange={({ target: { value } }) =>
                      setNewPack({ ...newPack, title: value })
                    }
                    className="pseudo-input m0"
                  />
                </div>
              </div>

              <div className="flex flex-column r-8 bg-w p-1">
                <div className="p-b-1">
                  <p className="m-0 meta">
                    * Supported file: Only PNG, not larger than 400KB at 1200 x
                    1200 pixels
                  </p>
                </div>
                <div className="grow flex flex-column ">
                  {sentimentSettings.map(el => {
                    return (
                      <EmojiSelect
                        key={uuidv4()}
                        handleUploadEmoji={handleUploadEmoji}
                        {...el}
                      />
                    );
                  })}
                </div>
                <div className="m-t-auto">
                  <GenericButton
                    className="full-width-percent white bold"
                    onClick={savePack}
                    disabled={addEmojiPackLoading}
                  >
                    <div className="full-width-percent">
                      <p className="m-0 center-align">
                        {addEmojiPackLoading
                          ? "Please Wait"
                          : `Add ${newPack?.title}`}
                      </p>
                    </div>
                  </GenericButton>
                </div>
              </div>
            </div>
          </div>
        )}

        {!_.isEmpty(packToUpdate) && (
          <div className="flex flex-basis-50 flex-column align-end m-l-p5">
            <div className="flex grow flex-column">
              <div className="m-b-1 p-v-1 flex align-center gap-1">
                <h4 className="m-0 display-inline">Update</h4>
                <input
                  type="text"
                  value={newTitle ?? packToUpdate?.title}
                  onChange={mutateTitle}
                  className="pseudo-input m0 display-inline"
                />
              </div>
              <div className="flex flex-column r-8 bg-w p-1">
                <div className="p-b-1">
                  <p className="m-0 meta">
                    * Supported file: Only PNG, not larger than 400KB at 1200 x
                    1200 pixels
                  </p>
                </div>
                <div className="grow flex flex-column ">
                  {returnSentimentList(packToUpdate.sentiments).map(
                    (el, idx) => {
                      function handleChange(event) {
                        console.log("running");

                        event.preventDefault();

                        const file = event.target.files[0];

                        const isImage =
                          file && file.type.substr(0, 5) === "image";

                        if (isImage) {
                          // setImage(file);

                          const postData = {
                            title: el.title,
                            rating: el.rating,
                            setting: packToUpdate?.id,
                            status: true,
                            emoji: file,
                          };

                          const formData = objectToFormData(postData);

                          for (var pair of formData.entries()) {
                            console.log(pair[0] + ", " + pair[1]);
                          }
                        }
                      }

                      const handleUpload = () => {
                        // fileInputRefs?.current?.[idx]?.click();
                      };

                      const hasEmoji = !!el.emoji;

                      return (
                        <div
                          className="p-p5 flex m-b-1 dashed r-8 bg-peach"
                          key={uuidv4()}
                        >
                          <div className="flex-basis-30 flex justify-center align-center">
                            <div
                              className="upload-image-circle circle bg-w h-w-6"
                              style={{
                                background: `${
                                  !!el.emoji ? `transparent` : `${colors.white}`
                                } url('${el.emoji}')`,
                                backgroundPosition: "center",
                                backgroundSize: "100% 100%",
                              }}
                            ></div>
                          </div>
                          <div className="flex-basis-70 flex flex-column justify-around align-start p-h-p5">
                            <p className="m-0">
                              Upload an emoji for{" "}
                              <span className="bold">"{el.title}"</span>
                            </p>
                            {
                              <div className="display-flex flex-1">
                                <input
                                  type="file"
                                  // ref={el => (fileInputRefs.current[idx] = el)}
                                  onChange={handleChange}
                                  className="hide"
                                />
                                <EmojiTextButton.Custom
                                  padding=".5em 1em"
                                  margin=".5em 0 0 0"
                                  backgroundColor="#fff"
                                  border={"1px solid" + colors.grey_3}
                                  color={hasEmoji ? colors.red : colors.purple}
                                  text={
                                    deleteEmojiLoading || addEmojiLoading
                                      ? "Please wait"
                                      : hasEmoji
                                      ? "- Remove"
                                      : "Upload"
                                  }
                                  textColor="black"
                                  flex="1"
                                  onClick={async () => {
                                    if (hasEmoji) {
                                      await deleteEmoji(el.id);
                                    } else {
                                      handleUpload();
                                    }
                                  }}
                                />
                              </div>
                            }
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
                <div className="bordered m-t-auto">
                  <GenericButton className="full-width-percent white bold">
                    <div className="full-width-percent">
                      <p className="m-0 center-align">
                        Save {newTitle ?? packToUpdate?.title}
                      </p>
                    </div>
                  </GenericButton>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

function EmojiSelect({ title, rating, emoji, handleUploadEmoji }) {
  const [preview, setPreview] = useState(undefined);

  useEffect(() => {
    if (!!emoji) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setPreview(reader?.result);
      };

      reader.readAsDataURL(emoji);
    }
    return () => {};
  }, [setPreview, emoji]);

  const fileUploadRef = useRef();

  const handleUpload = useCallback(() => {
    fileUploadRef?.current?.click();
  }, [fileUploadRef]);

  const handleChange = event => {
    // const file = event.target.files[0];
    // console.log(file);

    // if (file && file.type.substr(0, 5) === "image") {
    //   // handleUploadEmoji(rating, file);
    //   console.log(rating, file);
    // }

    console.log("event.target.value");
  };

  return (
    <div className="p-p5 flex m-b-1 dashed r-8 bg-peach" key={uuidv4()}>
      <div className="flex-basis-30 flex justify-center align-center">
        <div
          className="upload-image-circle circle bg-w h-w-6"
          style={{
            backgroundSize: "cover",
            backgroundImage: `url('${preview}')`,
            backgroundPosition: "center",
          }}
        >
          <input
            type="file"
            className="hide"
            onChange={handleChange}
            ref={fileUploadRef}
          />
        </div>
      </div>
      <div className="flex-basis-70 flex flex-column justify-around align-start p-h-p5">
        <p className="m-0">
          Upload an emoji for <span className="bold">"{title}"</span>
        </p>
        <div className="display-flex flex-1">
          <EmojiTextButton.Custom
            padding=".5em 1em"
            margin=".5em 0 0 0"
            text="Upload Image"
            textColor="black"
            onClick={handleUpload}
          />
        </div>
      </div>
    </div>
  );
}

export default SuperAdminEmojis;
