import React from "react";
import { CompaniesTable, CompanyTabs } from "../../components/companies";
import { Layout } from "../../components/dashboard";
import { SuperAdminFeedbacksTable } from "../../components/feedbacks/AdminFeedbacksTable";
import { DocumentHeader } from "../../components/general/DocumentHeader";

const Feedbacks = () => {
  return (
    <Layout tabs={CompanyTabs}>
      <DocumentHeader page="Feedback" />
      <SuperAdminFeedbacksTable />
    </Layout>
  );
};

export default Feedbacks;
