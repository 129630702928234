import React from "react";
import ReactModal from "react-modal";
import { defaultModalStyles } from "../../utils/modal";

export const Modal = props => {
  const { children, isOpen, handleCancel } = props;

  React.useEffect(() => {
    ReactModal.setAppElement("#root");
  }, []);

  return (
    <ReactModal
      style={defaultModalStyles}
      isOpen={isOpen}
      onRequestClose={handleCancel}
    >
      {React.cloneElement(children)}
    </ReactModal>
  );
};
