import { useQuery } from "react-query";
import { get } from "../../api/transport";
import { useAppContext } from "../../context";

export const useFeedback = (offset, limit) => {
  const { appState } = useAppContext();
  const profile = appState.profile || {};
  const is_admin = profile?.is_owner;

  const queryKey = ["feedback", offset, limit];

  const queryFn = () => get(`/user/sentiment/?offset=${offset}&limit=${limit}`);

  const enabled = typeof is_admin !== "undefined";

  const select = response => {
    let sentiments = response?.data?.results?.filter(el => {
      if (!is_admin) {
        return el?.tablet?.location?.find(
          el => el?.uuid === profile?.branch?.uuid
        );
      }
      return el;
    });
    return sentiments;
  };
  const options = { select, enabled };

  return useQuery(queryKey, queryFn, options);
};

export const useFeedbackData = (
  offset = "",
  limit = "",
  orderby = "",
  search = ""
) => {
  const queryKey = ["feedback", offset, limit, orderby, search];

  const queryFn = () =>
    get(
      `/user/sentiment/?offset=${offset}&limit=${limit}&orderby=${orderby}&search=${search}`
    );

  const select = response => response.data;

  const options = { select };

  return useQuery(queryKey, queryFn, options);
};
