import React from "react";
import { useHistory } from "react-router-dom";
import { SuccessPage } from "../components/account-creation/SuccessPage";
import { DocumentHeader } from "../components/general/DocumentHeader";

export const NewPasswordSet = () => {
  const history = useHistory();
  return (
    <>
      <DocumentHeader page="Success" />
      <SuccessPage
        title="New Password Set"
        subtext="Go to the Login page to continue with your new password."
        button={{
          children: (
            <div className="flex justify-center align-center grow">
              <span className="p-r-1">&larr;</span>
              <p className="m-0 white">Back to Login</p>
            </div>
          ),
          onClick: () => history.push("/login"),
          loading: false,
        }}
      />
    </>
  );
};
