import React from "react";
import { ResponsiveContainer, Tooltip, AreaChart, Area, XAxis } from "recharts";
import { Colors } from "../../constants";
import { subDays } from "date-fns";
import moment from "moment";
import { Loader } from "../general";

export const AreaChartComponent = props => {
  const {
    count,
    data = [],
    caption = "Overall Feedback",
    dataKey = "value",
    loading = false,
    tooltipUnit = "",
  } = props;
  const empty = data.length === 0;

  const empty_data = [];

  if (empty) {
    for (let num = 0; num >= 0; num--) {
      let new_ob = {};
      new_ob["date"] = moment(subDays(new Date(), num)).format("MMM, y");
      new_ob[dataKey] = Math.ceil(0.2 + Math.random() * 500);
      empty_data.push(new_ob);
    }
  }

  return (
    <div className="flex grow bordered flex-column r-8 bg-w">
      <div className="grow p-1 p-h-2">
        <h2 className="m-0">{empty ? 0 : count || data.length}</h2>
        <p className="m-0" style={{ opacity: 0.72 }}>
          {caption}
        </p>
      </div>
      <div className="flex-basis-60">
        {loading ? (
          <Loader />
        ) : (
          <ResponsiveContainer width={"100%"} height={"100%"}>
            <AreaChart data={empty ? empty_data : data}>
              {/* <XAxis dataKey={dataKey} /> */}
              <Area
                dataKey={dataKey}
                stroke={empty ? Colors.grey_3 : Colors.purple}
                fill={empty ? Colors.grey_3 : Colors.yellow}
              />
              {empty || (
                <Tooltip
                  tooltipUnit={tooltipUnit}
                  data_key={dataKey}
                  cursor={false}
                  content={CustomTooltip}
                />
              )}
            </AreaChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  );
};

function CustomTooltip({
  active,
  payload,
  label,
  tooltipUnit = "",
  data_key = "",
}) {
  const { payload: payload_data } = payload[0] || {};

  if (active) {
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: Colors.purple,
          color: Colors.white,
          padding: "5px",
          border: "1px solid #cccc",
          borderRadius: "4px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <label>
          {`${payload_data?.date} - ${
            payload_data?.[`${data_key || "sentiments"}`]
          } ${tooltipUnit || "Feedback(s)"}` || "value"}
        </label>
      </div>
    );
  }

  return null;
}
