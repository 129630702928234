import React from "react";
import styled from "styled-components";

const Button = ({ text = "Enter Text", alt, onClick = () => {}, ...rest }) => {
  return (
    <StyledButton onClick={onClick} alt={alt} {...rest}>
      <p>{text}</p>
    </StyledButton>
  );
};

export default Button;
const StyledButton = styled.div`
  height: 32px;
  color: #fff;
  background: ${({ alt }) => (alt ? `#FBF9F9` : `#72004c`)};
  ${({ alt }) => alt && `border: 1px solid #F5F3F3;`}
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: ${props => (props.disabled ? 0.4 : 1)};

  p {
    margin: 0;
    font-family: CircularStd;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: ${({ alt }) => (alt ? `#696969` : `#ffffff`)};
  }
`;

// #696969
