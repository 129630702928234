import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import { SuccessPage } from "../components/account-creation/SuccessPage";
import { DocumentHeader } from "../components/general/DocumentHeader";

export const PaymentSuccessful = () => {
  const history = useHistory();

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      toast.loading("Redirecting to Dashboard");
      setTimeout(() => {
        history.push("/getting-started");
      }, 2000);
    }

    return () => (mounted = false);
  }, []);

  return (
    <>
      <DocumentHeader page="Success" />
      <SuccessPage
        title="Payment Successful"
        subtext="You’ve successfully made payment for your subscription. "
        button={{
          children: (
            <div className="flex justify-between align-center grow">
              <p className="m-0 white">Go to dashboard</p>
              <>&rarr;</>
            </div>
          ),
          onClick: () => history.push("/getting-started"),
          loading: false,
        }}
      />
    </>
  );
};
