const colors = {
  bg: "#FBF9F9",
  purple: " #72004C",
  purple_1: "#51004B",
  grey: "#EDEEF5",
  grey_1: "#9B9B9B",
  grey_2: "#696969",
  grey_3: "#D9DAE4",
  black: "#151515",
  black_1: "#333333",
  white: "#FFFFFF",
  white_2: "#F1EEEE",
  white_4: "hsla(0, 0%, 100%, .4)",
  white_1: "#f5f3f3",
  yellow: "#F8CB4E",
  peach: "#F06669",
  red: "#E62728",
  green: "#2BA20D",
  orange: "#EB6A33",
};

export default colors;
