import toast from "react-hot-toast";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { delete_request, get, patch, post } from "../../api/transport";
import errorHandler from "../../selectors/errorHandler";

const defaultOptions = {
  // refetchOnWindowFocus: false,
  refetchOnmount: false,
  refetchOnReconnect: false,
  retry: false,
  staleTime: 86400000,
};

export const useBranches = (limit = "", offset = "") => {
  const queryKey = ["branches", limit, offset];

  const queryFn = () => get(`/user/branch/?limit=${limit}&offset=${offset}`);

  const select = response => response?.data;

  const options = { select, ...defaultOptions };

  return useQuery(queryKey, queryFn, options);
};

export const useCreateBranch = () => {
  const queryClient = useQueryClient();

  const queryFn = data => post("/user/branch/", data);

  const onSuccess = () => queryClient.invalidateQueries();

  const onError = error => errorHandler(error);

  const options = { onSuccess, onError };

  return useMutation(queryFn, options);
};

export const useDeleteBranch = id => {
  const queryClient = useQueryClient();

  const queryFn = () => delete_request(`/user/branch/${id}/`);

  const onSuccess = () => {
    queryClient.invalidateQueries("branches");
    toast.success("Success");
  };

  const onError = error => errorHandler(error);

  const options = { onSuccess, onError };

  return useMutation(queryFn, options);
};

export const useUpdateBranch = id => {
  const queryClient = useQueryClient();

  const queryFn = data => patch("/user/branch/", data);

  const onSuccess = () => queryClient.invalidateQueries("branches");

  const onError = error => errorHandler(error);

  const options = { onSuccess, onError };

  return useMutation(queryFn, options);
};
