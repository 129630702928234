import _ from "lodash";
import {
  Play,
  Blocks,
  Graph,
  Device,
  Quotes,
  QuestionCircle,
  Cube,
  Emoji,
  Building,
  User,
  Settings,
  CreditCard,
  Bag,
  Bell,
} from "../icons";

const SidebarItems = {
  Start: {
    name: "Getting Started",
    icon: Play,
    route: "/getting-started",
  },
  Dashboard: [
    {
      name: "Overview",
      icon: Blocks,
      route: "/dashboard/overview",
    },
    {
      name: "Analytics",
      icon: Graph,
      route: "/dashboard/analytics",
    },
    {
      name: "Devices",
      icon: Device,
      route: "/dashboard/devices",
    },
    {
      name: "Feedback",
      icon: Quotes,
      route: "/dashboard/feedbacks",
    },
    {
      name: "Questions",
      icon: QuestionCircle,
      route: "/dashboard/questions",
    },
  ],
  Company: [
    {
      name: "Logo & Background",
      icon: Cube,
      route: "/company/logo-background",
    },
    {
      name: "Emojis",
      icon: Emoji,
      route: "/company/emojis",
    },
    {
      name: "Branches",
      icon: Building,
      route: "/company/branches",
    },
    {
      name: "Branch Managers",
      icon: User,
      route: "/company/branch-managers",
    },
  ],
  Account: [
    {
      name: "Settings",
      icon: Settings,
      route: "/account/settings/account-information",
      isActive: (match, location) => {
        if (location.pathname.includes("/settings/")) {
          // console.log(location.pathname.includes("/settings/"));
          return true;
        }
      },
    },
    {
      name: "Subscription",
      icon: CreditCard,
      route: "/account/subscription",
    },
  ],
};

const categoryNames = ["Dashboard", "Company", "Account"];

const getSidebarItems = (profile = {}) => {
  const is_super_admin = profile?.is_superuser;
  const is_admin = profile?.is_owner;
  // const is_manager = profile?.is_branch_manager;

  let dashboard_items = {
    Start: {
      name: "Getting Started",
      icon: Play,
      route: "/getting-started",
    },
  };

  if (_.isEmpty(profile)) return {};

  const super_admin_dashboard_items = {
    Dashboard: [
      {
        name: "Overview",
        icon: Blocks,
        route: "/dashboard/overview",
      },
      {
        name: "Finance",
        icon: Graph,
        route: "/dashboard/finance",
      },
    ],
    Management: [
      {
        name: "Companies",
        icon: Building,
        route: "/management/company/all",
      },
      {
        name: "Industries",
        icon: Bag,
        route: "/management/industries",
      },
      {
        name: "Emojis",
        icon: Emoji,
        route: "/management/emojis",
      },
      {
        name: "Push Notification",
        icon: Bell,
        route: "/management/push-notifications",
      },
    ],
    Account: [
      {
        name: "Settings",
        icon: Settings,
        route: "/account/settings/account-information",
      },
    ],
  };

  const admin_dashboard_items = {
    Dashboard: [
      {
        name: "Overview",
        icon: Blocks,
        route: "/dashboard/overview",
      },
      {
        name: "Analytics",
        icon: Graph,
        route: "/dashboard/analytics",
      },
      {
        name: "Devices",
        icon: Device,
        route: "/dashboard/devices",
      },
      {
        name: "Feedback",
        icon: Quotes,
        route: "/dashboard/feedbacks",
      },
      {
        name: "Questions",
        icon: QuestionCircle,
        route: "/dashboard/questions",
      },
    ],
    Company: [
      {
        name: "Logo & Background",
        icon: Cube,
        route: "/company/logo-background",
      },
      {
        name: "Emojis",
        icon: Emoji,
        route: "/company/emojis",
      },
      {
        name: "Branches",
        icon: Building,
        route: "/company/branches",
      },
      {
        name: "Branch Managers",
        icon: User,
        route: "/company/branch-managers",
      },
    ],
    Account: [
      {
        name: "Settings",
        icon: Settings,
        route: "/account/settings/account-information",
      },
      {
        name: "Subscription",
        icon: CreditCard,
        route: "/account/subscription",
      },
    ],
  };

  const manager_dashboard_items = {
    Dashboard: [
      {
        name: "Overview",
        icon: Blocks,
        route: "/dashboard/overview",
      },
      {
        name: "Analytics",
        icon: Graph,
        route: "/dashboard/analytics",
      },
      {
        name: "Feedback",
        icon: Quotes,
        route: "/dashboard/feedbacks",
      },
      {
        name: "Devices",
        icon: Device,
        route: "/dashboard/devices",
      },

      {
        name: "Questions",
        icon: QuestionCircle,
        route: "/dashboard/questions",
      },
    ],
    Account: [
      {
        name: "Settings",
        icon: Settings,
        route: "/account/settings/account-information",
      },
    ],
  };

  if (is_super_admin) {
    dashboard_items = {
      ...dashboard_items,
      ...super_admin_dashboard_items,
    };
    return dashboard_items;
  }

  if (is_admin) {
    dashboard_items = {
      ...dashboard_items,
      ...admin_dashboard_items,
    };
    return dashboard_items;
  }

  return { ...dashboard_items, ...manager_dashboard_items };
};

const getCategoryNames = (profile = {}) => {
  const is_super_admin = profile?.is_superuser;
  const is_admin = profile?.is_owner;
  // const is_manager = profile?.is_branch_manager;

  if (is_super_admin) return ["Dashboard", "Management", "Account"];
  // if (is_admin) return ["Dashboard", "Company", "Account"];
  return ["Dashboard", "Company", "Account"];
};

export default SidebarItems;
export { categoryNames, getSidebarItems, getCategoryNames };
