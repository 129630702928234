import React from "react";
import Lottie from "react-lottie";
import lottieDefaultConfig from "../../selectors/lottieDefaultConfig";
import animationData from "../../assets/lottie/empty.json";

export const DropdownEmpty = () => {
  const defaultOptions = { ...lottieDefaultConfig, animationData };

  return (
    <div
      className="full-width-percent flex justify-center align-center flex-column"
      style={{ padding: "15px 20px" }}
    >
      {/* <Lottie options={defaultOptions} height={80} width={100} /> */}
      <p className="gray-2 m-0">No data</p>
    </div>
  );
};
