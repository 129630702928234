import React, { useState, useEffect, useMemo, useRef } from "react";
import { Table } from "../table";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { CSVLink } from "react-csv";
import { ReactComponent as Export } from "../../assets/svgs/export.svg";
import errorHandler from "../../selectors/errorHandler";
import { get } from "../../api/transport";
import { useCompaniesAdmin } from "../admin/broker";
import Paginator from "../table/Paginator";

export const CompaniesTable = props => {
  const [offset, setOffset] = useState(0);

  const { data, isLoading } = useCompaniesAdmin(offset);

  const tableData = useMemo(() => data?.results || [], [data, offset]);

  const downloadButtonRef = useRef();

  const history = useHistory();

  const COLUMNS = [
    {
      Header: "COMPANY",
      accessor: "name",
    },
    {
      Header: "INDUSTRY",
      accessor: "industry",
      Cell: ({ value }) => {
        return ` ${value.map(el => ` ${el}`)}`;
      },
    },
    {
      Header: "COUNTRY",
      accessor: "country",
    },
    {
      Header: "EMAIL ADDRESS",
      accessor: "email",
    },
    {
      Header: "PHONE NUMBER",
      accessor: "tel",
    },
    {
      Header: "TABLETS",
      accessor: "number_tablet",
      Cell: ({ value }) => {
        return value || Math.ceil(Math.random() * 10);
      },
    },
    {
      Header: "DATE",
      accessor: "created_at",
      Cell: ({ value }) => moment(value).format("MMM D, YYYY"),
    },
  ];

  return (
    <div className="flex flex-column grow">
      <div className="flex justify-between align-center p-v-1">
        <h4 style={{ margin: 0 }}>All Companies</h4>

        <CSVLink
          ref={downloadButtonRef}
          className="r-8 flex bg-purple white m-l-p5 text-decoration-none"
          style={{
            padding: ".8em 2em",
          }}
          data={[
            ...tableData.map(el => {
              return {
                NAME: el.name,
                INDUSTRY: ` ${el.industry?.map(el => el)}`,
                COUNTRY: el.country,
                "EMAIL ADDRESS": el.email,
                "PHONE NUMBER": `${el.tel}`,
                TABLETS: el.number_tablet,
                date: moment(el.created_at).format("MMM D, YYYY hh:mm a"),
              };
            }),
          ]}
        >
          <span className="m-r-p5">
            <Export />
          </span>
          <p className="m-0">Export Data</p>
        </CSVLink>
      </div>
      <div className="flex flex-column grow m-t-1 bg-w">
        <Table
          loading={isLoading}
          columns={COLUMNS}
          emptyRenderer
          data={tableData}
          rowProps={row => {
            return {
              onClick: () => {
                const { uuid } = row.original;
                history.push(`/management/company/${uuid}/about/`);
              },
              style: { cursor: "pointer" },
            };
          }}
        />
        <Paginator
          limit={10}
          offset={offset}
          count={data?.count}
          setOffset={setOffset}
        />
      </div>
    </div>
  );
};
