import React from "react";

export const FullScreenPage = props => {
  return (
    <div
      className="flex flex-column"
      style={{ minWidth: "100vw", minHeight: "100vh" }}
    >
      {props.children}
    </div>
  );
};
