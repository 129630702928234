import React, { useEffect, useState } from "react";

const useProfile = () => {
  const [profile, setProfile] = useState({});

  useEffect(() => {
    let profile = sessionStorage.getItem("profile");
    if (!profile) return;

    profile = JSON.parse(profile);
    setProfile(profile);
  }, [setProfile]);

  return profile;
};

export default useProfile;
