import axios from "axios";
import toast from "react-hot-toast";
import requests_object from "./config";

const BASE_URL = requests_object.getBaseUrl();

axios.defaults.baseURL = BASE_URL;

const config = {
  headers: {},
};

const notifyError = msg => {
  toast.error(msg);
};

const errorhandler = error => {
  if (error.message === "Network Error") {
    return notifyError("Network connection lost. Connect and try again");
  }

  // console.log(error);

  return Promise.reject({ ...error });
};

const successHandler = response => {
  return response;
};

const setToken = (config = {}) => {
  // config.headers["Access-Control-Allow-Origin"] = "*";
  config.headers["Accept"] = "application/json";

  let token = sessionStorage.getItem("token");

  if (token) {
    config.headers["Authorization"] = `Token ${token}`;
  }

  return config;
};

axios.interceptors.response.use(
  response => successHandler(response),
  error => errorhandler(error)
);

axios.interceptors.request.use(
  config => setToken(config),
  error => Promise.reject(error)
);

export const post = (route, payload) =>
  new Promise((resolve, reject) => {
    return axios
      .post(route, payload)
      .then(res => resolve(res))
      .catch(err => reject(err));
  });

export const patch = (route, payload) =>
  new Promise(function (resolve, reject) {
    axios
      .patch(route, payload)
      .then(res => resolve(res))
      .catch(err => reject(err));
  });

export const get = route =>
  new Promise((resolve, reject) =>
    axios
      .get(route, config)
      .then(res => resolve(res))
      .catch(err => reject(err))
  );

export const delete_request = route =>
  new Promise((resolve, reject) =>
    axios
      .delete(route)
      .then(res => resolve(res))
      .catch(err => reject(err))
  );

export const all = routes =>
  new Promise((resolve, reject) => {
    axios
      .all(routes)
      .then(res => resolve(res))
      .catch(err => reject(err));
  });

export default axios;
