import React, { useState } from "react";
import styled from "styled-components";
import { Colors } from "../../../constants";
import { Button } from "../../general";
import validator from "validator";
import { useAppContext } from "../../../context";
import { useHistory } from "react-router-dom";
import errorHandler from "../../../selectors/errorHandler";
import { get, post } from "../../../api/transport";
import { PhoneNumberInput } from "../../general/PhoneNumberInput";

const Form = ({ onSubmit }) => {
  const [first_name, set_first_name] = useState("");
  const [last_name, set_last_name] = useState("");
  const [email, set_email] = useState("");
  const [phone, set_phone] = useState("");
  const [password, set_password] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const { action, actions } = useAppContext();
  const [showPassword, setShowPassword] = useState(false);

  const history = useHistory();
  const [errors, set_errors] = React.useState({
    first_name: [],
    last_name: [],
    phone: [],
    email: [],
    password: [],
    password_confirm: [],
  });

  const disabled =
    [email, password, confirmPassword].some(el => el.length === 0) ||
    !phone ||
    Object.keys(errors).some(key => errors[key].length > 0) ||
    loading;

  function validateFirstName(firstName) {
    if (firstName === "") {
      set_first_name(firstName);
      return set_errors({
        ...errors,
        first_name: ["This field can not be left blank", ...errors.first_name],
      });
    }

    set_errors({
      ...errors,
      first_name: [],
    });
    set_first_name(firstName);
  }

  function validateLastName(lastName) {
    if (lastName === "") {
      set_last_name(lastName);
      return set_errors({
        ...errors,
        last_name: ["This field can not be left blank", ...errors.last_name],
      });
    }
    set_errors({
      ...errors,
      last_name: [],
    });
    set_last_name(lastName);
  }

  function validateEmail(emailArg) {
    if (emailArg === "") {
      set_email(emailArg);
      return set_errors({
        ...errors,
        email: ["Email field can not be left blank", ...errors.email],
      });
    }

    if (!emailArg.includes("@") || !validator.isEmail(emailArg)) {
      set_errors({
        ...errors,
        email: ["Invalid Email", ...errors.email],
      });
      return set_email(emailArg);
    }

    set_errors({
      ...errors,
      email: [],
    });
    set_email(emailArg);
  }

  function validatePhoneNumber(phoneNumber) {
    const empty = !phoneNumber;
    const string = String(phoneNumber);

    if (empty) {
      set_phone(phoneNumber);
      return set_errors({
        ...errors,
        phone: ["Phone number can not be left blank", ...errors.phone],
      });
    }

    if (!validator.isMobilePhone(string, false, { strictMode: true })) {
      set_phone(phone);
      return set_errors({
        ...errors,
        phone: ["Enter a valid phone number", ...errors.phone],
      });
    }

    set_errors({
      ...errors,
      phone: [],
    });
    set_phone(phoneNumber);
  }

  function validatePassword(passwordArg) {
    if (passwordArg === "") {
      set_password(passwordArg);
      return set_errors({
        ...errors,
        password: ["Password can not be empty", ...errors.password],
      });
    } else if (passwordArg.length < 8) {
      set_password(passwordArg);
      return set_errors({
        ...errors,
        password: ["Password is too short", ...errors.password],
      });
    } else if (!passwordArg.match(/\d+/g)) {
      set_password(passwordArg);
      return set_errors({
        ...errors,
        password: ["Password must include a number", ...errors.password],
      });
    } else if (confirmPassword.length > 0 && passwordArg !== confirmPassword) {
      set_password(passwordArg);
      return set_errors({
        ...errors,
        password: ["Passwords do not match", ...errors.password],
      });
    }

    set_errors({
      ...errors,
      password: [],
    });
    return set_password(passwordArg);
  }

  function validatePasswordConfirm(confirmPassword) {
    if (confirmPassword === "") {
      setConfirmPassword(confirmPassword);
      return set_errors({
        ...errors,
        password_confirm: [
          "Field can not be empty",
          ...errors.password_confirm,
        ],
      });
    } else if (password !== confirmPassword) {
      setConfirmPassword(confirmPassword);
      return set_errors({
        ...errors,
        password_confirm: [
          "Passwords do not match",
          ...errors.password_confirm,
        ],
      });
    }

    set_errors({
      ...errors,
      password_confirm: [],
    });
    setConfirmPassword(confirmPassword);
  }

  // console.log(phone);

  return (
    <StyledForm>
      <div className="joint-row">
        <div className="input-field">
          <label>Your First Name</label>
          <input
            required={true}
            aria-required={true}
            value={first_name}
            onChange={e => {
              let val = e.target.value;
              validateFirstName(val.trim());
            }}
            type="text"
            placeholder="First Name"
          />
          {errors.first_name?.[0] && (
            <p className="error-msg">{errors.first_name[0]}</p>
          )}
        </div>
        <div className="input-field">
          <label>Your Last Name</label>
          <input
            value={last_name}
            onChange={e => {
              let val = e.target.value;
              validateLastName(val.trim());
            }}
            type="text"
            placeholder="Last Name"
          />
          {errors.last_name?.[0] && (
            <p className="error-msg">{errors.last_name[0]}</p>
          )}
        </div>
      </div>
      <div className="row">
        <div className="input-field">
          <label>Your Email Address</label>
          <input
            type="email"
            value={email}
            onChange={e => validateEmail(e.target.value)}
            placeholder="Eg. someone@gmail.com"
            required={true}
          />
          {errors.email[0] && <p className="error-msg">{errors.email[0]}</p>}
        </div>
      </div>

      <div className="row">
        <div className="input-field">
          <label>Your Phone Number</label>

          <PhoneNumberInput
            value={phone}
            setValue={validatePhoneNumber}
            placeholder="Eg. 024 424 2342"
          />

          {errors.phone[0] && <p className="error-msg">{errors.phone[0]}</p>}
        </div>
      </div>

      <div className="row">
        <div className="input-field">
          <label>Password</label>
          <div className="relative">
            <input
              className="pseudo-input"
              value={password}
              onChange={e => validatePassword(e.target.value)}
              type={showPassword ? "text" : "password"}
              placeholder="Enter a password"
              required={true}
            />
            <div
              onClick={() => setShowPassword(!showPassword)}
              className="pointer flex grow justify-center align-center absolute"
              style={{ right: "2%", top: "30%" }}
            >
              <p className="m-0 bold purple">
                {showPassword ? "HIDE" : "SHOW"}
              </p>
            </div>
          </div>
          {errors.password[0] && (
            <p className="error-msg">{errors.password[0]}</p>
          )}
        </div>
      </div>

      <div className="row">
        <div className="input-field">
          <label>Confirm Password</label>
          <input
            type={showPassword ? "text" : "password"}
            value={confirmPassword}
            onChange={e => validatePasswordConfirm(e.target.value)}
            placeholder="Confirm password"
          />
          {errors.password_confirm[0] && (
            <p className="error-msg">{errors.password_confirm[0]}</p>
          )}
        </div>
      </div>

      <div className="row jc-flex-end">
        <Button
          loading={loading}
          disabled={disabled}
          onClick={() => {
            handleUserCreate();
          }}
          text="Continue"
        />
      </div>
    </StyledForm>
  );

  async function handleUserCreate() {
    const account_data = {
      first_name: first_name.trim(),
      last_name: last_name.trim(),
      phone_number: phone,
      email: email,
      password: password,
    };
    setLoading(true);

    try {
      const response = await post("/users/", account_data);

      const login_response = await post("/users/token/login/", {
        email,
        password,
      });

      const { data } = login_response;

      sessionStorage.setItem("email", email);

      sessionStorage.setItem("token", data.auth_token);

      action(actions.setUser, { user: response.data });

      const { data: profileData } = await get("/users/me/");

      sessionStorage.setItem("profile", JSON.stringify(profileData));

      history.push("/account-setup/company-information");
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  }
};

export default Form;

export const StyledForm = styled.form`
  padding: 5% 0;
  flex: 1;
  display: flex;
  flex-direction: column;

  .row {
    display: flex;
    align-items: center;

    .card-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 1;

      @media (max-width: 1024px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        gap: 1em;
      }
    }
  }

  .joint-row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 1em;

    @media (max-width: 1024px) {
      flex-direction: column;
      align-items: initial;

      .input-field {
        margin-left: 0 !important;
        margin-bottom: 1em !important;
      }
    }
  }

  p {
    margin: 0;
  }

  .row + .row {
    padding-top: 2em;
  }

  .input-field {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .relative {
    position: relative;
  }
  .input-field + .input-field {
    margin-left: 1em;
  }

  .input-field > label {
    font-family: CircularStd;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
    padding-bottom: 6px;
  }

  .input-field > input,
  select,
  .pseudo-input {
    width: 100%;
    height: 50px;
    border: 1px solid #d9dae4;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 15px 20px;
    background: #ffffff;

    font-family: CircularStd;
    font-size: 16px;
    line-height: 20px;
  }

  .input-field > input:focus,
  select:focus,
  .pseudo-input:focus {
    border: 1px solid #72004c;
    box-sizing: border-box;
    box-shadow: 0px 0px 0px 4px rgba(114, 0, 76, 0.08);
    border-radius: 6px;
  }

  .jc-flex-end {
    justify-content: flex-end;
  }

  .input-field > .pseudo-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }

  .input-field > input:focus {
    box-shadow: 0px 0px 0px 4px rgba(114, 0, 76, 0.08);
    outline: none;
  }

  .pseudo-phone-input {
    width: 100%;
    height: 50px;
    border: 1px solid #d9dae4;
    box-sizing: border-box;
    border-radius: 6px;
    display: flex;
    align-items: center;
    display: flex;
    background: #ffffff;

    .phone-input-selector-section {
      padding-right: 1em;
      display: flex;
      position: relative;
      cursor: pointer;

      p {
        white-space: nowrap;
      }

      .selected {
        background: #f4f6fa;
      }

      .country-code-dropdown {
        &:hover {
          background: #f4f6fa;
        }
      }
    }

    .phone-input-number-section {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      background: #ffffff;
      flex-grow: 1;

      input {
        width: 100%;
        height: 100%;
        padding: 0 15px;
        font-family: CircularStd;
        font-size: 16px;
        line-height: 20px;
        border: 0;
        border-left: 0.01em solid ${() => Colors.grey};
      }

      input:focus {
        outline: none;
      }
    }
  }

  .pseudo-phone-input:focus {
    box-shadow: 0px 0px 0px 4px rgba(114, 0, 76, 0.08);
    outline: none;
  }

  .dropdown {
    position: absolute;
    top: 105%;
    width: 100%;
    border: 1px solid #d9dae4;
    border-radius: 8px;
    background: white;
    padding: 0.5em 0;
    z-index: 10;
    max-height: 10em;
    overflow: auto;

    border: 1px solid #72004c;
    box-sizing: border-box;
    box-shadow: 0px 0px 0px 4px rgba(114, 0, 76, 0.08);
    border-radius: 6px;

    .dropdown-item:hover {
      background-color: #f4f6fa !important;
      // background-color: rgba(114, 0, 76, 1);
    }

    .dropdown-item {
      padding: 0.5em 20px;
      cursor: pointer;
      color: ${props => Colors.grey_2};
    }
  }
  .selected {
    background: #f4f6fa !important;
  }
  .error-msg {
    color: red;
    margin: 0;
    margin-top: 0.5em;
  }
`;
