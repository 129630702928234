import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import Modal from "react-modal";
import { Colors } from "../../constants";
import { ReactComponent as Close } from "../../assets/svgs/close.svg";
import { TextButton } from "../general";
import ModalTitle from "./ModalTitle";
import ModalFormStyleSheet from "./ModalFormStyleSheet";
import { useAppContext } from "../../context";
import { useOutsideDetect } from "../../selectors/UseOutsideDetect";
import toast from "react-hot-toast";
import DropdownLoader from "./DropdownLoader";
import errorHandler from "../../selectors/errorHandler";
import AutoCompleteInput from "../general/AutoCompleteInput";
import { post } from "../../api/transport";
// import { ModalForm } from "../branch-managers";

const AddBranchManagerModal = props => {
  const { branches, setBranches: set_branches } = props;

  const [first_name, set_first_name] = useState("");
  const [last_name, set_last_name] = useState("");
  const [email, set_email] = useState("");
  const [password, set_password] = useState("Itsundone007@@");

  const [new_branch_name, set_new_branch_name] = useState("");
  const [new_branch_location, set_new_branch_location] = useState("");

  const [branchSelection, setBranchSelection] = useState("existing");
  // const [branches, setBranches] = useState([]);
  const [selected_branch, set_selected_branch] = useState();
  const [showDropdown, setShowDropdown] = useState(false);
  const { fetchToken, appState } = useAppContext();
  const [loading, setLoading] = useState();
  const [save_loading, set_save_loading] = useState(false);
  const dropdownRef = useRef();

  function clearSelection() {
    set_first_name("");
    set_last_name("");
    set_email("");
    set_selected_branch(undefined);
    set_new_branch_location("");
    set_new_branch_name("");
    set_password(password);
  }

  useOutsideDetect(dropdownRef, () => setShowDropdown(false));

  useEffect(() => {
    let mounted = true;
    if (mounted) Modal.setAppElement("body");
    return () => {
      clearSelection();
      mounted = false;
    };
  }, []);

  return (
    <Modal
      style={{
        overlay: {
          background: "rgba(0,0,0,0.7)",
        },
        content: {
          background: Colors.white,
          margin: "auto auto",
          width: "30%",
          height: "50%",
        },
      }}
      isOpen={props.isOpen}
      onRequestClose={props.closeModal}
    >
      <AddBranchManagerModalContent>
        <div className="close-button-area">
          <Close id="close-button" onClick={props.closeModal} />
        </div>

        <div className="flex grow flex-column z-1">
          <ModalTitle
            title="Add a Branch Manager"
            subTitle="A link will be sent to the manager’s email address to complete
              account setup."
          />

          <ModalFormStyleSheet>
            <div className="row">
              <div className="input-field">
                <label>Manager's First Name</label>
                <input
                  value={first_name}
                  onChange={({ target }) => set_first_name(target.value)}
                  type="text"
                  placeholder="Eg. James"
                />
              </div>
              <div className="input-field">
                <label>Manager's Last Name</label>
                <input
                  value={last_name}
                  onChange={({ target }) => set_last_name(target.value)}
                  type="text"
                  placeholder="Eg. Anderson"
                />
              </div>
            </div>
            <div className="row">
              <div className="input-field">
                <label>Manager's Email Address</label>
                <input
                  type="email"
                  placeholder="Eg. jamesanderson@echo.com"
                  value={email}
                  onChange={({ target }) => set_email(target.value)}
                />
              </div>
            </div>

            <div className="row">
              <div className="input-field">
                <label>Manager's Default Password</label>
                <input
                  type="text"
                  placeholder="Enter password"
                  value={password}
                  onChange={({ target }) => set_password(target.value)}
                />
              </div>
            </div>

            <div className="row">
              <div className="input-field">
                <div className="label-container">
                  <label>Select Branch</label>
                  <p
                    onClick={toggleBranchSelection}
                    style={{
                      fontSize: ".8em",
                      color: Colors.purple,
                      cursor: "pointer",
                    }}
                  >
                    {branchSelection === "existing" && "+ Add a New Branch"}
                    {branchSelection === "new" && "Select an existing branch"}
                  </p>
                </div>
                {branchSelection === "existing" && (
                  <div
                    className="relative bordered-0 input-field"
                    ref={dropdownRef}
                  >
                    <div
                      onClick={() => setShowDropdown(!showDropdown)}
                      className="pseudo-input gray flex justify-between align-center pointer "
                    >
                      <p className="hide-overflow">
                        {selected_branch
                          ? `${selected_branch.name} | ${selected_branch.location_name}`
                          : "Select Manager's Branch"}
                      </p>
                      <span className="pointer">▾</span>
                    </div>
                    <div
                      className="dropdown bordered absolute bg-w p-t-1"
                      style={{
                        display: showDropdown ? "block" : "none",
                        top: "110%",
                        width: "99.5%",
                      }}
                    >
                      {loading && <DropdownLoader />}
                      {branches?.map(branch => (
                        <div
                          key={Math.random()}
                          className="m-b-1 flex align-center"
                        >
                          <div className="checkbox-wrapper">
                            <input
                              type="checkbox"
                              id={branch.uuid}
                              name={branch.uuid}
                              checked={selected_branch?.uuid === branch.uuid}
                              className="m-h-1"
                              onChange={() => selectBranch(branch)}
                            />
                          </div>
                          <p className="m-0 gray-2">
                            {branch.name} | {branch.location_name}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                {branchSelection === "new" && (
                  <div className="row">
                    <div className="input-field">
                      <div className="label-container">
                        <label>Branch Name</label>
                      </div>
                      <input
                        value={new_branch_name}
                        onChange={({ target }) =>
                          set_new_branch_name(target.value)
                        }
                        type="text"
                        placeholder="Eg. Greater Accra"
                      />
                    </div>
                    <div className="input-field relative">
                      <div className="label-container">
                        <label>Location</label>
                      </div>
                      <AutoCompleteInput
                        className="pseudo-input"
                        placeholder="Eg. Agyia Zongo, Accra, Ghana"
                        onChange={value => set_new_branch_location(value)}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </ModalFormStyleSheet>

          <div className="flex justify-center align-center m-0 p-v-1 z-1 ">
            <TextButton
              text="Continue"
              styleProp={{
                padding: ".5em",
                color: Colors.white,
                background: Colors.purple,
              }}
              loading={save_loading ? "true" : ""}
              onClick={createBranchManager}
            />
          </div>
        </div>
      </AddBranchManagerModalContent>
    </Modal>
  );

  function toggleBranchSelection() {
    if (branchSelection === "new") {
      setBranchSelection("existing");
    } else {
      setBranchSelection("new");
    }
  }

  function selectBranch(branch) {
    if (selected_branch?.uuid === branch.uuid) {
      set_selected_branch(null);
    } else {
      set_selected_branch(branch);
    }
  }

  function createNewBranch() {
    const branch_data = {
      is_active: true,
      name: new_branch_name,
      location_name: new_branch_location,
      status: "Approved",
      company: appState.profile?.company?.id,
    };
    return post(`/user/branch/`, branch_data)
      .then(response => response.data)
      .catch(error => errorHandler(error));
  }

  async function createBranchManager() {
    set_save_loading(true);
    toast.loading("Please wait");
    let branch = selected_branch?.id;

    if (branchSelection === "new") {
      try {
        const new_branch = await createNewBranch();
        branch = new_branch.id;
        set_branches([...branches, new_branch]);
      } catch (error) {
        props.closeModal();
        return;
      }
    }

    const new_branch_manager = {
      first_name: first_name,
      last_name: last_name,
      email: email,
      password: password,
      branch,
      is_staff: true,
      is_branch_manager: true,
    };
    post("/users/staff/", new_branch_manager)
      .then(response => {
        set_save_loading(false);
        const newSet = [...props.branchManagers, response.data];
        props.setBranchManagers(newSet);
        props.closeModal();
        toast.success("Saved");
      })
      .catch(error => {
        set_save_loading(false);
        errorHandler(error);
      })
      .finally(() => {
        return clearSelection();
      });
  }
};

export default AddBranchManagerModal;

const AddBranchManagerModalContent = styled.div`
  // border: 1px solid;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .close-button-area {
    display: flex;
    flex-direction: row-reverse;
    // ;

    #close-button {
      // border: 1px solid;
      height: 0.5em;
      width: 0.5em;
      cursor: pointer;
    }
  }
`;
