import React from "react";
import { CompaniesTable } from "../../components/companies";
import { Layout } from "../../components/dashboard";
import { DocumentHeader } from "../../components/general/DocumentHeader";

const AllCompanies = () => {
  return (
    <Layout>
      <DocumentHeader page="Companies" />
      <CompaniesTable />
    </Layout>
  );
};

export default AllCompanies;
