import numeral from "numeral";
import React from "react";
import styled from "styled-components";

const BillCard = props => {
  const {
    header = "Price Per Tablet",
    figure = "10.00",
    perMeasure = "tablet",
  } = props;

  let amount_shown = (Math.round(figure * 100) / 100).toFixed(2);

  return (
    <StyledBillCard style={{ height: "110px", ...props.style }}>
      <div className="billcard-content">
        <p style={{ fontSize: "1em" }}>{header}</p>
        <p>
          <sup>$</sup>
          <span className="fig">{numeral(amount_shown).format("0,0.00")}</span>
          <sub style={{ fontSize: "1em", margin: 0 }}>/ {perMeasure}</sub>
        </p>
      </div>
    </StyledBillCard>
  );
};

export default BillCard;

const StyledBillCard = styled.div`
  background: #f3f3f7;
  border-radius: 6px;
  padding: 0.5em 2em;
  display: flex;
  align-items: center;

  p {
    margin: 0;
  }

  .billcard-content {
    // border: 1px solid;

    .fig {
      font-family: CircularStdBold;
      font-size: 2em;
    }
  }
`;
