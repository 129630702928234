import React from "react";
import { ChooseSubscription } from "../../../page-content/account-creation";
import ContentGeneric from "../Content";

const Content = () => {
  return (
    <ContentGeneric>
      <ChooseSubscription />
    </ContentGeneric>
  );
};

export default Content;
