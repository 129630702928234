import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import Modal from "react-modal";
import { Colors } from "../../constants";
import { ReactComponent as Close } from "../../assets/svgs/close.svg";
import { EmojiTextButton, TextButton } from "../general";
import ModalTitle from "./ModalTitle";

import { ReactComponent as Excellent } from "../../assets/svgs/Excellent.svg";
import { ReactComponent as Good } from "../../assets/svgs/Good.svg";
import { ReactComponent as Terrible } from "../../assets/svgs/Terrible.svg";
import { ReactComponent as Bad } from "../../assets/svgs/Bad.svg";
import { ReactComponent as Okay } from "../../assets/svgs/Okay.svg";

import { ReactComponent as Check } from "../../assets/svgs/white-check.svg";

import ExcellentFile from "../../assets/pngs/Excellent.png";
import GoodFile from "../../assets/pngs/Good.png";
import TerribleFile from "../../assets/pngs/Terrible.png";
import BadFile from "../../assets/pngs/Bad.png";
import OkayFile from "../../assets/pngs/Okay.png";

import { v4 } from "uuid";
import toast from "react-hot-toast";

import { useDeviceSettingsContext } from "../../context/DeviceSettingsContext";
import colors from "../../constants/colors";
import errorHandler from "../../selectors/errorHandler";
import { useAppContext } from "../../context";
import objectToFormData from "../../selectors/objectToFormData";
import { patch, post } from "../../api/transport";
import { useDeleteSentimentSettings } from "../emojis/broker";

// console.log("%PUBLIC_URL%/Bad.png");

const SelectEmojiModal = props => {
  useEffect(() => {
    let mounted = true;
    if (mounted) setModalAppElement();
    return () => (mounted = false);
  }, []);

  const {
    action,
    actions,
    appState: sentiment_settings_state,
  } = useDeviceSettingsContext();

  const emojiDict = {
    excellent: [
      {
        id: v4(),
        component: Excellent,
        file: ExcellentFile,
        imageSrc: `../../assets/pngs/Excellent.png`,
      },
    ],
    good: [
      {
        id: v4(),
        component: Good,
        file: GoodFile,
        imageSrc: `../../assets/pngs/Good.png`,
      },
    ],
    okay: [
      {
        id: v4(),
        component: Okay,
        file: OkayFile,
        imageSrc: `../../assets/pngs/Okay.png`,
      },
    ],
    bad: [
      {
        id: v4(),
        component: Bad,
        file: BadFile,
        imageSrc: `../../assets/pngs/Bad.png`,
      },
    ],
    terrible: [
      {
        id: v4(),
        component: Terrible,
        file: TerribleFile,
        imageSrc: `../../assets/pngs/Terrible.png`,
      },
    ],
  };

  const styleObject = {
    overlay: {
      background: "rgba(0,0,0,0.7)",
    },
    content: {
      background: Colors.white,
      margin: "auto auto",
      width: "30%",
      height: "60%",
    },
  };

  const [selected_image, set_selected_image] = useState(undefined);

  const [preview, set_preview] = useState();
  const fileInputRef = useRef();
  const { appState } = useAppContext();

  const [sentiment_setting] = useState({
    is_active: true,
    title: props.data.name,
    rating: props.data.rating,
    status: "public",
    source: "Tablet",
    company: appState.profile?.company?.id,
    emoji: undefined,
  });

  const { title } = sentiment_setting;
  const { id: saved_setting_id, file: saved_setting_file } =
    sentiment_settings_state.company_emojis[title.toLowerCase()];

  const {
    mutateAsync: deleteSentimentSettingAsync,
    isLoading: deleteSentimentLoading,
  } = useDeleteSentimentSettings(saved_setting_id);

  const handleChange = event => {
    event.preventDefault();
    const file = event.target.files[0];
    const isImage = file && file.type.substr(0, 5) === "image";
    if (isImage) {
      action(actions.set_company_emojis, {
        title: title.toLowerCase(),
        data: {
          file,
        },
      });
    }
  };

  const handleUpload = event => {
    set_selected_image(undefined);
    event.preventDefault();
    fileInputRef.current.click();
  };

  const handleRemoveImage = event => {
    action(actions.set_company_emojis, {
      title: title.toLowerCase(),
      data: {
        file: undefined,
      },
    });
    set_preview(null);
  };

  const setPreviewHelper = () => {
    if (saved_setting_file) {
      const reader = new FileReader();
      reader.onloadend = () => set_preview(reader.result);
      reader.readAsDataURL(saved_setting_file);
    } else {
      return set_preview(null);
    }
  };

  const patchSentimentSetting = (data, id) => {
    return patch(`/user/sentiment-settings/${id}/`, data)
      .then(response => response.data)
      .catch(error => errorHandler(error));
  };

  const postSentimentSetting = data => {
    return post("/user/sentiment-settings/", data)
      .then(response => response.data)
      .catch(error => errorHandler(error));
  };

  const getBlobFromImage = selected_image => {
    return fetch(selected_image)
      .then(res => res.blob())
      .then(a => a);
  };

  const selectEmoji = async () => {
    let emoji_key = props.data.name;
    const lower_case_key = emoji_key.toLowerCase();
    const payload = {};

    if (!saved_setting_file && !selected_image) {
      toast.loading("Please wait");

      try {
        await deleteSentimentSettingAsync();

        action(actions.set_company_emojis, {
          title: sentiment_setting?.title.toLowerCase(),
          data: {
            id: undefined,
            link: undefined,
          },
        });

        toast.success("Emoji removed successfully");
      } catch (error) {
        errorHandler(error);
      } finally {
        return props.closeModal();
      }
    }

    (saved_setting_file || selected_image) && toast.loading("Saving");

    let request = !!saved_setting_id
      ? patchSentimentSetting
      : postSentimentSetting;

    if (saved_setting_file) {
      try {
        const { title, id, emoji } = await request(
          objectToFormData({ ...sentiment_setting, emoji: saved_setting_file }),
          saved_setting_id
        );
        props.closeModal();
        toast.success("Success");
        action(actions.set_company_emojis, {
          title: title.toLowerCase(),
          data: {
            id,
            link: emoji,
          },
        });
      } catch (error) {
        props.closeModal();
        return;
      }
    }

    if (selected_image) {
      payload[lower_case_key] = selected_image;

      try {
        let blob_response = await getBlobFromImage(selected_image);

        const emojiFile = new File(
          [blob_response],
          `${sentiment_setting?.title || "emoji"}.png`
        );

        const { title, id, emoji } = await request(
          objectToFormData({ ...sentiment_setting, emoji: emojiFile }),
          saved_setting_id
        );

        props.closeModal();
        toast.success("Success");

        action(actions.set_company_emojis, {
          title: title.toLowerCase(),
          data: {
            id,
            link: emoji,
          },
        });
      } catch (error) {
        props.closeModal();
        return;
      }
    }
  };

  const thisChild = emojiDict[props.data.name.toLowerCase()];

  useEffect(() => {
    let mounted = true;
    if (mounted) setPreviewHelper();
    return () => (mounted = false);
  }, [saved_setting_file]);

  return (
    <Modal
      style={styleObject}
      isOpen={props.isOpen}
      onRequestClose={props.closeModal}
    >
      <SelectEmojiModalContent>
        <div className="close-button-area">
          <Close id="close-button" onClick={props.closeModal} />
        </div>

        <div className="flex grow flex-column">
          <div className="flex grow flex-column ">
            <ModalTitle
              title={`Select an Emoji for ${props.data.name}`}
              subTitle="Choose an Emoji from the ones provided below. You can change this later."
            />

            <input
              className="hide"
              accept="image/*"
              type="file"
              ref={fileInputRef}
              onChange={handleChange}
            />

            {!preview && (
              <div className="flex grow align-start flex-wrap gap p-1">
                {thisChild?.map(el => {
                  const file_path = el.file;

                  return (
                    <EmojiSelect
                      {...el}
                      selected_image={selected_image}
                      src={el.component}
                      key={Math.random()}
                      onClick={() => {
                        if (selected_image === file_path) {
                          return set_selected_image(undefined);
                        }

                        return set_selected_image(file_path);
                      }}
                    />
                  );
                })}
              </div>
            )}

            {preview && (
              <div className=" flex flex-column grow justify-center align-center">
                <div className="flex flex-column justify-center gap">
                  <p className="m-b-p5">Uploaded image</p>
                  <div
                    className="p-2 flex flex-column justify-center align-center r-8 m-b-p5"
                    style={{ border: "1px solid #72004C" }}
                  >
                    <div
                      className="circle bg-gray "
                      style={{
                        width: "80px",
                        height: "80px",
                      }}
                    >
                      <img
                        src={preview}
                        style={{
                          width: "inherit",
                          height: "inherit",
                          objectFit: "contain",
                        }}
                        className="circle"
                      />
                    </div>
                  </div>
                  <button
                    className="r-8 bg-w p-1 bordered-0 purple pointer"
                    onClick={handleRemoveImage}
                    style={{ background: "#F1EEEE" }}
                  >
                    Remove
                  </button>
                </div>
              </div>
            )}

            <div className="flex justify-center align-center m-h-0 m-b-1 m-t-auto">
              <div className="bordered">
                <EmojiTextButton
                  styleProp={{
                    color: Colors.purple,
                    padding: "1em",
                    display: "flex",
                    flex: 1,
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "#fff",
                  }}
                  text={"Upload Emoji"}
                  onClick={handleUpload}
                />
              </div>
              <div className="bordered">
                <TextButton
                  text="Continue"
                  styleProp={{
                    flex: 1,
                    padding: "1em",
                    color: Colors.white,
                    background: Colors.purple,
                  }}
                  onClick={selectEmoji}
                />
              </div>
            </div>
          </div>
        </div>
      </SelectEmojiModalContent>
    </Modal>
  );

  function setModalAppElement() {
    Modal.setAppElement("body");
    return () => {};
  }
};

function EmojiSelect({ src: Icon, onClick, selected_image, file, imageSrc }) {
  // console.log(idx);
  const selected = file === selected_image;

  console.log(imageSrc);
  return (
    <EmojiSelectSheet onClick={onClick} selected={selected}>
      <div className="check-box flex justify-center align-center">
        {selected && <Check />}
      </div>
      {/* <Icon style={{ width: "80px", height: "80px" }} /> */}
      <img src={file} style={{ height: "80px", width: "80px" }} />
    </EmojiSelectSheet>
  );
}

const EmojiSelectSheet = styled.div`
  border: 1px solid ${({ selected }) => (selected ? colors.purple : "#d9dae4")};
  padding: 2em;
  border-radius: 8px;
  cursor: pointer;
  position: relative;

  .check-box {
    position: absolute;
    top: 10px !important;
    right: 10px !important;
    border: 1px solid #d9dae4;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background: ${({ selected }) => (selected ? colors.purple : "initial")};
  }

  :hover {
    border: 1px solid #72004c;
    box-sizing: border-box;
    box-shadow: 0px 0px 0px 4px rgba(114, 0, 76, 0.08);
    border-radius: 8px;
  }
`;

const SelectEmojiModalContent = styled.div`
  // border: 1px solid;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .close-button-area {
    display: flex;
    flex-direction: row-reverse;
    // ;

    #close-button {
      // border: 1px solid;
      height: 0.5em;
      width: 0.5em;
      cursor: pointer;
    }
  }
`;

export default SelectEmojiModal;
