import React, { useState, useRef } from "react";
import styled from "styled-components";
import { ReactComponent as Dots } from "../../assets/svgs/dots.svg";
import { useOutsideDetect } from "../../selectors/UseOutsideDetect";

function DotsButton({
  actions = [],
  deletefunction,
  editfunction,
  disabled: button_disabled,
  ...props
}) {
  const [showDropdown, setShowDropdown] = useState(false);
  const buttonRef = useRef(null);
  useOutsideDetect(buttonRef, () => setShowDropdown(false));
  const supplied_actions = actions.length !== 0;
  // const { deletefunction, editfunction } = props;
  // console.log(editfunction, deletefunction);

  return (
    <div
      className="flex justify-center align-center border-box pointer relative m-l-p5 bg-w"
      onClick={() => setShowDropdown(!showDropdown)}
      ref={buttonRef}
      {...props}
      style={{
        border: "1px solid #F5F3F3",
        borderRadius: "4px",
        width: "33px",
        height: "33px",
      }}
    >
      <Dots />
      {showDropdown && (
        <StyledDropdown>
          {button_disabled || supplied_actions ? (
            actions.map(el => {
              const { disabled, onClick, ...rest } = el;
              return (
                <div
                  className={`dropdown-item ${disabled && `half-transparent`}`}
                  {...rest}
                  onClick={() => disabled || onClick()}
                  key={Math.random()}
                >
                  <p>{el.title}</p>
                </div>
              );
            })
          ) : (
            <>
              <div onClick={editfunction} className="dropdown-item">
                <p>Edit</p>
              </div>
              <div onClick={deletefunction} className="dropdown-item">
                <p className="red">Delete</p>
              </div>
            </>
          )}
        </StyledDropdown>
      )}
    </div>
  );
}

export default DotsButton;

const StyledDropdown = styled.div`
  border: 1px solid #f5f3f3;
  background: white;
  border-radius: 4px;
  box-shadow: 0px 4px 16px rgba(21, 21, 21, 0.08);
  position: absolute;
  top: 110%;
  right: 0;
  z-index: 1;
  overflow: hidden;

  .dropdown-item {
    padding: 1em 2em;

    &:hover {
      background: #f4f6fa;
    }

    p {
      margin: 0;
      white-space: nowrap;
    }
  }
`;
