import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { EmojiTextButton } from "../components/general";
import colors from "../constants/colors";

const NotFound = () => {
  const history = useHistory();

  const routeToHome = useCallback(() => {
    return history.replace("/dashboard/overview");
  }, [useHistory]);

  return (
    <div className="not-found-page justify-center align-center">
      <div>
        <h1 className="purple bold not-found-text">Oops!</h1>
        <p className="not-found-subtext">
          The page you were looking for does not exist
        </p>
        <div className="flex justify-center m-v-2">
          <EmojiTextButton.Custom
            text="Back To Home"
            color="white"
            backgroundColor={colors.purple}
            padding="1em 2em"
            onClick={routeToHome}
          />
        </div>
      </div>
    </div>
  );
};

export default NotFound;
