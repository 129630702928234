import React from "react";
import styled from "styled-components";
import SidebarCategory from "./SidebarCategory";
import SidebarItem from "./SidebarItem";
import { Colors } from "../../constants";
import {
  // categoryNames,
  getSidebarItems,
  getCategoryNames,
} from "./SidebarItems";
import Logo from "../Logo";
import { useAppContext } from "../../context";
import { isOnboardingComplete } from "../../selectors";
import useProfile from "../../hooks/useProfile";

const Sidebar = () => {
  const { appState } = useAppContext();
  const profile = appState?.profile;

  const sidebarItems = getSidebarItems(profile);
  const categoryNames = getCategoryNames(profile);
  const is_super_admin = profile.is_superuser;

  return (
    <SidebarParent>
      <div className="sidebar-head">
        <span className="logo-chip">
          <Logo />
        </span>
        <div>
          <h4>Echo Feedback</h4>
          <p>{appState.profile?.company?.name}</p>
        </div>
      </div>

      <div className="sidebar-bottom">
        {/* getting started */}
        {is_super_admin ||
          (!isOnboardingComplete(profile) && (
            <SidebarItem
              {...sidebarItems["Start"]}
              style={{
                marginBottom: "1rem",
                marginTop: ".5rem",
              }}
            />
          ))}

        {/* rest of sidebar */}
        {categoryNames.map(name => {
          let category_empty = !sidebarItems[name];
          if (category_empty) return false;

          return (
            <SidebarCategory
              items={sidebarItems[name]}
              name={name}
              key={name}
            />
          );
        })}

        {/* sidebar footer */}
        <p className="sidebar-foot">
          <span>Powered by</span>
          <Logo />
        </p>
      </div>
    </SidebarParent>
  );
};

export default Sidebar;

const SidebarParent = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  z-index: 0;
  flex-basis: 15%;

  p {
    margin: 0;
  }

  @media (max-width: 1023px) {
    display: none;
  }

  .sidebar-head {
    height: 72px;
    box-sizing: border-box;
    padding: 15px 20px;
    display: flex;
    align-items: center;
    background: ${props => Colors.purple};
    color: ${Colors.white};

    .logo-chip {
      border: 2px solid ${Colors.white};
      width: 47px;
      height: 47px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 16px;
    }

    h4 {
      margin: 0;
      padding: 0;
      margin-bottom: 3px;
      font-size: 0.8em;
    }

    p {
      color: ${Colors.white_4};
      font-size: 0.6em;
    }
  }

  .sidebar-bottom {
    background: ${props => Colors.purple_1};
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    // border: 1px solid white;
    overflow-y: auto;
    scrollbar-width: 0;
    // max-width: 20em;
    // overflow-x: hidden;
    z-index: 0;

    &::-webkit-scrollbar {
      display: none;
    }

    .sidebar-foot {
      margin-top: auto;
      padding: 0 15px;
      font-size: 14px;
      padding-bottom: 0;
      color: ${Colors.white_4};

      span {
        margin-right: 5px;
      }
    }
  }
`;
