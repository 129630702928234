import React from "react";
import styled from "styled-components";
import { ReactComponent as Arrow } from "../../assets/svgs/arrow-left.svg";
import { Colors } from "../../constants/";
import Steps from "./Steps";
import { useHistory } from "react-router-dom";

const Head = props => {
  let history = useHistory();

  function handleBack() {
    history.goBack();
  }
  return (
    <StyledHead>
      <div className="head-left-section">
        {props.back !== false && (
          <span
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              color: Colors.purple,
            }}
            onClick={handleBack}
          >
            <Arrow id="left-arrow" fill={Colors.purple} />
            <p>Back</p>
          </span>
        )}
      </div>
      <Steps numberOfSteps={4} currentStep={props.currentStep} />
    </StyledHead>
  );
};

export default Head;

const StyledHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .head-left-section {
    display: flex;
    align-items: center;
  }

  #left-arrow {
    height: 1.2em;
    width: 1.2em;
    margin-right: 0.5em;
  }

  .head-left-section > p {
    color: ${() => Colors.purple};
    font-size: 1.2em;
  }
`;
