import React from "react";
import styled from "styled-components";
import Sidebar from "../../components/login/Sidebar";
import { Content } from "../../components/login/";
import { DocumentHeader } from "../../components/general/DocumentHeader";

const Wrapper = () => {
  return (
    <StyledWrapper>
      <DocumentHeader page="Login" />
      <Sidebar />
      <Content />
    </StyledWrapper>
  );
};

export default Wrapper;

const StyledWrapper = styled.div`
  display: flex;
  flex: 1;
`;
