import React from "react";
import styled from "styled-components";
import { Colors } from "../../constants";
import { ReactComponent as Happy } from "../../assets/svgs/happy.svg";

const EmojiSelection = () => {
  const emojiArray = [1, 2, 3, 4];
  return (
    <StyledEmojiSelection>
      {emojiArray.map(() => {
        return <EmojiOption />;
      })}
    </StyledEmojiSelection>
  );
};

export default EmojiSelection;

const StyledEmojiSelection = styled.div`
  display: flex;
  //   border: 1px solid green;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0.5em 0;
`;

function EmojiOption() {
  return (
    <StyledEmojiOption>
      <div
        style={{
          //   border: "1px solid red",
          display: "flex",
          flexDirection: "row-reverse",
          padding: ".1em .1em",
        }}
      >
        <input
          type="checkbox"
          style={{
            borderRadius: "50%",
          }}
        />
      </div>
      <div
        style={{
          flexGrow: 1,
          //   border: "1px solid purple",
          padding: ".5em 0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Happy
          style={{
            width: "60%",
            // height: "70%",
            // border: "1px solid",
            padding: 0,
            margin: 0,
          }}
        />
      </div>
    </StyledEmojiOption>
  );
}

const StyledEmojiOption = styled.div`
  border: 1px solid ${() => Colors.grey_3};
  width: 30%;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  margin-bottom: 0.8em;
  //   padding: 0.2em;

  :hover {
    box-shadow: 0px 0px 0px 4px rgba(114, 0, 76, 0.08);
    border: 1px solid ${() => Colors.purple};
  }
`;

export { EmojiOption };
