import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

const withAuth = WrappedComponent => {
  return props => {
    const history = useHistory();
    const [verified, setVerified] = useState(false);

    const navigateToLogin = useCallback(() => {
      history.push("/login");
    }, [history]);

    useEffect(() => {
      let token = sessionStorage.getItem("token");

      if (token) {
        return setVerified(true);
      }

      if (!token) {
        return navigateToLogin();
      }

      return () => {};
    }, []);

    if (verified) {
      return <WrappedComponent {...props} />;
    }

    return null;
  };
};

export default withAuth;
