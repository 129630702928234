import { useQuery } from "react-query";
import { get } from "../../api/transport";

export const useCategories = () => {
  const queryKey = "categories";

  const queryFn = () => get("/user/category/");

  const select = response => response?.data;

  const options = { select };

  return useQuery(queryKey, queryFn, options);
};
