import React from "react";
import styled from "styled-components";
import colors from "../../constants/colors";

const linkVariantStyles = {
  primary: {
    container: {
      background: "rgba(255, 255, 255, 0.08)",
      borderColor: "rgba(255, 255, 255, 0.16)",
      borderSize: "1px",
      borderStyle: "solid",
    },
    text: {
      color: "white",
    },
    icon: {
      width: "2em",
      height: "2em",
    },
  },
  secondary: {
    container: {
      background: colors.black,
      borderColor: "rgba(255, 255, 255, 0.16)",
      borderSize: "1px",
      borderStyle: "solid",
    },
    text: {
      color: "white",
    },
    icon: {
      width: "2em",
      height: "2em",
    },
  },
};

const EMPTY_VARIANT_STYLES = {
  container: {},
  text: {},
};

const DownloadLink = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  gap: 0.2em;
  border: 1px solid red;
  padding: 0.5em 0.8em;
  border-radius: 8px;

  .preamble-text {
    font-size: 0.8em;
  }
  .store-text {
    font-weight: bold;
    font-size: 1em;
  }

  p {
    margin: 0;
    white-space: nowrap !important;
  }
`;

const StyledLink = props => {
  const {
    icon: Icon,
    store,
    variant = "primary",
    appearance = "button",
    containerStyleOverrides = {},
    textStyleOverrides = {},
    iconStyleOverrides = {},
  } = props;

  const variantStyles = linkVariantStyles[variant] || EMPTY_VARIANT_STYLES;

  const containerDefaults = variantStyles.container || {};
  const textDefaults = variantStyles.text || {};
  const iconDefaults = variantStyles.icon || {};

  const containerStyle = {
    ...containerDefaults,
    ...containerStyleOverrides,
  };

  const textStyle = {
    ...textDefaults,
    ...textStyleOverrides,
  };

  const iconStyle = {
    ...iconDefaults,
    ...iconStyleOverrides,
  };

  return (
    <DownloadLink style={containerStyle} target={"_blank"} {...props}>
      <div className="flex">{<Icon style={iconStyle} />}</div>
      <div className="left-align grow flex flex-column">
        <p className="preamble-text" style={textStyle}>
          Download on the
        </p>
        <p className="store-text bold" style={textStyle}>
          {store}
        </p>
      </div>
    </DownloadLink>
  );
};

export default StyledLink;
