import toast from "react-hot-toast";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { delete_request, get, patch, post } from "../../api/transport";

const defaultOptions = {
  // refetchOnWindowFocus: false,
  refetchOnmount: false,
  refetchOnReconnect: false,
  retry: false,
  staleTime: 86400000,
};

export const useCompaniesAdmin = (offset = 0) => {
  const queryKey = ["company", offset];
  const queryFn = () => get(`/company/?limit=10&offset=${offset}`);
  const select = response => response?.data;
  const options = { select };
  return useQuery(queryKey, queryFn, options);
};
export const useCompanyAdmin = companyid => {
  const queryKey = "admin-use-company";
  const queryFn = () => get(`/admin/company/${companyid}`);
  const enabled = !!companyid;
  const select = response => response?.data;
  const options = { enabled, select };
  return useQuery(queryKey, queryFn, options);
};

export const useCompanySettings = companyid => {
  const queryKey = "admin-company-settings";
  const queryFn = () => get(`/admin/company/settings/${companyid}`);
  const enabled = !!companyid;
  const select = response => response?.data;
  const options = { enabled, select };
  return useQuery(queryKey, queryFn, options);
};

export const useUpdateCompanyAdmin = companyid => {
  const queryClient = useQueryClient();
  const queryFn = data => patch(`/company/${companyid}`);
  const onSuccess = () => queryClient.invalidateQueries();
  const options = { onSuccess };
  return useMutation(queryFn, options);
};

export const useCompanyBranches = companyid => {
  const queryKey = ["admin-company-branches", companyid];
  const queryFn = () => get(`/admin/company/branch/${companyid}`);
  const enabled = !!companyid;
  const select = response => response?.data;
  const options = { enabled, select };
  return useQuery(queryKey, queryFn, options);
};

export const useDeleteCompanyBranch = branchid => {
  const queryClient = useQueryClient();
  const queryFn = () => delete_request(`/user/branch/${branchid}/`);
  const onSuccess = () => queryClient.invalidateQueries();
  const options = { onSuccess };
  return useMutation(queryFn, options);
};

export const useUpdateCompanyBranch = branchid => {
  const queryClient = useQueryClient();
  const queryFn = data => patch(`/user/branch/${branchid}/`, data);
  const onSuccess = () => queryClient.invalidateQueries();
  const options = { onSuccess };
  return useMutation(queryFn, options);
};

export const useCreateCompanyBranch = branchid => {
  const queryClient = useQueryClient();
  const queryFn = data => post(`/user/branch/`, data);
  const onSuccess = () => queryClient.invalidateQueries();
  const options = { onSuccess };
  return useMutation(queryFn, options);
};

export const useCompanyBranchManagers = companyid => {
  const queryKey = ["admin-company-branch-managers", companyid];
  const queryFn = () => get(`/admin/company/branch-manager/${companyid}`);
  const enabled = !!companyid;
  const select = response => response?.data;
  const options = { enabled, select };
  return useQuery(queryKey, queryFn, options);
};

export const useUpdateCompanyBranchManager = branchManagerId => {
  const queryClient = useQueryClient();
  const queryFn = data => patch(`/users/staff/${branchManagerId}/`, data);
  const onSuccess = () => queryClient.invalidateQueries();
  const options = { onSuccess };
  return useMutation(queryFn, options);
};

export const useEmojiPacks = () => {
  const queryKey = "emoji-packs";
  const queryFn = () => get(`/admin/sentiment-settings/`);
  const select = response => response?.data?.results;

  const options = { select };

  return useQuery(queryKey, queryFn, options);
};

export const useAddEmojiPack = () => {
  const queryClient = useQueryClient();

  const queryKey = "emoji-packs";

  const queryFn = data => post(`/admin/sentiment-settings/`, data);

  const onSuccess = () => queryClient.invalidateQueries("emoji-packs");

  const options = { onSuccess };

  return useMutation(queryKey, queryFn, options);
};

export const useAddEmoji = () => {
  const queryClient = useQueryClient();

  const queryFn = data => post(`/admin/sentiment/`, data);

  const onSuccess = () => {
    queryClient.invalidateQueries();
    toast.success("Emoji Added Successfully");
  };

  const options = { onSuccess };

  return useMutation(queryFn, options);
};

export const useDeleteEmoji = () => {
  const queryClient = useQueryClient();

  const queryFn = id => delete_request(`/admin/sentiment/${id}/`);

  const onSuccess = () => {
    queryClient.invalidateQueries();
    toast.success("Deleted Successfully");
  };

  const options = { onSuccess };

  return useMutation(queryFn, options);
};

export const useDeleteEmojiPack = () => {
  const queryClient = useQueryClient();

  const queryFn = id => delete_request(`/admin/sentiment-settings/${id}/`);

  const onSuccess = () => {
    queryClient.invalidateQueries("emoji-packs");
    toast.success("Deleted Successfully");
  };

  const options = { onSuccess };

  return useMutation(queryFn, options);
};

export const useAdminTransactions = (
  orderby = "desc",
  filter_type = "",
  date_before = "",
  date_after = ""
) => {
  const queryKey = [
    "transaction-overview",
    orderby,
    filter_type,
    date_before,
    date_after,
  ];

  const queryFn = () =>
    get(
      `/admin/transaction/overview/?limit=&offset=&orderby=${orderby}&filter_type=${filter_type}&date_before=${date_before}&date_after=${date_after}`
    );

  const select = response => response;

  const options = { select, ...defaultOptions };

  return useQuery(queryKey, queryFn, options);
};

export const useAdminTransactionList = (
  orderby = "desc",
  limit = 10,
  offset = 0
) => {
  const queryKey = ["transaction-overview", orderby, limit, offset];

  const queryFn = () =>
    get(
      `/admin/transaction/?limit=${limit}&offset=${offset}&orderby=${orderby}`
    );

  const select = response => response.data;

  const options = { select, ...defaultOptions };

  return useQuery(queryKey, queryFn, options);
};

export const useIndustries = () => {
  const queryKey = "industries";

  const queryFn = () => get(`/industry/?limit=${50}&offset=${0}`);

  const select = response => response.data.results;

  const options = { select };

  return useQuery(queryKey, queryFn, options);
};

export const useCreateIndustry = () => {
  const queryClient = useQueryClient();

  const queryFn = data => post(`/industry/`, data);

  const onSuccess = () => queryClient.invalidateQueries("industries");

  const options = { onSuccess };

  return useMutation(queryFn, options);
};

export const useDeleteIndustry = () => {
  const queryClient = useQueryClient();

  const queryFn = id => delete_request(`/industry/${id}/`);

  const onSuccess = () => queryClient.invalidateQueries("industries");

  const options = { onSuccess };

  return useMutation(queryFn, options);
};
