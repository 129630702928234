import React, { useCallback, useEffect, useState } from "react";
import { Layout, PageTitle } from "../components/dashboard";
import { ReactComponent as Emoji } from "../assets/svgs/emoji-ellipsis.svg";
import { ReactComponent as BranchManagers } from "../assets/svgs/branch-managers-ellipsis.svg";
import { ReactComponent as Branches } from "../assets/svgs/branches-ellipsis.svg";
import { ReactComponent as Question } from "../assets/svgs/question-ellipsis.svg";
import { ReactComponent as Devices } from "../assets/svgs/devices-ellipsis.svg";
import { Card } from "../components/getting-started";
import { useAppContext } from "../context";
import { useHistory } from "react-router";
import { v4 } from "uuid";
import axios from "axios";
import toast from "react-hot-toast";
import errorHandler from "../selectors/errorHandler";
import { get } from "../api/transport";
import { DocumentHeader } from "../components/general/DocumentHeader";
import { useProfile } from "../components/getting-started/broker";

const RenderCards = ({ cards = [] }) => (
  <ul className="flex row-wrap align-start justify-start p-0 list-style-type-none gap-1">
    {cards.map(card => (
      <li key={v4()}>
        <Card {...card} />
      </li>
    ))}
  </ul>
);

const GettingStarted = () => {
  const { data: profileData, isLoading } = useProfile();
  const history = useHistory();

  const is_admin = profileData?.is_owner;

  const {
    added_branch,
    added_branch_manager,
    added_device,
    added_emojis,
    added_question,
    uploaded_logo,
  } = profileData?.company || {};

  const manager_cards = [
    {
      title: "Add a Question",
      subTitle: "Provide questions to ask users in order to give feedbacks.",
      Icon: Question,
      checked: added_question,
      primary: {
        text: "Add a Question",
        onClick: () =>
          history.push("/dashboard/questions", { openModal: true }),
        disabled: added_question,
      },
    },
    {
      title: "Add Devices",
      subTitle:
        "Devices added up here would be used to give feedbacks by customers.",
      Icon: Devices,
      checked: added_device,
      primary: {
        text: "Add Devices",
        onClick: () => history.push("/dashboard/devices", { openModal: true }),
        disabled: added_device,
      },
    },
  ];

  const cards = [
    {
      title: "Upload Logo",
      subTitle:
        "Upload logo, background image or select background colour, and choose a colour for text.",
      checked: uploaded_logo,
      primary: {
        text: "Upload Logo",
        onClick: () => history.push("/company/logo-background"),
        disabled: uploaded_logo,
      },
    },
    {
      title: "Add Emojis",
      subTitle:
        "You can add your company Emojis to match preferred brand style.",
      Icon: Emoji,
      checked: added_emojis,
      primary: {
        text: "Add Emojis",
        onClick: () => history.push("/company/emojis"),
        disabled: added_emojis,
      },
    },
    {
      title: "Add Company Branches",
      subTitle:
        "Let’s know all of your branches so as to assign devices and branch managers to each company branch.",
      checked: added_branch,
      primary: {
        text: "Add Company Branches",
        onClick: () => history.push("/company/branches", { openModal: true }),
        disabled: added_branch,
      },
    },
    {
      title: "Add Branch Managers",
      subTitle:
        "Branch managers would be assigned to their respective company branches.",
      Icon: BranchManagers,
      checked: added_branch_manager,
      primary: {
        text: "Add Branch Managers",
        onClick: () =>
          history.push("/company/branch-managers", { openModal: true }),
        disabled: added_branch_manager,
      },
    },
  ];

  let filtered_cards = is_admin
    ? [...cards, ...manager_cards]
    : [...manager_cards];

  return (
    <Layout>
      <DocumentHeader page="Getting Started" />
      <PageTitle
        title="Welcome to Echo!"
        subtitle="Let's have your account fully setup and get feedbacks coming in."
      />
      <RenderCards cards={filtered_cards} />
    </Layout>
  );
};

export { GettingStarted };
