import React from "react";
import styled from "styled-components";
import { ReactComponent as UploadEllipsis } from "../../assets/svgs/upload-ellipsis.svg";
import { ReactComponent as CheckEllipsis } from "../../assets/svgs/check-ellipsis.svg";
import Button from "./Button";
import { Colors } from "../../constants";

const Card = ({
  Icon = UploadEllipsis,
  title,
  subTitle,
  secondButton,
  primary,
  checked,
}) => {
  return (
    <StyledCard>
      <div>
        <div style={{ display: "flex", marginBottom: "1em" }}>
          <div style={{ position: "relative", flexShrink: 1 }}>
            <Icon />
            {checked && (
              <CheckEllipsis
                style={{ position: "absolute", right: 0, bottom: 0 }}
              />
            )}
          </div>
        </div>
      </div>

      <p style={{ marginBottom: "1em" }}>{title}</p>
      <div style={{ marginBottom: "1em", height: "5em" }}>
        <p className="card-subtitle">{subTitle}</p>
      </div>
      <div style={{ padding: ".5em 0" }}>
        <Button
          {...primary}
          onClick={() => {
            !checked && primary?.onClick();
          }}
        />
        {/* {secondButton && <Button text="Sec" alt={true} />} */}
      </div>
    </StyledCard>
  );
};

export default Card;

const StyledCard = styled.div`
  padding: 1em 1.5em;
  max-width: 200px;
  border: 1px solid #f5f3f3;
  border-radius: 8px;
  background: white;

  :hover {
    box-shadow: 0px 0px 0px 8px rgba(114, 0, 76, 0.08);
    border: 1px solid ${() => Colors.purple};
  }

  .card-subtitle {
    font-family: CircularStd;
    font-size: 0.9em;
    color: ${() => Colors.grey_2};
  }

  .card-title {
    color: ${() => Colors.black};
    font-family: CircularStdBold;
    font-weight: bold;
    font-size: 0.8em;
  }

  p {
    margin: 0;
  }
`;
