import { duration } from "moment";
import React, {
  createContext,
  useReducer,
  useContext,
  useState,
  useCallback,
  useEffect,
} from "react";

const SubscriptionContext = createContext({});

const sessionStorageKey = "echo_subscription";

const Provider = props => {
  const [subscription, setSubscription] = useState({
    min_number_tablet: 1,
    max_number_tablet: null,
    device_count: 1,
    unit_price: 0,
    duration: "",
  });

  const persistSubscription = useCallback(
    payload => {
      sessionStorage.setItem(sessionStorageKey, JSON.stringify(payload));
    },
    [sessionStorage]
  );

  const mutateSubscription = useCallback(
    payload => {
      const data = {
        ...subscription,
        ...payload,
      };

      setSubscription(data);

      persistSubscription(data);
    },
    [setSubscription, persistSubscription]
  );

  const getSubscription = useCallback(() => {
    return subscription;
  }, [subscription]);

  const restoreSubscription = useCallback(() => {
    const persistedSubscription = sessionStorage.getItem(sessionStorageKey);

    if (persistedSubscription) {
      return JSON.parse(persistedSubscription);
    }

    return {};
  }, [sessionStorage]);

  const saveSubscriptionToState = useCallback(() => {
    const subscriptionFromSession = restoreSubscription();

    const newSubscriptionsState = {
      ...subscriptionFromSession,
      ...subscription,
    };

    mutateSubscription(newSubscriptionsState);

    persistSubscription(newSubscriptionsState);
  }, [restoreSubscription, mutateSubscription, persistSubscription]);

  useEffect(() => {
    saveSubscriptionToState();
  }, [saveSubscriptionToState]);

  return (
    <SubscriptionContext.Provider
      value={{ getSubscription, mutateSubscription, persistSubscription }}
    >
      {props.children}
    </SubscriptionContext.Provider>
  );
};

const useCreateSubscriptionContext = () => useContext(SubscriptionContext);
export { SubscriptionContext, Provider, useCreateSubscriptionContext };
