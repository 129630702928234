import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { PageTitle } from "../components/account-creation";
import { GenericButton } from "../components/general/Button";
import { FullScreenPage } from "../components/general/FullScreenPage";
import { Sidebar } from "../components/login";
import validator from "validator";
import errorHandler from "../selectors/errorHandler";
import { post } from "../api/transport";
import Content from "../components/account-creation/Content";
import { DocumentHeader } from "../components/general/DocumentHeader";

const sendResetPasswordMail = data => {
  return post("/users/reset-password/", data)
    .then(response => response.data)
    .catch(error => errorHandler(error));
};

export const ResetPassword = () => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState([]);
  const [loading, set_loading] = useState(false);

  function validateEmail(emailArg) {
    if (emailArg.length === 0) {
      setEmail(emailArg);
      return setErrors(["Email field can not be left blank", ...errors]);
    }

    if (!emailArg.includes("@") || !validator.isEmail(emailArg)) {
      setEmail(emailArg);
      return setErrors(["Invalid Email", ...errors]);
    }

    setErrors([]);
    setEmail(emailArg);
  }

  const handleSubmit = async () => {
    set_loading(true);
    try {
      const result = await sendResetPasswordMail({
        email,
      });
      if (result && typeof Number(result) !== "number") {
        history.push("/link-sent/");
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      set_loading(false);
    }
  };

  const disabled =
    loading ||
    email.length === 0 ||
    Object.keys(errors).some(el => errors[el].length > 0);

  return (
    <FullScreenPage>
      <DocumentHeader page="Reset Password" />
      <div className="flex grow flex-row">
        <Sidebar />

        <Content>
          <p
            className="purple m-0 pointer bold"
            onClick={() => history.push("/login")}
          >
            <span>&larr;</span> Back to Login
          </p>
          <PageTitle
            title="Reset Password"
            subTitle={"Enter your email address to get a password reset link."}
          />
          <div className="row m-v-2">
            <div className="input-field">
              <label>Email Address</label>
              <input
                value={email}
                type="email"
                placeholder="Eg. someone@gmail.com"
                onChange={e => {
                  const val = e.target.value;
                  validateEmail(val);
                }}
              />
              {errors.length > 0 && <p className="error-msg">{errors[0]}</p>}
            </div>
          </div>
          <div className="bordered-0 m-v-1 flex flex-row justify-end">
            <GenericButton
              disabled={disabled}
              onClick={() => {
                validateEmail(email);
                handleSubmit();
              }}
            >
              <div className="flex justify-between align-center grow">
                <p className="m-0 white">
                  {loading ? "loading" : "Reset Password"}
                </p>
                <>&rarr;</>
              </div>
            </GenericButton>
          </div>
        </Content>
      </div>
    </FullScreenPage>
  );
};
