import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Table } from "../table";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { Colors } from "../../constants";
import { EmojiTextButton } from "../general";
import { ReactComponent as QR } from "../../assets/svgs/Qrcode.svg";
import Searchbar from "../dashboard/Searchbar";
import { ReactComponent as Arrow } from "../../assets/svgs/topright.svg";
import { ReactComponent as Plus } from "../../assets/svgs/plus.svg";
import { AddDeviceModal } from "../modal";
import { DeviceCount } from "./DeviceCount";
import DotsButton from "../general/DotsButton";
import { useAppContext } from "../../context";
import QRCodeModal from "../modal/QRCodeModal";
import toast from "react-hot-toast";
import EditDeviceModal from "../modal/EditDevice";
import { useDeleteDevice, useDevices, useNumberDevices } from "./broker";
import ConfirmModal from "../modal/ConfirmModal";
import { useBranches } from "../branches/broker";
import { useQuestions } from "../questions/broker";
import { useCategories } from "../categories/broker";
import Paginator from "../table/Paginator";

export const DevicesTable = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { data: branches = [] } = useBranches();
  const { data: questions = [] } = useQuestions();
  const { data: categories = [] } = useCategories();
  const { data: device_count } = useNumberDevices();
  const [search_term, set_search_term] = useState("");
  const { appState } = useAppContext();
  const [qr_code_modal_open, set_qr_code_modal_open] = useState(false);
  const [device, set_device] = useState();
  const history = useHistory();

  const addDeviceOpen = useMemo(() => {
    return modalIsOpen;
  }, [modalIsOpen]);

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);

  const profile = appState?.profile;
  const { uploaded_logo } = profile?.company || {};

  const { data, isLoading } = useDevices(limit, offset, search_term);

  let devices = data?.results || [];

  const tableData = useMemo(() => devices, [data, search_term, limit, offset]);

  const canCreateDevice = useCallback(() => {
    if (typeof device_count === "number") {
      return devices.length < device_count;
    }
    return false;
  }, [devices, device_count]);

  const COLUMNS = [
    {
      Header: "DEVICE",
      accessor: "name",
    },
    {
      Header: "BRANCH",
      accessor: "location",
      Cell: data => {
        const { location } = data?.row?.original;
        return `${location.map(el => ` ${el.name}`)}`;
      },
    },
    {
      Header: "DATE ADDED",
      accessor: "created_at",
      Cell: ({ value }) => moment(value).format("MMM D, YYYY hh:mm a"),
    },
    {
      Header: "SENTIMENTS",
      accessor: "total_sentiment",
      Cell: ({ value }) => <p className="right-align">{value}</p>,
    },
    {
      Header: () => <p className="center-align">QR CODE</p>,
      id: "qr_code_column",
      accessor: "pass_code",
      Cell: data => {
        const device = data?.row?.original;
        return (
          <EmojiTextButton
            onClick={() => {
              set_device(device);
              set_qr_code_modal_open(true);
            }}
            text="Get QR Code"
            IconProp={QR}
            styleProp={{
              background: Colors.purple,
              color: Colors.white,
              padding: ".8em 2em",
            }}
          />
        );
      },
    },
    {
      Header: "PASSCODE",
      accessor: "pass_code",
    },
    {
      Header: "ACTIVE QUESTION",
      accessor: "question.question",
    },
    {
      Header: "TIME LEFT FOR QUESTION",
      accessor: "question_end_date",
      Cell: ({ value }) => {
        return moment(value).isSameOrBefore(moment(), "minute")
          ? "Expired"
          : `${value ? moment(value).fromNow(true) : ""}`;
      },
    },
    {
      id: "action",
      Cell: data => {
        const tablet = data?.row?.original;
        const { id } = tablet;
        const [open, setOpen] = useState(false);

        const updateDeviceOpen = useMemo(() => {
          return open;
        }, [open]);

        const { mutateAsync, isLoading: deleteLoading } = useDeleteDevice(id);

        const deleteDevice = async () => {
          const result = await ConfirmModal.show({
            text: "Are you sure you want to delete this device?",
          });
          if (result) {
            toast.loading("Please wait");
            await mutateAsync();
          }
        };

        function editDevice() {
          setOpen(true);
        }

        return (
          <>
            <DotsButton
              deletefunction={deleteDevice}
              editfunction={editDevice}
            />
            <EditDeviceModal
              isOpen={updateDeviceOpen}
              closeModal={() => setOpen(false)}
              primaryButtonText={"Save"}
              devices={devices}
              device={tablet}
              questions={questions}
              branches={branches}
              categories={categories}
            />
          </>
        );
      },
    },
  ];

  return (
    <div
      className="flex grow flex-column"
      style={{ maxHeight: "90vh", overflowY: "auto" }}
    >
      <div className="flex justify-between align-center p-v-1">
        <div className="flex align-center justify-between">
          <h4 style={{ margin: 0 }}>Devices</h4>
          <DeviceCount
            number_tablet={useMemo(() => device_count, [device_count])}
            count={useMemo(() => devices.length, [devices])}
          />
        </div>

        <div>
          <Searchbar term={search_term} filter={set_search_term} />
        </div>

        <div className="flex align-center justify-between">
          <EmojiTextButton
            text="Deploy Question"
            IconProp={Arrow}
            styleProp={{
              background: Colors.black,
              color: Colors.white,
              padding: ".8em 2em",
            }}
            onClick={() => history.push("/dashboard/questions")}
          />

          <EmojiTextButton
            text="Add a Device"
            IconProp={Plus}
            styleProp={{
              background: Colors.purple,
              color: Colors.white,
              padding: ".8em 2em",
              marginLeft: ".5em",
            }}
            disabled={!canCreateDevice()}
            onClick={() => {
              if (!uploaded_logo) {
                toast.error("Create device settings before creating a device");
                toast.loading("Redirecting to tablet settings page");
                return setTimeout(() => {
                  history.push("/company/logo-background");
                }, 2000);
              }
              canCreateDevice() && setModalIsOpen(true);
            }}
          />
        </div>
      </div>
      <div className="flex flex-column grow m-t-1 bg-w">
        <Table columns={COLUMNS} data={tableData} loading={isLoading} />

        <Paginator
          limit={limit}
          offset={offset}
          count={data?.count}
          setOffset={setOffset}
        />

        <AddDeviceModal
          isOpen={addDeviceOpen}
          closeModal={() => setModalIsOpen(false)}
          primaryButtonText={"Continue"}
          devices={devices}
          questions={questions}
          branches={branches}
          categories={categories}
        />

        <QRCodeModal
          isOpen={qr_code_modal_open}
          closeModal={() => set_qr_code_modal_open(false)}
          device={device}
          setDevice={set_device}
        />
      </div>
    </div>
  );
};
