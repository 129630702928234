import React, { useEffect, useMemo, useState, memo, useCallback } from "react";
import styled from "styled-components";
import { Colors } from "../../../constants";
import { useAppContext } from "../../../context";
import { determineTotalPrice } from "../../../selectors";

const Summary = ({ subscription, setSubscription, coupon }) => {
  const exchange_rate = process.env.REACT_APP_USD_CEDI_EXCHANGE_RATE;

  const totalPrice = useMemo(() => {
    const { type = "Fixed", fixed_price = 0, percent_off = "" } = coupon;

    if (type === "Fixed") {
      const discounted_amount = fixed_price;

      return determineTotalPrice(subscription, discounted_amount);
    }

    if (type === "Percentage") {
      const discounted_amount =
        (percent_off / 100) * determineTotalPrice(subscription);

      return determineTotalPrice(subscription, discounted_amount);
    }
  }, [determineTotalPrice, subscription, coupon]);

  const finalCouponAmount = useMemo(() => {
    const { type = "Fixed", fixed_price = 0, percent_off = "" } = coupon;

    if (type === "Fixed") {
      return fixed_price;
    }

    if (type === "Percentage") {
      const percentage = Number(percent_off) / 100;
      const new_amount = percentage * determineTotalPrice(subscription);
      return new_amount;
    }
  }, [coupon, determineTotalPrice, coupon]);

  return (
    <StyledSummary>
      <h4>Subscription Summary</h4>
      <div className="summary-body">
        <div className="summary-row">
          <p className="category">Number of Tablets</p>
          <p className="data">{subscription.device_count}</p>
        </div>
        <div className="summary-row">
          <p className="category">Duration</p>
          <p className="data">1 {subscription.duration}</p>
        </div>
        <div className="summary-row">
          <p className="category">Discount</p>
          <p className="data">
            - ${(finalCouponAmount * exchange_rate)?.toFixed(2)}
          </p>
        </div>
        <div className="summary-row">
          <p className="category">Total to Pay Now</p>
          <p className="data">${(totalPrice * exchange_rate).toFixed(2)}</p>
        </div>
      </div>
    </StyledSummary>
  );
};

export default Summary;

const StyledSummary = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  border: 1px solid red;
  background: #ffffff;
  border: 1px solid #d9dae4;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 0 1em;

  h4,
  p {
    margin: 0;
  }

  h4 {
    margin: 1.5em 0 1em 0;
  }

  .summary-body {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex-grow: 1;

    .summary-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #d9dae4;
      padding: 1em 0;

      .category,
      .data {
        font-size: 0.9em;
      }

      .category {
        color: ${props => Colors.grey_2};
      }

      .data {
        color: ${props => Colors.black_1};
      }
    }

    .summary-row:last-child {
      border: 0;
    }
  }
`;
