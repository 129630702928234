import React from "react";
import styled from "styled-components";

const Sidebar = ({ children }) => {
  return <StyledSidebar>{children}</StyledSidebar>;
};

export default Sidebar;

const StyledSidebar = styled.div`
  flex: 0.32;
  display: flex;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  position: relative;
  z-index: 0;
`;
