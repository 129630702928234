import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Modal from "react-modal";
import { Colors } from "../../constants";
import { ReactComponent as Close } from "../../assets/svgs/close.svg";
import { TextButton } from "../general";
import ModalTitle from "./ModalTitle";
import axios from "axios";
import { useAppContext } from "../../context";
import { useOutsideDetect } from "../../selectors/UseOutsideDetect";
import ModalFormStyleSheet from "../modal/ModalFormStyleSheet";
import moment from "moment";
import toast from "react-hot-toast";
import DropdownLoader from "./DropdownLoader";
import errorHandler from "../../selectors/errorHandler";
import { post } from "../../api/transport";
import { useCreateBranch } from "../branches/broker";
import { useCreateQuestion } from "../questions/broker";

const AddQuestionModal = props => {
  const styles = {
    buttonStyles: {
      padding: ".5em",
      color: Colors.white,
      background: Colors.purple,
    },
    modalStyles: {
      overlay: {
        background: "rgba(0,0,0,0.7)",
      },
      content: {
        background: Colors.white,
        margin: "auto auto",
        width: "30%",
        height: "40%",
      },
    },
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) Modal.setAppElement("body");
    return () => (mounted = false);
  }, []);

  const {
    categories,
    // setCategories: set_categories,
    branches,
    // setBranches,
  } = props;
  const [branchSelection, setBranchSelection] = useState("existing");
  // const [branches, setBranches] = useState(props.branches);
  const [selectedBranches, setSelectedBranches] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const { fetchToken, appState } = useAppContext();
  const [loading, setLoading] = useState();
  const dropdownRef = useRef();

  const [question, set_question] = useState("");
  const [new_branch_name, set_new_branch_name] = useState("");
  const [new_branch_location, set_new_branch_location] = useState("");
  const [save_loading, set_save_loading] = useState(false);

  const category_dropdown_ref = useRef();
  // const [categories, set_categories] = useState([]);
  const [selected_categories, set_selected_categories] = useState([]);
  const [show_category_dropdown, set_show_category_dropdown] = useState(false);
  const [category_fetch_loading, set_category_fetch_loading] = useState(false);

  const { mutateAsync: createBranch, isLoading: createBranchLoading } =
    useCreateBranch();
  const { mutateAsync: createQuestion, isLoading: createQuestionLoading } =
    useCreateQuestion();

  function clearSelection() {
    set_question("");
    setSelectedBranches([]);
    set_new_branch_name("");
    set_selected_categories([]);
    set_new_branch_location("");
  }

  useOutsideDetect(dropdownRef, () => setShowDropdown(false));

  const profile = appState?.profile || {};
  const is_admin = profile?.is_owner || profile?.is_superuser;

  return (
    <Modal
      style={styles.modalStyles}
      isOpen={props.isOpen}
      onRequestClose={props.closeModal}
    >
      <AddQuestionModalContent>
        <div className="close-button-area">
          <Close id="close-button" onClick={props.closeModal} />
        </div>

        <div className="flex grow flex-column">
          <ModalTitle
            title="Add a Question"
            subTitle="This question will be later set to display on the specified devices."
          />

          <ModalFormStyleSheet>
            <div className="row">
              <div className="input-field">
                <div className="label-container">
                  <label>Enter Question</label>
                  <p
                    onClick={toggleBranchSelection}
                    style={{
                      fontSize: ".6em",
                      color: Colors.grey_2,
                    }}
                  >
                    {question.length}/60
                  </p>
                </div>
                <input
                  type="text"
                  placeholder="Eg. How was your experience with us?"
                  value={question}
                  onChange={e =>
                    question.length < 60 && set_question(e.target.value)
                  }
                />
              </div>
            </div>

            <div>
              <div
                className="row relative bordered-0"
                ref={category_dropdown_ref}
              >
                <div className="input-field">
                  <label>Enter Categories (optional)</label>
                  <div
                    onClick={() =>
                      set_show_category_dropdown(!show_category_dropdown)
                    }
                    className="pseudo-input gray flex justify-between align-center pointer "
                  >
                    <p>
                      {selected_categories.length > 0
                        ? `${
                            selected_categories.length
                          } selected | ${selected_categories.map(
                            el => ` ${el.title}`
                          )}`
                        : "Select categories"}
                    </p>
                    <span className="pointer">▾</span>
                  </div>
                </div>

                <div
                  className="dropdown bordered absolute bg-w p-t-1"
                  style={{
                    display: show_category_dropdown ? "block" : "none",
                    top: "110%",
                    width: "99.5%",
                  }}
                >
                  {category_fetch_loading && <DropdownLoader />}

                  {categories?.map(category => {
                    const toggle_category_select = () => {
                      selected_categories.find(el => el.uuid === category.uuid)
                        ? set_selected_categories(
                            selected_categories.filter(
                              el => el.id !== category.id
                            )
                          )
                        : set_selected_categories([
                            ...selected_categories,
                            category,
                          ]);
                    };

                    return (
                      <div
                        key={Math.random()}
                        className="m-b-1 flex align-center pointer"
                        onClick={toggle_category_select}
                      >
                        <div className="checkbox-wrapper">
                          <input
                            type="checkbox"
                            id={category.uuid}
                            name={category.uuid}
                            checked={selected_categories.find(
                              el => el.uuid === category.uuid
                            )}
                            className="m-h-1"
                            onChange={toggle_category_select}
                          />
                        </div>
                        <p className="m-0 gray-2">{category.title}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            {is_admin && (
              <div className="row">
                <div className="input-field">
                  <div className="label-container">
                    <label>Select Branch</label>
                    <p
                      onClick={toggleBranchSelection}
                      style={{
                        fontSize: ".8em",
                        color: Colors.purple,
                        cursor: "pointer",
                      }}
                    >
                      {branchSelection === "existing" && "+ Add a New Branch"}
                      {branchSelection === "new" && "Select an existing branch"}
                    </p>
                  </div>
                  {branchSelection === "existing" && (
                    <div className="relative bordered-0" ref={dropdownRef}>
                      <div className="input-field">
                        <div
                          onClick={() => setShowDropdown(!showDropdown)}
                          className="pseudo-input gray flex justify-between align-center pointer "
                        >
                          <p>
                            {selectedBranches.length > 0
                              ? `${
                                  selectedBranches.length
                                } selected | ${selectedBranches.map(
                                  (el, idx) => {
                                    return ` ${el.name}`;
                                  }
                                )}`
                              : "You can select multiple branches"}
                          </p>
                          <span className="pointer">▾</span>
                        </div>
                        <p className="meta">
                          * This question will be available to be deployed on
                          devices across selected branches only
                        </p>
                      </div>
                      <div
                        className="dropdown bordered absolute bg-w p-t-1"
                        style={{
                          display: showDropdown ? "block" : "none",
                          top: "75%",
                          width: "99.5%",
                        }}
                      >
                        {loading && <DropdownLoader />}
                        {!loading && branches?.length !== 0 && (
                          <div className="m-b-1 flex align-center">
                            <div className="checkbox-wrapper">
                              <input
                                type="checkbox"
                                id={"all-branches"}
                                name={"all-branches"}
                                checked={
                                  selectedBranches.length === branches?.length
                                }
                                className="m-h-1"
                                onChange={event => {
                                  if (!event.target.checked) {
                                    return setSelectedBranches([]);
                                  }
                                  setSelectedBranches(branches?.results);
                                }}
                              />
                            </div>
                            <p className="m-0 gray-2">All Branches</p>
                          </div>
                        )}

                        {branches?.results?.map(branch => (
                          <div
                            key={Math.random()}
                            className="m-b-1 flex align-center"
                          >
                            <div className="checkbox-wrapper">
                              <input
                                type="checkbox"
                                id={branch.uuid}
                                name={branch.uuid}
                                checked={selectedBranches?.find(
                                  el => el.uuid === branch.uuid
                                )}
                                className="m-h-1"
                                onChange={() => selectBranch(branch)}
                              />
                            </div>
                            <p className="m-0 gray-2">
                              {branch.name} | {branch.location_name}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  {branchSelection === "new" && (
                    <div className="row">
                      <div className="input-field">
                        <div className="label-container">
                          <label>Branch Name</label>
                        </div>
                        <input
                          value={new_branch_name}
                          onChange={e => set_new_branch_name(e.target.value)}
                          type="text"
                          placeholder="Eg. Greater Accra"
                        />
                      </div>
                      <div className="input-field">
                        <div className="label-container">
                          <label>Location</label>
                        </div>
                        <input
                          type="text"
                          placeholder="Eg. Agyia Zongo, Accra, Ghana"
                          value={new_branch_location}
                          onChange={e =>
                            set_new_branch_location(e.target.value)
                          }
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </ModalFormStyleSheet>

          <div className="flex justify-center align-center m-0 p-v-1">
            <TextButton
              text="Continue"
              disabled={false}
              loading={save_loading}
              onClick={() => {
                if (branchSelection === "new") {
                  saveQuestionNewBranch();
                } else {
                  saveQuestionSelectedBranch();
                }
              }}
              styleProp={styles.buttonStyles}
            />
          </div>
        </div>
      </AddQuestionModalContent>
    </Modal>
  );

  function toggleBranchSelection() {
    if (branchSelection === "new") {
      setBranchSelection("existing");
    } else {
      setBranchSelection("new");
    }
  }

  function selectBranch(branch) {
    if (selectedBranches?.find(el => el.uuid === branch.uuid)) {
      setSelectedBranches(
        selectedBranches?.filter(el => el.uuid !== branch.uuid)
      );
    } else {
      setSelectedBranches([...selectedBranches, branch]);
    }
  }

  function saveQuestionSelectedBranch() {
    // set_save_loading(true);
    toast.loading("Please wait");
    const location = selectedBranches.map(el => el.id);
    const data = {
      is_active: true,
      question,
      status: "public",
      start_date: moment(),
      end_date: moment(),
      location: is_admin ? location : [profile?.branch?.id],
      categories: selected_categories?.map(el => el.id),
      company: appState?.profile?.company?.id,
    };

    // post("/user/question-settings/", data)
    createQuestion(data)
      .then(response => {
        // set_save_loading(false);
        // const newSet = [...props.questions, response.data];
        // props.setQuestions(newSet);
        toast.success("Saved");
        clearSelection();
        props.closeModal();
      })
      .catch(error => {
        errorHandler(error);
      });
  }

  function saveQuestionNewBranch() {
    // set_save_loading(true);
    toast.loading("Please wait");

    const data = {
      is_active: true,
      name: new_branch_name,
      location_name: new_branch_location,
      description: "",
      status: "Approved",
      company: appState?.profile?.company?.id,
    };

    // post("/user/branch/", data)
    createBranch(data)
      .then(response => {
        const post_data = {
          is_active: true,
          question,
          status: "public",
          start_date: moment(),
          end_date: moment(),
          location: [response.data.id],
          categories: selected_categories?.map(el => el.id),
          company: appState?.profile?.company?.id,
        };

        createQuestion(post_data).then(response => {
          // set_save_loading(false);
          // console.log(response);
          // const newSet = [...props.questions, response.data];
          // props.setQuestions(newSet);
          toast.success("Saved");
          clearSelection();
          props.closeModal();
        });
      })
      .catch(error => {
        // set_save_loading(false);
        errorHandler(error);
      });
  }
};

export default AddQuestionModal;

const AddQuestionModalContent = styled.div`
  // border: 1px solid;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .close-button-area {
    display: flex;
    flex-direction: row-reverse;
    // ;

    #close-button {
      // border: 1px solid;
      height: 0.5em;
      width: 0.5em;
      cursor: pointer;
    }
  }
`;
