import React from "react";
import styled from "styled-components";
import echo from "../../assets/pngs/echo-logo.png";
import background from "../../assets/pngs/gray-generic.png";

export const SuperAdminUploadField = props => {
  const { logo_image, background_image, bg_color, is_bg } = props;

  console.log(props);

  return (
    <StyledUploadField>
      <div
        className="flex flex-column justify-center align-center full-width-percent grow r-8 relative"
        style={{
          border: "1px dashed" + "#D9DAE4",
          backgroundImage: is_bg && background_image,
          backgroundSize: "cover",
          backgroundColor: (is_bg && bg_color) || "#FBF9F9",
        }}
      >
        {is_bg || (
          <img
            style={{
              width: "6em",
              height: "6em",
              objectFit: "cover",
            }}
            src={logo_image || echo}
            className="circle"
          />
        )}
      </div>
      {/* )} */}
    </StyledUploadField>
  );
};

const StyledUploadField = styled.div`
  // border: 1px solid red;
  min-height: 8em;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5em;
  position: relative;

  .upload-button {
    width: 4em;
    height: 4em;
  }

  .remove-button {
  }
`;
