import React from "react";
import { BranchesTable } from "../components/branches/BranchesTable";
import { Layout } from "../components/dashboard";
import { DocumentHeader } from "../components/general/DocumentHeader";

const Branches = props => {
  // console.log(props);
  const { openModal } = props.location?.state || {};
  return (
    <>
      <DocumentHeader page="Branches" />
      <Layout>
        <BranchesTable openModal={openModal} />
      </Layout>
    </>
  );
};

export { Branches };
