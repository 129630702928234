import React from "react";
import styled from "styled-components";
import { Content } from "../../components/account-creation/make-payment";
import Sidebar from "../../components/account-creation/Sidebar";
import { DocumentHeader } from "../../components/general/DocumentHeader";

const Wrapper = () => {
  return (
    <StyledWrapper>
      <DocumentHeader page="Onboarding" />
      <Sidebar currentStep={4} />
      <Content currentStep={4} />
    </StyledWrapper>
  );
};

export default Wrapper;

const StyledWrapper = styled.div`
  display: flex;
  flex: 1;

  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
`;
