import theme from "../theme";

const getBorderColor = (state, isError) => {
  if (isError) {
    return theme.colors.red[400];
  }

  if (state.isFocused) {
    return theme.colors.primary;
  }

  return "none";
};

export const selectStyles = isError => {
  return {
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderColor: getBorderColor(state, isError),
      outline: getBorderColor(state, isError),
      backgroundColor: "transparent",
      borderRadius: "8px",
      padding: "4px 12px",
    }),
    valueContainer: baseStyles => ({
      ...baseStyles,
      padding: 0,
    }),
    input: baseStyles => ({
      ...baseStyles,
      margin: 0,
      padding: 0,
    }),
    indicatorsContainer: baseStyles => ({
      ...baseStyles,
      margin: 0,
      padding: 0,
    }),
    indicatorSeparator: _baseStyles => ({
      display: "none",
    }),
    placeholder: baseStyles => ({
      ...baseStyles,
      color: theme.colors.gray[500],
    }),
    indicatorContainer: baseStyles => ({
      ...baseStyles,
      margin: 0,
      padding: 0,
    }),
    menu: baseStyles => ({
      ...baseStyles,
      border: "1px solid #D9DAE4",
    }),
  };
};

export const mockoptions = [
  { value: 1, label: "Monthly" },
  { value: 2, label: "Yearly" },
];
