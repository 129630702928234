import React from "react";
import styled from "styled-components";
import { Colors } from "../../constants/";
import { ReactComponent as Happy } from "../../assets/svgs/happy.svg";
import {
  BarChart,
  ResponsiveContainer,
  Bar,
  XAxis,
  CartesianGrid,
  YAxis,
  Tooltip,
  Legend,
} from "recharts";
import _ from "lodash";
import moment from "moment";
import { Loader } from "../general";

const Feedbacks = ({ data, title, loading, emoji: Emoji }) => {
  const data_count = data
    .map(el => el.sentiment_count)
    .reduce((sum, value) => sum + value, 0);
  const is_empty = _.isEmpty(data);

  return (
    <StyledFeedbacks>
      <div className="flex p-1">
        <div className="flex grow flex-column bordered-0">
          <div>
            <p className="gray-2">{title}</p>
          </div>
          <div>
            <p className="bold" style={{ fontSize: "24px" }}>
              {data_count}
            </p>
          </div>
        </div>
        <div className="flex flex-1 bordered-0 justify-end align-start">
          <img
            src={Emoji}
            style={{ width: "32px", height: "32px" }}
            alt="emoji"
          />
        </div>
      </div>

      {loading ? <Loader /> : is_empty || <BarChartSection data={data} />}
      {loading ||
        (is_empty && (
          <div className="grow flex justify-center align-center gray-2">
            <p className="m-0">No data</p>
          </div>
        ))}
    </StyledFeedbacks>
  );
};

export default Feedbacks;

const StyledFeedbacks = styled.div`
  width: 300px;
  height: 300px;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 10.1372px;
  display: flex;
  flex-direction: column;
  border: 1px solid #f5f3f3;
  margin-right: 10px;
  margin-bottom: 10px;

  p {
    margin: 0;
  }
`;

function BarChartSection({ data = [] }) {
  return (
    <StyledBarChartSection>
      <ResponsiveContainer width={"100%"} height={"90%"}>
        <BarChart
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          width={"100%"}
          height={"100%"}
          data={data}
          barSize={5}
          outerRadius={50}
          innerRadius={50}
          style={{ borderRadius: "8px" }}
          shape={() => <div className="bg-purple"></div>}
        >
          <XAxis tickLine={false} dataKey="date" style={{ fontSize: "8px" }} />
          <Tooltip content={CustomTooltip} />
          <Tooltip />
          <Bar
            dataKey="sentiment_count"
            fill={Colors.purple}
            background={{ fill: "#FBF9F9" }}
            width="1em"
          />
          <Bar dataKey="uv" fill="#82ca9d" />
        </BarChart>
      </ResponsiveContainer>
    </StyledBarChartSection>
  );
}

function CustomTooltip({ active, payload, label }) {
  const { payload: payload_data } = payload[0] || {};

  // console.log(payload, "payload");
  if (active) {
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: Colors.purple,
          color: Colors.white,
          padding: "5px",
          border: "1px solid #cccc",
          borderRadius: "4px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <label>
          {`${payload_data?.date} - ${payload_data?.sentiment_count} Feedback(s)` ||
            "value"}
        </label>
      </div>
    );
  }

  return null;
}

const StyledBarChartSection = styled.div`
  //   border: 1px solid;
  display: flex;
  flex-grow: 1;
  padding: 20px 15px 0 15px;
`;
