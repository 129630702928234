import React from "react";
import { useHistory } from "react-router-dom";
import { SuccessPage } from "../components/account-creation/SuccessPage";
import { DocumentHeader } from "../components/general/DocumentHeader";

export const LinkSent = () => {
  const history = useHistory();
  return (
    <>
      <DocumentHeader page="Success" />
      <SuccessPage
        title="Link Sent"
        subtext={`A password reset link has been sent to your email address. \n
      Follow the link in your email to reset your password.`}
        button={{
          children: (
            <div className="flex justify-center align-center grow">
              <span className="p-r-1">&larr;</span>
              <p className="m-0 white">Back to Login</p>
            </div>
          ),
          onClick: () => history.push("/login"),
          loading: false,
        }}
      />
    </>
  );
};
