import React from "react";
import { CompaniesTable, CompanyTabs } from "../../components/companies";
import { Layout } from "../../components/dashboard";
import { SuperAdminDevicesTable } from "../../components/devices/AdminDevicesTable";
import { DocumentHeader } from "../../components/general/DocumentHeader";

const Devices = () => {
  return (
    <Layout tabs={CompanyTabs}>
      <DocumentHeader page="Devices" />
      <SuperAdminDevicesTable />
    </Layout>
  );
};

export default Devices;
