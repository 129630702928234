import { useQuery } from "react-query";
import { get } from "../../../api/transport";

const defaultOptions = {
  // refetchOnWindowFocus: false,
  refetchOnmount: false,
  refetchOnReconnect: false,
  retry: false,
  staleTime: 86400000,
};

export const useSubscriptionPackages = () => {
  const queryKey = "subscription-packages";
  const queryFn = () => get("/subscription/");

  const select = response => response?.data?.results;

  const onSuccess = response => {};

  const options = { select, onSuccess, ...defaultOptions };

  return useQuery(queryKey, queryFn, options);
};
